import { useAsync } from 'react-async';
import { getHotelDmcRoom } from 'api/hotels';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RoomItem from './RoomItem';
import HotelBookedSummary from './HotelBookedSummary';
import { useBooking, useExperienceBooking } from 'hooks';
import { Stack } from '@koob/margaret';
import { Text } from 'ui';
import { Button, Modal } from 'components';
import { cloneDeep, omit } from 'lodash';
import KoobPlaceholder from '../../Koob/KoobPlaceholder';
import { KOEDIA } from '../../../constants';
import KoobLoaderText from '@koob/ui/src/components/KoobLoaderText';
import formatHotelRoomPossibilities from '@koob/checkout/src/utilities/formatHotelRoomPossibilities';

const getRoomsAvailable = async ({ id, filters }) => {
  return await getHotelDmcRoom({ id, filters });
};

const replaceIdentifier = (id, newIdentifier) => {
  return id.replace(/\+(\d+)\+/, `+${newIdentifier}+`);
};

export const getCorrectedRooms = (selectedRooms, currentSelectedRoom) => {
  const isKoediaSupplier = selectedRooms?.[0]?.supplier?.toUpperCase() === KOEDIA;
  const lastRoomID = currentSelectedRoom?.bed?.id;
  const match = lastRoomID.match(/\+(\d+)\+/);
  const newIdentifier = match ? match[1] : null;

  return isKoediaSupplier
    ? selectedRooms?.map(room => {
      return {
        ...room,
        bed: {
          ...room.bed,
          id: replaceIdentifier(
            room?.bed?.id,
            newIdentifier,
          )
        }
      };
    })
    : selectedRooms;
}

const RoomCompositionContent = ({
                                  isLoading,
                                  selectedRooms,
                                  availableRooms,
                                  deleteRoom,
                                  hotel,
                                  currentIndex,
                                  handleRoomIndexChange,
                                  filters,
                                  totalRoomNumber,
                                  currentSelectedRoom,
                                  validateRoom,
                                  isOtherProposal,
                                  selectedRoomsFromPromotionSelect,
                                  requestId,
                                  promoList
                                }) => {
  const { t } = useTranslation('booking');

  const [selectedPromotionSupplements, setSelectedPromotionSupplements] = useState({});

  return (
    <div className="max-w-7xl mx-auto grid grid-cols-3 gap-5 items-start">
      <div className="col-span-2">
        <div className="flex-col space-y-5">
          {isLoading && (
            <>
              <KoobLoaderText
                title={t('loadingRooms.title')}
                description={t('loadingRooms.description')}
              />
              <KoobPlaceholder className="h-40 w-full rounded-lg" />
              <KoobPlaceholder className="h-40 w-full rounded-lg" />
              <KoobPlaceholder className="h-40 w-full rounded-lg" />
            </>
          )}

          {!isLoading && selectedRooms.length === 0 && (!availableRooms || availableRooms.length === 0) && (
            <div className="text-center text-gray-400">
              <i className="far fa-empty-set fa-2x" />

              <div className="mt-5">
                {t("noPossibilities")}
              </div>
            </div>
          )}

          {!isOtherProposal && selectedRooms?.length > 0 && (
            selectedRooms.map((room, selectedRoomIndex) => (
              <RoomItem
                key={selectedRoomIndex}
                possibility={room}
                isSelected
                isRoomSelected
                filters={filters}
                deleteRoom={() => deleteRoom(selectedRoomIndex)}
                promotions={promoList}
                setSelectedPromotionSupplements={setSelectedPromotionSupplements}
                selectedPromotionSupplements={selectedPromotionSupplements}
              />
            ))
          )}

          {!isOtherProposal && selectedRooms?.length > 0 && (
            <hr />
          )}

          {availableRooms?.length > 0 && (
            availableRooms.map((possibility, possibilityIndex) => {
              return (
                <RoomItem
                  key={possibilityIndex}
                  possibility={possibility}
                  selectedPossibilityIndex={currentIndex}
                  isSelected={
                    currentIndex !== undefined && (
                      currentIndex === possibility.originalIndex
                      || possibility.variants?.map(variant => variant.originalIndex).includes(currentIndex)
                    )
                  }
                  handleSelect={(possibilityIndex, bedIndex = 0) => {
                    const newPossibility =
                      possibility.variants?.find(variant => variant.originalIndex === possibilityIndex) ?? possibility;
                    handleRoomIndexChange(possibilityIndex, bedIndex, newPossibility);
                  }}
                  filters={filters}
                  promotions={promoList}
                  selectedRoomsFromPromotionSelect={selectedRoomsFromPromotionSelect}
                  setSelectedPromotionSupplements={setSelectedPromotionSupplements}
                  selectedPromotionSupplements={selectedPromotionSupplements}
                />
              );
            })
          )}
        </div>
      </div>

      {!isOtherProposal && (
        <div className="sticky top-[120px] bg-red-50">
          <HotelBookedSummary
            startDate={filters.startAt}
            endDate={filters.endAt}
            hotel={hotel}
            isInfo={false}
            rooms={selectedRooms}
            roomsFilter={filters?.rooms}
            totalRoomNumber={totalRoomNumber}
            requestId= {requestId}
            step={1}
            disabledButton={!Boolean(currentSelectedRoom) || currentIndex === undefined}
            handleNextStep={validateRoom}
          />
        </div>
      )}
    </div>
  );
};

const HotelRoomComposition = ({
                                goInfosPage,
                                hotel,
                                booking,
                                setIsOpen,
                                filters: updateFilters,
                                setFieldValue,
                                selectedRoomIndex,
                                setRequestId: setEditRoomRequestId
                              }) => {
  const { t } = useTranslation("booking");
  const {
    filters: createFilters,
    setCurrentSelectedRoom,
    currentSelectedRoom,
    selectedRooms,
    setSelectedRooms,
    setRequestId,
    setExpirationDate,
    selectedSupplements,
  } = useBooking();
  const { basket, setBasket } = useExperienceBooking();

  const filters = updateFilters ?? createFilters;
  const { rooms } = filters;
  const [currentIndex, setCurrentIndex] = useState(undefined);
  const [possibilities, setPossibilities] = useState([]);
  const [initialPossibilities, setInitialPossibilities] = useState([]);
  const [bedCompositions, setBedCompositions] = useState(Array(rooms?.length));
  const { hotelId } = useParams();

  const totalRoomNumber = rooms?.length;
  const [newBedroom, setNewBedroom] = useState();

  const handleRoomIndexChange = (index, bedIndex, possibility) => {
    if (booking) {
      setNewBedroom({
        name: possibility.name,
        bedChoice: possibility.bedChoices[bedIndex],
        otherProposal: true
      });
    }

    const promosPrice = selectedSupplements?.reduce((total, { price }) => total + price, 0)

    setCurrentSelectedRoom({
      ...possibility,
      bed: {
          ...possibility.bedChoices[bedIndex],
          totalPriceWithPromo: possibility?.bedChoices[bedIndex]?.totalPriceWithPromo + promosPrice,
        },
      optionalPromos: selectedSupplements
    });

    setCurrentIndex(index);
  };

  const formatDataBasedOnSelection = (selectedRooms, possibility) => {
    let formattedData = {};

    for (let i = 0; i < selectedRooms.length; i++) {
      if (!possibility) {
        return formattedData;
      }

      formattedData = {
        possibilities: possibility?.compatibleRoomCompositions,
      };
    }

    return formattedData;
  };

  const validateRoom = (hotelBasketRoomCount) => {
    const roomCount = Number.isInteger(hotelBasketRoomCount) ? hotelBasketRoomCount : totalRoomNumber
    if (roomCount !== selectedRooms.length + 1) {
      setSelectedRooms(prev => [...prev, currentSelectedRoom]);
      setCurrentSelectedRoom(undefined);
      setCurrentIndex(undefined);
      return;
    }

    const correctedRooms = getCorrectedRooms(selectedRooms, currentSelectedRoom);

    setSelectedRooms([...correctedRooms, currentSelectedRoom]);
    goInfosPage();
  };

  const deleteRoomFromBasket = roomIndex => {
    if(!basket){
      return;
    }

    const basketHotelIndex = basket?.findIndex(basketExperience => basketExperience.internalId === filters.internalId);
    if (basketHotelIndex !== -1) {
      const updatedRooms = basket[basketHotelIndex]?.selectedRooms.filter((_room, index) => index !== roomIndex);
      const updatedBasket = [...basket];
      updatedBasket[basketHotelIndex] = {
        ...updatedBasket[basketHotelIndex],
        selectedRooms: updatedRooms
      };
      setBasket(updatedBasket);
    }
  }

  const deleteRoom = roomIndex => {
    setSelectedRooms(prev => prev.filter((r, i) => i !== roomIndex));
    setCurrentIndex(undefined);
    setCurrentSelectedRoom(undefined);
    deleteRoomFromBasket(roomIndex);
  };

  const { data, isLoading } = useAsync(
    {
      promiseFn: getRoomsAvailable,
      id: hotelId ?? booking.hotel.id,
      filters: {
        bookingId: filters.bookingId,
        startAt: filters.startAt,
        endAt: filters.endAt,
        templateId: filters?.templateId,
        rooms: filters?.rooms?.map(room => ({
          ...omit(room, 'children'),
          firstAdultNationality: room?.firstAdultNationality?.value,
        })),
      },
    },
    false,
  );
  const promoList = (data?.data?.possibilities || [])
    .flatMap(choice => {
      const supplement = choice?.bedChoices[0]?.optionalSupplements;
      if (supplement?.length > 0) {
        return supplement.map(supp => ({
          id: supp?.id,
          displayName: supp?.name,
          value: supp?.name,
          price: Math.round(supp?.value ,100),
          priceInDollar: Math.round(supp?.priceInDollar ,100),
          kind: supp?.kind,
        }));
      } else {
        return [];
      }
    });

  //find the right compositions in compatibilties room
  function findRoomByRoomNumber(rooms, roomNumber) {
    const response = [] ;
    for (const room of rooms) {
      if (getRoomNumberFromBedChoices(room.bedChoices) === roomNumber) {
        response.push(room);
      }
      for (const compatibleRoom of room.compatibleRoomCompositions) {
        const foundRoom = findRoomByRoomNumber([compatibleRoom], roomNumber);
        if (foundRoom) {
          response.push(...foundRoom);
        }
      }
    }
    return response.filter(item => Boolean(item?.name));
  }

  function getRoomNumberFromBedChoices(bedChoices) {
    for (const choice of bedChoices) {
      if (choice.promotionInfo && choice.promotionInfo.roomNumber) {
        return choice.promotionInfo.roomNumber;
      }
    }
    return null;
  }

  function groupPossibilities(data) {
    const filteredPossibilities = [];
    const is_supplement = ['supplement', 'supplement_percent'];
    const datas = cloneDeep(data.possibilities);
    let possibilitiesData = datas;
      if (selectedRooms?.length > 0 && selectedRooms?.[0]?.supplier === 'Koob') {
        possibilitiesData = findRoomByRoomNumber(
          datas,
          selectedRooms?.length,
        );
      }
    const originalPossibilities = possibilitiesData.sort((a, b) => {
        const hasPromoA = !!a.bedChoices?.[0]?.promotions?.[0];
        const hasPromoB = !!b.bedChoices?.[0]?.promotions?.[0];
        return hasPromoB - hasPromoA;
      }).map(
      (possibility, index) => {
        possibility.originalIndex = index;
        if (possibility.supplier === 'Koob') {
          filteredPossibilities.push(possibility);
        }

        return possibility;
      },
    );

    const groupedPossibilities = {};

    filteredPossibilities.forEach(possibility => {
      const key = `${possibility?.supplierSpecificInformations?.hotelRoomId}`;
      if (!groupedPossibilities[key]) {
        groupedPossibilities[key] = {
          ...possibility,
          allPromotions: {},
          originalIndexes: [],
        };
      }
      possibility.bedChoices.forEach(bedChoice => {
        if (!is_supplement.includes(bedChoice.promotionInfo.kind)) {
          if (!groupedPossibilities[key].allPromotions[bedChoice.id.split('##').pop()]) {
            groupedPossibilities[key].allPromotions[bedChoice.id.split('##').pop()] = [
              { [bedChoice.id]: bedChoice.promotions },
            ];
          } else {
            groupedPossibilities[key].allPromotions[bedChoice.id.split('##').pop()].push({
              [bedChoice.id]: bedChoice.promotions,
            });
          }
          if (
            groupedPossibilities[key].originalIndexes.indexOf(
              possibility.originalIndex,
            ) === -1
          ) {
            groupedPossibilities[key].originalIndexes.push(
              possibility.originalIndex,
            );
          }
        }
      });
    });

    const newPossibilities = Object.values(groupedPossibilities).map((possibility) => {
      const allPromotions = Object.values(possibility.allPromotions).flat();
      delete possibility.allPromotions;
      return { ...possibility, allPromotions };
    });

    newPossibilities.forEach(possibility => {
      originalPossibilities.forEach(originalPossibility => {
        if (possibility.originalIndexes.includes(originalPossibility.originalIndex)) {
          originalPossibility.allPromotions = possibility.allPromotions;
        }
      });
    });

    const remainingPossibilitiesKoedia = originalPossibilities.filter(
      possibility => possibility.supplier === 'Koedia',
    );

    const remainingPossibilitiesRakuten = originalPossibilities.filter(
      possibility => possibility.supplier === 'Rakuten',
    );

    const wrappedRemainingPossibilitiesKoedia =
      wrapAvailableRoomPossibilities(remainingPossibilitiesKoedia);

    const r = JSON.parse(JSON.stringify(newPossibilities));
    r.forEach(po => {
      po.compatibleRoomCompositions = [];
    });

    updateOriginalCompatibilityRoom(
      originalPossibilities,
      originalPossibilities,
    );
    updateGroupedCompatibilityRoom(newPossibilities, r);
    updateGroupedCompatibilityRoom(originalPossibilities, r);

    const newOriginalPossibilities = [
      ...newPossibilities,
      ...remainingPossibilitiesRakuten,
      ...wrappedRemainingPossibilitiesKoedia,
    ].sort((a, b) => a.originalIndex - b.originalIndex);

    const updatedPossibilitiesList = newOriginalPossibilities.map(item => {
        const composition = item;
        composition?.bedChoices.forEach(bedChoice => {
          if (is_supplement.includes(bedChoice?.promotionInfo?.kind)) {
            bedChoice.totalPriceWithPromo = bedChoice.totalPriceWithoutPromo;
          }
        });
        return composition;
      });
    const cleanedPossibilities = removeDuplicateRooms(updatedPossibilitiesList);
    return { original: originalPossibilities, new: cleanedPossibilities };
  }

  const tagsKey = tags => {
    return tags
      ?.map(t => t.label?.toLowerCase()?.replace(/ /g, ''))
      ?.sort()
      ?.join('#');
  };

  const areCompositionsEqual = useCallback((composition1, composition2) => {
    const isSameCompositions =
      composition1.name === composition2.name &&
      composition1.supplierCode === composition2.supplierCode &&
      composition1.source === composition2.source &&
      composition1.bedChoices[0].totalPriceWithPromo ===
        composition2.bedChoices[0].totalPriceWithPromo &&
      tagsKey(composition1.bedChoices[0].tags) ===
        tagsKey(composition2.bedChoices[0].tags);

    return isSameCompositions;
  }, []);

  const getRoomKey = useCallback((room, depth) => {
    return `${room.name}_${room.supplierCode || room.supplier}_${room.source}_${
      room.bedChoices[0].totalPriceWithPromo
    }_${depth}_${tagsKey(room.bedChoices[0].tags)}`;
  }, []);

  const mergeCompatibleRoomCompositions = useCallback(
    (targetRoom, compatibleRoomCompositions, depth) => {
      targetRoom.compatibleRoomCompositions =
        targetRoom.compatibleRoomCompositions || [];
      compatibleRoomCompositions.forEach(composition => {
        const existingComposition = targetRoom.compatibleRoomCompositions.find(
          existingComposition =>
            areCompositionsEqual(existingComposition, composition),
        );
        if (!existingComposition) {
          targetRoom.compatibleRoomCompositions.push({ ...composition });
        } else if (depth > 0) {
          mergeCompatibleRoomCompositions(
            existingComposition,
            composition.compatibleRoomCompositions,
            depth - 1,
          );
        }
      });
    },
    [areCompositionsEqual],
  );

  const wrapAvailableRoomCompositions = useCallback(
    (mergedRooms, room, depth) => {
      const key = getRoomKey(room, depth);
      if (!mergedRooms.has(key)) {
        mergedRooms.set(key, { ...room });
      } else {
        mergeCompatibleRoomCompositions(
          mergedRooms.get(key),
          room.compatibleRoomCompositions,
          depth,
        );
      }
      if (depth > 0) {
        room.compatibleRoomCompositions.forEach(composition => {
          wrapAvailableRoomPossibilities(composition.compatibleRoomCompositions || []);
        });
      }
    },
    // eslint-disable-next-line
    [mergeCompatibleRoomCompositions, getRoomKey],
  );

  const wrapAvailableRoomPossibilities = useCallback(
    availableRooms => {
      const mergedRooms = new Map();
      availableRooms.forEach(room => {
        wrapAvailableRoomCompositions(mergedRooms, room, rooms?.length - 1);
      });
      return Array.from(mergedRooms.values());
    },
    [wrapAvailableRoomCompositions],
  );

  const removeDuplicateRooms = rooms => {
    const uniqueRoomsIndex = {};
    return rooms.filter(room => {
      const key = `${room.name}_${
        room?.supplierCode ? room?.supplierCode : room?.supplier
      }_${room.source}_${room.bedChoices[0].totalPriceWithPromo}_${tagsKey(
        room.bedChoices[0].tags,
      )}`;
      if (!uniqueRoomsIndex[key]) {
        uniqueRoomsIndex[key] = true;
        return true;
      }
      return false;
    });
  };

  const updateGroupedCompatibilityRoom = (rooms, referencesRooms) => {
    rooms.forEach(room => {
      if (
        room.compatibleRoomCompositions.length > 0 &&
        room.supplier === 'Koob'
      ) {
        if (
          room.compatibleRoomCompositions[0].compatibleRoomCompositions.length >
          0
        ) {
          updateGroupedCompatibilityRoom(
            room.compatibleRoomCompositions[0].compatibleRoomCompositions,
            referencesRooms,
          );
        }
        const groupedCompatibilityRoom = {};
        room.compatibleRoomCompositions.forEach(compatibleRoom => {
          referencesRooms.forEach(referenceRoom => {
            if (
              referenceRoom.originalIndexes.includes(
                compatibleRoom.originalIndex,
              )
            ) {
              groupedCompatibilityRoom[referenceRoom.originalIndex] =
                referenceRoom;
            }
          });
        });
        room.compatibleRoomCompositions = Object.values(
          groupedCompatibilityRoom,
        );
      }
    });
  };

  const updateOriginalCompatibilityRoom = (rooms, referencesRooms) => {
    rooms.forEach(room => {
      if (room.compatibleRoomCompositions.length > 0) {
        updateOriginalCompatibilityRoom(
          room.compatibleRoomCompositions,
          referencesRooms,
        );
      }

      if (room.originalIndex === undefined) {
        if (room.supplier !== 'Koob') {
          const originalRoom = referencesRooms.find(rRef => {
            if (rRef.supplier === 'Koob') {
              return false;
            }
            return (
              rRef.name === room.name &&
              rRef.supplier === room.supplier &&
              rRef.source === room.source
            );
          });
          if (originalRoom) {
            room.originalIndex = originalRoom.originalIndex;
          }
        } else {
          const originalRoom = referencesRooms.find(rRef => {
            if (rRef.supplier !== 'Koob') {
              return false;
            }
            const rBedsIds = rRef.bedChoices
              .map(bed => {
                return `${bed.promotions.join('_')}`;
              })
              .join('_');
            const bedIds = room.bedChoices
              .map(bed => {
                return `${bed.promotions.join('_')}`;
              })
              .join('_');

            return (
              rRef.supplierSpecificInformations.hotelRoomId ===
              room.supplierSpecificInformations.hotelRoomId &&
              rBedsIds === bedIds
            );
          });

          room.originalIndex = originalRoom?.originalIndex;
          room.allPromotions = originalRoom?.allPromotions;
          room.allOptionalSupplements = originalRoom?.allOptionalSupplements;
        }
      }
    });
  };

  function selectedRoomsFromPromotionSelect(originalIndex, bedChoicePromo, bedName) {
    const room = initialPossibilities.filter((possibility) => {
      return possibility.originalIndex === originalIndex;
    }).pop();
    const rooms = initialPossibilities.filter((possibility) => {
      return possibility?.supplierSpecificInformations?.hotelRoomId === room?.supplierSpecificInformations?.hotelRoomId;
    })

    let found = false;
    for (let possibility of rooms) {
      if (found) {
        break;
      }
      for (let i = 0; i < possibility.bedChoices.length; i++) {
        let bed = possibility.bedChoices[i];
        if (bed.name === bedName && bed.promotions.join(', ') === bedChoicePromo) {
          handleRoomIndexChange(possibility.originalIndex, i, possibility)
          found=true;
          break;
        }
      }
    }
  }

  useEffect(() => {
    const isKoediaSupplier = selectedRooms?.[0]?.supplier === 'Koedia';
    const isRakutenSupplier = selectedRooms?.[0]?.supplier === 'Rakuten';
    if (data && data.data && selectedRooms.length === 0) {
      const response = cloneDeep(data?.data);
      const groupedPossibilities = groupPossibilities(response);
      setPossibilities(groupedPossibilities.new);
      setInitialPossibilities(groupedPossibilities.original);

      setRequestId(data.data.requestId);
      if (setEditRoomRequestId) {
        setEditRoomRequestId(data.data.requestId);
      }

      setExpirationDate(data.data.expiresAt);
    }

    const formatDataInput = isKoediaSupplier ? possibilities : selectedRooms;
    if (selectedRooms.length > 0) {
      const response =
        isKoediaSupplier || isRakutenSupplier
          ? (isKoediaSupplier ? formatDataInput : possibilities)
          : cloneDeep(data?.data)?.possibilities;

      const possibility = response?.find(item => {
        const lastSelectedRoom = selectedRooms.at(-1);

        return (
          item.name === lastSelectedRoom.name &&
          (!isKoediaSupplier ||
            tagsKey(item.bedChoices[0].tags) ===
              tagsKey(lastSelectedRoom.bedChoices[0].tags))
        );
      });

      if (possibility) {
        const updatedPossibilities = formatDataBasedOnSelection(
          formatDataInput,
          possibility,
        );
        const groupedPossibilities = groupPossibilities(updatedPossibilities);

        setPossibilities(groupedPossibilities.new);
        setInitialPossibilities(groupedPossibilities.original);
        setRequestId(data.data.requestId);
        setExpirationDate(data.data.expiresAt);
      }
    }
  }, [data, selectedRooms]);

  useEffect(() => {
    const draftBedCompositions = [...bedCompositions];
    draftBedCompositions[currentIndex] = null;
    setBedCompositions(draftBedCompositions);
  }, [currentIndex]);

  const formattedPossibilities = formatHotelRoomPossibilities(possibilities);

  return booking ? (
    <Modal
      size="large"
      title={t("changeRoom")}
      isOpen
      onRequestClose={() => setIsOpen(false)}
      header={
        <Stack direction="column" gutterSize={0.25}>
          <div>
            <Text color="text" fontWeight={700}>
              {t("hotelKeeperMessage") + " :"}
            </Text>
          </div>
          <div style={{ textAlign: "left" }}>
            <Text color="textLight">
              {booking?.hotelKeeperMessage
                ? "« " + booking?.hotelKeeperMessage + " »"
                : t("noMessage")}
            </Text>
          </div>
        </Stack>
      }
      footer={
        <Stack size="full" alignX="flex-end" gutterSize={2}>
          <Button
            variant="simple"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            {t("misc:cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={currentIndex === undefined}
            onClick={() => {
              setFieldValue(
                `roomCompositions.${selectedRoomIndex}.room`,
                newBedroom,
                true
              );
              setIsOpen(false);
            }}
          >
            {t("room.confirmSelection")}
          </Button>
        </Stack>
      }
    >
      <RoomCompositionContent
        isLoading={isLoading}
        selectedRooms={selectedRooms}
        availableRooms={formattedPossibilities}
        deleteRoom={deleteRoom}
        hotel={hotel}
        currentIndex={currentIndex}
        handleRoomIndexChange={handleRoomIndexChange}
        filters={filters}
        totalRoomNumber={totalRoomNumber}
        currentSelectedRoom={currentSelectedRoom}
        selectedRoomsFromPromotionSelect={selectedRoomsFromPromotionSelect}
        validateRoom={validateRoom}
        isOtherProposal={true}
        initialPossibilities={initialPossibilities}
        resquestId= {data?.data?.requestId}
        promoList={promoList}
      />
    </Modal>
  ) : (
    <RoomCompositionContent
      isLoading={isLoading}
      selectedRooms={selectedRooms}
      availableRooms={formattedPossibilities}
      deleteRoom={deleteRoom}
      hotel={hotel}
      currentIndex={currentIndex}
      handleRoomIndexChange={handleRoomIndexChange}
      filters={filters}
      totalRoomNumber={totalRoomNumber}
      currentSelectedRoom={currentSelectedRoom}
      validateRoom={validateRoom}
      isOtherProposal={false}
      selectedRoomsFromPromotionSelect={selectedRoomsFromPromotionSelect}
      initialPossibilities={initialPossibilities}
      promoList={promoList}
    />
  );
};

export default HotelRoomComposition;
