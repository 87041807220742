import { Stack } from "@koob/margaret";
import styled, { css } from "styled-components";
import { Text } from "ui";

export const SubSectionTitle = styled(Text).attrs({ type: 'h3' })`
  font-weight: 700;
`;

export const SubSectionTitleContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  width:100%;
`;

export const LigneHorizontal = styled.hr`
  border: 0.00001em solid #d1d5db;
  width: 99%;
  opacity: 0.4;
  margin-top: 50px;
`;

export const Tag = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
  background: #ffffff;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.separator};
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border-radius: 4px;
`;

export const HotelTag = styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
})`
  background: #EEE9F3;
  color: #785097;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  ${props =>
  props.color === 'orange' &&
  css`
    background: ${({ theme }) => theme.orangeLight2};
    color: ${({ theme }) => theme.orange};
  `};

  border-radius: 10px;
`;
