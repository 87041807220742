import { Stack } from '@koob/margaret';
import styled from 'styled-components';
import { LigneHorizontal } from './Base';



const BlocContainer = styled.div`
  width: 360px;
`;

const BlocRight = ({ children }) => {


  return (
    <>
      <BlocContainer>
        <Stack direction="column">
          <Stack size="full" direction="column" style={{ marginTop: '40px' }}>
            {children}
          </Stack>
          <LigneHorizontal />
        </Stack>
      </BlocContainer>
    </>
  );
};

export default BlocRight;
