import { MdChevronRight } from 'react-icons/md';
import styled from 'styled-components';
import { Troncate } from '../../../../ui';

function getColorClass(status) {
  switch (status) {
    case 'new':
      return 'text-blue-500';
    case 'pending_dmc':
      return 'text-orange-500';
    case 'pending_to':
      return 'text-purple-500';
    case 'closed':
      return 'text-red-500';
    default:
      return 'text-black';
  }
}

const getStatusText = status => {
  switch (status) {
    case 'new':
      return 'new';
    case 'pending_dmc':
      return 'pending dmc';
    case 'pending_to':
      return 'pending to';
    case 'closed':
      return 'closed';
    default:
      return '';
  }
};

function getMessageSince(time) {
  const now = new Date();
  const targetDate = new Date(time);
  const timeDifference = now - targetDate;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}j`;
  } else if (hours > 0) {
    return `${hours}h`;
  } else if (minutes > 0) {
    return `${minutes}min`;
  } else {
    return `${seconds}s`;
  }
}

const LinkStyled = styled.div`
  text-decoration: none;
  cursor: pointer;
`;

export default function TripConversationItem({
  conversation,
  selectedConversation,
  setSelectedConversation,
  setShowConversationTab,
}) {
  return (
    <div className="w-full mt-3">
      <LinkStyled
        className={`${
          conversation?.id === selectedConversation?.id
            ? 'bg-gray-200 rounded-lg'
            : ''
        }`}
        onClick={() => {
          setSelectedConversation(conversation);
          setShowConversationTab('list');
        }}
      >
        <div className="flex justify-between border-b">
          <div className="flex items-center">
            <div className="ml-2">
              <div
                className={`font-bold text-sm uppercase ${getColorClass(
                  conversation?.status,
                )}`}
              >
                {getStatusText(conversation?.status)}
              </div>
              <Troncate maxWidth={145} className="text-gray-500 text-sm">
                {conversation?.title}
              </Troncate>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-gray-500 text-sm mr-2">
              {getMessageSince(conversation?.updatedAt)}
            </div>
            <div className="">
              <MdChevronRight size={20} />
            </div>
          </div>
        </div>
      </LinkStyled>
    </div>
  );
}
