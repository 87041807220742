import { useAsync } from 'react-async';
import LocalMultiSearchableSelectField from './LocalMultiSearchableSelectField';
import { getMarkets } from 'api/markets';

const SelectMarketsField = ({ label }) => {
  const marketFilters = {
    order: 'name',
    direction: 'asc',
    page: 0,
    pageSize: 0,
  };
  const { data } = useAsync({ promiseFn: getMarkets,filters: marketFilters });
  const markets = (data?.data ?? []).map(({ id, name }) => {
    return {
      label: name,
      value: id,
    };
  });

  return (
    <LocalMultiSearchableSelectField
      options={markets}
      label={label}
      name="markets"
      renderOption={value => value.label}
      renderSelectedOption={value => value.label}
      required
    />
  );
};
export default SelectMarketsField;
