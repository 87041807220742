import KoobCard from "components/Koob/KoobCard";
import { BsClockHistory } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { HistoryItem } from "./HistoryItem";

export const TripHistory = ({histories}) => {
  const { t } = useTranslation('folders', { keyPrefix: 'tripHistory' });

  return (
    <KoobCard className="h-[36rem] overflow-scroll mt-3">
      {histories?.length === 0 ? (
        <div className="flex justify-center mt-6">
          <BsClockHistory size={30} />
          <h1 className="text-lg ml-2 tracking-wide">{t('emptyMessage')}</h1>
        </div>
      ) : (histories ?? []).map(history => (
          <HistoryItem key={history?.id} history={history} />
        )
      )}
     
    </KoobCard>
  )
}