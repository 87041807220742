import styled from 'styled-components';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import KoobTitle from '../../../components/Koob/KoobTitle';
import { useTranslation } from 'react-i18next';
import KoobButton from '../../../components/Koob/KoobButton';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: ${({ theme }) => theme.spacing()};
  }
`;

export default function ExperienceExtrasModalSkipChildrenExtra({
  skipChildrenExtra,
  setSkipChildrenExtra,
  onContinue,
  disabled,
}) {
  const { t } = useTranslation('experiences');

  return (
    <div className="m-3 bg-gray-100 p-4 rounded-lg w-full">
      <div className="flex items-center justify-between space-x-8">
        <div>
          <KoobTitle size="lg">
            {t('tripDesigner:extrasModal.childrenExtras.skip.title')}
          </KoobTitle>
          <KoobParagraph size="md">
            {t('tripDesigner:extrasModal.childrenExtras.skip.description')}
            <br />
          </KoobParagraph>
        </div>

        <div>
          <CheckboxWrapper>
            <input
              type="checkbox"
              checked={skipChildrenExtra}
              onChange={() => setSkipChildrenExtra(!skipChildrenExtra)}
              disabled={disabled}
            />
          </CheckboxWrapper>
        </div>

        <div className="whitespace-nowrap">
          <KoobButton
            onClick={() => onContinue()}
            disabled={!skipChildrenExtra || disabled}
          >
            {t('requiredExperiencesModal.other.button')} &rarr;
          </KoobButton>
        </div>
      </div>
    </div>
  );
}
