import React from 'react';

export default function KoobContainer({ children }) {
  return (
    <div className="bg-k-bg">
      <div className="px-4 sm:px-6 lg:px-8 py-6">
        <div className="max-w-7xl mx-auto">
          {children}
        </div>
      </div>
    </div>
  );
}
