import { Tooltip } from '@koob/dmc/src/components';
import IcLightning from '@koob/dmc/src/components/icons/IcLightning';
import { Lightning } from '@koob/to/src/components/Lightning';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function CheckoutHotelRoomAllotmentAvailable({ scope, room }) {
  const { t } = useTranslation();

  return scope === 'dmc' ? (
    <Tooltip
      tip={
        room?.supplier?.toUpperCase() === 'KOEDIA'
          ? t('connectedChannelManager:tooltip')
          : t('allotments:tooltip')
      }
      hasArrow={false}
      color="purple"
    >
      <IcLightning size={24} />
    </Tooltip>
  ) : (
    <Lightning popover={t('booking:room.instantAvaibility')}/>
  );
}
