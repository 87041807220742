import { css } from 'styled-components';
import { media, viewportSizes } from '@koob/margaret';
import { keys, toPairs } from 'lodash';

const cssLock = ({
  valueUnit = '',
  minValue,
  maxValue,
  minViewportWidth = viewportSizes.tablet,
  maxViewportWidth = viewportSizes.desktop,
}) =>
  `calc((${minValue} * 1${valueUnit}) + (${maxValue} - ${minValue}) * (100vw - ${
    minViewportWidth / 16
  }em) / (${maxViewportWidth / 16} - ${minViewportWidth / 16}))`;

const colors = {
  text: 'rgba(0, 0, 0, 0.87)',
  textLight: 'rgba(0, 0, 0, 0.6)',
  textLighter: 'rgba(0, 0, 0, 0.38)',

  orange: '#f5700a',
  orangeLight2: '#fcd4b5',
  orangeLight: 'rgba(245, 112, 10, 0.12)',
  orangeLighter: '#fffaf6',
  primaryGradient: 'linear-gradient(to bottom, #f5870a, #f5700a)',

  blue: '#0093ff',
  blueLight: 'rgba 0 147 255, 0.12',
  blueLighter: '#E0F1FF',

  green: '#29CC87',
  greenLight: '#E4F8EF',

  purple: '#1b2d49',

  yellow: '#ffd500',
  yellowLight: 'rgba(255, 213, 0, 0.1)',

  red: '#e0351b',
  redLight: 'rgba(224, 53, 27, 1)',
  redLighter: '#FAE6E3',

  black: '#1E2020',
  separatorLight: 'rgba(0, 0, 0, 0.06)',
  separator: 'rgba(0, 0, 0, 0.12)',
  darkSeparator: 'rgba(0, 0, 0, 0.24)',

  focus: 'rgba(0, 147, 255, 0.38)',
  disabled: 'rgba(0, 0, 0, 0.08)',

  darkPurple: '#9c27b0',
  lightPurple: '#af52bf',

  danger: '#e0351b',
  dangerDark: 'rgba 224 53 27, 0.5',
  success: '#29cc88',
  background: '#FBFBFB',
  backgroundArchived:
    'repeating-linear-gradient(45deg, #f5f8fa, #f5f8fa 10px, #e5e9eb 10px, #e5e9eb 12px)',

  gray: '#767676',
  grayBg: '#F2F2F2',
  white: '#FFFFFF',
  grayDark: '#1E2020',
};

export const theme = {
  ...colors,

  primary: colors.orange,
  primaryBackground: 'rgba(245, 112, 10, 0.32)',

  orangeShadowDark: 'rgba(245, 112, 10, 0.5)',
  primaryLight: colors.orangeLight,
  borders: '#fab784',

  secondary: colors.blue,
  secondaryLight: 'rgba(0, 147, 255, 0.12)',
  secondaryBackground: 'rgba(0, 147, 255, 0.12)',
  secondaryBackgroundLight: 'rgba(0, 147, 255, 0.08)',
  secondarySeparator: 'rgba(0, 147, 255, 0.24)',

  shadow: 'rgba(0, 0, 0, 0.08)',
  shadowFocus: 'rgba(0, 0, 0, 0.12)',
  hover: 'rgba(0, 0, 0, 0.04)',
  selected: 'rgba(0, 0, 0, 0.06)',

  error: colors.red,
  errorLight: colors.redLight,

  containerBackground: '#fff',

  sidebarBackground: '#fff',
  sidebarTextColor: 'rgba(0, 0, 0, 0.6)',
  sidebarHoverColor: 'rgba(245, 112, 10, 0.9)',
  sidebarBackgroundHoverColor: 'rgba(245, 112, 10, 0.12)',

  borderRadius: {
    default: '4px',
    large: '8px',
    extraLarge: '16px',
  },

  button: {
    transition: 'background 150ms ease',
    borderRadius: '4px',
    alignY: 'center',
    alignX: 'center',
    fontWeight: '600',
    fontSize: '16px',
    paddingHorizontal: 0,
    paddingVertical: 0,
    minWidth: 0,
    color: colors.orange,
    // colorDisabled: 'rgba(0, 77, 64, 0.3)',
    // colorHover: colors.greenDark,
    // colorFocus: colors.greenDark,

    link: {
      fontSize: '14px',
      color: colors.orange,
      colorDisabled: colors.disabled,

      colorHover: colors.orangeLight,
      boxShadowFocus: `inset 0 0 0 2px ${colors.focus}`,
    },

    simple: {
      background: '#fff',
      boxShadow: `inset 0 0 0 1px ${colors.separator}`,
      color: colors.textLight,
      fontSize: '14px',

      boxShadowHover: `inset 0 0 0 1px ${colors.separator}, 0 2px 4px 0 ${colors.separator}`,

      boxShadowFocus: `inset 0 0 0 3px ${colors.focus}, 0 1px 2px 0 ${colors.separator}`,

      boxShadowActive: `inset 0 0 0 1px ${colors.separator}, 0 4px 8px 0 ${colors.separator}`,

      backgroundDisabled: 'rgba(0, 0, 0, 0.16)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
      boxShadowDisabled: '0 0 0',

      paddingVertical: '12px',
      paddingHorizontal: '12px',
    },

    warning: {
      background: 'rgba(245,112,10,0.12)',
      boxShadow: `inset 0 0 0 1px ${colors.separator}`,
      color: colors.primary,
      fontSize: '14px',

      boxShadowHover: `inset 0 0 0 1px ${colors.separator}, 0 2px 4px 0 ${colors.separator}`,

      boxShadowFocus: `inset 0 0 0 3px ${colors.focus}, 0 1px 2px 0 ${colors.separator}`,

      boxShadowActive: `inset 0 0 0 1px ${colors.separator}, 0 4px 8px 0 ${colors.separator}`,

      backgroundDisabled: 'rgba(0, 0, 0, 0.16)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
      boxShadowDisabled: '0 0 0',

      paddingVertical: '12px',
      paddingHorizontal: '12px',
    },

    danger: {
      background: `linear-gradient(to bottom, #ff6852, ${colors.red} 100%)`,
      color: '#ffffff',
      boxShadow: `0 1px 2px 0 ${colors.redLight}`,
      fontWeight: 600,
      fontSize: '14px',
      paddingVertical: '12px',
      paddingHorizontal: '12px',

      boxShadowHover: `0 2px 4px 0 ${colors.dangerDark}`,
      backgroundHover: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(to bottom, #ff6852, ${colors.red} 100%)`,

      boxShadowFocus: `inset 0 0 0 3px ${colors.focus}`,

      boxShadowActive: `0 4px 8px 0 rgba(224 53 27, 0.5)`,

      backgroundDisabled: 'rgba(0, 0, 0, 0.16)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
      boxShadowDisabled: '0 0 0',
    },

    primary: {
      background: colors.primaryGradient,
      color: '#fff',
      boxShadow: `0 1px 2px 0 ${colors.orangeDark}`,

      backgroundHover: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${colors.primaryGradient};
      `,

      boxShadowFocus: `inset 0 0 0 3px ${colors.focus}`,

      boxShadowActive: `0 4px 8px 0 rgba(245,112,10, 0.5)`,

      backgroundDisabled: 'rgba(0, 0, 0, 0.16)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
      boxShadowDisabled: '0 0 0',

      fontWeight: '600',
      fontSize: '14px',

      paddingVertical: '12px',
      paddingHorizontal: '12px',
    },

    primaryBig: {
      fontSize: '16px',
      background: colors.primaryGradient,
      color: '#fff',
      boxShadow: `0 1px 2px 0 ${colors.orangeDark}`,

      backgroundHover: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${colors.primaryGradient};
      `,

      boxShadowFocus: `inset 0 0 0 3px ${colors.focus}`,

      boxShadowActive: `0 4px 8px 0 rgba(245,112,10, 0.5)`,

      backgroundDisabled: 'rgba(0, 0, 0, 0.16)',
      colorDisabled: 'rgba(0, 0, 0, 0.38)',
      boxShadowDisabled: '0 0 0',

      paddingHorizontal: '24px',
      paddingVertical: '16px',
      width: '100%',
    },
  },

  mainNav: {
    width: '280px',
    maxWidth: '100%',
    background: {
      mobile: '#ffffff',
      tablet: '#ffffff',
    },
    boxShadow: `2px 0 4px rgba(0, 0, 0, 0.05)`,
    position: 'left',
    triggerColor: {
      expanded: '#41D974',
      collapsed: 'rgba(0, 0, 0, 0.87)',
    },
  },

  tooltip: {
    fontStyle: 'bodySmall',
    fontSize: '14px',
    fontWeight: '400',
    color: '#ffffff',
    background: colors.orange,
    borderRadius: '3px',
    paddingVertical: '4px',
    paddingHorizontal: '8px',
    maxWidth: '300px',
  },

  pills: {
    activeDisabledBackground: colors.separator,
    activeDisabledColor: colors.textLighter,
    disabledColor: colors.darkSeparator,
  },

  fonts: {
    title:
      'Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  },

  fontSizes: {
    h1Mega: {
      sizeMinRem: 1.75,
      sizeMaxRem: 2,
      lineHeightMin: 1.2,
      lineHeightMax: 1.1,
      fontWeight: 700,
      defaultTag: 'h1',
    },
    h1: {
      sizeMinRem: 1.375,
      sizeMaxRem: 1.625,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h1',
    },
    h2: {
      sizeMinRem: 1.25,
      sizeMaxRem: 1.5,
      lineHeightMin: 1.25,
      lineHeightMax: 1.2,
      fontWeight: 700,
      defaultTag: 'h2',
    },
    h3: {
      sizeMinRem: 1.125,
      sizeMaxRem: 1.25,
      lineHeightMin: 1.4375,
      lineHeightMax: 1.25,
      fontWeight: 700,
      defaultTag: 'h4',
    },
    bodyLarge: {
      sizeMinRem: 1.0625,
      sizeMaxRem: 1.1875,
      lineHeightMin: 1.3,
      lineHeightMax: 1.5,
      fontWeight: 400,
      defaultTag: 'span',
    },
    body: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.35,
      lineHeightMax: 1.4,
      fontWeight: 400,
      defaultTag: 'span',
    },
    bodySmall: {
      sizeMinRem: 0.8125,
      sizeMaxRem: 0.875,
      lineHeightMin: 1.4,
      lineHeightMax: 1.35,
      fontWeight: 400,
      defaultTag: 'span',
    },
  },
};

export const fontSize = toPairs(theme.fontSizes).reduce(
  (acc, [key, { sizeMinRem, sizeMaxRem }]) => ({
    ...acc,
    [key]: css`
      font-size: ${sizeMinRem}rem;

      ${media.tablet`
        font-size: ${cssLock({
          valueUnit: 'rem',
          minValue: sizeMinRem,
          maxValue: sizeMaxRem,
        })};
      `}

      ${media.desktop`
        font-size: ${sizeMaxRem}rem;
      `}
    `,
  }),
  {},
);

export const lineHeight = toPairs(theme.fontSizes).reduce(
  (acc, [key, { lineHeightMin, lineHeightMax }]) => ({
    ...acc,
    [key]: css`
      line-height: ${lineHeightMin}em;

      ${media.tablet`
        line-height: ${cssLock({
          valueUnit: 'em',
          minValue: lineHeightMin,
          maxValue: lineHeightMax,
        })};
      `}

      ${media.desktop`
        line-height: ${lineHeightMax}em;
      `}
    `,
  }),
  {},
);

export const fontWeight = toPairs(theme.fontSizes).reduce(
  (acc, [key, { fontWeight }]) => ({
    ...acc,
    [key]: css`
      font-weight: ${fontWeight};
    `,
  }),
  {},
);

export const fontStyles = keys(theme.fontSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: css`
      ${fontSize[key]}
      ${lineHeight[key]}
      ${fontWeight[key]}
    `,
  }),
  {},
);
