import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

export default function ExperienceProgramContentHeader({ title, date, location }) {
  const { t } = useTranslation('experiences');

  return (
    <div className="flex flex-col text-gray-500 font-light">
      <div>
        <span className="font-semibold">{title}</span> - {moment(date).format('dddd')}
      </div>

      <div className="flex flex-row space-x-3">
        <div className="flex items-center space-x-1.5">
          <svg className="h-4 w-4" viewBox="0 0 448 512">
            <path d="M112 0c8.8 0 16 7.2 16 16V64H320V16c0-8.8 7.2-16 16-16s16 7.2 16 16V64h32c35.3 0 64 28.7 64 64v32 32V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192 160 128C0 92.7 28.7 64 64 64H96V16c0-8.8 7.2-16 16-16zM416 192H32V448c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V192zM384 96H64c-17.7 0-32 14.3-32 32v32H416V128c0-17.7-14.3-32-32-32z"/>
          </svg>

          <div>
            {date ? moment(date).format('DD/MM/YYYY') : '--/--/----'}
          </div>
        </div>

        <div className="font-semibold">
          {t('detail.programContent.at')}
        </div>

        <div className="flex items-center space-x-1.5">
          <svg className="h-4 w-4" viewBox="0 0 384 512">
            <path d="M352 192c0-88.4-71.6-160-160-160S32 103.6 32 192c0 20.2 9.1 48.6 26.5 82.7c16.9 33.2 39.9 68.2 63.4 100.5c23.4 32.2 46.9 61 64.5 81.9c1.9 2.3 3.8 4.5 5.6 6.6c1.8-2.1 3.6-4.3 5.6-6.6c17.7-20.8 41.1-49.7 64.5-81.9c23.5-32.3 46.4-67.3 63.4-100.5C342.9 240.6 352 212.2 352 192zm32 0c0 88.8-120.7 237.9-170.7 295.9C200.2 503.1 192 512 192 512s-8.2-8.9-21.3-24.1C120.7 429.9 0 280.8 0 192C0 86 86 0 192 0S384 86 384 192zm-240 0a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 80a80 80 0 1 1 0-160 80 80 0 1 1 0 160z"/>
          </svg>

          <div>
            {location}
          </div>
        </div>
      </div>
    </div>
  )
}
