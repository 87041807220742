import Icon from 'react-icon-base';

const IcGroom = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <defs>
      <filter id="m83hbmskya">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 0.960784 0 0 0 0 0.438562 0 0 0 0 0.039216 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g
                filter="url(#m83hbmskya)"
                transform="translate(-612 -462) translate(560 112) translate(32 292) translate(0 38) translate(12 12)"
              >
                <g>
                  <path d="M0 0H24V24H0z" transform="translate(8 8)" />
                  <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                  >
                    <path
                      d="M8.283 15L8.283 19.375M12.662 5.625v-3.75h0c0-.69-.56-1.25-1.25-1.25h-6.25 0c-.69 0-1.25.56-1.25 1.25 0 0 0 0 0 0v3.75h0C3.912 8.041 5.87 10 8.287 10c2.416 0 4.375-1.959 4.375-4.375 0 0 0 0 0 0h0zM3.908 5L12.658 5M8.287 15h0c1.528 0 2.904-.928 3.476-2.345h0c-2.2-1.04-4.752-1.04-6.953 0h0C5.383 14.072 6.758 15 8.287 15h0zM11.408 17.183L11.408 17.183M11.412 17.188h0c-.173 0-.313.14-.313.312 0 .173.14.313.313.313.172 0 .312-.14.312-.313h0c0-.173-.14-.313-.312-.313"
                      transform="translate(8 8) translate(4 2)"
                    />
                    <path
                      d="M16.38 19.375h0c-.34-4.47-4.239-7.818-8.708-7.478-3.998.303-7.175 3.48-7.479 7.478"
                      transform="translate(8 8) translate(4 2)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);

export default IcGroom;
