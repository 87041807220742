import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styled from 'styled-components';
import ComposeHotelCard from './ComposeHotelCard';
import { generateMarker } from '../../../../utils';
import markerActive from '../../../../images/place-hotel-active.svg';

const Wrapper = styled.div`
  width: 250px;
  max-width: 100vw;
`;

export default function MapHotelMarker ({ hotel, addHotel, kind, suggested = false }) {
  const [isOpen, setIsOpen] = useState(false);
  const accomodationMarker = {
    default: generateMarker({ type: 'hotel', mainColor: '#FF1A1A' }),
    active: generateMarker({
      type: 'hotel',
      mainColor: '#F5700A',
      fillColor: '#FFFF',
    }),
  };

  return (
    <Marker
      position={{
        lat: parseFloat('' + hotel?.lat),
        lng: parseFloat('' + hotel?.lon),
      }}
      icon={
        suggested
          ? isOpen
            ? accomodationMarker.active
            : accomodationMarker.default
          : markerActive
      }
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen && (
        <InfoWindow
          options={{ maxWidth: 280, padding: 0 }}
          position={{
            lat: parseFloat('' + hotel?.lat),
            lng: parseFloat('' + hotel?.lon),
          }}
          onCloseClick={() => setIsOpen(false)}
        >
          <Wrapper>
            <ComposeHotelCard
              hotel={hotel}
              addHotel={() => {
                addHotel(hotel);
                setIsOpen(false);
              }}
              isForTrip={true}
              kind={kind}
            />
          </Wrapper>
        </InfoWindow>
      )}
    </Marker>
  );
}
