import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import Tooltip from 'components/Tooltip';
import KoobBadge from '../Koob/KoobBadge';

function RoomTags({room}) {
  const {t} = useTranslation('booking', {keyPrefix: 'room'});

  const bedChoice = room?.bedChoice ?? room?.bedChoices?.[0];
  const tags = bedChoice?.tags?.length > 0 ? bedChoice?.tags : room?.tags;
  const filteredTags = tags?.filter(t => !!t.label);

  const badgeColor = (label) => {
    switch (label) {
      case 'room_refundable':
        return 'green';
      case 'room_non_refundable':
        return 'red';
      default:
        return 'gray';
    }
  }

  return filteredTags?.length > 0 ? (
    <>
      {filteredTags.map((tag, index) => (
        <>
          <Tooltip
            key={index}
            tip={(tag?.startAt && tag?.endAt) ? `${format(parseISO(tag?.startAt), 'dd/MM/yyyy')} - ${format(parseISO(tag?.endAt), 'dd/MM/yyyy')}` : ''}
            disabled={!tag?.startAt || !tag?.endAt}
            color="purple"
            hasArrow={false}
            position="left"
          >
            <KoobBadge color={badgeColor(tag.label)}>
              {tag.label?.indexOf('_') !== -1 || tag.label?.indexOf('.') !== -1 ? t(tag.label) : tag.label}
            </KoobBadge>
          </Tooltip>
        </>
      ))}
    </>
  ) : null;
}

export default RoomTags;
