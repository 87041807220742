import React from 'react';

export default function KoobPlaceholder({ className, count = 1 }) {
  return (
    <>
      {[...Array(count)].map((_, i) => (
        <div
          key={i}
          className={['animate-pulse bg-gray-200', className].join(' ')}
        />
      ))}
    </>
  );
}
