import ComposeList from './Partials/ComposeList';
import ComposeMap from './Partials/ComposeMap';
import ComposeChatTrip from './Partials/ComposeChatTrip';
import { useTranslation } from 'react-i18next';
import { useLanguage, useSnack, useTripDesigner } from '../../../hooks';
import { useAsync } from 'react-async';
import { getTrip, getTripPrograms } from '../../../api/trip';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { getHotel, getHotels } from '../../../api/hotels';
import moment from 'moment';
import TripsFilter from '../../../components/Trip/TripsFilter';
import { formatTripFromApi } from '../utils';
import { getExperience } from '../../../api/experiences';
import { decodeId } from '../../../utils';

export default function TripDesigner({ headerHeight, tripId }) {
  const {
    tripFilters,
    setTripData,
    setLoading,
    setTripFilters,
    selectedDay,
    selectedType,
    setInitialExtras,
    resetDaySelection,
    setLastAddedExperienceId,
    listMode,
    setListMode
  } = useTripDesigner();
  const { t } = useTranslation('tripDesigner');
  const { notify } = useSnack();
  const { lang } = useLanguage();

  const [{ value: days }, , { setValue: setDays }] = useField('days');
  const [formatExtras, setFormatExtras] = useState(false);
  const [previousVersionId, setPreviousVersionId] = useState(null);

  let { data: tripData, reload: reloadTrip } = useAsync({
    promiseFn: getTrip,
    tripId,
    watchFn: (props,prevProps) => {
      return !isEqual(props.tripId, prevProps.tripId)
    }
  });
  const trip = tripData?.data;

  const currentVersion = trip?.currentVersion;
  const versions =
    trip?.versions
      ?.sort((a, b) => b.id - a.id)?.map(v => {
        return {
          ...v,
          current: currentVersion?.id === v.id
        }
      }) ?? [];

  // Update trip days when the current version changes
  useEffect(() => {
    if (
      tripData
      && previousVersionId
      && previousVersionId !== currentVersion?.id
    ) {
      const newVersionNumber = trip?.currentVersion?.version;

      getTrip({
        tripId: tripFilters?.tripId,
        versionId: newVersionNumber
      })
        .then(async (res) => {
          const trip = res.data;
          const { days: newDays, initialExtras } = await formatTripFromApi(trip);

          if (newDays.length > 0) {
            const filteredDays = newDays?.filter(d => d?.experience?.programs?.length > 0);

            setDays(filteredDays);
            setTripData({
              ...tripData,
              days: filteredDays
            });
            setInitialExtras(initialExtras);
          }
        });

      setTripFilters({
        ...tripFilters,
        departureDate: new Date(trip?.startDate),
        tripDuration: trip?.duration,
      });
    }

    setPreviousVersionId(currentVersion?.id);
  },[currentVersion?.id]);

  const maxDuration = tripFilters?.expDuration?.max ?? 50;

  const experienceFilters = {
    cities: tripFilters?.location?.[0]?.kind === 'city' ? tripFilters?.location?.map((city) => city.id) ?? [] : [],
    countries: tripFilters?.countries?.map((country) => country.value) ?? [],
    dmcId: tripFilters?.dmcId?.value ? Number(tripFilters?.dmcId?.value) : undefined,
    tripDuration: maxDuration,
    minDuration: tripFilters?.expDuration?.min ?? 0,
    maxDuration: maxDuration,
    departureDate: tripFilters?.departureDate,
    minPax: tripFilters?.minPax,
    maxPax: tripFilters?.maxPax,
    experiences: tripFilters?.location?.[0]?.kind === 'experience' ? tripFilters?.location?.map((exp) => exp.id) ?? [] : [],
    departureLocation: tripFilters?.departureLocation?.map((city) => city.id) ?? [],
    returnLocation: tripFilters?.returnLocation?.map((city) => city.id) ?? [],
    guided: tripFilters?.guided,
    themes: (tripFilters?.themes || []).map(({ id }) => id),
    segments: (tripFilters?.segments || []).map(({ id }) => id),
    templateId: tripFilters?.templateId,
    startingPoint: tripFilters?.startingPoint,
    endingPoint: tripFilters?.endingPoint,
    priceRange: tripFilters?.priceRange,
    travelersCount: tripFilters?.composition?.adults,
    experienceComposition: tripFilters?.composition,
  };

  const { data: experiencesData, reload } = useAsync({
    promiseFn: getTripPrograms,
    filters: experienceFilters,
    locale: lang,
    watchFn: (props,prevProps) => {
      return !isEqual(props.filters,prevProps.filters) || !isEqual(props.locale,prevProps.locale)
    }
  });
  const experiences = experiencesData?.data ?? [];

  useEffect(() => {
    if (lang && experiences) {
      reload();
    }
  }, [lang]);

  const roomsComposition = tripFilters?.composition?.roomsComposition ?? [{...tripFilters?.composition}];
  
  const hotelFilters= {
    cities: tripFilters?.location?.[0]?.kind === 'city' ? tripFilters?.location?.map((city) => city.id) ?? [] : [],
    countries: tripFilters?.countries?.map((country) => country.value) ?? [],
    dmcId: tripFilters?.dmcId?.value ? Number(tripFilters?.dmcId?.value) : undefined,
    hotels: tripFilters?.location?.[0]?.kind === 'hotel' ? tripFilters?.location?.map((exp) => exp.id) ?? [] : [],
    kinds: (tripFilters?.kinds ?? []).map(({ id }) => id),
    regions: [],
    styles: (tripFilters?.styles ?? []).map(({ id }) => id),
    sustainability:tripFilters.sustainableLevel?.id ?? null,
    stars: tripFilters?.stars,
    startAt: moment(tripFilters?.departureDate).format('YYYY-MM-DD'),
    endAt: moment(tripFilters?.departureDate).add(1, 'day').format('YYYY-MM-DD'),
    rooms: roomsComposition.map(room => ({
      ...room,
      firstAdultNationality: room?.firstAdultNationality?.value ?? ''
    })),
    bypassLocationRequirement: true
  };

  const { data: hotelsData, reload: reloadHotels } = useAsync({
    promiseFn: getHotels,
    locale: lang,
    filters: hotelFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(
        JSON.stringify(props.filters),
        JSON.stringify(prevProps.filters),
      );
    },
    onResolve: response => {
      if (response?.data?.state === 'inProgress') {
        setTimeout(() => reloadHotels(), 3000);
      }
    },
    onReject: () => {
      setTimeout(() => reloadHotels(), 5000);
    },
  });
  const hotels = hotelsData?.data?.data ?? [];

  useEffect(() => {
    setTripData({
      days
    });
  }, [days]);

  // Prefill days with empty objects up to the selected day
  // This prevents crashes when using useField(${dayIndex}.experience);
  const prefillDays = (days, upToIndex) => {
    const newDays = days ?? [];

    for (let i = 0; i < upToIndex; i++) {
      if (!newDays[i]) {
        newDays[i] = {
          experience: null,
          hotel: null
        };
      }
    }

    return newDays;
  };

  const addHotel = (hotel) => {
    const newDays = prefillDays(days, selectedDay + 1);
    const currentDay = newDays?.[selectedDay]
    const programDay = currentDay?.experience?.programs[selectedDay]
    const isIncludedHotel =
      programDay?.isIncludedAccomodation &&
      (programDay?.accomodationHotel?.id === hotel?.id?.toString() || 
      programDay?.alternativeHotels?.some(({ id }) => id === hotel?.id?.toString()));

    newDays[selectedDay] = {
      ...currentDay,
      hotel: {
        ...hotel,
        id: decodeId(hotel.id),
        isIncluded: isIncludedHotel,
        filters: undefined
      }
    };

    setDays(newDays);

    setListMode('map');
    resetDaySelection();
  }

  const addExperience = async (experienceId) => {
    setLoading(true);

    const experienceData = await getExperience({ experienceId });
    const experience = experienceData?.data;

    const newDays = prefillDays(days, selectedDay + experience.durationDays);

    const hasOverlappingPrograms = newDays
      .slice(selectedDay, selectedDay + experience.durationDays)
      .some(day => day?.experience);

    if (hasOverlappingPrograms) {
      notify(t('compose.overlappingPrograms'), {type: 'error'});
      setLoading(false);
      return;
    }

    for (let i = 0; i < Math.max(experience.durationDays, 1); i++) {
      newDays[selectedDay + i] = {
        experience: {
          ...experience,
          dayIndex: i + 1
        }
      }

      const program = experience?.programs?.[i];

      if (program?.accomodationHotel && program?.isIncludedAccomodation) {
        const hotel = await getHotel({ hotelId: program?.accomodationHotel?.id });

        newDays[selectedDay + i] = {
          ...newDays[selectedDay + i],
          hotel: {
            ...hotel?.data,
            id: decodeId(hotel?.data?.id),
            isIncluded: true
          }
        };
      }
    }

    if (newDays?.length > days?.length) {
      notify(t('compose.tripDurationExtended'));
    }

    setDays(newDays);

    setLoading(false);
    setListMode('map');
    resetDaySelection();
    setLastAddedExperienceId(experience.id);
  };

  const getExperienceCompatibility = (experience) => {
    if (
      selectedDay === null
      || !days
      || days?.length === 0
      || selectedType !== 'experience'
    ) {
      return null;
    }

    const incompatibleIds = [
      ...(days[selectedDay - 1]?.experience?.incompatibilities?.map(e => e.id)) ?? [],
      ...(days[selectedDay + 1]?.experience?.incompatibilities?.map(e => e.id)) ?? []
    ];

    const bestMatchIds = [
      ...(days[selectedDay - 1]?.experience?.bestMatches?.map(e => e.id)) ?? [],
      ...(days[selectedDay + 1]?.experience?.bestMatches?.map(e => e.id)) ?? []
    ];

    for (let i = 1; i < experience.durationDays; i++) {
      incompatibleIds.push(
        ...(days[selectedDay + 1 + i]?.experience?.incompatibilities?.map(e => e.id)) ?? []
      );

      bestMatchIds.push(
        ...(days[selectedDay + 1 + i]?.experience?.bestMatches?.map(e => e.id)) ?? []
      );
    }

    if (incompatibleIds.includes(experience.id)) {
      return 'incompatible';
    }
    if (bestMatchIds.includes(experience.id)) {
      return 'bestMatch';
    }

    return null;
  };

  const isLoading = !hotelsData || hotelsData?.data?.state !== 'finished';

  return (
    <div>
      <TripsFilter
        tripId={tripId}
        versions={versions}
        currentVersion={currentVersion}
        reloadTrip={reloadTrip}
      />

      {listMode === 'list' && (
        <ComposeList
          experiences={experiences}
          hotels={hotels}
          addExperience={addExperience}
          addHotel={addHotel}
          isLoading={isLoading}
          getExperienceCompatibility={getExperienceCompatibility}
        />
      )}

      {(['map', 'itinerary'].includes(listMode)) && (
        <ComposeMap
          experiences={experiences}
          hotels={hotels}
          headerHeight={headerHeight}
          addExperience={addExperience}
          addHotel={addHotel}
          tripId={tripId}
          getExperienceCompatibility={getExperienceCompatibility}
          reloadTrip={reloadTrip}
          currentVersion={currentVersion}
          formatExtras={formatExtras}
          setFormatExtras={setFormatExtras}
          itineraryMode={listMode === 'itinerary'}
        />
      )}

      {listMode === 'chat' && (
        <ComposeChatTrip
          tripId={tripId}
          reloadTrip={reloadTrip}
          trip={trip}
        />
      )}
    </div>
  )
}
