import { useTranslation } from 'react-i18next';
import React from 'react';
import ExportDaySectionTitle from './ExportDaySectionTitle';
import ExportDaySectionContent from './ExportDaySectionContent';

export default function ExportDayHotelBookedOnMyOwn() {
  const { t } = useTranslation('tripDesigner');

  return (
    <ExportDaySectionContent>
      <div className="-mt-6">
        <ExportDaySectionTitle>
          <i className="fa-regular fa-bed mr-2" />
          {t('accomodation')}
        </ExportDaySectionTitle>

        <div className="mt-3 bg-gray-100 h-[50vh] p-8 rounded-lg flex-col space-y-3">
          <div>
            <div className="flex justify-between">
              <div>
                <div className="flex space-x-3 items-center">
                  <div className="font-medium text-2xl">
                  {t('hotelDetails')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ExportDaySectionContent>
  );
}
