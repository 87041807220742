import KoobTitle from '../../../../components/Koob/KoobTitle';
import { useState } from 'react';
import ReceiptHiddenSection from './ReceiptHiddenSection';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { useTranslation } from 'react-i18next';

export default function ReceiptNotes() {
  const { t } = useTranslation('trips');

  const [title, setTitle] = useState(t('receipt.notes.defaultTitle'));
  const [notes, setNotes] = useState('');

  const formattedNotes = notes?.replace(/\n/g, '<br />');

  return (
    <div className={notes?.length > 0 ? '' : 'export-hidden'}>
      <KoobTitle>
        {notes?.length === 0 && (
          <i className="fa fa-eye-slash mr-1.5" />
        )}
        {title}
      </KoobTitle>

      <div className="my-5 text-gray-500">
        <div dangerouslySetInnerHTML={{ __html: formattedNotes }} />
      </div>

      <ReceiptHiddenSection>
        <KoobTitle size="md">
          <div className="flex space-x-3 items-center">
            <div>
              {t('receipt.notes.title')}
            </div>
            <KoobBadge>
              {t('receipt.optional')}
            </KoobBadge>
          </div>
        </KoobTitle>
        <KoobParagraph size="sm">
          {t('receipt.notes.description1')}
        </KoobParagraph>

        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="mt-3 w-full bg-white border border-gray-300 rounded-md p-2"
          placeholder="e.g. Additional Information"
        />

        <textarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          className="mt-1 w-full bg-white border border-gray-300 rounded-md p-2"
          rows="4"
          placeholder="e.g. Please note that the hotel does not accept pets."
        />
      </ReceiptHiddenSection>
    </div>
  )
}
