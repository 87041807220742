import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import marker from 'images/place-hotel.svg';
import markerActive from 'images/place-hotel-active.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 250px;
  max-width: 100vw;
`;

const MapMarker = ({ lat, lon, infoWindowContent, title, Icon, name, address }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Marker
        position={{ lat: parseFloat('' + lat), lng: parseFloat('' + lon) }}
        icon={!Icon ? (isOpen ? markerActive : marker) : (isOpen ? Icon?.active : Icon?.default)}
        onClick={() => setIsOpen(true)}
      >
        {(infoWindowContent ?? title) && isOpen && (
          <InfoWindow
            options={{ maxWidth: 280, padding: 0 }}
            position={{ lat: parseFloat('' + lat), lng: parseFloat('' + lon) }}
            onCloseClick={() => setIsOpen(false)}
          >
            <Wrapper>
              <div className="text-lg font-bold">{name}</div>
              <div>{address}</div>
              {infoWindowContent ?? (name ? null : title)}
            </Wrapper>
          </InfoWindow>
        )}
      </Marker>
    </>
  );
};

export default MapMarker;
