import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TextField } from 'components/Fields';
import Modal from './Modal';
import { useField } from 'formik';
import { Stack, media } from '@koob/margaret';
import { Button } from 'components';
import AvatarField from './Fields/AvatarField';
import { IcGroom } from 'components/icons';
import PhoneNumberField from './Fields/PhoneNumberField';

const Layout = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1)};

  ${media.tablet`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

const OrganizationContactFieldsModal = ({ kind, onRequestClose, name }) => {
  const { t } = useTranslation('organization');
  const [{ value }, , { setValue }] = useField(kind);
  const [initialValues] = useState(value);

  const onDismiss = () => {
    setValue(initialValues);
    onRequestClose();
  };

  const onSave = () => {
    onRequestClose();
  };

  return (
    <Modal
      isOpen
      title={
        kind === 'mainContact'
          ? t('form.contact.mainContactTitle', {
              name: name || 'the organization',
            })
          : t('form.contact.technicalContactTitle', {
              name: name || 'the organization',
            })
      }
      onRequestClose={onDismiss}
      footer={
        <Stack alignX="flex-end" gutterSize={1}>
          <Button onClick={onDismiss} variant="simple">
            {t('misc:cancel')}
          </Button>
          <Button onClick={onSave} variant="primary">
            {t('form.contact.save')}
          </Button>
        </Stack>
      }
    >
      <Layout>
        <AvatarField
          label={t('avatar')}
          name={`${kind}.avatar`}
          placeholder={<IcGroom size={42} />}
        />

        <TextField label={t('form.contact.job')} name={`${kind}.job`} />

        <TextField
          label={t('form.contact.firstName')}
          name={`${kind}.firstName`}
        />

        <TextField
          label={t('form.contact.lastName')}
          name={`${kind}.lastName`}
        />

        <TextField label={t('form.contact.email')} name={`${kind}.email`} />

        <PhoneNumberField
          label={t('form.contact.phoneNumber')}
          name={`${kind}.phoneNumber`}
        />
      </Layout>
    </Modal>
  );
};

export default OrganizationContactFieldsModal;
