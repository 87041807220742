import { formatCurrency } from '../../../../utils';
import { useTranslation } from 'react-i18next';

export default function ReceiptContentTotal({ price, currency }) {
  const { t } = useTranslation('trips');

  return (
    <div className="py-5 border-t border-gray-200 flex justify-between items-center text-gray-800 font-medium">
      <div>
        {t('receipt.total')}
      </div>

      <div className="text-right">
        {formatCurrency({
          amount: price,
          currency: currency
        })}
      </div>
    </div>
  )
}
