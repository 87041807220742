import { useEffect, useRef } from 'react';
import { Form, Formik } from 'formik';
import TripDesigner from './TripDesigner';
import { useLocation, useNavigate } from 'react-router-dom';
import { useExperienceBooking, useTripDesigner } from '../../../hooks';
import Spinner from '../../../components/Spinner';
import KoobLayout from '../../../components/Koob/KoobLayout';

export default function TripComposer() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { tripData, tripFilters, loading } = useTripDesigner();
  const { resetBasket } = useExperienceBooking();
  const headerRef = useRef();
  const headerHeight = headerRef.current?.offsetTop || 0;

  useEffect(() => {
    if (!tripFilters) {
      navigate('/trip-designer/design-trip');
    }
    resetBasket([]);
  }, []);

  useEffect(() => {
    if (loading) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [loading]);

  return tripFilters ? (
    <KoobLayout>
      {loading && (
        <div className="absolute z-40 top-0 left-0 h-screen w-screen bg-black opacity-50">
          <Spinner />
        </div>
      )}

      <Formik
        initialValues={tripData ?? {}}
        onSubmit={() => {}}
      >
        <Form>
          <TripDesigner
            headerHeight={headerHeight}
            tripId={state?.tripId ?? tripFilters?.tripId}
          />
        </Form>
      </Formik>
    </KoobLayout>
  ) : null;
}
