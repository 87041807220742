import { get, put } from './';

export const getOrganizationCountry = (organizationId, countryId) => {
  return get(`/api/v1/organizations/${organizationId}/countries/${countryId}`);
};

export const updateOrganizationCountry = (
  organizationId,
  countryId,
  emails,
) => {
  return put(`/api/v1/organizations/${organizationId}/countries/${countryId}`, {
    bookingReceptionEmails: emails,
  });
};
