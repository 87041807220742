import { get } from './';

export const getCities = ({ search }) => {
  return get(`/api/v1/locations/city?search=${search}`);
}

export const searchLocations = (search, dmcId, locations, kind, locale='en') => {
  if (!search?.search) {
    return Promise.resolve({ data: [] });
  }

  let url = `/api/v1/locations?search=${search.search}`;

  if (dmcId && typeof dmcId !== 'object') {
    url += `&dmcId=${dmcId}`;
  }

  if (locations && locations.length > 0) {
    locations.forEach(location => {
      url += `&locations=${location}`;
    });
  }
  if (kind && kind === 'experiences') {
    url += `&isMarketPlaceSource=${true}`;
  }

  if (locale) {
    url += `&locale=${locale}`;
  }
  return get(url);
}

