import { Container } from 'ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import KoobTitle from 'components/Koob/KoobTitle';
import Spinner from 'components/Spinner';
import LocaleSwitcher from 'components/LocaleSwitcher';
import { Stack } from '@koob/margaret';
import ExperienceCard from 'components/Experience/ExperienceCard';
import ComposeHotelCard from './ComposeHotelCard';
import { useTripDesigner } from '../../../../hooks';

const KoobExperienceCount = styled.h6`
  margin-top: 15px;
  color: grey;
`;

const ComposeList = ({
  experiences,
  hotels,
  isLoading,
  refreshExperiences,
  addExperience,
  addHotel,
  getExperienceCompatibility
}) => {
  const { t } = useTranslation('experiences');
  const { searchHotels } = useTripDesigner();

  return (
    <Container>
      <Stack direction="row" alignX="space-between" gutterSize={1} className="pt-3">
        <KoobTitle>
          { searchHotels ? t('titleListHotel') : t('titleList')}
        </KoobTitle>

        <LocaleSwitcher />
      </Stack>

      <KoobExperienceCount>
        {t('results', { count: searchHotels ? hotels.length : experiences?.length })}
      </KoobExperienceCount>

      <div className="mt-3 grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {!searchHotels && (
          experiences.sort((a, b) => {
            const compatibilityA = getExperienceCompatibility(a);
            const compatibilityB = getExperienceCompatibility(b);
            if (compatibilityA === 'bestMatch' && compatibilityB !== 'bestMatch') {
              return -1;
            }
            if (compatibilityA !== 'bestMatch' && compatibilityB === 'bestMatch') {
              return 1;
            }
            if (compatibilityA === null && compatibilityB !== null) {
              return -1;
            }
            if (compatibilityA !== null && compatibilityB === null) {
              return 1;
            }
            return 0;
          }).map(experience => (
            <ExperienceCard
              experience={experience}
              key={experience?.id}
              refreshExperiences={refreshExperiences}
              mode="compose"
              compatibility={getExperienceCompatibility(experience)}
              isForTrip={true}
              onSelect={() => addExperience(experience?.id)}
            />
          ))
        )}

        {searchHotels && (
          hotels?.map((hotel) => (
            <ComposeHotelCard
              key={hotel.id}
              hotel={hotel}
              addHotel={addHotel}
              hotelWithPrice={hotel}
              isForTrip={true}
            />
          ))
        )}

        {isLoading && <Spinner noAbsolute />}
      </div>
    </Container>
  );
};

export default ComposeList;
