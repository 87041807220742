import { Disclosure, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import KoobNavigation from './KoobNavigation';
import React, {Fragment, useState} from 'react';
import {MdShoppingCart} from "react-icons/md";
import ExperiencesBasket from "../Experience/ExperiencesBasket";
import { useExperienceBooking } from '../../hooks';
import NotificationsDropdown from '../NotificationsDropdown';
import KoobNavbarTripDesigner from './KoobNavbarTripDesigner';

export default function KoobNavbar() {
  const { basket } = useExperienceBooking();

  const [barOpened, setBarOpened] = useState(undefined);

  return (
    <>
      <Disclosure as="nav" className="fixed w-full bg-white shadow-k z-40">
        {({ open,close }) => (
          <>
            <div className="px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-24 justify-between space-x-5">
                <div className="flex items-center" onClick={() => {
                  setBarOpened('Side');
                }}>
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-gray-500 focus:outline-none border-0">
                    <span className="sr-only">Open main menu</span>
                    {open && barOpened === 'Side' ? (
                      <XMarkIcon className="block h-10 w-10 mt-4 z-50" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-10 w-10" aria-hidden="true"/>
                    )}
                  </Disclosure.Button>
                </div>

                <div className="flex flex-1 space-x-16 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-28 w-auto"
                      src="/logo512.png"
                      alt="Koob Logo"
                    />
                  </div>

                  <KoobNavbarTripDesigner />
                </div>

                <div className="flex space-x-5 items-center">
                  <NotificationsDropdown/>

                  <div
                    className="flex items-center"
                    onClick={() => {
                      setBarOpened('Basket');
                    }}
                  >
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-gray-500 focus:outline-none border-0">
                      <span className="sr-only">Open basket</span>
                      {open && barOpened === 'Basket' ? (
                        <XMarkIcon className="block h-9 w-9 z-50" aria-hidden="true" />
                      ) : (
                        <div className="relative">
                          <MdShoppingCart className="block h-9 w-9" aria-hidden="true"/>
                          {basket.length > 0 && (
                            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-medium text-white bg-orange-500 border-2 border-white rounded-full -top-2 -right-2">
                              {basket.length}
                            </div>
                          )}
                        </div>
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </div>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                onClick={() => close()}
                className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity"
              />
            </Transition.Child>

            <Disclosure.Panel>
              {barOpened === 'Side' && (
                <KoobNavigation closeMenu={close} />
              )}

              {barOpened === 'Basket' && (
                <ExperiencesBasket closeMenu={close} />
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
