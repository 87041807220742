import { useAsync } from 'react-async';
import SelectField from './SelectField';
import { Stack } from '@koob/margaret';
import { getLanguages } from 'api/languages';

const SelectLanguageField = ({ name, label }) => {
  const { data: languagesData } = useAsync({ promiseFn: getLanguages });
  const languagages = languagesData?.data?.map(({ name, description }) => {
    return {
      label: name.toUpperCase(),
      value: name,
      alpha2: description.toLowerCase(),
    };
  });

  return (
    <SelectField
      options={languagages}
      label={label}
      name={name}
      renderSelectedValue={({ label, alpha2 }) => (
        <Stack gutterSize={0.375} alignY="center">
          <span className={`flag-icon flag-icon-${alpha2}`} />
          <span>{label}</span>
        </Stack>
      )}
      renderOption={({ label, alpha2 }) => (
        <Stack gutterSize={0.375} alignY="center">
          <span className={`flag-icon flag-icon-${alpha2}`} />
          <span>{label}</span>
        </Stack>
      )}
      required
    />
  );
};

export default SelectLanguageField;
