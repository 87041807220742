import i18n from 'i18next';

export default function formatHotelRoomTags(currentBed) {
  const bedChoice = currentBed?.bedChoice ?? currentBed?.bedChoices?.[0];

  const tags = bedChoice?.tags?.length > 0
    ? bedChoice?.tags
    : currentBed?.tags;

  return tags
    ?.filter(tag => !!tag.label)
    ?.map(tag => ({
      label: tag.label?.indexOf('_') !== -1 || tag.label?.indexOf('.') !== -1
        ? i18n.t(`booking:room.${tag.label}`)
        : tag.label,
      value: tag.label,
      startAt: tag.startAt,
      endAt: tag.endAt,
    }));
}
