import React from 'react';
import KoobModal from './KoobModal';
import KoobButton from './KoobButton';
import KoobMap from './KoobMap';
import { useTranslation } from 'react-i18next';

const KoobLocationModalComponent = ({
  markers,
  open,
  close,
  infoWindowContent,
}) => {
  const { t } = useTranslation('experience', { keyPrefix: 'detail' });

  return (
    <KoobModal
      open={!!open}
      setOpen={() => close()}
      widthClass="sm:max-w-5xl overflow-visible"
    >
      <KoobMap
        markers={markers}
        infoWindowContent={infoWindowContent}
        style={{
          width: '100%',
          height: '500px',
          marginTop: '30px',
          borderRadius: '15px',
        }}
      />
      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton onClick={() => close()} color="secondary">
          {t('misc:cancel')}
        </KoobButton>
      </div>
    </KoobModal>
  );
};

export default KoobLocationModalComponent;
