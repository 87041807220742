import {
  ButtonReset,
  Stack,
  TableWrapper as MgtTableWrapper,
} from '@koob/margaret';
import styled, { css } from 'styled-components';

export const TableWrapper = styled(MgtTableWrapper)`
  width: 100%;
  overflow-x: auto;
  --tw-shadow: 0 0 5px 3px rgba(214, 214, 214, 0.2);
  --tw-shadow-colored: 0 0 5px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 0.75rem;
  background-color: white;
  padding: 2rem;
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;

  ${({ variant }) =>
    variant === 'bordered' &&
    css`
      table-layout: auto;

      ${Td}, ${Th} {
        border: 1px solid #e0e0e0;
        padding-right: ${({ theme }) => theme.spacing(0.75)};
      }

      ${Tr} > ${Th}:first-child,
      ${Tr} > ${Td}:first-child {
        border-left: 0;
      }

      ${Tr} > ${Th}:last-child,
      ${Tr} > ${Td}:last-child {
        border-right: 0;
      }

      ${Th} {
        border-top: 0;
      }

      ${TBody}:last-child ${Td} {
        border-bottom: 0;
      }
    `}
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Th = styled(Stack).attrs({ as: 'th' })`
  font-size: 14px;
  font-weight: 600;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  padding-left: ${({ theme }) => theme.spacing(0.75)};

  &:last-child {
    padding-right: ${({ theme }) => theme.spacing(0.75)};
  }

  ${({ alignX }) =>
    alignX === 'center' &&
    css`
      text-align: center;
    `}

  ${({ alignX }) =>
    alignX === 'flex-end' &&
    css`
      text-align: right;
    `}

  ${({ variant }) =>
    variant === 'numeral' &&
    css`
      text-align: right;
    `}
`;

Th.defaultProps = {
  paddingVertical: 0.75,
};

export const Td = styled(Stack).attrs({ as: 'td' })`
  border-top: 1px solid ${({ theme }) => theme.separatorLight};
  display: table-cell;
  vertical-align: middle;
  overflow: hidden;
  overflow-x: ellipsis;
  padding-left: ${({ theme }) => theme.spacing(0.75)};

  &:last-child {
    padding-right: ${({ theme }) => theme.spacing(0.75)};
  }

  ${({ isLight, theme }) =>
    Boolean(isLight) &&
    css`
      color: ${theme.textLighter};
    `}

  ${({ variant }) =>
    variant === 'bareRight' &&
    css`
      padding-right: 0;
    `}

  ${({ variant }) =>
    variant === 'numeral' &&
    css`
      text-align: right;
    `}

  ${({ allowOverflow }) =>
    Boolean(allowOverflow) &&
    css`
      overflow: visible;
    `}

  ${({ verticalAlign }) =>
    Boolean(verticalAlign) &&
    css`
      vertical-align: ${verticalAlign};
    `}
`;

Td.defaultProps = {
  paddingVertical: 0.75,
};

export const ColumnHeader = styled(ButtonReset)`
  white-space: nowrap;

  svg {
    color: ${({ theme }) => theme.textLighter};
    transition: transform 150ms ease, color 150ms ease;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      svg {
        color: ${({ theme }) => theme.text};
      }
    `}

  ${({ svgShouldGetUpsideDown }) =>
    svgShouldGetUpsideDown &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `}

  &[disabled] {
    cursor: default;
  }
`;

export const Tr = styled.tr`
  ${({ isDragged }) =>
    isDragged &&
    css`
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0 4px 8px ${({ theme }) => theme.shadowFocus};

      > ${Td} {
        border-top: 0;
      }
    `}
`;

export const Content = ({ render, value }) =>
  render ? render() : <span>{value}</span>;

export const formatAmount = ({ amount, currency = 'USD' }) =>
  new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency,
  }).format(amount);
