import TextField from './TextField';
import { useField } from 'formik';

const PhoneNumberField = props => {
  const [ , , helpers] = useField(props);
  const setFieldValue = helpers.setValue;
  const onChange = value => {
    setFieldValue(value.target.value.replace(/[^+\d]|(?<!^)\+/g, ''));
  };

  return <TextField {...props} onChange={value => onChange(value)} />;
};

export default PhoneNumberField;
