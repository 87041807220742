import moment from 'moment/moment';
import ExportSection from './ExportSection';
import React from 'react';

export default function ExportDaySection({ dayData, children }) {
  const { trip, tourOperator, day, index, experience, program, startAt, endAt } = dayData;
  const tripDate =
    startAt && endAt
      ? `${moment(startAt).format('DD/MM/YYYY')} — ${moment(endAt).format('DD/MM/YYYY')}`
      : moment(trip?.startDate).add(index, 'days').format('DD/MM/YYYY');

  return (
    <ExportSection
      title={`Day ${index + 1} - ${program?.title ?? experience?.name}`}
      subtitle={`${!trip?.template ? `${tripDate} — ` : ''}${program?.startPosition?.city}, ${program?.startPosition?.country}`}
      badge={day.dayIndex + '/' + Math.max(trip?.duration, trip?.tripDays?.length)}
      trip={trip}
      tourOperator={tourOperator}
    >
      {children}
    </ExportSection>
  )
}
