import { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import App from './App';
import Login from 'containers/Auth/Login';
import SignUp from 'containers/Auth/SignUp';
import { MargaretProvider } from '@koob/margaret';
import { theme } from 'ui/theme';
import AuthProvider from './providers/AuthProvider';
import SnackProvider from 'providers/SnackProvider';
import 'sanitize.css';
import 'sanitize.css/typography.css';
import 'sanitize.css/forms.css';
import 'flag-icon-css/css/flag-icon.css';

import './index.css';
import Impersonate from './containers/Auth/Impersonate';
import { Navigate } from 'react-router';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  defaultNS: 'shared',
  resources: {
    fr: require('./locales/fr.json'),
    en: require('./locales/en.json'),
  },
});

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<div />}>
      <MargaretProvider theme={theme}>
        <SnackProvider>
          <AuthProvider>
            <Router>
              <Routes>
                <Route path="login" element={<Login />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="sign-in" element={<Navigate to="/sign-up"/>} />
                <Route path="impersonate/:token" element={<Impersonate />} />
                <Route path="/*" element={<App />} />
              </Routes>
            </Router>
          </AuthProvider>
        </SnackProvider>
      </MargaretProvider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
