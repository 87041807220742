import styled, { css } from 'styled-components';
import {
  Stack,
  Box,
  media,
  ButtonReset,
} from '@koob/margaret';
import { Link as RawLink, NavLink } from 'react-router-dom';
import { fontStyles } from './theme';

export const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 6em;
  width: fit-content;
  background-color: ${({ theme }) => theme.secondaryBackground};
  border-radius: 4px;
  padding: 2px ${({ theme }) => theme.spacing(0.5)};
  font-size: 14px;
  color: ${({ theme }) => theme.secondary};

  svg {
    font-size: 20px;
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
      background-color: ${theme.primaryLight};
      color: ${theme.primary};
  `}

  ${({ variant, theme }) =>
    variant === 'green' &&
    `
    background-color: ${theme.greenLight};
    color: ${theme.green};
  `}

  ${({ variant, theme }) =>
    variant === 'gray' &&
    `
    background-color: ${theme.disabled};
    color: ${theme.textLight};
  `}

  ${({ variant, theme }) =>
    variant === 'red' &&
    `
    background-color: ${theme.redLighter};
    color: ${theme.red};
  `}

  ${({ variant, theme }) =>
    variant === 'blue' &&
    `
    background-color: ${theme.blueLighter};
    color: ${theme.blue};
  `}

  ${({ size, theme }) =>
    size === 'big' &&
    `
      padding: ${theme.spacing(0.5)} ${theme.spacing(0.75)};
  `}
`;

export const Link = styled(RawLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
`;

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing()};
  flex-direction: column;
  display: flex;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `}

  ${({ noPadding }) =>
  Boolean(noPadding) &&
  `
      padding: 0 !important;
    `}

  ${({ barePaddingVertical }) =>
    Boolean(barePaddingVertical) &&
    `
      padding-top: 0;
      padding-bottom: 0;
    `}
`;

styled(Stack).attrs({
  gutterSize: 0.5,
  alignY: 'center',
  alignX: 'flex-end',
  size: 'full',
})``;

styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.textLighter};
  font-size: 24px;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
`;

styled(ButtonReset).attrs({ type: 'button' })`
  display: flex;
  color: ${({ theme }) => theme.textLighter};
  font-size: 24px;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }

  ${({ variant, theme }) =>
    variant === 'danger' &&
    `
      &:hover {
        color: ${theme.danger};
      }
    `}
`;

styled.a.attrs({
  target: '_blank',
  rel: 'noopener, noreferrer',
})`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.secondary};
  text-decoration: none;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryLight};
  }
`;

export const Text = styled(Box).attrs(({ as, type = 'body', theme }) => ({
  as: as || theme?.fontSizes?.[type]?.defaultTag || type || 'span',
}))`
  ${({ type, theme }) =>
    Boolean(type) &&
    Boolean(theme?.fontSizes?.[type]) &&
    css`
      ${fontStyles?.[type]}
    `}

  ${({ marginTop, marginVertical, margin }) =>
    !Boolean(marginTop) &&
    !Boolean(marginVertical) &&
    !Boolean(margin) &&
    `
      margin-top: 0;
    `}

  ${({ marginBottom, marginVertical, margin }) =>
    !Boolean(marginBottom) &&
    !Boolean(marginVertical) &&
    !Boolean(margin) &&
    `
      margin-bottom: 0;
    `}

  ${({ color, theme }) =>
    Boolean(color) &&
    `
      color: ${theme?.colors?.[color] || theme?.[color]};
    `}

  ${({ fontWeight }) =>
    Boolean(fontWeight) &&
    `
      font-weight: ${fontWeight};
    `}
`;

styled(Stack).attrs({
  gutterSize: 1,
})``;

styled(NavLink)`
  display: flex;
  text-decoration: none;
  font-weight: 600;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  background-color: #ffffff;
  color: ${({ theme }) => theme.textLight};
  border: none;
  box-shadow: none;
  border-radius: 4px;

  &.active {
    background-color: ${({ theme }) => theme.primaryLight};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.primary},
      0 1px 2px 0 ${({ theme }) => theme.primaryBackground};
    color: ${({ theme }) => theme.primary};
  }

  ${({ variant, theme }) =>
    variant === 'bordered' &&
    `
      box-shadow: 0 0 0 1px ${theme.separator},
        0 1px 2px 0 ${theme.separator};
    `}

  ${({ size }) =>
    size === 'large' &&
    `
      min-width: 320px;
  `}
`;

export const MailTo = styled.a.attrs({
  target: '_blank',
  rel: 'noopener, noreferrer',
})`
  ${fontStyles.bodySmall};
  color: ${({ theme }) => theme.secondary};
  text-decoration: none;
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryLight};
  }
`;

export const Label = styled.div`
  ${fontStyles?.bodySmall}
  color: ${({ theme }) => theme.textLighter};
`;

export const ActionsLinks = styled(Stack).attrs({
  gutterSize: 1,
  alignY: 'center',
  alignX: 'flex-end',
  size: 'full',
})``;

export const ActionLink = styled(Link)`
  display: flex;
  color: ${({ theme }) => theme.textLighter};
  font-size: 24px;

  &:hover {
    color: ${({ theme }) => theme.secondary};
  }
    
  &:disabled {
    color: ${({ theme }) => theme.disabled};
    pointer-events: none;
  }
`;

export const Card = styled(Box)`
  padding: 1.5rem;
  background-color: ${({theme}) => theme.containerBackground};
  box-shadow: 0 0 5px 3px rgba(214, 214, 214, 0.2);
  border-radius: 0.75rem;
  text-align: inherit;
  text-decoration: none;
  color: inherit;

  ${({variant, theme}) =>
          variant === 'small' &&
          `
      padding: ${theme.spacing(0.75)};
    `}
`;

export const SectionCard = styled(Card).attrs({ size: 'full', as: 'section' })`
  padding: ${({ theme }) => theme.spacing()};
  height: min-content;

  ${({ isUpdated, theme }) =>
    isUpdated &&
    `
      background-color: ${theme.orangeLighter}
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${({ theme }) => theme.primary};
    `}

  ${({ variant }) =>
    variant === 'archive' &&
    css`
      background-color: ${({ theme }) => theme.yellowLight};
      background: repeating-linear-gradient(
        45deg,
        ${({ theme }) => theme.separatorLight},
        ${({ theme }) => theme.separatorLight} 16px,
        ${({ theme }) => theme.yellowLight} 16px,
        ${({ theme }) => theme.yellowLight} 32px
      );
    `}

    ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled}
    `}
`;

export const Caption = styled(Box)`
  ${fontStyles?.bodySmall}
  font-weight: 600;
  color: ${({ theme }) => theme.textLighter};
`;

styled(Card)`
  width: 100%;
`;

export const EmptyStateWrapper = styled(Card)`
  width: 100%;
`;

styled(Box)`
  ${fontStyles?.bodySmall}
  font-weight: 600;
  color: ${({ theme }) => theme.textLighter};
`;

styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

styled(Card).attrs({ size: 'full', as: 'section' })`
  padding: ${({ theme }) => theme.spacing()};
  height: min-content;

  ${({ isUpdated, theme }) =>
    isUpdated &&
    `
      background-color: ${theme.orangeLighter}
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${({ theme }) => theme.primary};
    `}

  ${({ variant }) =>
    variant === 'archive' &&
    css`
      background-color: ${({ theme }) => theme.yellowLight};
      background: repeating-linear-gradient(
        45deg,
        ${({ theme }) => theme.separatorLight},
        ${({ theme }) => theme.separatorLight} 16px,
        ${({ theme }) => theme.yellowLight} 16px,
        ${({ theme }) => theme.yellowLight} 32px
      );
    `}

    ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled}
    `}
`;

styled(ButtonReset).attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  line-height: 1.2;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  background-color: #ffffff;
  color: ${({ theme }) => theme.textLight};
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.separator};
  border: solid 1px ${({ theme }) => theme.separator};
  border-radius: 4px;

  ${({ isActive, theme }) =>
    isActive &&
    `
      background-color: ${theme.primaryLight};
      border: solid 1px ${theme.primary};
      box-shadow: 0 1px 2px 0 ${theme.primaryBackground};
      color: ${theme.primary};
  `}

  ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled};
      border: solid 1px ${theme.disabled};
      box-shadow: revert;
      color: ${theme.textLight};
  `}
`;

export const CardTitle = styled.div`
  ${fontStyles?.bodyLarge};
  font-weight: bold;
`;

export const Troncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${props => props.maxWidth}px;
`;

