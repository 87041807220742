import React from 'react';
import DocumentUpload from './Partials/DocumentUpload';
import { useAsync } from 'react-async';
import { getTripDocuments } from '../../../api/trip';
import DocumentList from '../../Hotel/Booking/Edit/Documents/Partials/DocumentList';

export default function TripDocuments({tripId, conversationId}) {

  const { data, reload } = useAsync({
    promiseFn: getTripDocuments,
    tripId,
    conversationId
  });

  return (
    <div className="flex flex-col space-y-5 mt-5">
      <DocumentUpload
        tripId={tripId}
        conversationId={conversationId}
        onUpload={reload}
      />

      <DocumentList
        documents={data?.data ?? []}
      />
  </div>
  );
}
