import { BookingContext } from 'contexts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {uniqueId} from "lodash";
import {differenceInYears} from "date-fns";
import { useAsync } from 'react-async';
import { getAllCountries } from 'api/countries';
import { DEFAULT_LOCAL } from 'constants';

export const getTravelerComposition = ({
  compositions,
  customerFile,
  typeFieldName = 'type'
}) => {
  const travelerRooms = (compositions || []).reduce((acc, curr) => {
    const peoples = [];
    for (let i = 0; i < curr.adults; i++) {
      const id = uniqueId();
      const people = { id}
      people[typeFieldName] = 'adult';
      peoples.push(people);
    }
    for (let j = 0; j < curr.children; j++) {
      const id = uniqueId();
      const people = { id}
      people[typeFieldName] = 'child';
      peoples.push(people);
    }
    acc.push({ ...curr, travelers: peoples });
    return acc;
  }, []);

  if (customerFile) {
    let adultIndex = 0;
    let childIndex = 0;
    const adults = customerFile.travelers.filter((traveler) => !!traveler.birthdate && differenceInYears(new Date(),new Date(traveler.birthdate)) >= 18);
    const childs = customerFile.travelers.filter((traveler) => !!traveler.birthdate && differenceInYears(new Date(),new Date(traveler.birthdate)) < 18);
    travelerRooms.forEach((room) => {
      room.travelers.forEach((traveler,index) => {
        let person = undefined;
        if (traveler[typeFieldName] === 'adult') {
          if (adultIndex < adults.length) {
            person = adults[adultIndex];
            adultIndex++;
          }
        } else {
          if (childIndex < childs.length) {
            person = childs[childIndex];
            childIndex++;
          }
        }
        if (person) {
          traveler.id = person.id,
          traveler.gender = person.gender;
          traveler.firstname = person.firstName;
          traveler.lastname = person.lastName;
          traveler.birthdate = person.birthdate;
          traveler.nationality = index === 0 && traveler[typeFieldName] === 'adult'
                ? room?.firstAdultNationality?.value || person.nationality
                : person.nationality,
          traveler.passportNumber = person.passportNumber;
          traveler.expirationDate = person.passportExpirationDate;
          traveler.ageIsExact = person?.ageIsExact;
        }else{
          traveler.nationality = index === 0 && traveler[typeFieldName] === 'adult' && room?.firstAdultNationality?.value
        }
      });
    });
  } else{
    travelerRooms.forEach((room) => {
      room.travelers.forEach((traveler, index) => {
        if (index === 0 && traveler.type === 'adult') {
          traveler.nationality = room?.firstAdultNationality?.value;
        } else {
          traveler.nationality = null;
        }
      });
    });

  }
  return travelerRooms;
}

const BookingProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [currentSelectedRoom, setCurrentSelectedRoom ] = useState();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [customerFile, setCustomerFile] = useState(undefined);
  const [requestId, setRequestId ] = useState();
  const [expirationDate, setExpirationDate ] = useState();
  const [replaceBookingId, setReplaceBookingId] = useState(undefined);
  const [guests, setGuests] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);

  const resetBooking = useCallback(() => {
    setCurrentSelectedRoom(undefined);
    setSelectedRooms([]);
    setTravelerRooms([]);
    setExpirationDate(undefined);
  }, []);

  const resetContext = useCallback(() => {
    setFilters({});
    setCustomerFile(undefined);
  }, []);

  const [travelerRooms, setTravelerRooms] = useState([]);
  const [nationalities, setNationalities] = useState([]);

  const { data: countriesData } = useAsync({
    promiseFn: getAllCountries,
  });
  const countries = useMemo(() => {
    return (countriesData?.data ?? []).map(country => ({
      value: country?.alpha2,
      label: country?.translations.find(elem => elem.locale === DEFAULT_LOCAL).title,
    }));
  }, [countriesData]);

  useEffect(() => {
    setNationalities(countries);
  }, [countries, setNationalities]);

  const value = {
    filters,
    setFilters,
    resetBooking,
    currentSelectedRoom,
    setCurrentSelectedRoom ,
    requestId,
    setRequestId,
    selectedRooms,
    setSelectedRooms: param => {
      setSelectedRooms(param);
      setTravelerRooms(
        getTravelerComposition({
          compositions: filters.rooms,
          customerFile: customerFile,
        }),
      );
    },
    travelerRooms,
    customerFile,
    setCustomerFile,
    resetContext,
    expirationDate,
    setExpirationDate,
    nationalities,
    setNationalities,
    replaceBookingId,
    setReplaceBookingId,
    guests,
    setGuests,
    selectedSupplements,
    setSelectedSupplements,
  };

  return (
    <BookingContext.Provider value={value}>{children}</BookingContext.Provider>
  );
};

export default BookingProvider;
