import moment from "moment";

export function getPricingForCompositionExtra({ composition, pricings = [] }) {
  const adults = composition?.adults ?? composition?.adultCount ?? 0;
  const childrenBirthdates = composition?.childrenBirthdates ?? [];
  const formattedChildrenBirthdates = childrenBirthdates.map((birthdate) =>
    moment(birthdate).format('YYYY-MM-DD'),
  );

  return pricings?.find((price) => {
    return (
      price.validForAdultCount === adults &&
      price.validForChildrenBirthdates.length === childrenBirthdates.length &&
      price.validForChildrenBirthdates.every((birthdate) =>
        formattedChildrenBirthdates.includes(birthdate),
      )
    );
  });
}

export function formatExtraPrice(composition, pricing){
  const participants =
    (composition?.adults ?? 0) +
    (composition?.childrenBirthdates?.length ?? 0);

  if (pricing?.perPax) {
    return {
      ...pricing,
      price: pricing?.price / participants,
      priceWithoutPromo: pricing?.priceWithoutPromo / participants,
    };
  } else {
    return pricing;
  }
}