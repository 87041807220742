import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import MapMarker from 'components/Hotel/MapMarker';
import { GOOGLE_MAPS_API_KEY } from 'constants';
import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

const containerStyle = {
  width: '100%',
  height: '200px',
  marginTop: '30px',
  borderRadius: '15px',
};

const KoobMap = ({ markers, infoWindowContent, style }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });
  const [map, setMap] = useState();
  const onLoad = mapInstance => {
    if (!Boolean(map)) {
      setMap(mapInstance);
    }
  };

  useDeepCompareEffect(() => {
    if (!Boolean(map) || markers.length === 0) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    markers
      .filter(marker => Boolean(marker?.lat) || Boolean(marker?.lon))
      .forEach(marker =>
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat('' + marker?.lat),
            parseFloat('' + marker?.lon),
          ),
        ),
      );
    if (markers.length === 1) {
      map.setCenter(bounds.getCenter());
      map.setZoom(12);
    } else {
      map.fitBounds(bounds);
    }

  }, [markers, map]);

  const onUnmount = () => {
    setMap(null);
  };

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={style ?? containerStyle}
          options={{
            maxZoom: 20,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          zoom={8}
        >
          {(markers ?? []).map((marker, index) => (
            <MapMarker
              key={index}
              lat={marker?.lat}
              lon={marker?.lon}
              infoWindowContent={infoWindowContent}
              name={marker?.name}
              address={marker?.address}
              title={marker?.title}
              Icon={marker?.icon}
            ></MapMarker>
          ))}
        </GoogleMap>
      )}
    </>
  );
};

export default KoobMap;
