import LocalMultiSearchableSelect from './LocalMultiSearchableSelect';
import { useField } from 'formik';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';
import { Stack } from '@koob/margaret';
import { isArray, isEqual } from 'lodash';

const formatError = error => {
  if (isArray(error)) {
    return error.filter(Boolean)?.[0];
  }
  return error;
};

const LocalMultiSearchableSelectField = ({
  name,
  label,
  rightLabel,
  onSelectOption,
  touchRequired = true,
  options,
  required,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField({ name });
  const hasError =
    (!touchRequired || Boolean(touched)) && Boolean(formatError(error));
  Boolean(touched) && Boolean(formatError(error));

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        {label && (
          <FormLabel>
            {label}
            {required && <span className="ml-1 text-red-500">*</span>}
          </FormLabel>
        )}
        {rightLabel}
      </Stack>
      <LocalMultiSearchableSelect
        {...props}
        excludedValues={(value || []).map(option => option.value)}
        onChange={value => setValue(value)}
        value={value}
        hasError={hasError}
        error={hasError && error}
        onSelectOption={option => {
          if (Boolean(value)) {
            setValue([...value, option]);
          } else {
            setValue([option]);
          }
          if (onSelectOption) {
            onSelectOption(option);
          }
        }}
        onUnselectOption={option =>
          setValue(value.filter(valueChunk => !isEqual(option, valueChunk)))
        }
        optionList={options}
      />
      {Boolean(hasError) && (
        <ErrorMessageWrapper>{formatError(error)}</ErrorMessageWrapper>
      )}
    </FormField>
  );
};

export default LocalMultiSearchableSelectField;
