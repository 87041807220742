import { post, put } from '../api';
import { useAsync } from 'react-async';
import { Popover } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getConversation, getTrip } from '../api/trip';

const getNotifications = async () => {
  return post('/api/v1/notifications');
}

const markAsRead = async (id) => {
  return put(`/api/v1/notifications/${id}/read`);
}

function BellIconLabel({ children }) {
  return (
    <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-medium text-white bg-orange-500 border-2 border-white rounded-full -top-2 -right-2">
      {children}
    </div>
  );
}

export default function NotificationsDropdown() {
  const { t } = useTranslation('notifications');
  const navigate = useNavigate();

  const { data, loading, reload } = useAsync({
    promiseFn: getNotifications,
    onResolve: () => {
      setTimeout(() => reload(), 15 * 1000);
    },
    onReject: () => {
      setTimeout(() => reload(), 30 * 1000);
    }
  });

  const onNotificationClick =  (notification) => {
    markAsRead(notification.id)
      .then(async() => {
        let relatedId = notification?.relatedObject;
        let data;
        let state =  { state: {from: 'draftEdit'} };
        let prefix = '?from=draftEdit';
        switch(notification.notificationType) {
          case 'trip_help_requested':
            data = await getConversation(notification?.relatedObject);
            relatedId = data.data.trip;
            break;
          case 'trip_new_proposal':
            data = await getTrip({tripId: notification?.relatedObject});
            relatedId = data?.data?.clientFolder?.id;
            prefix = '';
            state = undefined
            break;
          default:
            break;
        }
        const link = (linkByType[notification.notificationType] + relatedId + prefix)
        reload();
        navigate(link,state);

      });
  }

  const notifications = data?.data;
  const unreadNotificationsCount = notifications?.filter(n => n.read === false).length;

  const iconByType = {
    'trip_help_requested': 'far fa-hands-helping',
    'trip_new_version_available': 'fa-regular fa-download',
  }

  const linkByType = {
    'trip_help_requested': '/trip-designer/design-trip/',
    'trip_new_version_available': '/trip-designer/design-trip/',
    'trip_new_proposal': '/folders/',
  }

  return (
    <Popover className="relative">
      <Popover.Button className="border-none focus:outline-none">
        <div className="relative">
          <i className="far fa-bell text-3xl text-gray-400 hover:text-gray-500 transition" />
          {loading && (
            <BellIconLabel>
              <i className="far fa-refresh animate-spin" />
            </BellIconLabel>
          )}
          {unreadNotificationsCount > 0 && (
            <BellIconLabel>
              {unreadNotificationsCount}
            </BellIconLabel>
          )}
        </div>
      </Popover.Button>

      <Popover.Panel className="fixed right-20 top-20 z-10">
        <div className="bg-white h-72 w-[500px] overflow-y-scroll rounded-lg shadow-k p-3">
          {notifications?.length > 0 ? (
            <div>
              {notifications?.map((notification) => (
                <div
                  key={notification.id}
                  className="border-b cursor-pointer"
                  onClick={() => onNotificationClick(notification)}
                >
                  <div className="hover:bg-gray-50 py-3 px-4 transition rounded">
                    <div className="flex space-x-3 items-center">
                      <div className='w-8'>
                        <i
                          className={[
                            iconByType[notification.notificationType],
                            'text-2xl',
                            notification.read ? 'text-gray-400' : 'text-orange-500',
                          ].join(' ')}
                        />
                      </div>

                      <div className="w-full flex justify-between items-center">
                        <div className="text-sm font-medium text-gray-700">
                          {t('types.' + notification.notificationType)}
                        </div>

                        <div className="text-xs text-gray-400">
                          {moment(notification.createdAt).fromNow()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-full flex justify-center items-center">
              <div className="text-gray-400 text-center">
                <i className="far fa-inbox text-4xl mb-3" />
                <div className="text-lg">
                  {t('emptyState.title')}
                </div>
                <div className="text-sm">
                  {t('emptyState.description')}
                </div>
              </div>
            </div>
          )}
        </div>
      </Popover.Panel>
    </Popover>
  );
}
