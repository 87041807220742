import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useDeepCompareEffect } from 'react-use';
import { GOOGLE_MAPS_API_KEY } from '../../../../constants';
import KoobTitle from 'components/Koob/KoobTitle';
import ExperienceMarker from "components/Experience/ExperienceMarker";
import { useApp, useBooking, useExperienceBooking, useSnack, useTripDesigner } from '../../../../hooks';
import ComposeDay from './ComposeDay';
import { useField } from 'formik';
import KoobButton from '../../../../components/Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import MapHotelMarker from './MapHotelMarker';
import { getExperiences } from "../../../../api/experiences";
import { useNavigate } from "react-router-dom";
import { saveTripDays } from '../../../../api/trip';
import { decodeId, formatCurrency, formatDate } from '../../../../utils';
import CreateTemplateModal from './CreateTemplateModal';
import { Tooltip } from '../../../../components';
import { createFolder, getFolder } from 'api/folders';
import { uniqueId } from 'lodash';
import ComposeVersionCompare from './ComposeVersionCompare';
import ComposeMapItinerary from './ComposeMapItinerary';
import FoldersGuestsModal from  '../../../../containers/Folders/Partials/FoldersGuestsModal';
import ExperienceProgramContentHeader from '../../../Experiences/Partials/ExperienceProgramContentHeader';
import KoobLoaderFullscreen from '@koob/ui/src/components/KoobLoaderFullscreen';
import { ExtraScopeEnum } from '@koob/enums';

const Sidebar = styled.div`
  background-color: #ffffff;
  width: ${({ compare }) => compare ? '1000' : '550'}px;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.12);
  height: calc(95vh - ${({ offsetTop }) => offsetTop}px);
  top: ${({ offsetTop }) => offsetTop + 35}px;
  overflow-y: auto;
  position:fixed;
  z-index: 1;
  border-radius: 10px;
`;

const ExperiencesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: 100%;
  grid-template-rows: auto;
  height: 100%;
`;

const MapWrapper = styled.div`
  position: fixed;
  top: ${({ offsetTop }) => offsetTop}px;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ComposeMap = ({
  experiences,
  hotels,
  headerHeight,
  addExperience,
  addHotel,
  tripId,
  getExperienceCompatibility,
  reloadTrip,
  currentVersion,
  formatExtras,
  setFormatExtras,
  itineraryMode
}) => {
  const { t } = useTranslation('tripDesigner');
  const { setExperienceFilters, addExperienceToBasket } = useExperienceBooking();
  const { notify } = useSnack();
  const {
    tripFilters,
    tripData,
    compareWithVersion,
    setCompareWithVersion,
    setSelectedExtras,
    searchHotels,
    selectedExtras,
    setLoading,
    compareVersionTotalPrice
  } = useTripDesigner();
  const { setFilters } = useBooking();
  const { user } = useApp();
  const navigate = useNavigate();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const [{ value: days }] = useField('days');

  const [map, setMap] = useState();
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [canSaveAsTemplate, setCanSaveAsTemplate] = useState(true);
  const [saving, setSaving] = useState(false);
  const [finalizing, setFinalizing] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [drafting, setDrafting] = useState(false);
  const [hasCurrencyMismatch, setHasCurrencyMismatch] = useState(false);

  const onLoad = mapInstance => {
    if (!Boolean(map)) {
      setMap(mapInstance);
    }
  };

  const isLoading = tripData?.days?.some(item => Boolean(item?.hotel?.isLoading));
  const tripGuestRequired = tripFilters?.composition;

  useDeepCompareEffect(() => {
    if (!Boolean(map)) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    experiences
      .filter((experience) => Boolean(experience?.inCity?.lat) || Boolean(experience?.inCity?.lon))
      .forEach((experience) =>
        bounds.extend(new window.google.maps.LatLng(parseFloat('' + experience?.inCity?.lat), parseFloat('' + experience?.inCity?.lon))),
      );
    if (experiences.length === 0) { // default bounds if no experiences
      bounds.extend(new window.google.maps.LatLng(50.633, 3.066));
    }
    map.fitBounds(bounds);
  }, [experiences, map]);

  const onUnmount = () => {
    setMap(null);
  };

  const setOptions = (index, value) => {
    setSelectedExtras(prev => {
      const newExtras = [...prev];
      newExtras[index] = value;
      return newExtras;
    })
  };

  const isTripComplete = useMemo(() => {
    return days?.length >= tripFilters.tripDuration
      && days?.every(day => day?.experience)
      && days?.every(day => {
        return day?.hotel
          || day.hotelBookedOnMyOwn === true
          || (
            day?.experience?.programs
            && day?.experience?.programs[(day?.experience?.dayIndex ?? 1) - 1]
            && ['noAccomodation', 'nightTransfer'].includes(day?.experience?.programs[(day?.experience?.dayIndex ?? 1) - 1]?.accomodationType)
          );
      });
  }, [days, tripFilters]);

  const isFormValidForTemplate = useMemo(() => {
    return isTripComplete && canSaveAsTemplate;
  }, [days, tripFilters]);

  const suggestedHotels = useMemo(() => {
    const results = [];

    days
      ?.filter(d => d?.experience?.programs?.length > 0)
      ?.map(day => {
        day.experience.programs.map(program => {
          if (
            program.accomodationHotel
            && !results.some(p => p.id === program.accomodationHotel?.id)
          ) {
            const matchedAccomodationHotel = hotels?.find((hotel) => hotel?.id === program.accomodationHotel?.id);
            if (matchedAccomodationHotel) {
              results.push(matchedAccomodationHotel);
            }
          }
          program?.alternativeHotels?.map(hotel => {
            if (!results.some(p => p.id === hotel.id)) {
              const matchedAlternativeHotel = hotels?.find((hotel) => hotel?.id === program.alternativeHotels?.id);
              if (matchedAlternativeHotel) {
                results.push(matchedAlternativeHotel);
              }
            }
          });
        }).filter(Boolean);
      }).filter(Boolean);

    return results;
  }, [days, hotels]) ?? [];

  const isNotFirstExperience = experiences?.[0] || !isFirstRender
  if (isNotFirstExperience && isFirstRender) {setIsFirstRender(false)}

  const getHotelDayIndex = (hotel, dayIndex) => {
    let currentIndex = 0;
    let totalDays = 0;

    for (let i = 0; i < days?.length; i++) {
      if (
        days?.[i]?.hotel
        && !days?.[i]?.hotel?.isIncluded
        && days?.[i]?.hotel?.id === hotel?.id
      ) {
        if (i <= dayIndex) {
          currentIndex++;
        }
        totalDays++;
      } else {
        if (i >= dayIndex) {
          break;
        }
        currentIndex = 0;
        totalDays = 0;
      }
    }

    return {
      current: currentIndex,
      total: totalDays
    }
  }

  const getSelectedHotelsForProgram = (experienceId) => {
    return days.reduce((acc, { experience, hotel }) => {
      if (experience.id === experienceId && hotel) {
        acc.push(hotel);
      }
      return acc;
    }, []);
  };

  const createClientFolder = async () => {
    const icr =
      user.lastName.substring(0, Math.min(3, user.lastName.length))
      + user.firstName.substring(0, Math.min(3, user.firstName.length))
      + uniqueId();

    const newFolderData = await createFolder({
      icr: icr,
      name: user.lastName + ' ' + icr,
      travelers: [],
    });
    const newFolder = newFolderData.data;
    tripFilters.clientFolder = newFolder;

    return newFolder;
  };

  const updateFolder = async () => {
    const newFolderData = await getFolder({id: tripFilters?.clientFolder?.id});
    const newFolder = newFolderData.data;
    tripFilters.clientFolder = newFolder;

    return newFolder;
  }

  const buildBasket = async (dayIndex) => {
    const day = days[dayIndex];

    const experience = day.experience;
    experience.tripId = tripId;
    const startDate = new Date(tripFilters?.departureDate);
    startDate.setDate(startDate.getDate() + dayIndex);
    const endDate = new Date(startDate);
    const durationDays = experience?.durationDays >= 2 ? experience?.durationDays - 1 : 0;
    endDate.setDate(endDate.getDate() + durationDays);

    experience.filters = {
      ...experience.filters,
      experienceComposition: tripFilters?.composition,
      startAt: startDate,
      endAt: endDate
    };

    if (tripFilters.clientFolder) {
      setExperienceFilters({
        ...experience.filters,
        clientFolder: tripFilters.clientFolder
      });
    }

    if (!experience.dayIndex || experience.dayIndex === 1) {
      const newExp = await getExperiences({
        filters: {
          ...experience.filters,
          experiences: [experience.id]
        },
        locale: 'en'
      });
      setFilters(experience.filters);
      setExperienceFilters(experience.filters);

      const selectedHotels = experience?.programs?.reduce((acc, program, index) => {
        if (program.accomodationType === 'hotel') {
          acc.push({
            ...getSelectedHotelsForProgram(experience?.id)[index],
            programId: program.id,
          })
        }
        return acc;
      }, [])?.filter(sHotel => sHotel.hotelId);

      addExperienceToBasket(
        {
          ...experience,
          selectedExtras: selectedExtras?.filter(
            extra => extra.experienceId === experience.id,
          ),
          price: newExp.data[0].price,
          selectedHotels,
          tripId: tripId,
          clientFolder: tripFilters?.clientFolder,
        },
        experience.filters,
      );
    }

    if (day.hotel && !day.hotel?.isIncluded) {
      const hotel = day.hotel;

      const hotelIndex = getHotelDayIndex(hotel, dayIndex);

      if (hotelIndex?.current === 1) {
        const startAt = moment(tripFilters?.departureDate).add(dayIndex, 'days').toDate();
        const endAt = moment(startAt).add(hotelIndex.total, 'days').toDate();
        hotel.filters = {
          ...hotel.filters,
          experienceComposition: tripFilters?.composition?.roomsComposition ?? [tripFilters?.composition]
        }

        addExperienceToBasket({
          ...hotel,
          durationDays: 1,
          type: "hotel",
          name: hotel.displayName,
          selectedExtras: [],
          imageUrl: hotel.imageUrl,
          hotelId: hotel.id,
          price: {
            promotions: []
          },
          rooms: hotel.filters.experienceComposition,
          tripId: tripId,
          clientFolder: tripFilters?.clientFolder
        }, {
          ...hotel.filters,
          startAt: startAt,
          endAt: endAt
        });
      }
    }
  }

  const handleFinalize = async (shouldUpdateFolder = false) => {
    if (finalizing) {
      return;
    }

    setFinalizing(true);

    let folder = tripFilters?.clientFolder;

    if (!folder) {
      await createClientFolder()
        .then(f => folder = f);
    }

    if (shouldUpdateFolder) {
      await updateFolder()
        .then(f => folder = f);
    }

    if (!folder?.id) {
      setFinalizing(false);
      return;
    }

    if (folder?.travelers?.length < tripGuestRequired) {
      setFinalizing(false);
      setShowCreateModal(true);
      return;
    }

    setLoading(true);

    await handleSave("draft");
    const promises = [];

    for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
      promises.push(buildBasket(dayIndex));
    }

    await Promise.allSettled(promises);

    setSaving(false);
    setFinalizing(false);
    setLoading(false);
    navigate('/experiences/checkout');
  }

  const getTotalTripPrice = () => {
    let price = 0;
    const currencies = [];
    for (let dayIndexe = 0; dayIndexe < days.length; dayIndexe++) {
      const day = days[dayIndexe];
      const experience = day?.experience;
      const totalExperience = experience?.tripPrice ?? experience?.price;
      const hotel = day.hotel;

      if (experience && totalExperience?.price) {
        currencies[totalExperience?.currency] = totalExperience?.currency;
      }

      if (hotel && hotel?.price) {
        currencies[hotel?.currency] = hotel?.currency;
      }

      if (experience && (!experience?.dayIndex || experience.dayIndex === 1)) {
        price += Math.round(totalExperience?.price) ?? 0;
      }
      if (hotel?.price) {
        price += Math.round(hotel?.price) ?? 0;
      }
    }
    const currency = Object.values(currencies)
    return { totalPrice: price, currency: currency[0]};
  };

  const handleSave = async (kind) => {
    const travelers = {
      adultPax: tripFilters?.composition?.adults,
      childrenBirthdates: tripFilters?.composition.childrenBirthdates.map(item => formatDate(
        item,
        'yyyy-MM-dd'
      ))
    }
    setDrafting(true);

    let clientFolder = tripFilters?.clientFolder;
    if (!clientFolder && kind === "draft") {
      await createClientFolder()
        .then(f => clientFolder = f);
    }

    if (kind === "draft") {
      await updateFolder()
      .then(f => clientFolder = f);
      if (clientFolder?.travelers?.length < tripGuestRequired && kind === "draft") {
        setShowCreateModal(true);
        return;
      }
    }

    setSaving(true);

    saveTripDays({
      tripId: tripId,
      tripDays: days?.map((day, index) => {
        return {
          dayIndex: index + 1,
          experienceId: day?.experience?.id ?? undefined,
          hotelId: day?.hotel?.id ? ''+decodeId(day.hotel.id) : undefined,
          hotelBookedOnMyOwn: day?.hotelBookedOnMyOwn ?? false,
          options: selectedExtras
            ?.filter((extra) => extra.scope === ExtraScopeEnum.DAY && extra.dayIndex === index)
            ?.map(extra => ({
              extraId: extra.id,
              parentExtraId: extra.parentExtraId
            }))
        };
      }) ?? [],
      draftPrice: getTotalTripPrice(),
      folderId: kind === "draft" ? tripFilters?.clientFolder?.id : undefined,
      travelers: travelers,
      globalOptions: selectedExtras
        ?.filter((extra) => extra.scope === ExtraScopeEnum.GLOBAL)
        ?.map(extra => ({
          dayIndex: extra.dayIndex + 1,
          extraId: extra.id,
          parentExtraId: extra.parentExtraId
        }))
    })
      .then(() => {
        notify(t('compose.savedAsDraft'))
        reloadTrip();
      })
      .finally(() => {
        setSaving(false);
        setShowCreateModal(false);
        setDrafting(false);
      });
  }

  const availableHotels = hotels.filter(
    hotel =>
      !suggestedHotels.some(suggestedHotel => suggestedHotel?.id === hotel?.id),
  );

  return (
    <>
      {isLoaded && !isNotFirstExperience && (
        <KoobLoaderFullscreen
          opacity="semi"
          title={t('loading.title')}
          description={t('loading.description')}
        />
      )}

      <div className="mt-8">
        {!itineraryMode && (
          <Sidebar
            offsetTop={headerHeight + 220}
            compare={!!compareWithVersion}
          >
            <div
              className={[
                compareWithVersion && 'flex gap-5'
              ].join(' ')}
            >
              <div className="w-[545px] shrink-0 flex flex-col space-y-5 p-6">
                <div className="flex items-center justify-between whitespace-nowrap">
                  <KoobTitle size="text-2xl">
                    {t('compose.title')}
                  </KoobTitle>
                </div>

                <ExperiencesGrid>
                  <ExperienceProgramContentHeader
                    title={t('compose.arrival')}
                    date={tripFilters.departureDate}
                    location={tripFilters.startLocation?.title}
                  />

                  {!days && (
                    <div className="bg-gray-100 px-6 py-4 rounded-lg">
                      <div className="flex items-center space-x-3 text-sm text-gray-600">
                        <svg className="h-10 w-10" viewBox="0 0 512 512">
                          <path
                            d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"
                          />
                        </svg>

                        <div>
                          {t('compose.noDays')}
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    {Array.from({ length: Math.max(tripFilters.tripDuration, days?.filter(d => d && Object.keys(d)?.length).length ?? 0) }).map((_, i) => (
                      <ComposeDay
                        key={i}
                        dayIndex={i}
                        date={moment(tripFilters.departureDate).add(i, 'days')}
                        addHotel={addHotel}
                        getHotelDayIndex={getHotelDayIndex}
                        setOptions={setOptions}
                        currentVersion={currentVersion}
                        formatExtras={formatExtras}
                        setFormatExtras={setFormatExtras}
                        finalizing={finalizing}
                        setHasCurrencyMismatch={setHasCurrencyMismatch}
                      />
                    ))}
                  </div>

                  <div className="flex flex-col space-y-3">
                    <KoobButton
                      block
                      onClick={() => {
                        handleSave("draft")
                      }}
                      loading={saving || finalizing}
                      disabled={isLoading}
                    >
                      {t('compose.saveAsDraft')}
                    </KoobButton>

                    <Tooltip
                      tip={!canSaveAsTemplate ? t('compose.alreadySavedAsTemplate') : t('compose.missingDays')}
                      disabled={isFormValidForTemplate}
                      position="top"
                      block
                    >
                      <KoobButton
                        block
                        onClick={() => {
                          handleSave("template").then(() => setShowTemplateModal(true));
                        }}
                        loading={saving || finalizing}
                        disabled={!isFormValidForTemplate || isLoading}
                      >
                        {t('compose.saveAsTemplate')}
                      </KoobButton>
                    </Tooltip>

                    <Tooltip
                      tip={t('compose.missingDays')}
                      position="top"
                      block
                      disabled={isTripComplete}
                    >
                      <KoobButton
                        block
                        loading={saving || finalizing}
                        disabled={!isFormValidForTemplate || isLoading || hasCurrencyMismatch}
                        onClick={() => handleFinalize()}
                      >
                        {t('compose.finalize')}
                      </KoobButton>
                    </Tooltip>
                  </div>
                </ExperiencesGrid>
              </div>

              {compareWithVersion && (
                <div className="w-full bg-gray-50 p-6">
                  <div className="flex justify-between items-center">
                    <KoobTitle size="text-2xl">
                      {t('compose.versions.compareTitle')} {compareWithVersion?.version}
                    </KoobTitle>

                    <button
                      onClick={() => setCompareWithVersion(null)}
                      className="border-none text-gray-500 hover:text-gray-700 transition"
                    >
                      <i className="far fa-times text-lg"/>
                    </button>
                  </div>

                  <div className="leading-none text-lg text-orange-600 font-semibold">
                    {compareVersionTotalPrice && t('total', {
                      price: formatCurrency({
                        amount: compareVersionTotalPrice?.price,
                        currency: compareVersionTotalPrice?.currency,
                      }),
                    })}
                  </div>
                  <ComposeVersionCompare
                    tripId={tripId}
                    getHotelDayIndex={getHotelDayIndex}
                  />
                </div>
              )}
            </div>
          </Sidebar>
        )}

        <CreateTemplateModal
          open={showTemplateModal}
          setOpen={setShowTemplateModal}
          close={() => setShowTemplateModal(false)}
          onTemplateSaved={() => {
            setCanSaveAsTemplate(false);
          }}
          tripId={tripId}
        />

        <MapWrapper>
          {isLoaded && isNotFirstExperience && (
            <GoogleMap
              mapContainerStyle={{ height: `calc(100vh - 95px)`, width: '100%' }}
              options={{ maxZoom: 14, mapTypeControl: false, streetViewControl: false, fullscreenControl: false }}
              zoom={12}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {!itineraryMode ? (
                <>
                  {!searchHotels && experiences?.map((experience) => (
                    <ExperienceMarker
                      key={'map-e-' + experience?.id}
                      experience={experience}
                      mode="compose"
                      isForTrip={true}
                      onSelect={() => addExperience(experience?.id)}
                      compatibility={getExperienceCompatibility(experience)}
                    />
                  ))}

                  {suggestedHotels?.map((hotel, index) => (
                    <MapHotelMarker
                      key={'map-sh-' + hotel?.id}
                      hotel={hotel}
                      addHotel={(h) => addHotel(h)}
                      index={index}
                      suggested
                    />
                  ))}

                  {searchHotels && availableHotels?.map((hotel, index) => (
                    <MapHotelMarker
                      key={'map-h-' + hotel?.id}
                      hotel={hotel}
                      addHotel={(h) => addHotel(h)}
                      index={index}
                    />
                  ))}
                </>
              ) : (
                <ComposeMapItinerary
                  days={days}
                  showHotels={searchHotels}
                />
              )}
            </GoogleMap>
          )}

          {tripFilters?.clientFolder?.id && (
            <FoldersGuestsModal
              open={showCreateModal}
              setOpen={setShowCreateModal}
              onSave={() => {
                if (drafting && !finalizing) {
                  const kind = drafting ? 'draft' : 'template';
                  handleSave(kind);
                  setDrafting(false);
                } else {
                  handleFinalize(true);
                }
              }}
              mode={"Add"}
              idFolder={tripFilters.clientFolder.id}
              requiredGuest={tripGuestRequired}
              travelersList={tripFilters.clientFolder?.travelers}
            />
          )}
        </MapWrapper>
      </div>
    </>
  );
};

export default ComposeMap;
