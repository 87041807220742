export default function ReceiptHiddenSection({ children, className }) {
  return (
    <div
      className={[
        'export-hidden relative bg-gray-100 border-2 border-dashed border-gray-300 p-5 rounded-lg overflow-visible',
        className,
      ].join(' ')}
    >
      {children}
    </div>
  )
}
