import { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import styled from 'styled-components';
import ExperienceCard from './ExperienceCard';
import { generateMarker } from 'utils';

const ExperienceMarker = ({
  experience,
  showInfoWindow = true,
  mode,
  onSelect,
  isForTrip = false,
  compatibility,
  lat,
  lng,
  typeOverride = null
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const color = {
    Activity: "#29CC87",
    AIR: "#9333EA",
    LAND: "#9333EA",
    RAIL: "#9333EA",
    BOAT: "#9333EA",
    Program: "#FF204E",
    flag: "#FF204E",
    start: "#FF204E",
  }

  const markerType = (type, isOpen) => {
    return generateMarker({
      type: type,
      fillColor: '#FFFFFF',
      mainColor: color[type]
    })
  }

  const latitude = parseFloat('' + (lat ?? experience?.startPosition?.lat ?? experience?.inCity?.lat));
  const longitude = parseFloat('' + (lng ?? experience?.startPosition?.lon ?? experience?.inCity?.lon));

  return (
    <Marker
      position={{
        lat: latitude,
        lng: longitude,
      }}
      icon={markerType(typeOverride ?? experience.transferType ?? experience?.experienceType ?? experience?.type, isOpen)}
      onClick={() => setIsOpen(!isOpen)}
    >
      {showInfoWindow && isOpen && (
        <InfoWindow
          options={{ maxWidth: 350, padding: 0 }}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          onCloseClick={() => setIsOpen(false)}
        >
          <ExperienceCard
            experience={experience}
            mode={mode}
            onSelect={onSelect}
            compatibility={compatibility}
            isForTrip={isForTrip}
          />
        </InfoWindow>
      )}
    </Marker>
  );
};

export default ExperienceMarker;
