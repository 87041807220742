import { signUp } from 'api/auth';
import { useState } from 'react';
import { Stack } from '@koob/margaret';
import { Button } from 'components';
import { Text } from 'ui';
import { FormGroup } from 'ui/forms';
import { camelCase, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  EditorField,
  SearchableSelectField,
  SelectCurrencyField,
  SelectField,
  TextField,
} from 'components/Fields';
import ToggleSwitchField from 'components/Fields/ToggleSwitchField';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useSnack } from 'hooks';
import styled from 'styled-components';
import { Container } from 'ui';
import { Wrapper } from 'ui/auth';
import logo from 'images/logo-koob.svg';
import { getAllCountries } from 'api/countries';
import { getCitiesByCountry } from 'api/cities';
import { useAsync } from 'react-async';
import { listSupports, phoneRegex } from 'utils';
import PhoneNumberField from 'components/Fields/PhoneNumberField';
import KoobParagraph from 'components/Koob/KoobParagraph';
import SelectLanguageField from 'components/Fields/SelectLanguageField';
import SelectMarketsField from 'components/Fields/SelectMarketsField';

const Logo = styled.img`
  align-self: center;
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

const Content = styled.div`
  flex: 1;
`;

const Sidebar = styled.div`
  background-color: #ffffff;
  width: 70%;
  margin-left: 15%;
  border-top: solid ${({ theme }) => theme.orange} 10px;
`;

const BoxTitle = styled.h1`
  font-size: ${({ theme }) => theme.spacing(1.6)};
  margin-top: 0;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 0;
`;

const BoxSubtitle = styled.p`
  color: ${({ theme }) => theme.textLight};
  text-align: center;
  font-size: ${({ theme }) => theme.spacing(1)};
  margin-top: 0;
`;

const getOmittedValues = () => {
  return ['country', 'city', 'currencyData', 'howKnowKoobData'];
};

const SignUp = () => {
  const { t } = useTranslation(['auth', 'errors']);
  const { notify } = useSnack();
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState(null);

  const { data: countriesData } = useAsync({
    promiseFn: getAllCountries,
  });

  const supports = (listSupports || [])
    .map(({ name: support }) => ({
      value: support,
      label: support,
    }))
    .sort((support1, support2) => support1.label.localeCompare(support2.label));

  const countries = (countriesData?.data || [])
    .map(country => ({
      value: country.id,
      label: country?.translations?.filter(item => item.locale === 'en')[0]?.title
      || country?.translations?.filter(item => item.locale === 'en')[1]?.title
    }))
    .sort((country1, country2) => country1.label.localeCompare(country2.label));

  const { data: citiesData } = useAsync({
    promiseFn: selectedCountry ? getCitiesByCountry : null,
    watch: selectedCountry,
    countryId: selectedCountry,
  });

  const cities = (citiesData?.data || [])
    .map(city => ({
      value: city.id,
      label: city?.title,
    }))
    .sort((city1, city2) => city1.label.localeCompare(city2.label));

  const handleChange = value => {
    setSelectedCountry(value);
  };

  const handleSignUp = async values => {
    const input = omit(
      {
        ...values,
        cityId: parseInt(values.city.value),
        countryId: parseInt(values?.country?.value),
        currency: values?.currencyData?.value,
        howKnowKoob: values.howKnowKoobData?.value,
        typology: values.typology?.value,
        language: values.language?.value,
        markets: values?.markets?.map((market) => {
          return {
            id: market.value,
            name: market.label,
          }
        })
      },
      getOmittedValues(),
    );

    try {
      await signUp(input);
      notify(t('signUp.form.success'));
      return navigate('/');
    } catch (err) {
      if (err.response && err.response.status === 403) {
        notify(t('errors:existingMail'), { type: 'error' });
        return;
      }
      const error = camelCase(err?.response?.data?.message ?? 'error');

      notify(t(`errors:${error}`), { type: 'error' });
    }
  };

  return (
    <Wrapper>
      <Sidebar>
        <Container hasVerticalPadding>
          <Logo src={logo} alt="KOOB" />

          <Content>
            <BoxTitle>
              {t('signUp.title')}
            </BoxTitle>
            <BoxSubtitle>
              {t('signUp.subtitle')}
            </BoxSubtitle>

            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                jobPosition: '',
                email: '',
                companyName: '',
                phoneNumber: '',
                website: '',
                address: '',
                zipCode: '',
                city: '',
                country: '',
                currencyData: '',
                comment: '',
                howKnowKoobData: '',
                marcketplaceRequest: true,
                tripDesignerRequest: false,
                language: '',
                markets:[],
                typology: '',
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required(t('errors:required')),
                lastName: Yup.string().required(t('errors:required')),
                email: Yup.string()
                  .email(t('errors:email'))
                  .required(t('errors:required')),
                phoneNumber: Yup.string()
                  .required(t('errors:required'))
                  .matches(phoneRegex, t('errors:invalidFormat')),
                city: Yup.mixed().required(t('errors:required')),
                country: Yup.mixed().required(t('errors:required')),
                address: Yup.string().required(t('errors:required')),
                zipCode: Yup.string().required(t('errors:required')),
                jobPosition: Yup.string(),
                companyName: Yup.string().required(t('errors:required')),
                website: Yup.string().url(t('errors:url')),
                marcketplaceRequest: Yup.boolean(),
                tripDesignerRequest: Yup.boolean(),
                language: Yup.object().required(t('errors:required')),
                typology: Yup.object().required(t('errors:required')),
                markets: Yup.array().of(Yup.object()).required(t('errors:required')).min(1),
              })}
              onSubmit={handleSignUp}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <FormGroup>
                    <Stack gutterSize={1}>
                      <TextField
                        label={t('signUp.form.firstName')}
                        name="firstName"
                        required
                      />

                      <TextField
                        label={t('signUp.form.lastName')}
                        name="lastName"
                        required
                      />
                    </Stack>

                    <Stack gutterSize={1}>
                      <TextField
                        label={t('signUp.form.jobPosition')}
                        name="jobPosition"
                      />

                      <TextField
                        label={t('signUp.form.companyName')}
                        name="companyName"
                        required
                      />
                    </Stack>

                    <Stack gutterSize={1}>
                      <SearchableSelectField
                        name="country"
                        label={t('signUp.form.country')}
                        datas={countries}
                        renderOption={value => value?.label}
                        renderSelectedOption={value => value?.label}
                        handleChangeCity={value => {
                          handleChange(value?.value);
                        }}
                        local
                        required
                      />

                      <SearchableSelectField
                        name="city"
                        label={t('signUp.form.city')}
                        datas={cities}
                        renderOption={value => value?.label}
                        renderSelectedOption={value => value?.label}
                        renderSelectedValue={value => value?.label}
                        disabled={!selectedCountry}
                        local
                        required
                      />
                    </Stack>

                    <Stack gutterSize={1}>
                      <TextField
                        label={t('signUp.form.email')}
                        type="email"
                        tabIndex="1"
                        name="email"
                        required
                      />

                      <PhoneNumberField
                        label={t('signUp.form.phoneNumber')}
                        name="phoneNumber"
                        required
                      />
                    </Stack>

                    <Stack gutterSize={1}>
                      <TextField
                        label={t('signUp.form.website')}
                        name="website"
                        placeholder="https://www.mywebsite.com"
                      />

                      <SelectCurrencyField
                        label={t('signUp.form.currency')}
                        name="currencyData"
                      />
                    </Stack>

                    <Stack gutterSize={1}>
                      <TextField
                        label={t('signUp.form.address')}
                        name="address"
                        required
                      />

                      <TextField
                        label={t('signUp.form.zipCode')}
                        name="zipCode"
                        required
                      />
                    </Stack>

                    <Stack gutterSize={1}>
                      <SelectLanguageField
                        name="language"
                        label={t('signUp.form.language')}
                        required
                      />

                      <SelectField
                        options={[
                          { label: t('signUp.form.organizationType.to'), value: 'tour_operator' },
                          { label: t('signUp.form.organizationType.travelAgency'), value: 'travel_agency' },
                        ]}
                        label={t('signUp.form.typology')}
                        name="typology"
                        renderSelectedValue={value => value?.label}
                        required
                      />
                    </Stack>

                    <Stack>
                      <SelectMarketsField label={t('signUp.form.markets')} />
                    </Stack>

                    <Stack gutterSize={1} direction="column" size="full">
                      <Text type='h3'>
                        {t('signUp.form.interestedIn')}
                      </Text>

                      <Stack direction="row" gutterSize={1} size="full">
                        <Stack size='full'>
                          <KoobParagraph>
                            {t('signUp.form.descMarketplace')}
                          </KoobParagraph>
                        </Stack>

                        <Stack size='full'>
                          <KoobParagraph>
                            {t('signUp.form.descTripDesigner')}
                          </KoobParagraph>
                        </Stack>
                      </Stack>

                      <Stack direction="row" gutterSize={1} size="full">
                        <ToggleSwitchField
                          name="marketplaceRequest"
                          label={t('signUp.form.requestMarketplace')}
                          checked= {true}
                          disabled= {true}
                        />

                        <ToggleSwitchField
                          name="tripDesignerRequest"
                          label={t('signUp.form.requestTripDesigner')}
                        />
                      </Stack>
                    </Stack>

                    <EditorField
                      label={t('signUp.form.comment')}
                      name="comment"
                    />

                    <SelectField
                      options={supports}
                      label={t('signUp.form.how_know_koob')}
                      name="howKnowKoobData"
                      renderSelectedValue={value => value?.label}
                    />
                  </FormGroup>

                  <Stack marginTop={1.5}>
                    <Button
                      variant="primaryBig"
                      size="full"
                      isLoading={isSubmitting}
                      tabIndex="2"
                      disabled={!isValid}
                    >
                      {t('signUp.form.button')}
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Content>
        </Container>
      </Sidebar>
    </Wrapper>
  );
};

export default SignUp;
