import { get } from './';

export const getAllCountries = () => get('/api/v1/countries');

export const getCountriesByDmcId = ({ dmcId }) => {
  if (!dmcId) {
    return get('/api/v1/countries')
  }

  return get(`/api/v1/countries/${dmcId}`)
}


export const getCountriesByOrganization = ({ organizationId }) =>
  get(`/api/v1/countries/${organizationId}`);

export const getCountriesWithQuery = ({ search }) =>
  get(`/api/v1/countries/search?search=${search??''}`);
