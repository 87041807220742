import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import KoobTitle from 'components/Koob/KoobTitle';
import UploadDocument from '../../../Hotel/Booking/Edit/Partials/UploadDocument';
import { createTripDocuement } from '../../../../api/trip';
import { useError } from '../../../../hooks';

export default function DocumentUpload({ secret, tripId, conversationId, onUpload }) {
  const { t } = useTranslation('tripDesigner');
  const { sendErrorSnack } = useError();

  const [processing, setProcessing] = useState(false);
  const initialesValues = {
    tripId,
    conversationId,
    document: null,
  };
  const onSubmit = async values => {
    setProcessing(true);

    try {
      const body = new FormData();

      body.append('input', JSON.stringify(values));
      body.append('document', values.document);

      await createTripDocuement({
        tripId,
        conversationId,
        data: body
      });
      onUpload();
    } catch (error) {
      sendErrorSnack(error);
    }
    setProcessing(false);
  };

  return (
    <UploadDocument
      initialesValues={initialesValues}
      secret={secret}
      processing={processing}
      onSubmit={onSubmit}
      loaderClass="h-16 w-16"
    >
      <div className="group h-40 w-full px-4 flex justify-center items-center rounded-lg border-2 border-gray-400 border-dashed hover:border-gray-500 focus:outline-none transition">
        <div className="max-w-xs flex flex-col justify-center">
          <div className="mx-auto text-gray-400 group-hover:text-gray-500 transition">
            <svg className="w-16 h-16" viewBox="0 0 640 512">
              <path d="M308.7 164.7C314.9 158.4 325.1 158.4 331.3 164.7L411.3 244.7C417.6 250.9 417.6 261.1 411.3 267.3C405.1 273.6 394.9 273.6 388.7 267.3L336 214.6V368C336 376.8 328.8 384 320 384C311.2 384 304 376.8 304 368V214.6L251.3 267.3C245.1 273.6 234.9 273.6 228.7 267.3C222.4 261.1 222.4 250.9 228.7 244.7L308.7 164.7zM272 32C331.5 32 384.1 61.55 416 106.8C430.5 99.87 446.8 96 464 96C525.9 96 576 146.1 576 208C576 218.7 574.5 228.1 571.7 238.8C612.3 260.2 640 302.9 640 352C640 422.7 582.7 480 512 480H144C64.47 480 0 415.5 0 336C0 273.2 40.15 219.9 96.17 200.1C100.3 106.6 177.4 32 272 32zM272 64C194.6 64 131.5 125 128.1 201.5C127.6 214.6 119.1 225.1 106.8 230.3C63.18 245.7 32 287.2 32 336C32 397.9 82.14 448 144 448H512C565 448 608 405 608 352C608 315.2 587.3 283.2 556.8 267.1C543.4 259.1 536.8 244.5 540.9 229.1C542.9 223 544 215.7 544 208C544 163.8 508.2 128 464 128C451.7 128 440.1 130.8 429.7 135.7C415.7 142.4 398.8 137.9 389.8 125.2C363.7 88.12 320.7 64 272 64V64z" />
            </svg>
          </div>

          <div className="text-gray-500 text-center">
            <KoobTitle size="text-lg">{t('conversation.documents.upload.title')}</KoobTitle>
            <p>{t('conversation.documents.upload.description')}</p>
          </div>
        </div>
      </div>
    </UploadDocument>
  );
}
