import { Text } from "ui";

const {Stack} = require("@koob/margaret");
import styled from "styled-components";
import { HotelTag } from "components/Hotel/HotelDetails/Base";

const SectionTitle = styled(Text).attrs({type: 'h2'})`
  font-weight: 700;
  margin-top: 20px;
`;

const KoobHeader = ({title, tags, children}) => {

  return (
    <>
      <Stack direction="column">
        <SectionTitle>{title}</SectionTitle>
      </Stack>

      <div className="flex flex-wrap">
        <Stack gutterSize={1.5} alignX="space-between" style={{marginTop: '10px'}}>
          <Stack direction="row" gutterSize={1.5}>
            <Stack direction="row" gutterSize={2} alignY="center">
              {children}
            </Stack>
          </Stack>
        </Stack>
      </div>
      <div>
        {tags.length > 0 && (
          <div className="my-4 flex flex-wrap items-center gap-3">
            {tags.map((tag, index) => (
              <div className="rounded-full" key={index}>
                <HotelTag>
                  <div className="text-xs font-extrabold uppercase leading-none m-0">
                    {tag}
                  </div>
                </HotelTag>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}
export default KoobHeader;
