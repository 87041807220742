import { useField } from 'formik';
import { useTripDesigner } from '../../../../hooks';

export default function ComposeDayInsertBetween({ dayIndex, position, experience }) {
  const { tripFilters, setTripFilters, setTripData } = useTripDesigner();
  const [{ value: days }, , { setValue: setDays }] = useField('days');
  const [{ value: previousExperience }] = useField(`days[${dayIndex - 1}].experience`);

  const dayOffset = position === 'before' ? 0 : 1;

  // If the experience is not set, the button should be a remove button
  const shouldRemove =
    position === 'before' && !experience?.id;

  // If button is before the day, it should render if the previous experience is different from the current experience or if there is no previous experience
  // If button is after the day, it should render if the day is the last day of the trip
  const shouldRender =
    (((days?.length ?? 1) > 1 || !shouldRemove) &&
      position === 'before' &&
      (previousExperience?.id !== experience?.id || !previousExperience?.id)) ||
    (position === 'after' &&
      dayIndex ===
      Math.max((days?.length ?? 1) - 1, tripFilters?.tripDuration - 1));

  const addDay = () => {
    const newDays = days ?? [];

    if (newDays?.length === 0) {
      for (let i = 0; i < tripFilters?.tripDuration; i++) {
        newDays?.push({
          experience: null,
          hotel: null,
        });
      }
    }

    newDays.splice(dayIndex + dayOffset, 0, {
      experience: null,
      hotel: null,
    });

    setDays(newDays);
    setTripData(prev => ({
      ...prev,
      days: newDays
    }));
  }

  const removeDay = () => {
    const newDays = days ?? [];

    newDays.splice(dayIndex + dayOffset, 1);

    // If the trip duration is greater than the number of days, update the trip duration
    if (tripFilters?.tripDuration > (days.length ?? 1)) {
      setTripFilters({
        ...tripFilters,
        tripDuration: days.length,
      });
    }

    setDays(newDays);
    setTripData(prev => ({
      ...prev,
      days: newDays
    }));
  }

  return shouldRender ? (
    <div
      onClick={() => {
        if (shouldRemove) {
          removeDay();
        } else {
          addDay();
        }
      }}
      className="relative group py-2 cursor-pointer"
    >
      <div
        className={[
          'w-full h-1 bg-gray-200 transition rounded-full',
          shouldRemove ? 'group-hover:bg-red-500' : 'group-hover:bg-k-orange',
        ].join(' ')}
      >
        <div
          className={[
            'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-6 h-6 bg-gray-200 transition rounded-full flex justify-center items-center',
            shouldRemove ? 'group-hover:bg-red-500' : 'group-hover:bg-k-orange',
          ].join(' ')}
        >
          {shouldRemove ? (
            <i className="fa fa-minus text-gray-400 group-hover:text-white transition" />
          ) : (
            <i className="fa fa-plus text-gray-400 group-hover:text-white transition" />
          )}
        </div>
      </div>
    </div>
  ) : null;
}
