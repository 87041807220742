export default function formatHotelRoomPossibilities(possibilities) {
  return possibilities.reduce((acc, possibility) => {
    if (
      !['Koedia', 'Rakuten'].includes(possibility.supplier) ||
      !acc.some((room) => room.name === possibility.name)
    ) {
      acc.push({
        ...possibility,
        variants: [
          possibility
        ]
      });
    } else {
      const roomIndex = acc.findIndex((room) => room.name === possibility.name);
      if (roomIndex !== -1) {
        acc[roomIndex].variants.push(possibility);
      }
    }
    return acc;
  }, []);
}
