import { useState } from 'react';
import { Text } from 'ui';
import { Stack } from '@koob/margaret';
import {
  HotelTag,
  LigneHorizontal,
  SubSectionTitle,
  SubSectionTitleContainer
} from 'components/Hotel/HotelDetails/Base';
import { Iclocation } from '../icons';
import KoobMap from './KoobMap';
import { TbMapSearch } from 'react-icons/tb';
import KoobLocationModalComponent from './KoobLocationModalComponent';
import KoobButton from './KoobButton';

const KoobLocationComponent = ({markers, address, tags, title, infoWindowContent}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {address && (
        <Stack direction="column">
          <SubSectionTitleContainer>
            <Stack size="full" style={{flexWrap: 'wrap'}}>
              <div className="flex-1">
                <SubSectionTitle>
                  {title}
                </SubSectionTitle>
              </div>

              {(tags ?? []).map((tag, index) => (
                <div className="rounded-full py-1 mr-2 flex-none" key={index}>
                  <HotelTag color="orange">
                    <div className="text-xs font-extrabold uppercase leading-none m-0">
                      {tag}
                    </div>
                  </HotelTag>
                </div>
              ))}
            </Stack>
          </SubSectionTitleContainer>

          <div className="flex space-x-1 items-center text-gray-500">
            <Iclocation width={20}/>
            <Text>{address}</Text>
          </div>

          <div className="flex justify-end w-full">
            <KoobButton
              onClick={() => {
                setShowModal(true)
              }}
            >
              <TbMapSearch size={24}/>
            </KoobButton>
          </div>

          <KoobMap
            markers={markers}
            infoWindowContent={infoWindowContent}
          />

          <LigneHorizontal/>
        </Stack>
      )}

      <KoobLocationModalComponent
        open={showModal}
        close={() => setShowModal(false)}
        markers={markers}
      />
    </>
  )
}

export default KoobLocationComponent;
