import { useAsync } from 'react-async';
import { getExperience } from '@koob/to/src/api/experiences';
import React from 'react';

export default function ExportDayOption({optionId}) {
  const {data} = useAsync({
    promiseFn: getExperience,
    experienceId: optionId,
  });
  const option = data?.data;

  return (
    <div>
      &bull; {option?.name}
    </div>
  )
}
