import { useTranslation } from 'react-i18next';
import { useTripDesigner } from '../../../../hooks';
import React, { useMemo, useState } from 'react';
import { useField } from 'formik';
import { ProductTypeEnum } from '@koob/enums';

export default function ComposeDayItemSelector({ dayIndex, type, isIncluded, isFreeAccomodation, onSecondaryClick }) {
  const { t } = useTranslation('tripDesigner');
  const { selectedDay, selectedType, selectDay, resetDaySelection } = useTripDesigner();
  const [, , { setValue: setBookOnMyOwn }] = useField(`days[${dayIndex}].hotelBookedOnMyOwn`);
  const [{ value: experience }] = useField(`days[${dayIndex}].experience`);

  const [exploreHotels, setExploreHotels] = useState(true);

  const program = experience?.programs?.[(experience?.dayIndex ?? 1) - 1];

  const isSelected = selectedDay === dayIndex && selectedType === type;

  const hasSuggestedHotels = program?.accomodationHotel || program?.alternativeHotels?.length > 0;

  const onSelect = () => {
    if (!isSelected) {
      selectDay(dayIndex, type);
    } else {
      if (onSecondaryClick && exploreHotels && hasSuggestedHotels) {
        onSecondaryClick();
        setExploreHotels(false);
      } else {
        resetDaySelection();
        setExploreHotels(true);
      }
    }
  };

  const selectorLabel = useMemo(() => {
    if (type === ProductTypeEnum.EXPERIENCE) {
      return t('compose.addProgram');
    }

    if (isSelected && exploreHotels && hasSuggestedHotels) {
      return isIncluded
        ? t('compose.exploreIncludedHotels')
        : t('compose.exploreSuggestedHotels');
    }

    return t('compose.addHotel');
  }, [type, isSelected, isIncluded, exploreHotels, hasSuggestedHotels]);

  return (
    <div
      id={`day-${dayIndex}-${type}-selector`}
      onClick={() => onSelect()}
      className={[
        'bg-gray-100 h-full p-5 rounded-md border-2 transition',
        isSelected
          ? [
            'animate-pulse',
            isIncluded
              ? 'border-green-500'
              : 'border-orange-500'
          ].join(' ')
          : 'cursor-pointer border-transparent hover:border-gray-300'
      ].join(' ')}
    >
      <div className="h-full flex justify-center items-center">
        <div className="text-gray-400 text-sm text-center">
          {selectorLabel}

          {type === ProductTypeEnum.HOTEL && (
            <>
              <div className="my-1 text-gray-400 text-xs">
                — {t('compose.or')} —
              </div>

              <button
                className="border-none text-gray-400 italic hover:text-orange-500 transition"
                onClick={(event) => {
                  event.stopPropagation();
                  setBookOnMyOwn(true);
                }}
              >
                {t('compose.bookOnMyOwn')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
