export default function KoobLoaderText({ title, description }) {
  return (
    <div className="text-center">
      <div className="text-3xl font-bold text-gray-800">
        {title}
      </div>
      <div className="mt-1 text-gray-500">
        {description}
      </div>
    </div>
  );
}
