import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function ChatboxMessage({ message, displayAs, dangerouslyHtml }) {
  const { t } = useTranslation('booking');

  const currentDateTime = moment(message.createdAt);
  const dateTimeWithBrowserTimezone = currentDateTime.local();
  const currentDateTimeZone = dateTimeWithBrowserTimezone.format('DD/MM/YYYY HH:mm:ss');
  

  return message.senderType !== 'system' ? (
    <div
      className={[
        'p-4 border rounded-lg',
        message.senderType === displayAs
          ? 'bg-orange-500 text-orange-50 ml-[25%]'
          : 'bg-gray-100 text-gray-700 mr-[25%]',
      ].join(' ')}
    >
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <div className="text-base font-semibold">
            {message.user
              ? `${message?.user?.firstName} ${message?.user?.lastName}`
              : t('chat.anonymous')}
          </div>

          <div className="border border-black/20 px-1 rounded">
            <div className="text-sm font-base uppercase">
              {message.senderType}
            </div>
          </div>
        </div>

        <div>
          {currentDateTimeZone}
        </div>
      </div>

      <div className="whitespace-pre-line">
        {dangerouslyHtml ? (
          <div
            className={`col-span-2 prose prose-sm ${message.senderType === displayAs ? 'text-white' : 'text-gray-600'} text-lg`}
            dangerouslySetInnerHTML={{ __html: message.message }}
          />
        ) : (
            message.message
        )}
        
      </div>
    </div>
  ) : (
    <div className="text-center text-gray-500">
      {message.message}
    </div>
  );
}
