import { useParams } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { useEffect } from 'react';

export default function Impersonate() {
  const { token } = useParams();
  const { accessToken, onUpdate, setImpersonationToken } = useAuth();

  useEffect(() => {
    if (token) {
      if (!accessToken) {
        onUpdate({ accessToken: token });
      }
      setImpersonationToken(token);
    }
    window.location.href = '/';
  }, []);

  return null;
}
