import { useEffect, useState} from 'react';
import { Stack } from '@koob/margaret';
import { FormField } from 'ui/forms';
import styled, { css } from 'styled-components';

const ImageAvatar = styled.img`
background-color: ${({ theme }) => theme.primaryLight};
color: ${({ theme }) => theme.primary};
${props =>
  props.shape === 'full' &&
  css`
    border-radius: 10px;
    width: 1225px;
    height: 340px;
    object-fit: cover;
  `}
${props =>
    props.shape === 'multiple' &&
    css`
      border-radius: 10px;
      width: 850px;
      height: 340px;
      object-fit: cover;
    `};
${props =>
  props.shape === 'header' &&
  css`
    border-radius: 10px;
    width: 140px;
    height: 50px;
  `};
${props =>
  props.shape === 'square' &&
  css`
    border-radius: 10px;
    width: 260px;
    height: 220px;
  `};

  ${props =>
  props.shape === 'minus' &&
  css`
    border-radius: 5px;
    width: 128px;
    height: 128px;
  `}
`;

const ImageField = ({
  url,
  shape,
  variant,
}) => {
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    setImageUrl(url);
  }, [url]);

  return (
    <FormField>
      <Stack gutterSize={0.5} alignX={shape === "multiple" ? "left" : "center"} size={'full'}>
        <Stack>
          <ImageAvatar
            src={encodeURI(imageUrl)}
            onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
            shape={shape}
            variant={variant}
          />
        </Stack>

      </Stack>
    </FormField>
  );
};

export default ImageField;
