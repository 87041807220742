import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { API_NODE_BASE } from '../constants';
import { refreshToken, revokeToken } from './auth';

const api = axios.create({ baseURL: API_NODE_BASE });

const formatConfig = ({ params, ...opts } = {}) => ({
  ...opts,
  params: decamelizeKeys(params),
});

const isFormData = val =>
  typeof FormData !== 'undefined' && val instanceof FormData;

api.interceptors.request.use(config => {
  const impersonationToken = localStorage.getItem('impersonationToken');
  const accessToken = impersonationToken?.length > 0
    ? impersonationToken
    : localStorage.getItem('accessToken');

  config.headers['Accept'] = 'application/json';

  if (Boolean(accessToken)) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  if (!isFormData(config.data)) {
    config.data = camelizeKeys(config.data);
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  async error => {
    if (
      (error.response?.status === 400 && error.response?.data.message('Credentials are invalid.'))
      || (error.response?.status === 401 && error.response?.data.includes('Unauthorized'))
    ) {
      await revokeToken();
      localStorage.setItem('accessToken', '');
      localStorage.setItem('refreshToken', '');
      localStorage.setItem('impersonationToken', '');
      window.location.reload();
      return;
    }

    const originalRequest = error.config;
    const refreshTokenValue = localStorage.getItem('refreshToken');

    if (!error.response) {
      return Promise.reject(error);
    }

    if (originalRequest.url.indexOf('oauth/token') !== -1) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      if (originalRequest._hasBeenRetried) {
        localStorage.setItem('accessToken', '');
        localStorage.setItem('refreshToken', '');
        localStorage.removeItem('impersonationToken');
      } else {
        if (!refreshTokenValue) {
          localStorage.setItem('accessToken', '');
          localStorage.setItem('refreshToken', '');
          localStorage.removeItem('impersonationToken');
          window.location.reload();
          return Promise.reject(error);
        }

        originalRequest._hasBeenRetried = true;

        return refreshToken({refreshToken: `${refreshTokenValue}`}).then(
          ({data}) => {
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);

            originalRequest.headers[
              'Authorization'
              ] = `Bearer ${data.accessToken}`;
            return axios(originalRequest);
          },
          () => {
            localStorage.setItem('accessToken', '');
            localStorage.setItem('refreshToken', '');
            localStorage.removeItem('impersonationToken');
          },
        );
      }
    }

    return Promise.reject(error);
  },
);

const formatResponse = response => {
  if (!Boolean(response)) {
    return response;
  }

  return camelizeKeys(response);
};

export const get = (uri, config = {}, formatResp = true) =>
  api
    .get(uri, formatConfig(config))
    .then(formatResp ? formatResponse : response => response);

export const post = (uri, payload = {}, config, formatResp = true) =>
  api
    .post(uri, payload, formatConfig(config))
    .then(formatResp ? formatResponse : response => response);

export const put = (uri, payload = {}, config) =>
  api
    .put(uri, payload, formatConfig(config))
    .then(formatResponse);

export const putFile = (uri, payload) =>
  api
    .put(uri, payload, {headers : {'Content-Type': 'multipart/form-data'}})
    .then(formatResponse);

export const patch = (uri, payload = {}, config) =>
      api.patch(uri, payload, formatConfig(config)).then(formatResponse);

export const destroy = (uri, config) =>
  api
    .delete(uri, formatConfig(config))
    .then(formatResponse);
