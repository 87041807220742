export default function ExportTourOperatorLogo({ tourOperator, noImage = false }) {
  return (
    <div>
      {(tourOperator?.avatarUrl && !noImage) ? (
        <img
          src={tourOperator?.avatarUrl}
          alt="logo"
          className="w-40 h-40 object-contain"
        />
      ) : (
        <div className="text-2xl font-semibold">
          {tourOperator?.displayName}
        </div>
      )}
    </div>
  );
}
