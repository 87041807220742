import CheckoutHotelRoomVariant from './CheckoutHotelRoomVariant';
import { cva } from 'class-variance-authority';

export default function CheckoutHotelRoomFooter({
  scope,
  variants,
  selectedRoomIndex,
  travelersCount,
  onSelectVariant
}) {
  const container = cva([
    'bg-gray-50 border-t border-gray-200 py-1.5'
  ], {
    variants: {
      scope: {
        dmc: 'px-4',
        to: 'px-6',
      }
    }
  });

  return variants?.length > 1 ? (
    <div className={container({ scope })}>
      <div className="flex-col divide-y divide-gray-200">
        {variants.map((variant, index) => (
          <CheckoutHotelRoomVariant
            key={index}
            scope={scope}
            possibility={variant}
            travelersCount={travelersCount}
            isSelected={selectedRoomIndex === variant.originalIndex}
            onSelect={() => onSelectVariant(variant.originalIndex)}
          />
        ))}
      </div>
    </div>
  ) : null;
}
