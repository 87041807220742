import { useMemo } from 'react';

export default function ReceiptContentItemType({ type }) {
  const icon = useMemo(() => {
    switch (type?.toLowerCase()) {
      case 'hotel':
        return 'fa-bed';
      case 'transfer':
        return 'fa-map-marked-alt';
      case 'program':
        return 'fa-calendar-image';
      case 'activity':
        return 'fa-layer-group';
      case 'manual':
        return 'fa-plus';
      case 'extra':
        return 'fa-layer-plus'
      default:
        return 'fa-question';
    }
  }, [type]);

  return (
    <div>
      <i
        className={[
          'far text-k-orange',
          icon,
        ].join(' ')}
      />
    </div>
  );
}
