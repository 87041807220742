import React from 'react';
import KoobTitle from './KoobTitle';
import KoobParagraph from './KoobParagraph';

export default function KoobDescriptionList({ title, description, children }) {
  return (
    <div className="border border-gray-200 rounded-lg">
      {(title || description) && (
        <div className="border-b border-gray-200 px-4 py-3 sm:px-6">
          <KoobTitle size="text-lg">{title}</KoobTitle>
          <KoobParagraph>{description}</KoobParagraph>
        </div>
      )}

      <dl className="sm:divide-y sm:divide-gray-200 px-6 sm:px-0">
        {children}
      </dl>
    </div>
  );
}
