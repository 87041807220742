import { useField, useFormikContext } from 'formik';
import { Stack } from '@koob/margaret';
import { FormLabel, ErrorMessageWrapper, FormField } from 'ui/forms';
import styled from 'styled-components';
import { at } from 'lodash';

const CustomButton = styled.button`
  &:not([disabled]):hover {
    opacity: 0.8;
  }
  &.active[disabled] {
    background-color: ${({ theme }) => theme.primaryBackground};
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }
`;

const SegmentedControlField = ({ label, options, ...props }) => {
  const [meta] = useField(props);
  const formikProps = useFormikContext();

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField style={{ flex: 0 }}>
      {label && <FormLabel>{label}</FormLabel>}
      <Stack>
        {options.map(({ value, label }) => (
          <CustomButton
            key={value}
            type="button"
            className={`bg-white border-2 rounded-xl shadow mr-2 p-3 ${
              at(formikProps.values, props.name)[0] === value
                ? 'border-k-orange'
                : 'border-gray-300'
            }`}
            onClick={() => formikProps.setFieldValue(props.name, value)}
            disabled={props.disabled}
          >
            {label}
          </CustomButton>
        ))}
      </Stack>
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default SegmentedControlField;
