export default function ExperienceExtraComposition({ adults, childs }) {
  return (
    <div className="flex space-x-3 text-gray-500 text-sm">
      <div className="flex space-x-1 items-center">
        <svg className="h-4 w-4" viewBox="0 0 448 512">
          <path d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H416c-1.2-79.7-66.2-144-146.3-144H178.3c-80 0-145 64.3-146.3 144zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/>
        </svg>
        <div>
          {adults ?? '0'}
        </div>
      </div>

      <div className="flex space-x-1 items-center">
        <svg className="h-4 w-4" viewBox="0 0 448 512">
          <path d="M224 128a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM224 0a80 80 0 1 1 0 160A80 80 0 1 1 224 0zM46.1 146.8c20.5-23.1 55.9-25.2 79.1-4.7l24.2 21.5C169.9 181.9 196.5 192 224 192s54.1-10.1 74.6-28.4l24.2-21.5c23.1-20.5 58.5-18.5 79.1 4.7s18.5 58.5-4.7 79.1L373 247.3c-16.1 14.3-33.9 26-53 35.1v32.3l49.9 56.1c16.9 19 18.9 47.1 4.7 68.3l-32 48c-17.2 25.7-51.9 32.7-77.7 15.5s-32.7-51.9-15.5-77.7l8-12L238.9 392H209.1l-18.6 20.9 8 12c17.2 25.7 10.2 60.5-15.5 77.7s-60.5 10.2-77.7-15.5l-32-48c-14.1-21.2-12.2-49.2 4.7-68.3L128 314.7V288.1c0 0 0 0 0-.1s0 0 0-.1v-5.5c-19.1-9-37-20.8-53-35.1L50.8 225.9c-23.1-20.5-25.2-55.9-4.7-79.1zM160 304v16.8c0 .2 0 .4 0 .6l38.9 38.9c1-.2 2-.3 3-.3H246c1 0 2 .1 3 .3L288 321.4c0-.2 0-.4 0-.6V304H160zm128-32c0-6.5 3.9-12.3 9.9-14.7c19.6-8.1 37.8-19.5 53.9-33.8l24.2-21.5c9.9-8.8 10.8-24 2-33.9s-24-10.8-33.9-2l-24.2 21.5C293.5 211 259.3 224 224 224s-69.5-13-95.9-36.5l-24.2-21.5c-9.9-8.8-25.1-7.9-33.9 2s-7.9 25.1 2 33.9l24.2 21.5c16.1 14.3 34.4 25.7 53.9 33.8c6 2.5 9.8 8.3 9.9 14.7H288zm18.7 75.9l-34 34 16.8 19c4.8 5.4 5.4 13.4 1.4 19.5L276 442.7c-7.4 11-4.4 25.9 6.7 33.3s25.9 4.4 33.3-6.7l32-48c6.1-9.1 5.2-21.1-2-29.3l-39.2-44.1zm-131.4 34l-34-34-39.2 44.1c-7.2 8.2-8.1 20.2-2 29.3l32 48c7.4 11 22.3 14 33.3 6.7s14-22.3 6.7-33.3l-14.9-22.3c-4-6.1-3.5-14.1 1.4-19.5l16.8-19z"/>
        </svg>
        <div>
          {childs ?? '0'}
        </div>
      </div>
    </div>
  )
}
