import React, { useEffect, useState } from 'react';
import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import KoobButton from '../../../components/Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import KoobModal from '../../../components/Koob/KoobModal';
import {  addGuestsToFolder, updateGuestsToFolder } from '../../../api/folders';
import { useSnack } from '../../../hooks';
import {camelCase} from "lodash";
import FoldersGuestsModalForm from './FoldersGuestsModalForm';
import { useParams } from 'react-router-dom';

export default function FoldersGuestsModal(
  {
    open,
    setOpen,
    onSave,
    mode,
    guest,
    idFolder,
    requiredGuest,
    travelersList
  }) {
  const { t } = useTranslation('folders');
  const { notify } = useSnack();
  const { folderId } = useParams();
  const [guestCount, setGuestCount] = useState(travelersList?.length > 0 ? travelersList.length : 1);
  const [requiredGuestCount, setRequiredGuestCount] = useState(requiredGuest);
  const [pending, setPending] = React.useState(false);
  const clientFolderId = folderId || idFolder;

  const handleAddGuests = async values => {
    setPending(true);
    try {
      await addGuestsToFolder(clientFolderId,
        values.travelers.map(guest => {
          return {
            id: guest.id,
            gender: guest.gender?.value,
            firstName: guest.firstName,
            lastName: guest.lastName,
            birthdate: guest.birthdate,
            nationality: guest.nationality?.value,
            passportNumber: guest.passportNumber,
            passportExpirationDate: guest.passportExpirationDate,
            ageIsExact: guest?.ageIsExact,
          };
        }),
      );

      notify(t('success'));
      onSave();
      setOpen(false);
    } catch (err) {
      const error = camelCase(err?.response?.data?.message ?? 'error');

      notify(t(`errors:${error}`), { type: 'error' });
    } finally {
      setPending(false);
    }
  };
  const handleEditGuests = async values => {
    setPending(true);
    try {
      await updateGuestsToFolder(folderId,
        values.travelers.map(guest => {
          return {
            id: guest.id,
            gender: guest.gender?.value,
            firstName: guest.firstName,
            lastName: guest.lastName,
            birthdate: guest.birthdate,
            nationality: guest.nationality?.value,
            passportNumber: guest.passportNumber,
            passportExpirationDate: guest.passportExpirationDate,
            ageIsExact: guest?.ageIsExact,
          };
        }),
      );

      notify(t('success'));
      onSave();
      setOpen(false);
    } catch (err) {
      const error = camelCase(err?.response?.data?.message ?? 'error');

      notify(t(`errors:${error}`), { type: 'error' });
    } finally {
      setPending(false);
    }
  }

  return (
    <KoobModal open={open} setOpen={() => setOpen(false)} widthClass="sm:max-w-5xl overflow-visible">
      <div className="text-center max-w-md mx-auto">
        <KoobTitle size="text-xl">
          {t(`guestsModal.${mode}Title`)}
        </KoobTitle>
        <KoobParagraph size="text-sm">
          {t(`guestsModal.${mode}Description`)}
        </KoobParagraph>
      </div>
      <FoldersGuestsModalForm
        onSubmit={values => mode === "Edit" ? handleEditGuests(values) : handleAddGuests(values)}
        isPending={pending || requiredGuest - guestCount > 0 }
        mode={mode}
        guest={guest}
        message={((requiredGuest - guestCount) > 0 ) ? t("guestMessageCount", { count: requiredGuest - guestCount }) : undefined}
        setGuestCount={setGuestCount}
        travelersList={(travelersList || []).map(
          (item) => {
            return {
              ...item,
              gender:
                {
                  value: item.gender,
                  label: item.gender,
                }
            }
          }
        )}
      />

      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton onClick={() => setOpen(false)} color="secondary">
          {t('misc:cancel')}
        </KoobButton>
      </div>
    </KoobModal>
  )
}
