import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import {IcAddCircle, IcCancel} from 'components/icons';
import { isEqual } from 'lodash';
import { Dropdown, Spinner, Stack } from '@koob/margaret';
import {
  PopoverContainer,
  PopoverMenu as RawPopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from '@koob/margaret';
import Scrollbars from 'react-custom-scrollbars';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import {useAsync} from 'react-async';

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--location-search-trigger-height, auto);
  background-color: #ffffff;
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 20px;
  line-height: 15px;
  min-height: 36px;
  position: relative;
  transition: border-color 100ms ease;

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      background-color: ${({ theme }) => theme.backgroundDisabled};
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

const PlaceHolderLabel = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-weight: 500;
  white-space: nowrap;
  padding: 0 8px;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'transparent' &&
    `
      color: #ffffff;
      font-style: none;
    `}
`;

const SearchInput = styled.input`
  outline: none;
  border: 0;
  padding: 0;
`;

const PopoverSearchItem = ({ data, value, onChange, renderSelectedOption, valueKey }) => {
  return (
    <PopoverItem>
      {data?.map((obj) => (
        <PopoverItemButton
          style={{ fontSize: 14 }}
          key={obj[valueKey]}
          type="button"
          onClick={() => onChange(obj)}
          isActive={isEqual(value, obj)}
        >
          {renderSelectedOption(obj)}
        </PopoverItemButton>
      ))}
    </PopoverItem>
  );
};

const SearchableSelect = ({
  placeholder,
  onChange,
  onAfterChange,
  disabled,
  wrapperStyle,
  inputStyle,
  query,
  popoverFooter,
  renderSelectedOption,
  emptyState,
  hasError,
  value,
  setValue,
  addElement,
  datas
}) => {
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const inputRef = useRef();

  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const { loading, data, reload } = useAsync({search: debouncedSearch,promiseFn:query, watch: debouncedSearch});

  useEffect(reload, [value])

  const options = data?.data ?? datas ?? [];

  const removeFilter = e => {
    e.preventDefault();
    onChange('');
    setSearch('');
    setValue(null);
  }

  const handleChange = value => {
    onChange(value);
    setValue(value);

    if (Boolean(onAfterChange)) {
      onAfterChange(value);
    }
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  useDeepCompareEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  useEffect(() => {
    setSearch('');

    if (!isOpen) {
      return;
    }

    inputRef.current.focus();
  }, [isOpen]);

  return (
    <Dropdown
      disabled={disabled}
      ref={dropdownRef}
      wrapperStyle={wrapperStyle}
      onToggle={setIsOpen}
      trigger={
        <Trigger disabled={disabled} hasError={hasError}>
          {isOpen ? (
            <>
              <SearchInput
                style={inputStyle}
                ref={inputRef}
                onChange={e => setSearch(e.target.value)}
                placeholder={
                  Boolean(value) ? renderSelectedOption(value) : placeholder
                }
              />
              {loading && <Spinner size={20} variant="button" />}
            </>
          ) : (
            <>
              <PlaceHolderLabel disabled={disabled}>
                {Boolean(value) ? renderSelectedOption(value) : placeholder}
              </PlaceHolderLabel>
              { value && !disabled &&
                <IcCancel size={24} color="rgba(0, 0, 0, 0.6)" onClick={removeFilter} style={{marginLeft: 5}}/>
              }
              { !value && addElement &&
                <IcAddCircle size={24} color="rgba(0, 0, 0, 0.6)" onClick={addElement} style={{marginLeft: 5}}/>
              }
            </>
          )}
        </Trigger>
      }
    >
      <PopoverContainer style={{ maxHeight: '100px' }}>
        <PopoverMenu>
          <PopoverMenuInner
            ref={popoverRef}
            autoHeight
            autoHeightMax={250}
          >
            {Boolean(options?.length > 0) && (
              <PopoverSearchItem
                data={options}
                value={value}
                onChange={handleChange}
                renderSelectedOption={renderSelectedOption}
              />
            )}
          </PopoverMenuInner>

          {(Boolean(popoverFooter) ||
            (Boolean(emptyState) && !loading && data.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </PopoverContainer>
    </Dropdown>
  );
};

SearchableSelect.defaultProps = {
  placeholder: '',
  options: [],
  excludedIds: [],
  renderOption: ({ id }) => id,
  renderSelectedOption: ({ id }) => id,
};

export default SearchableSelect;
