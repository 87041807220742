import React, { useEffect, useState } from 'react';
import {useApp, useAuth, useBooking, useExperienceBooking} from '../../hooks';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getRequestAccess } from 'api/toRequestAccess';
import { useAsync } from 'react-async';
import { KoobStateEnum } from 'utils';
import { IcBags, IcLibrary, IcTools } from 'components/icons';

export default function KoobNavigation({ closeMenu }) {
  const { currentOrganizationIdInt } = useApp();
  const { logout } = useAuth();
  const { t } = useTranslation('mainSidebar');
  const {resetContext} = useBooking();
  const {resetContext: resetExperienceContext} = useExperienceBooking();
  const [openSubMenu, setOpenSubMenu] = useState(true);
  const [isActived, setIsActived] = useState(false);
  const [isValidTripDesignerRequest, setIsValidTripDesignerRequest] = useState(
    localStorage.getItem('tripDesignerRequestState') === KoobStateEnum.VALIDATED ?? false
  );
  const {data} = useAsync({
    promiseFn: getRequestAccess
  });

  const requestAccess = data?.data;
  const isTripPath = window.location.href.split('/').indexOf('trip-designer') !== -1;

  useEffect(() => {
    if (requestAccess?.tripDesignerRequestState) {
      setIsValidTripDesignerRequest(requestAccess?.tripDesignerRequestState);
      localStorage.setItem('tripDesignerRequestState', requestAccess?.tripDesignerRequestState);
    }
  }, [requestAccess?.tripDesignerRequestState]);

  const links = [
    {
      name: t('dashboard'),
      href: '/',
      icon: <i className="far fa-house text-xl" />,
      end: true
    },
    {
      name: t('hotels'),
      href: '/hotels',
      icon: <i className="far fa-bed text-xl" />,
      end: true,
      customOnClick: () => {resetContext();}
    },
    {
      name: t('experiences'),
      href: '/experiences',
      icon: <i className="far fa-layer-group text-xl" />,
      end: true,
      customOnClick: () => {
        resetExperienceContext();
      }
    },
    {
      name: t('tripDesigner'),
      description: t('discover'),
      slug: 'trip_designer',
      href: isValidTripDesignerRequest ? (isTripPath ? '#' : ' ') : '/trip-designer',
      icon: <i className="far fa-cube text-xl" />,
      subMenu: [
        {
          name: t('designTrip'),
          href: '/trip-designer/design-trip',
          slug: 'trip_designer',
          icon: <IcTools className="w-[18px] h-[18px]"/>
        },
        {
          name: t('dmcTemplates'),
          href: '/trip-designer/trips',
          slug: 'trip_designer',
          icon: <IcBags className="w-[18px] h-[18px]"/>
        },
        {
          name: t('library'),
          href: '/trip-designer/library',
          slug: 'trip_designer',
          icon: <IcLibrary className="w-[18px] h-[18px]"/>
        }
      ]
    },
    {
      name: t('organization'),
      href: `/organizations/${currentOrganizationIdInt}`,
      icon: <i className="far fa-house-building text-xl" />
    },
    {
      name: t('dmcs'),
      href: '/dmcs',
      icon: <i className="far fa-link text-xl" />
    },
    {
      name: t('folders'),
      href: '/folders',
      icon: <i className="far fa-folder-user text-xl" />
    },
  ];

  return (
    <div className="fixed left-0 top-0 bg-white">
      <div className="flex justify-center">
        <img
          className="h-28 w-auto"
          src="/logo512.png"
          alt="Koob Logo"
        />
      </div>
      <div className="min-h-screen w-72 shadow-r-k">
        <div className="pt-2 px-8">
          <div className="flex flex-col items-between">
            <div className="flex flex-col space-y-2">
              {links.map(l => (
                <>
                <NavLink
                  to={l.href}
                  className={({ isActive }) => {
                    if (isTripPath) {
                      setIsActived(false);
                      setOpenSubMenu(false);
                    }
                    return [
                      'block py-3 px-5 text-base font-medium rounded-xl border border-white hover:border-gray-300 transition ease-in',
                      isActive ? 'bg-gradient-to-b from-k-gd1 to-k-gd2 text-white' : 'text-gray-500',
                    ].join(' ')
                  }}
                  key={l.name}
                  end={l.end ?? false}
                  onClick={() => {
                    if (l.customOnClick) {
                      l.customOnClick();
                    }
                    if(l.name !== t('tripDesigner') || !isValidTripDesignerRequest){
                      closeMenu();
                    }else {
                      setOpenSubMenu(!openSubMenu);
                    }
                  }}
                >
                  <div className="flex items-center space-x-3">
                    <div className="h-6 w-6">
                      {l.icon}
                    </div>

                    <div>
                      {l.name}
                      {!isValidTripDesignerRequest && l.description &&
                      <div
                      className={`text-sm ${isActived ? 'text-white' : 'text-orange-500'}`}
                      >{l.description}</div>}
                    </div>
                  </div>
                </NavLink>

                {l.subMenu && openSubMenu || l.subMenu && isValidTripDesignerRequest && (
                    <div className="ml-4 space-y-2">
                      {l.subMenu.map(subLink => (
                        <NavLink
                          to={subLink.href}
                          key={subLink.name}
                          className={({ isActive }) => [
                            'block py-3 px-5 text-base font-medium rounded-xl border border-white hover:border-gray-300 transition ease-in',
                            isActive ? 'bg-gradient-to-b from-k-gd1 to-k-gd2 text-white' : 'text-gray-500',
                          ].join(' ')}
                          onClick={(closeMenu) => {
                            if (l.customOnClick) {
                              l.customOnClick();
                            }
                            closeMenu()
                          }}
                        >
                          <div className="flex items-center space-x-3">
                            <div className="h-6 w-6">
                              {subLink.icon}
                            </div>

                            <div>
                              {subLink.name}
                            </div>
                          </div>
                        </NavLink>
                      ))}
                    </div>
                  )}
                </>
              ))}

              <button
                onClick={logout}
                className="block py-3 px-5 text-base font-medium rounded-xl border border-white hover:border-gray-300 transition ease-in text-gray-500"
              >
                <div className="flex items-center space-x-3">
                  <i className="far fa-arrow-right-from-bracket text-xl" />

                  <div>
                    {t('signOut')}
                  </div>
                </div>
              </button>
            </div>

            <div className="fixed bottom-5 w-56">
              <a
                href="https://docs.google.com/document/d/1o_T5l0tq0SHgPRVJZE8v9LGHoIRWt9ei"
                target="_blank"
                className="block py-3 px-5 text-base font-medium rounded-xl border border-white hover:border-gray-300 transition ease-in text-gray-500" rel="noreferrer"
              >
                <div className="flex items-center space-x-3">
                  <i className="far fa-book text-xl" />

                  <div>
                    {t('userGuide')}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
