import {Button} from '@koob/margaret';
import {useApp, useSnack} from 'hooks';
import {useTranslation} from "react-i18next";

export const RoleButton = (props) => {
  const {currentUserRole} = useApp();
  const isButtonUsable = !props.disabledRoles || props.disabledRoles.indexOf(currentUserRole) === -1;
  const { notify } = useSnack();
  const { t } = useTranslation();
  const showError = (e) => {
    e.preventDefault();
    notify(props.disabledRolesMessage ? props.disabledRolesMessage: t('errors:noRight'), { type: 'error' });
  }
  return (
    <Button
      {...props}
      onClick={isButtonUsable ? props.onClick : showError}
    />
  )
}
