import { useTranslation } from 'react-i18next';
import KoobLoaderFullscreen from '@koob/ui/src/components/KoobLoaderFullscreen';

export default function ReceiptLoading() {
  const { t } = useTranslation('trips');

  return (
    <KoobLoaderFullscreen
      title={t('receipt.loading.title')}
      description={t('receipt.loading.description')}
    />
  );
}
