import 'react-toastify/dist/ReactToastify.css';
import { SnackContext } from '../contexts';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';
import { MdClose } from 'react-icons/md';

const ToastsStyles = createGlobalStyle`
  .Toastify {
    .Toastify__toast-container {
      z-index: 2147483647;
    }
    .Toastify__toast {
      border-radius: 28px;
      padding: ${({ theme }) => theme.spacing()};
      align-items: center;
      width: 480px;
      font-weight: bold;
      line-height: 1.43;
      margin-left: -64px;

      &:before {
        content: '';
        position: absolute;
        display: block;
        bottom: 0;
        width: 4px;
      }
      svg {
        font-size: 20px;
      }
    }
    .Toastify__toast--default {
      background-color: #e1eefb;
      color: rgba(0,0,0,0.87);
      &:before {
        background-color: #62acec;
      }
      svg {
        color: #62acec;
      }
    }
    .Toastify__toast--success {
      background-color: rgba(255, 255, 255, 0.96);
      color: #29cc88;
      &:before {
        background-color: #82c886;
      }
      svg {
        color: #82c886;
      }
    }
    .Toastify__toast--error {
      background-color: #fee7e1;
      color: rgba(0, 0, 0, 0.87);
      &:before {
        background-color: #ff8863;
      }
      svg {
        color: #ff8863;
      }
    }
  }
`;

const SnackProvider = ({ children }) => {
  const notify = (message, options) =>
    toast(message, { transition: Slide, type: 'success', ...options });

  const notifyError = (message, options) =>
    toast(message, { transition: Slide, type: 'error', ...options });

  return (
    <SnackContext.Provider value={{ notify, notifyError }}>
      {children}
      <ToastsStyles />
      <ToastContainer position="bottom-center" closeButton={<MdClose />} />
    </SnackContext.Provider>
  );
};

export default SnackProvider;
