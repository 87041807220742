import { get } from 'api';
import { SelectField } from 'components/Fields';
import { useAsync } from 'react-async';
import { formatCurrencyLabel } from 'utils';

const getCurrencies = () => get('api/v1/currencies');

const SelectCurrencyField = ({ ...props }) => {
  const { data: listCurrency } = useAsync({ promiseFn: getCurrencies });
  const currenciesOptions = (listCurrency?.data || [])
    .map(({ name: currency }) => ({
      value: currency,
      label: formatCurrencyLabel(currency),
    }))
    .sort((currency1, currency2) =>
      currency1.label.localeCompare(currency2.label),
    );

  return (
    <SelectField
      options={currenciesOptions}
      {...props}
      renderSelectedValue={value => value?.label || formatCurrencyLabel(value)}
    />
  );
};

export default SelectCurrencyField;
