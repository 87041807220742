import React from 'react';

export default function KoobAvatar({ size = 16, width, rounded = 'rounded-xl', img }) {
  return img ? (
    <img
      src={img}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src= 'https://via.placeholder.com/150';
      }}
      className={`${width ?? 'w-' + size} h-${size} ${rounded} shadow-k max-w-none`}
    />
  ) : (
    <div className={`w-${size} h-${size} ${rounded} bg-gray-100 border-k-gray shadow-k`} />
  );
}
