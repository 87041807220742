import React, { useEffect, useRef } from 'react';
import KoobCard from '../../../Koob/KoobCard';
import ChatboxInput from './ChatboxInput';
import { sendMessage } from 'api/trip';
import { useTranslation } from 'react-i18next';
import ChatboxMessage from '../../../Hotel/Booking/Edit/Chat/Partials/ChatboxMessage';
import { useError, useTripDesigner } from '../../../../hooks';

export default function ChatboxTrip({
  messages,
  refetch,
  tripId,
  conversationId,
  conversationTitle,
  secret,
  displayAs,
  showConversationTab,
  conversationReload
}) {
  const messageEndRef = useRef(null);
  const { t } = useTranslation('tripDesigner');
  const { sendErrorSnack } = useError();
  const { tripFilters } = useTripDesigner();

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);


  const sendMessageTrip = async values => {
    if (!values.message || values.message.trim() === '') return;

    try {
      await sendMessage({
        tripId: tripId ?? tripFilters?.tripId,
        conversationId: conversationId,
        body: {
            title: values.title,
            message: values.message
          }
      });
      refetch();
      conversationReload();
    } catch (error) {
      sendErrorSnack(error);
    }
  };

  return (
    <KoobCard>
      <div className="h-[36rem] overflow-scroll">
        <div className="py-5 px-[6%] flex flex-col space-y-5">
          {showConversationTab === 'list' && <div className="text-sm text-center pb-2 border-b">
            <div className='flex items-center justify-center'>
              <div className='font-bold mr-2'>
                {t('conversation.object')}
              </div>
              {conversationTitle}
            </div>

          </div>}
          {conversationId && messages?.length > 0 && messages?.map(message => (
            <ChatboxMessage
              key={message.id}
              message={message}
              displayAs={displayAs}
              dangerouslyHtml
            />
          ))}
          <div ref={messageEndRef} />
        </div>
      </div>

      {showConversationTab === 'list' &&
      <ChatboxInput
        tripId={tripId}
        conversationId={conversationId}
        secret={secret}
        refetch={refetch}
        sendMessage={sendMessageTrip}
      />}
    </KoobCard>
  );
}
