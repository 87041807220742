import SearchableSelectField from './SearchableSelectField';
import KoobPlaceholder from '../Koob/KoobPlaceholder';
import { useBooking } from 'hooks';

const SelectNationatilyField = ({
  label,
  name,
  placeholder,
  disabled,
  ...props
}) => {

  const { nationalities } = useBooking();

  return nationalities ? (
    <SearchableSelectField
      name={name}
      label={label}
      datas={nationalities}
      renderOption={value => value?.label}
      renderSelectedOption={value => value?.label}
      renderSelectedValue={value => value?.label}
      disabled={disabled}
      placeholder={placeholder}
      local
      {...props}
    />
  ) : (
    <KoobPlaceholder className="h-8 w-full rounded" />
  );
};

export default SelectNationatilyField;
