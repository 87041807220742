import React from 'react';

export default function KoobSelect({ placeholder, options, value, onSelect, disabled }) {
  return (
    <div className={disabled ? 'opacity-50 pointer-events-none' : ''}>
      <select
        className="sm:text-sm pl-3 pr-10 py-2 border-gray-300 focus:border-gray-300 block w-full rounded-md shadow-sm"
        onChange={event => onSelect(event.target.value)}
      >
        {value === null && (
          <option
            selected
            disabled
          >
            {placeholder ?? 'Select'}
          </option>
        )}

        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            selected={option.value === value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}
