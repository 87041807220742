import { useTranslation } from 'react-i18next';
import {DateField, SelectField, TextField} from 'components/Fields';
import { Stack } from '@koob/margaret';
import { FieldArray, Form, Formik, useField} from 'formik';
import { Button, Tooltip } from 'components';
import {ErrorMessageWrapper} from "../../../components/Hotel/Booking/BookingInfos";
import * as Yup from "yup";
import SelectNationatilyField from 'components/Fields/SelectNationatilyField';
import { useBooking } from 'hooks';
import CheckboxField from 'components/Checkbox';
import { differenceInYears } from 'date-fns';
import { GrCircleQuestion } from 'react-icons/gr';
import styled from 'styled-components';

export const FormField = ({name, children}) => {
  const [field, meta] = useField(name);
  const hasError = Boolean(meta.error);
  return (
    <>
      {children(field)}
      {hasError && <ErrorMessageWrapper>{meta.error}</ErrorMessageWrapper>}
    </>
  )
}

const Message = styled(Stack)`
  color: orange;
  font-weight: bold;
`;
export default function FoldersGuestsModalForm ({
  onSubmit,
  isPending,
  mode,
  guest,
  message,
  setGuestCount,
  travelersList
}) {
  const { t } = useTranslation('folders');
  var travelers = travelersList?.length > 0 ? travelersList : [{}];
  const { nationalities } = useBooking();

  if (mode === "Edit"){
    travelers = [
      {
        id: guest?.id,
        gender: guest?.gender !== "non_binary" ?
        {label : guest?.gender.charAt(0).toUpperCase() + guest?.gender.slice(1), value :guest?.gender}
        : {label : "Other", value :guest?.gender} ?? '',
        firstName: guest?.firstName ?? '',
        lastName: guest?.lastName ?? '',
        birthdate: guest?.birthdate?.substring(0,10) ?? undefined,
        nationality: nationalities.find((country)=> !!country.value && country?.value === guest?.nationality) ?? '',
        passportNumber: guest?.passportNumber ?? '',
        passportExpirationDate: guest?.passportExpirationDate ?? undefined,
        ageIsExact: guest?.ageIsExact ?? null,
      },
    ]
  }


  return (
    <Formik
      initialValues={
        {
          travelers
        }
      }
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        travelers: Yup.array()
          .of(
            Yup.object().shape({
              gender: Yup.object().shape({
                value: Yup.string()
                  .oneOf(['non_binary', 'male', 'female'])
                  .required(t('required', { ns: 'errors' }))
              }).required(),
              firstName: Yup.string().required(t('required', { ns: 'errors' })),
              lastName: Yup.string().required(t('required', { ns: 'errors' })),
              birthdate: Yup.date().nullable(),
            }),
          )
          .min(1),

      })}
    >
      {({ values, isValid, errors }) => (
        <Form>
          <FieldArray name="travelers">
            {({ push }) => (
              <>
                <Stack
                  gutterSize={1}
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                {mode === "Add" ?
                  <>
                    <Stack marginTop={1} size="full" alignX="flex-start">
                      <p>{t('modal.guests.title')}</p>
                    </Stack>

                    <Stack marginTop={1} size="full" alignX="flex-end">
                      <Button
                        variant="primary"
                        style={{ minWidth: 60, minHeight: 30 }}
                        onClick={() => {
                          push();
                          setGuestCount(values.travelers.length + 1);
                        }}
                        type="button"
                      >
                        {t('modal.guests.add')}
                      </Button>
                    </Stack>
                  </>
                    :
                    null
                }
                </Stack>

                {values.travelers?.map((guest, index) => (
                  <div key={index} className="border-b py-5 grid grid-cols-4 gap-3">
                    <div className="">
                      <SelectField
                        label={t('modal.guests.gender')}
                        name={`travelers.${index}.gender`}
                        touchRequired={false}
                        options={[
                          { label: t('misc:genders.male'), value: 'male' },
                          { label: t('misc:genders.female'), value: 'female' },
                          {
                            label: t('misc:genders.other'),
                            value: 'non_binary',
                          },
                        ]}
                      />
                    </div>

                    <div>
                      <TextField
                        label={t('modal.guests.firstName')}
                        name={`travelers.${index}.firstName`}
                        touchRequired={false}
                      />
                    </div>

                    <div>
                      <TextField
                        label={t('modal.guests.lastName')}
                        name={`travelers.${index}.lastName`}
                        touchRequired={false}
                      />
                    </div>
                    <div>
                      <FormField name={`travelers.${index}.birthdate`}>
                        {(field) => (
                          <DateField
                            name={`travelers.${index}.birthdate`}
                            datePlaceholder={t('modal.guests.birthdate')}
                            field={field}
                            showPlaceHolder={true}
                            actionOnfocus={function () {
                              return null
                            }}
                          />
                        )}
                      </FormField>
                      {
                        differenceInYears(new Date(),new Date(guest?.birthdate)) < 18 && (
                          <div className="flex items-center space-x-1  py-3">
                            <CheckboxField label={t('definitiveDate')} inCardPay name={`travelers.${index}.ageIsExact`}/>
                            {!guest?.ageIsExact && (
                              <Tooltip
                                tip={t('modal.birthdateTip')}
                                color="purple"
                                hasArrow={false}
                                position="top"
                              >
                                <GrCircleQuestion size={18} />
                              </Tooltip>
                            )}
                          </div>
                        )
                      }
                    </div>

                    <div>
                      <SelectNationatilyField
                        label={t('modal.guests.nationality')}
                        name={`travelers.${index}.nationality`}
                        touchRequired={false}
                      />
                    </div>

                    <div className="col-span-2">
                      <TextField
                        label={t('modal.guests.passportNumber')}
                        name={`travelers.${index}.passportNumber`}
                        touchRequired={false}
                      />
                    </div>
                    <div>
                      <DateField
                        name={`travelers.${index}.passportExpirationDate`}
                        datePlaceholder={t('modal.guests.passportExpirationDate')}
                        showPlaceHolder={true}
                        actionOnfocus={function () {
                          return null
                        }}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </FieldArray>

          <Stack alignY="center" gutterSize={0.5} paddingBottom={0.5}>
            <Message marginTop={2} size="full" alignX="flex-start">
                {message}
            </Message>
            <Stack marginTop={2} size="full" alignX="flex-end">
              <Button
                disabled={isPending || !isValid}
                type="submit"
                variant="primary"
                style={{ minWidth: 120, minHeight: 40, marginLeft: 25 }}
              >
                {t('misc:saveChanges')}
              </Button>
            </Stack>
          </Stack>

        </Form>
      )}
    </Formik>
  );
}
