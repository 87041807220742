import React, { useState } from 'react';
import FoldersGuestsListRow from './FoldersGuestsListRow';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import FoldersGuestsModal from './FoldersGuestsModal';

export default function FoldersGuestsList({ isPending, reload, travelers }) {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [mode, setMode] = useState(null);
  const [index, setIndex] = useState(null);

  return !isPending ? (
    <>
      {travelers?.map((guest, idx) => (
        <div onClick={() => {setShowCreateModal(true), setMode("Edit"), setIndex(idx)}} key={idx} className='cursor-pointer'>
          <FoldersGuestsListRow
            key={idx}
            gender={guest.gender}
            firstName={guest.firstName}
            lastName={guest.lastName}
            birthdate={guest.birthdate}
            nationality={guest.nationality}
            passportNumber={guest.passportNumber}
            passportExpirationDate={guest.passportExpirationDate}
          />
        </div>
      ))}
      <button
        className="h-50 border-2 border-dashed border-gray-400 rounded-xl"
        onClick={() => {setShowCreateModal(true), setMode("Add")}}
      >
        <div className="h-full flex justify-center items-center">
          <div className="text-gray-400">
            <svg
              className="w-10 h-10"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>
      </button>

      <FoldersGuestsModal
        open={showCreateModal}
        setOpen={setShowCreateModal}
        onSave={() => reload()}
        mode={mode}
        guest={travelers[index]}
      />
    </>
  ) : (
    [...Array(4)].map((_e, key) => (
      <KoobPlaceholder className="h-40 rounded-xl" key={key} />
    ))
  );
}
