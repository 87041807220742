import React from 'react';
import CheckoutHotelRoomPrice from './CheckoutHotelRoomPrice';
import KoobBadge from '@koob/ui/src/components/KoobBadge';
import formatHotelRoomTags from '../utilities/formatHotelRoomTags';
import CheckoutHotelRoomTag from './CheckoutHotelRoomTag';
import CheckoutHotelRoomAllotmentAvailable from './CheckoutHotelRoomAllotmentAvailable';

export default function CheckoutHotelRoomVariant({
  scope,
  possibility,
  travelersCount = 1,
  isSelected,
  onSelect,
}) {
  const cheapestBedChoice = possibility?.bedChoices
    ?.sort((a, b) => a.totalPriceWithPromo - b.totalPriceWithPromo)?.[0];

  const tags = formatHotelRoomTags(possibility?.bedChoices?.[0]);

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
        onSelect(possibility.originalIndex);
      }}
      className="py-1.5 flex justify-between items-center"
    >
      <div className="flex flex-wrap gap-1.5 items-center">
        <div className="text-md font-semibold">
          {possibility?.name}
        </div>

        {possibility?.supplierCode && (
          <KoobBadge size="sm">
            <i className="far fa-link mr-1" />
            {possibility.supplierCode}
          </KoobBadge>
        )}

        {tags?.map((tag, index) => (
          <CheckoutHotelRoomTag
            key={index}
            label={tag.label}
            value={tag.value}
            startAt={tag.startAt}
            endAt={tag.endAt}
            size="sm"
          />
        ))}
      </div>

      <div className="flex space-x-5 justify-end items-center">
        {possibility?.allotmentAvailable && (
          <CheckoutHotelRoomAllotmentAvailable
            scope={scope}
            room={possibility}
          />
        )}

        <div className="flex-shrink-0 text-right">
          <CheckoutHotelRoomPrice
            price={cheapestBedChoice?.totalPriceWithPromo}
            currency={cheapestBedChoice?.currency}
            travelersCount={travelersCount}
            small
          />
        </div>

        <div className="text-lg">
          {isSelected ? (
            <i className="fas fa-square-check text-k-orange"/>
          ) : (
            <i className="far fa-square text-gray-400"/>
          )}
        </div>
      </div>
    </div>
  );
}
