import { KoobDescriptionList, KoobDescriptionListRow } from '@koob/ui';
import moment from 'moment';
import ExportDaySectionTitle from './ExportDaySectionTitle';
import { differenceInDays } from 'date-fns';
import { getAllCountries } from '@koob/to/src/api/countries';
import { useMemo } from 'react';
import { useAsync } from 'react-async';

export function ExportGeneralSummaryGuest({ guest }) {
  const { data: countriesData } = useAsync({
    promiseFn: getAllCountries,
  });

  const nationalities = useMemo(() => {
    return (countriesData?.data ?? []).map(country => ({
      value: country?.regions[0]?.country?.alpha2,
      label: country?.translations.find((elem) => elem.locale === 'en').title,
    }));
  }, [countriesData]);

  const iconClass = () => {
    if (guest.kind === 'adult') {
      if (guest.gender === 'female') {
        return 'fa-person-dress';
      }
      return 'fa-person';
    } else if (guest.kind === 'child') {
      return 'fa-child';
    } else if (guest.kind === 'baby') {
      return 'fa-baby';
    }
  }

  const nationality = nationalities?.find((nationality) => nationality?.value === guest?.nationality)

  return (
    <div className="py-4 px-6 border rounded-lg">
      <div className="flex space-x-5 items-center">
        <div>
          <i
            className={[
              'fa-regular fa-person text-4xl',
              iconClass()
            ].join(' ')}
          />
        </div>

        <div>
          <div className="font-medium text-lg">
            {guest?.gender?.charAt(0)?.toUpperCase()}. {guest.firstName} {guest.lastName}
          </div>

          {nationality?.value && (
            <div>
              <i className="fa-regular fa-flag mr-2"/>
              {nationality?.label}
            </div>
          )}

          <div>
            <i className="fa-regular fa-cake-candles mr-2" />
            {moment(guest.birthdate).format('DD/MM/YYYY')}
            <span className="ml-1 text-gray-600">({moment().diff(guest.birthdate, 'years')} years old)</span>
          </div>

          {guest.passportNumber && (
            <div>
              <i className="fa-regular fa-passport mr-2"/>
              {guest.passportNumber} — {moment(guest.expirationDate).format('DD/MM/YYYY')}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default function ExportGeneralSummary({trip, tourOperator}) {
  const guestsSet = new Set();
  const daysCount = differenceInDays(new Date(trip?.endDate), new Date(trip?.startDate));

  function generateGuestKey(guest) {
    const { firstName, lastName, birthdate, gender } = guest;
    return `${firstName}_${lastName}_${birthdate}_${gender}`;
  }

  const guests = trip?.bookings?.reduce((acc, booking) => {
    booking?.experiencesBooked?.forEach(experience => {
      if (experience && experience.travelers) {
        experience.travelers.forEach(traveler => {
          const key = generateGuestKey(traveler);
          if (!guestsSet.has(key)) {
            guestsSet.add(key);
            acc.push(traveler);
          }
        });
      }
    });

    booking?.roomsBooked?.forEach(room => {
      if (room && room.travelers) {
        room.travelers.forEach(traveler => {
          const key = generateGuestKey(traveler);
          if (!guestsSet.has(key)) {
            guestsSet.add(key);
            acc.push(traveler);
          }
        });
      }
    });

    return acc;
  }, []);

  return (
    <div className="grid grid-cols-2 gap-16">
      <div>
        <ExportDaySectionTitle>
          <i className="fa-regular fa-suitcase mr-2"/> Trip
        </ExportDaySectionTitle>

        <KoobDescriptionList>
          <KoobDescriptionListRow
            label="Tour Operator"
            value={tourOperator?.displayName}
          />

          <KoobDescriptionListRow
            label="Duration"
            value={`${daysCount + 1} days — ${daysCount} nights`}
          />

          <KoobDescriptionListRow label="Departure">
            {!trip?.template && (
              <>
                {moment(trip?.startDate).format('DD/MM/YYYY')}
                <br/>
              </>
            )}
            {trip?.startLocation?.title}, {trip?.startLocation?.country?.alpha2}
          </KoobDescriptionListRow>

          <KoobDescriptionListRow label="Arrival">
            {!trip?.template && (
              <>
                {moment(trip?.endDate).format('DD/MM/YYYY')}
                <br/>
              </>
            )}
            {trip?.endLocation?.title}, {trip?.endLocation?.country?.alpha2}
          </KoobDescriptionListRow>
        </KoobDescriptionList>
      </div>

      {guests?.length > 0 && (
        <div>
          <ExportDaySectionTitle>
            <i className="fa-regular fa-users mr-2"/> Guests
          </ExportDaySectionTitle>

          <div className="flex-col space-y-3">
            {guests?.map(guest => (
              <ExportGeneralSummaryGuest
                key={guest.id}
                guest={guest}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
