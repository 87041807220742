import styled, { css } from 'styled-components';
import { fontSize } from 'ui';
import { Stack } from '@koob/margaret';

const Wrapper = styled(Stack).attrs({
  direction: 'column',
  alignX: 'center',
  alignY: 'center',
  size: 'full',
})`
  ${fontSize.body}
  color: ${({ theme }) => theme.textLight};

  ${props =>
    props.variant === 'full' &&
    css`
      min-height: 300px;
    `};
`;

const EmptyState = ({ children, variant }) => {
  return (
    <Wrapper variant={variant}>
      <div>{children}</div>
    </Wrapper>
  );
};

export default EmptyState;
