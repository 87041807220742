import { Route, Routes } from 'react-router-dom';
import TripComposer from './Compose';
import { Discover } from './Discover';
import { DesignTrip } from './DesignTrip';
import Library from './Library';
import Trips from './DmcTemplates';

const TripDesignerRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Discover />} />
      <Route index path="/compose" element={<TripComposer />} />
      <Route index path="/design-trip" element={<DesignTrip />} />
      <Route index path="/design-trip/:fromId" element={<DesignTrip />} />
      <Route index path="/library" element={<Library />} />
      <Route index path="/trips" element={<Trips />} />
    </Routes>
  );
};

export default TripDesignerRoutes;
