import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { Stack } from '@koob/margaret';
import { Button } from 'components';
import {
  TextField,
} from 'components/Fields';
import * as Yup from 'yup';
import KoobButton from '../Koob/KoobButton';

const ConversationForm = ({ onSubmit, setShowConversationModal }) => {
  const { t } = useTranslation(['tripDesigner', 'errors']);

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={{title: ''}}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(t('errors:required'))
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack direction="column" gutterSize={1.5} size="full">
              <Stack
                gutterSize={1.5}
                size="full"
                direction={{ default: 'column', tablet: 'row' }}
              >
                <TextField label={t('conversation.create')} name="title" />
              </Stack>

              <Stack alignY="center" gutterSize={1}>
                <Button
                  type="submit"
                  variant="primary"
                  isLoading={isSubmitting}
                >
                  {t('misc:saveChanges')}
                </Button>
                <KoobButton onClick={() => setShowConversationModal(false)} color="secondary">
                {t('misc:cancel')}
               </KoobButton>
              </Stack>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConversationForm;
