import { Stack } from "@koob/margaret";
import { groupBy, sortBy, values } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, {css, useTheme} from "styled-components";
import { Text } from "ui";
import {LigneHorizontal, SubSectionTitle, SubSectionTitleContainer} from "./Base";
import { Button } from 'components';
import { IcAlert } from 'components/icons';
import { Tooltip } from 'react-tooltip';
import sanitizeHtml from 'sanitize-html';

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const IconPlaceholder = styled.div`
  height: 20px;
  width: 20px;
`;

const GroupTitle = styled(Text).attrs({type: 'h4'})`
  font-weight: 700;
  margin-top: 30px;
`;

const ButtonMore = styled(Button)`
  ${props =>
          props.variant === 'roundButton' &&
          css`
            background-color: white;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
            border: 1px solid #1a1a1a26;
            color: #000;
            padding: 0 30px 0 30px !important;
            border-radius: 28px !important;
            font-weight: bold;
            text-transform: none;
            margin-top: 50px;
            height: 50px;

            &:hover,
            &:active {
              background: #fff;
            }
          `}
`

const CustomTooltip = styled(Tooltip)`
  max-width: 300px;
  overflow-wrap: break-word;
  display: block;
  border-radius:50px !important;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

const ServiceAndFacilities = ({hotel}) => {

  const {t} = useTranslation('hotel');
  const [isShown, setIsShown] = useState(false);
  const theme = useTheme();

  const hotelFacilities = hotel?.hotelFacilities;
  const groupesQuery = sortBy(
    values(
      groupBy(hotelFacilities, ({facility}) => {
        if(facility.groups.length === 0) {
          return '-42';
        }
        return facility.groups[0].id;
        },
      )
    ))

  let groupes = []
  groupesQuery.forEach((item, index) => {

    let groupe = {
      title: t('otherFacility'),
      liste: [],
    }
    if (index <= 4 && !isShown) {
      if (item[0].facility.groups.length !== 0) {
        groupe.title = item[0].facility.groups[0].displayName
      }
      item.forEach((element) => {
        let facility = {
          name: element.facility.displayName,
          icone: element.facility.iconUrl
        }
        groupe.liste.push(facility)
      })
      groupes.push(groupe)
    } else if (isShown) {
      if (item[0].facility.groups.length !== 0) {
        groupe.title = item[0].facility.groups[0].displayName
      }
      item.forEach((element) => {
        let facility = {
          name: element.facility.displayName,
          icone: element.facility.iconUrl,
          description: element.details
        }
        groupe.liste.push(facility)
      })
      groupes.push(groupe)
    }
  })

  return (
    <>
      {hotel?.hotelFacilities.length > 0 &&
        <Stack size="full" direction="column">
          <SubSectionTitleContainer>
            <SubSectionTitle>{t('servicesAndFacilities')}</SubSectionTitle>
          </SubSectionTitleContainer>

          <div className="grid grid-cols-2 gap-4 mt">
            {(groupes || []).map((item, index) => (
              <div className={index > 1 ? "mt-6" : ''} key={index}>
                <div>
                  <GroupTitle>{item.title}</GroupTitle>
                  {(item.liste || []).map((facility, indx) => (
                    <div className="mt-2 max-w-5xl flex items-center" key={indx}>
                      {facility.icone !== '' ? (
                        <Icon
                          src={facility.icone}
                        />
                      ) : (
                        <IconPlaceholder/>
                      )}
                      <span className="ml-5">{facility.name}</span>
                      {
                        facility.description && facility.description.length > 0 && (
                          <div>
                            <div data-tooltip-html={sanitizeHtml(facility.description)} data-tooltip-id={`toopltip${indx}`}>
                              <IcAlert style={{marginLeft: 5, color: theme.grey}}></IcAlert>
                            </div>
                            <CustomTooltip
                              id={`toopltip${indx}`}
                              effect="solid"
                              delayHide={100}
                              delayShow={100}
                              variant="light"
                              border={false}
                              multiline={true}
                              place="bottom"
                            />
                          </div>
                        )
                      }
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <ButtonMore variant="roundButton" onClick={() => setIsShown(!isShown)}>
            {' '}
            {isShown ? t('services.hide') : t('services.show')}
          </ButtonMore>

          <LigneHorizontal/>
        </Stack>
      }
    </>
  )
}

export default ServiceAndFacilities;
