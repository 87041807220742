import KoobTitle from '../../../../components/Koob/KoobTitle';
import ReceiptHiddenSection from './ReceiptHiddenSection';
import { TextField } from '../../../../components/Fields';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import { useTranslation } from 'react-i18next';

export default function ReceiptContentMargin({ markup, setMarkup }) {
  const { t } = useTranslation('trips');

  return (
    <ReceiptHiddenSection className="mt-3">
      <KoobTitle size="md">
        <div className="flex space-x-3 items-center">
          <div>
            {t('receipt.markup.title')}
          </div>
          <KoobBadge>
            {t('receipt.optional')}
          </KoobBadge>
        </div>
      </KoobTitle>

      <KoobParagraph size="sm">
        {t('receipt.markup.description')}
      </KoobParagraph>

      <div className="my-3">
        <input
          value={markup}
          onChange={(e) => setMarkup(e.target.value)}
          className="w-full bg-white border border-gray-300 rounded-md p-2"
          placeholder="e.g. 10%"
        />
      </div>
    </ReceiptHiddenSection>
  )
}
