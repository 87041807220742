import KoobTableCell from '../../../../components/Koob/KoobTableCell';
import moment from 'moment/moment';
import { formatCurrency } from '../../../../utils';
import ReceiptContentItemType from './ReceiptContentItemType';
import { useMemo } from 'react';
import KoobBadge from '../../../../components/Koob/KoobBadge';
import { useTranslation } from 'react-i18next';

export const receiptItemDateFormatter = (startAt, endAt) => {
  if (moment(startAt).isSame(endAt, 'day') || !endAt) {
    return moment(startAt).format('MMM DD, YYYY');
  }
  return `${moment(startAt).format('MMM DD')} — ${moment(endAt).format('MMM DD, YYYY')}`;
};

export default function ReceiptContentItem({ item }) {
  const { t } = useTranslation('folders');

  const formattedDate = useMemo(() => {
    if (!item.startAt && !item.endAt) {
      return '';
    }
    return receiptItemDateFormatter(item.startAt, item.endAt);
  }, [item.startAt, item.endAt]);

  return (
    <tr>
      <KoobTableCell>
        <div className="whitespace-normal">
          <div className="flex space-x-3 items-center">
            <ReceiptContentItemType type={item.type}/>

            <div>
              <div className="font-medium text-base text-gray-600">
                {item.name}
              </div>
              <div>
                {item.location}
              </div>
            </div>

            {item.state === 'canceled' && (
              <KoobBadge
                color="red"
                size="sm"
              >
                {t('bookings.status.cancelled')}
              </KoobBadge>
            )}
          </div>
        </div>
      </KoobTableCell>

      <KoobTableCell>
        {formattedDate}
      </KoobTableCell>

      <KoobTableCell>
        {item.pax && (
          <>
            <i className="far fa-user mr-1"/>
            {item.pax}
          </>
        )}
      </KoobTableCell>

      {item.price !== undefined && (
        <KoobTableCell>
          <div
            className={[
              'text-right',
              item.state === 'canceled' ? 'line-through text-gray-500' : ''
            ].join(' ')}
          >
            {formatCurrency({
              amount: item.price,
              currency: item.currency
            })}
          </div>

          {item.state === 'canceled' && (
            <div className="text-right font-medium text-red-500">
              {formatCurrency({
                amount: item.cancellationFee,
                currency: item.currency
              })}
            </div>
          )}
        </KoobTableCell>
      )}
    </tr>
  )
}
