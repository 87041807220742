import { get, patch, post, put } from './index';

export const getFolders = ({search, from, to}) => {
  const filters = [];
  if (search) {
    filters.push('search='+search);
  }
  if (from) {
    filters.push('from='+from);
  }
  if (to) {
    filters.push('to='+to);
  }
  return get(`/api/v1/folders?${filters.join('&')}`);
}

export const getFolder = ({id}) => get(`/api/v1/folders/${id}`);

export const createFolder = payload => post(`/api/v1/folders`, payload);

export const updateFolderInfo = (id, payload) => patch(`/api/v1/folders/${id}`, payload);

export const addGuestsToFolder = (id, payload) => post(`/api/v1/folders/${id}/travelers`, payload);

export const updateGuestsToFolder = (id, payload) => put(`/api/v1/folders/${id}/travelers`, payload);
