import BookingRoomsCompositionModal from './BookingRoomsCompositionModal';

export const ChildrenBookingRoomsCompositionModal = ({
  open,
  setOpen,
  value,
  setValue,
  index = 0,
}) => {
  const maxAdults = value.reduce((total, composition) => {
    return total + composition.adults;
  }, 0);

  const maxChildren = value.reduce((total, composition) => {
    return total + composition.children;
  }, 0);
  const composition = value[index];
  const minPaxRequired = 2;
  const initialComposition = composition?.roomsComposition ?? [
    {
      adults: 0,
      children: 0,
      childrenBirthdates: [],
      count: 1,
      firstAdultNationality: composition.firstAdultNationality,
    },
  ];

  return (
    <>
      {open && (
        <BookingRoomsCompositionModal
          initialValue={initialComposition}
          onRequestClose={() => setOpen(false)}
          onSave={childModalComposition => {
            setValue(prevValue => {
              const newValue = [...prevValue];
              newValue[index] = {
                ...newValue[index],
                roomsComposition: childModalComposition,
              };
              return newValue;
            });
            setOpen(false);
          }}
          maxAdults={maxAdults}
          maxChildren={maxChildren}
          multiselect={
            maxAdults >= minPaxRequired || maxChildren >= minPaxRequired
          }
          parentChildrenBirthDates={composition.childrenBirthdates}
          hasNationalityField
          isChildrenComposition
        />
      )}
    </>
  );
};