import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function KoobBackButton() {
  const navigate = useNavigate();
  return (
    <Link
      to=".."
      className="bg-white p-3 text-gray-900 shadow-k rounded-full border-none"
      onClick={e => {
        e.preventDefault();
        navigate(-1);
      }}
    >
      <svg
        className="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 19l-7-7 7-7"
        />
      </svg>
    </Link>
  );
}
