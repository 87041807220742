import { get, post } from './';

export const getExperiences = ({ filters, locale, skip = false }) => {
  if (skip) {
    return Promise.resolve(null);
  }

  return post(`api/v1/experiences/liste?locale=${locale ?? 'en'}`, filters);
};

export const getExperience = ({ experienceId, locale }) => {
  if (!experienceId) {
    return Promise.resolve(null);
  }

  return get(`api/v1/experiences/${experienceId}?locale=${locale ?? 'en'}`);
};

export const exportExperience = ({ experienceId, locale, exportFormat, options }) => {
  return post(
    `api/v1/experiences/${experienceId}/export/${exportFormat}?locale=${locale ?? 'en'}`,
    options,
    { responseType: 'blob' },
    false
  );
};

export const bookExperiences = (data) => {
  return post(`/api/v1/experiences/book`, data);
};
