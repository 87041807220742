import React from 'react';
import BookingStatusBadge from '../../../components/Hotel/Booking/Edit/BookingStatusBadge';
import KoobCard from '../../../components/Koob/KoobCard';
import KoobPlaceholder from '../../../components/Koob/KoobPlaceholder';
import { useTranslation } from 'react-i18next';
import {format} from 'date-fns';
import { IcCancel, IcPencil } from 'components/icons';
import { Field,  } from 'formik';
import { FormField } from './FoldersGuestsModalForm';
import { Button } from 'components/index';

export default function FoldersDetailsCard({ isPending, folder, editFolderField, setEditFolderField }) {
  const { t } = useTranslation('folders');
  const booking = folder?.bookings?.length > 0 ? folder?.bookings[0] : undefined;
  const travelers = booking?.roomsBooked[0]?.travelers ?? booking?.experiencesBooked[0]?.travelers;
  const detailsColumns = [
    [
      {
        label: t('table.name'),
        value: folder?.name,
        inputField: <input name='name'/>,
        fieldName: "name",
        isEditable: true,
      },
      {
        label: t('table.icr'),
        value: folder?.icr,
        inputField: <input name='icr'/>,
        fieldName: "icr",
        isEditable: true
      },
      {
        label: t('table.status'),
        value: <BookingStatusBadge status={folder?.bookings[0]?.state} />
      }
    ],
    [
      {
        label: t('details.mainGuest'),
        value: booking ?
          travelers ? travelers[0]?.firstName + ' ' + travelers[0]?.lastName : '-'
          : '-'
      },
      {
        label: t('details.numberOfGuests'),
        value: folder?.travelers?.length
      },
      {
        label: t('details.numberOfBookings'),
        value: folder?.bookings?.length
      }
    ],
    [
      {
        label: t('details.createdAt'),
        value: folder?.createdAt ? format(new Date(folder?.createdAt), 'dd/MM/yyyy') : '-'
      },
      {
        label: t('table.createdBy'),
        value: folder?.createdBy?.firstName ?
          folder?.createdBy?.firstName + ' ' + folder?.createdBy?.lastName
          : '-'
      },
      {
        label: t('details.updatedAt'),
        value: folder?.updatedAt ? format(new Date(folder?.updatedAt), 'dd/MM/yyyy') : '-'
      }
    ]
  ]

  return (
    !isPending ? (
      <KoobCard>
        <div className="flex justify-end space-x-5">
          {!(editFolderField) ? (
              <Button 
              type="button"
              variant={'simple'}
              onClick={() => setEditFolderField(prev => !prev)}
              >
                <IcPencil />
              </Button>
            ) : (
              <Button 
                type="button"
                variant={'simple'}
                onClick={() => setEditFolderField(prev => !prev)}
                >
                <IcCancel />
              </Button>
            )}
        </div>
        <div className="grid grid-cols-3 gap-10 px-5">
          {detailsColumns.map((column, index) => (
            <div className="flex flex-col divide-y divide-gray-100 text-gray-800" key={index}>
              {column.map(item => (
                <div className="justify-between" key={item.label}>
                  <div className="py-5 leading-none flex justify-between items-center" >
                    <span className="uppercase text-sm text-gray-500 font-medium">{item.label}</span>
                    {item.isEditable && editFolderField
                      ? (
                          <div className="flex justify-between space-x-5">
                            <FormField name={item.fieldName}>
                              {field => (
                                <Field
                                  name={item.fieldName}
                                  type="text"
                                  field={field}
                                  style={{
                                    borderRadius: '4px',
                                    width: 'fit-content',
                                    minHeight: '30px'
                                  }}
                                />
                              )}
                            </FormField>
                          </div>
                        )
                      : <span>{item.value}</span>
                    }
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </KoobCard>
    ) : (
      <KoobPlaceholder className="h-48 rounded-xl" />
    )
  )
}
