import React from 'react';

export default function KoobTableCell({ children }) {
  return (
    <td
      className={`whitespace-nowrap text-sm text-gray-500 py-6`}>
      {children}
    </td>
  );
}
