import { IcPencil } from 'components/icons';
import { ActionLink } from 'ui';

const FoldersBookingEditButton = ({ bookingId }) => {
  return (
    <ActionLink to={`/bookings/${bookingId}`}>
      <IcPencil />
    </ActionLink>
  );
};

export default FoldersBookingEditButton;
