import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBooking, useSnack } from '../../../hooks';
import KoobSelect from '../../Koob/KoobSelect';
import { differenceInYears, format, parseISO } from "date-fns";

export default function LoadFromTraveler({ loadTraveler, type, customerFile }) {
  const { t } = useTranslation('booking', {keyPrefix: 'form'});
  const { notify } = useSnack();
  const { guests, nationalities } = useBooking();

  const [travelerIndex, setTravelerIndex] = React.useState(null);

  const travelers = [
    ...(customerFile?.travelers || []).map(traveler => ({
      ...traveler,
      nationality: traveler.nationality ? nationalities?.find(
        nationality =>
          nationality.value === traveler.nationality)
       : undefined,
      birthdate: traveler.birthdate ? parseISO(traveler.birthdate) : undefined,
    })),
    ...(guests || []).map(guest => ({
      id: guest.id,
      gender: guest.gender,
      firstName: guest.firstName,
      lastName: guest.lastName,
      birthdate: guest.birthdate ? parseISO(guest.birthdate) : undefined,
      nationality: guest.nationality ? nationalities?.find(
        nationality =>
          nationality.value === guest.nationality)
       : undefined,
    })),
  ].filter(traveler => !!traveler.firstName && !!traveler.lastName);

  const travelersEligible =
    type === 'adult'
      ? travelers.filter(
        (traveler) =>
          traveler.birthdate &&
          differenceInYears(new Date(), new Date(traveler.birthdate)) >= 18
      )
      : travelers.filter(
        (traveler) =>
          traveler.birthdate &&
          differenceInYears(new Date(), new Date(traveler.birthdate)) < 18
      );

  useEffect(() => {
    if (travelerIndex) {
      loadTraveler(travelers[travelerIndex]);
      setTravelerIndex(null);
      notify(t('importFromTraveler.success'));
    }
  }, [travelerIndex]);

  return (
    ((travelers?.length > 0 ||
        (customerFile &&
          customerFile.travelers &&
          customerFile.customerFileId)) && (
        <>
          <div className="flex space-x-3">
            {travelersEligible.length === 0 && travelers.length > 0 && (
              <div className="bg-red-300 rounded-lg max-w-xs">
                <div className="px-3 py-2">
                  <div className="text-xs">
                    {t('importFromTraveler.noEligible')}
                  </div>
                </div>
              </div>
            )}

            <KoobSelect
              placeholder={t('importFromTraveler.placeholder')}
              options={
                travelers.map((traveler, index) => ({
                  label: `${
                    traveler?.gender && traveler.gender.charAt(0).toUpperCase()
                  } ${traveler?.firstName} ${traveler?.lastName} ${
                    !!traveler?.birthdate
                      ? `(${format(traveler?.birthdate, 'dd/MM/yyyy')})`
                      : ''
                  }`,
                  value: index,
                })) || []
              }
              value={travelerIndex}
              onSelect={value => setTravelerIndex(value)}
            />
          </div>
        </>
      )
    )
  ) ?? null;
}
