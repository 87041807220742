import KoobTable from '../../../../components/Koob/KoobTable';
import ReceiptContentItem from './ReceiptContentItem';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import { useMemo, useState } from 'react';
import ReceiptContentManualItem from './ReceiptContentManualItem';
import ReceiptContentTotal from './ReceiptContentTotal';
import ReceiptContentMargin from './ReceiptContentMargin';
import { useTranslation } from 'react-i18next';

export default function ReceiptContent({ items }) {
  const { t } = useTranslation('trips');

  const [manualItems, setManualItems] = useState([]);
  const [markup, setMarkup] = useState(0);

  const heads = [
    { label: t('receipt.bookedItems.columnItem') },
    { label: t('receipt.bookedItems.columnDates') },
    { label: t('receipt.bookedItems.columnPax') },
    { label: t('receipt.bookedItems.columnTotal'), align: 'right' },
  ];

  const allItems = [...items, ...manualItems]?.map((item) => ({
    ...item,
    price: item.price * (1 + (markup ?? 0) / 100),
  }));

  const grandTotalPrice = useMemo(() => {
    return allItems
      ?.filter((item) => !!item.price && (item.state !== 'canceled' || item.cancellationFee))
      ?.reduce((acc, item) => acc + (item.cancellationFee >= 0 ? item.cancellationFee : item.price), 0);
  }, [allItems]);

  const grandTotalCurrency = items?.[0]?.currency;

  return (
    <div>
      <KoobTitle>
        {t('receipt.bookedItems.title')}
      </KoobTitle>

      <KoobTable heads={heads}>
        {allItems?.map((item, index) => (
          <ReceiptContentItem
            key={index}
            item={item}
          />
        ))}
      </KoobTable>

      <ReceiptContentTotal
        price={grandTotalPrice}
        currency={grandTotalCurrency}
      />

      <ReceiptContentManualItem
        setManualItems={setManualItems}
        currency={grandTotalCurrency}
      />

      <ReceiptContentMargin
        markup={markup}
        setMarkup={setMarkup}
      />
    </div>
  )
}
