import { memo } from 'react';

const IcLocation = props => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      strokeWidth={2}
      d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
      fill="currentColor"
    />
  </svg>
);

export default memo(IcLocation);
