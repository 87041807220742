import { get, post } from './';

export const getTags = async ({scope}) => {
  return get(`/api/v1/tags?scope=${scope}`);
}

export const getTagsByTrip = async ({tripId}) => {
  return get(`/api/v1/tags/${tripId}`);
}

export const createTag = async (tag) => {
  return post(`/api/v1/tags`, tag);
}

export const saveTags = ({values, tripId}) => {
  return post(`/api/v1/tags/save/${tripId}`, values);
}