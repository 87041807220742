import React  from 'react';
import KoobTitle from '../../../components/Koob/KoobTitle';
import { useTranslation } from 'react-i18next';
import LibraryFilters from '../../../components/Trip/LibraryFilters';
import styled from 'styled-components';
import TripCard from 'components/Trip/TripCard';
import { useTripDesigner } from '../../../hooks';
import { getTripsBooked } from 'api/trip';
import { isEqual } from 'lodash';
import { useAsync } from 'react-async';
import Spinner from '../../../components/Spinner';
import KoobLayout from '../../../components/Koob/KoobLayout';

export default function DistributedTrips() {

  const KoobTripCount = styled.h6`
    margin-top: 15px;
    color: grey;
  `;

  const { t } = useTranslation('tripDesigner');
  const { libraryFilters } = useTripDesigner();

  const requestFilters= {
    search: libraryFilters?.search ?? "",
    cities: libraryFilters?.cities?.map((city) => city.id) ?? [],
    countries: libraryFilters?.countries?.map((country) => country.value) ?? [],
    tripDuration: libraryFilters?.tripDuration ?? 50,
    dmcId: libraryFilters?.dmcId?.value ? Number(libraryFilters?.dmcId?.value) : undefined,
    userId: parseInt(libraryFilters?.users?.id) ?? undefined,
    departureLocation: libraryFilters?.departureLocation?.[0]?.id ?? "",
    returnLocation: libraryFilters?.returnLocation?.[0]?.id ?? "",
    guided: libraryFilters?.guided,
    segments: libraryFilters?.segments?.map((segment) => segment.id) ?? [],
    themes: libraryFilters?.themes?.map((theme) => theme.id) ?? [],
    type: 'template',
    withArchived: libraryFilters?.withArchived ?? false,
    isDistributedTrip: true
  };

  const { data: tripsData, reload, isLoading } = useAsync({
    promiseFn: getTripsBooked,
    filters: requestFilters,
    watchFn: (props,prevProps) => {
      return !isEqual(props.filters, prevProps.filters)
    },
   });
  const trips = tripsData?.data ?? [];

  return (
    <KoobLayout>
      <div className="max-w-7xl mx-auto">
        <LibraryFilters isDmcTrips/>

        <div className="flex flex-row place-content-between">
          <KoobTitle size="text-2xl">{t('dmcTemplates.title')}</KoobTitle>
        </div>

        <KoobTripCount>
          {t('dmcTemplates.results', {count: trips?.length})}
        </KoobTripCount>

        <div className="mt-3">
          {!isLoading ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 items-start gap-5">
              {trips.map(trip => (
                <TripCard
                  key={trip?.id}
                  trip={trip}
                  reload={reload}
                  enableExport
                  isDistributedTrip={true}
                />
              ))}
            </div>
          ) : (
            <Spinner noAbsolute/>
          )}
        </div>
      </div>
    </KoobLayout>
  );
}
