import React, { useState } from 'react';
import CheckoutHotelRoomTag from './CheckoutHotelRoomTag';
import KoobBadge from '@koob/ui/src/components/KoobBadge';
import CheckoutHotelRoomFeature from './CheckoutHotelRoomFeature';
import { useTranslation } from 'react-i18next';
import CheckoutHotelRoomPrice from './CheckoutHotelRoomPrice';
import CheckoutHotelRoomCard from './CheckoutHotelRoomCard';
import CheckoutHotelRoomSource from './CheckoutHotelRoomSource';
import Carousel, { Modal, ModalGateway } from 'react-images';
import formatHotelRoomTags from '../utilities/formatHotelRoomTags';
import formatHotelRoomName from '../utilities/formatHotelRoomName';
import CheckoutHotelRoomAllotmentAvailable from './CheckoutHotelRoomAllotmentAvailable';

export default function CheckoutHotelRoom({
  scope,
  room,
  parentRoom,
  selectedRoomIndex,
  currentBed,
  price,
  isSelected,
  onSelect,
  children,
}) {
  const { t } = useTranslation('booking', {keyPrefix: 'room'});

  const [openPicturesModal, setOpenPicturesModal] = useState(false);

  const tags = formatHotelRoomTags(currentBed);

  return (
    <CheckoutHotelRoomCard
      scope={scope}
      room={room}
      parentRoom={parentRoom}
      selectedRoomIndex={selectedRoomIndex}
      travelersCount={price?.travelersCount}
      isSelected={isSelected}
      onSelect={onSelect}
    >
      <div className="relative flex space-x-5 items-center">
        <div className="w-full">
          <div className="flex justify-between gap-5">
            <div className="flex flex-col place-content-center">
              <div className="flex flex-wrap items-center gap-3">
                <div className="text-xl font-semibold">
                  {formatHotelRoomName(room, currentBed)}
                </div>

                <div className="flex flex-wrap items-center gap-2">
                  {tags?.map((tag, index) => (
                    <CheckoutHotelRoomTag
                      key={index}
                      label={tag.label}
                      value={tag.value}
                      startAt={tag.startAt}
                      endAt={tag.endAt}
                    />
                  ))}
                </div>

                {room?.allotmentAvailable && (
                  <CheckoutHotelRoomAllotmentAvailable
                    scope={scope}
                    room={room}
                  />
                )}
              </div>

              {room?.description && (
                <div
                  className="mt-1 text-gray-500"
                  dangerouslySetInnerHTML={{ __html: room?.description }}
                />
              )}

              <div className="mt-5 flex flex-wrap gap-3">
                {room?.supplierCode && (
                  <CheckoutHotelRoomFeature icon="fa-link">
                    <KoobBadge>
                      {room.supplierCode}
                    </KoobBadge>
                  </CheckoutHotelRoomFeature>
                )}

                {room?.roomCapacity && (
                  <CheckoutHotelRoomFeature icon="fa-bed-front">
                    {t('capacity', { count: room.roomCapacity })}
                  </CheckoutHotelRoomFeature>
                )}

                {room?.facilities?.map((facility, index) => (
                  <CheckoutHotelRoomFeature
                    key={index}
                    icon="fa-check"
                    iconUrl={facility.iconUrl}
                  >
                    {facility.name}
                  </CheckoutHotelRoomFeature>
                ))}

                {room?.attachments?.length > 0 && (
                  <div
                    onClick={() => setOpenPicturesModal(true)}
                    className="cursor-pointer"
                  >
                    <KoobBadge color="orange">
                      <i className="far fa-images mr-1.5"/>
                      {t('showAllPictures')}
                    </KoobBadge>
                  </div>
                )}
              </div>
            </div>

            <div className="flex-shrink-0 w-40 text-right">
              <CheckoutHotelRoomPrice
                price={price?.total}
                currency={price?.currency}
                travelersCount={price?.travelersCount}
                nightsCount={price?.nightsCount}
              />
            </div>
          </div>
        </div>

        <div className="text-xl">
          {isSelected ? (
            <i className="far fa-check-circle text-k-orange"/>
          ) : (
            <i className="far fa-circle text-gray-400"/>
          )}
        </div>
      </div>

      <div className="mt-3 w-full flex-col space-y-3">
        {children}

        <div className="flex justify-end">
          <CheckoutHotelRoomSource
            name={room?.source}
            logo={room?.sourceLogo}
          />
        </div>
      </div>

      <ModalGateway>
        {openPicturesModal && (
          <Modal onClose={() => setOpenPicturesModal(false)}>
            <Carousel
              views={room?.attachments?.map(img => {
                return {
                  src: img?.url,
                  caption: img?.name
                }
              })}
            />
          </Modal>
        )}
      </ModalGateway>
    </CheckoutHotelRoomCard>
  );
};
