import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { KoobPlaceholder } from '@koob/ui';
import { GOOGLE_MAPS_API_KEY } from '@koob/to/src/constants';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { generateMarker } from '@koob/to/src/utils';

function PoiMarker ({ poi })  {
  const [isOpen, setIsOpen] = useState(false);
  const marker = {
    default: generateMarker({
      type: 'flag',
    }),
    active: generateMarker({
      type: 'flag',
      mainColor: '#F5700A',
      fillColor: '#FFFF',
    }),
  };

  return (
    <Marker
      position={{
        lat: parseFloat('' + poi?.lat),
        lng: parseFloat('' + poi?.lon),
      }}
      icon={isOpen ? marker.active : marker.default}
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen && (
        <InfoWindow
          options={{ maxWidth: 280, padding: 0 }}
          position={{
            lat: parseFloat('' + poi?.lat),
            lng: parseFloat('' + poi?.lon),
          }}
          onCloseClick={() => setIsOpen(false)}
        >
          <div>
            {poi?.name}
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}

export default function ExportDayPointsOfInterestsMap({ pois }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });
  const [map, setMap] = useState();

  const onLoad = mapInstance => {
    if (!Boolean(map)) {
      setMap(mapInstance);
    }
  };

  useDeepCompareEffect(() => {
    if (!Boolean(map)) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    pois
      .filter((poi) => Boolean(poi?.lat) || Boolean(poi?.lon))
      .forEach((poi) =>
        bounds.extend(new window.google.maps.LatLng(parseFloat('' + poi?.lat), parseFloat('' + poi?.lon))),
      );
    if (pois.length === 0) { // default bounds if no pois
      bounds.extend(new window.google.maps.LatLng(50.633, 3.066));
    }
    map.fitBounds(bounds);
  }, [ pois, map ]);

  const onUnmount = () => {
    setMap(null);
  };

  return (
    <div className="h-[700px] w-[600px] rounded-lg overflow-hidden mx-auto">
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{height: `100%`, width: '100%', borderRadius: '1.5rem', overflow: 'hidden'}}
          options={{maxZoom: 16, mapTypeControl: false, streetViewControl: false, fullscreenControl: false}}
          zoom={12}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {pois?.map((poi) => (
            <PoiMarker
              key={'map-poi-' + poi?.id}
              poi={poi}
            />
          ))}
        </GoogleMap>
      ) : (
        <KoobPlaceholder className="h-[75vh] w-full rounded-lg"/>
      )}
    </div>
  );
}
