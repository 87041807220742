const {default: styled} = require("styled-components");
import { useState } from "react";
import { MdChevronRight } from "react-icons/md";

const {Stack, ButtonReset} = require("@koob/margaret");

const OptionalInfoWrapper = styled(Stack).attrs({
  size: 'full',
  direction: 'column',
  alignX: 'stretch',
})`
`;

const DropdownListSubtainabilities = ({item}) => {
  const [isExpanded, setIsExpanded] = useState();
  const data = item;

  return (
    <>
      <div className="my-2 w-full bg-white rounded-lg shadow-k" onMouseEnter={() => setIsExpanded(true)}>
        {data?.content != '' && <OptionalInfoWrapper>
          <ButtonReset
            type="button"
            onClick={() => setIsExpanded(!isExpanded)}
            style={{width: '100%'}}>

            <div className="p-4 flex justify-between">
              <span> {data.sustainableCluster.displayName} </span>
              <div style={isExpanded ? {transform: 'rotate(90deg)'} : undefined}>
                <MdChevronRight size={20}/>
              </div>
            </div>
          </ButtonReset>

          {isExpanded && (
            <div className="p-4 border-t">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.descriptions
                }}
              />
            </div>
          )}
        </OptionalInfoWrapper>
        }
      </div>
    </>
  )
}

export default DropdownListSubtainabilities;
