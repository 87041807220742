import { Stack } from '@koob/margaret';
import { MdArrowForward } from 'react-icons/md';
import styled from 'styled-components';
import { formatDate } from 'utils';

const DateChunk = styled.span`
  text-align: center;
  white-space: nowrap;
`;

const DateRange = ({ startAt, endAt, size }) => (
  <Stack gutterSize={0.5} size={size} alignX="space-between">
    {Boolean(startAt) ? (
      <DateChunk>{formatDate(startAt, 'dd MMM yyyy').toUpperCase()}</DateChunk>
    ) : (
      <DateChunk>--/--/----</DateChunk>
    )}
    <MdArrowForward size={20} />
    {Boolean(endAt) ? (
      <DateChunk>{formatDate(endAt, 'dd MMM yyyy').toUpperCase()}</DateChunk>
    ) : (
      <DateChunk>--/--/----</DateChunk>
    )}
  </Stack>
);

export default DateRange;
