import KoobModal from '../Koob/KoobModal';
import KoobTitle from '../Koob/KoobTitle';
import KoobButton from '../Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import ScratchFiltersModal from './ScratchFiltersModal';
import { useState, useEffect } from 'react';
import Button from 'components/Button';
import { useTripDesigner } from '../../hooks';
import {useLocation, useNavigate} from "react-router-dom";

export default function DesignTripModal({ open, close }) {
  const { t } = useTranslation('tripDesigner');
  const { tripData, libraryFilters, setLibraryFilters } = useTripDesigner();
  const navigate = useNavigate();
  const { state, search } = useLocation();

  const [showSetFiltersModal, setShowSetFiltersModal] = useState(false);

  const from =  new URLSearchParams(search).get('from') ?? null;
  const update = (state?.from === 'draftEdit' || from === 'draftEdit') ?? false;

  const handleRedirectToLink = (link) => {
    setLibraryFilters({
      ...libraryFilters,
      type: 'template',
    });
    close();
    navigate(link);
  }

  useEffect(() => {
    setTimeout(() => {
      setShowSetFiltersModal(!!tripData && open ? true : showSetFiltersModal)}, 200);
  }, []);

  return (
    <>
      <KoobModal
        open={open}
        setOpen={() => close()}
        widthClass="max-w-md"
      >
        <ScratchFiltersModal
          open={open && showSetFiltersModal}
          close={() => setShowSetFiltersModal(false)}
          closeParent={() => close()}
          update={update}
        >
          {t('designTripModal.fromScratch')}
        </ScratchFiltersModal>

        <div className="mb-4 text-center">
          <KoobTitle>
            {t('designTripModal.title')}
          </KoobTitle>
        </div>

        <div className="flex flex-col mt-8 space-y-8">
          <KoobButton onClick={() => setShowSetFiltersModal(true)} id={'design-trip-from-scratch-button'}>
            {t('designTripModal.fromScratch')}
          </KoobButton>

          <KoobButton block={true} onClick={() => handleRedirectToLink('/trip-designer/library')}>
            {t('designTripModal.fromMyOwn')}
          </KoobButton>

          <KoobButton block={true} onClick={() => handleRedirectToLink('/trip-designer/trips')}>
            {t('designTripModal.fromDmcTemplate')}
          </KoobButton>
        </div>

        <div className='mx-8 mt-10 flex justify-center'>
          <Button
            type="button"
            variant="simple"
            onClick={() => close(false)}
          >
            {t('misc:cancel')}
          </Button>
        </div>
      </KoobModal>
    </>
  )
}
