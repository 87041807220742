import { Route, Routes } from 'react-router-dom';
import FoldersList from './FoldersList';
import FoldersDetails from './FoldersDetails';
import KoobLayout from '../../components/Koob/KoobLayout';

const Folders = () => {
  return (
    <KoobLayout>
      <Routes>
        <Route path="/" element={<FoldersList />} />
        <Route path="/:folderId" element={<FoldersDetails />} />
      </Routes>
    </KoobLayout>
  );
};

export default Folders;
