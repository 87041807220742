import { differenceInDays, differenceInYears, parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

export const generateBookingGroupUuid = () => {
  return uuidv4();
};

export const calculateTravelerKind = traveler => {
  const birthdate = traveler?.birthdate;
  const date = parse(birthdate, 'yyyy-mm-dd', new Date());
  const age = differenceInYears(new Date(), date);
  if (age < 18) return 'child';
  else return 'adult';
};

export const calculateBookingTotalPriceWithPromo = ({ roomsBooked }) => {
  let totalPriceWithPromo = 0;
  roomsBooked.forEach(({ room }) => {
    totalPriceWithPromo += room?.bedChoice.totalPriceWithPromo;
  });

  return totalPriceWithPromo;
};

export const calculateBookingNumberOfNights = ({ startAt, endAt }) => {
  const startDate = parse(startAt, 'yyyy-mm-dd', new Date());
  const endDate = parse(endAt, 'yyyy-mm-dd', new Date());
  return differenceInDays(endDate, startDate);
};

export const calculateBookingTravelersCount = ({ roomsBooked, experiencesBooked }) => {
  let travelersCount = 0;
  (roomsBooked ?? []).forEach(({ travelers }) => {
    travelersCount += travelers.length;
  });

  (experiencesBooked ?? []).forEach(({ travelers }) => {
    travelersCount += travelers.length;
  });

  return travelersCount;
};

export const calculateRoomNumberOfAdult = ({ travelers }) => {
  return travelers?.filter(
    traveler => calculateTravelerKind(traveler) === 'adult',
  ).length;
};

export const getRoomChildrenBirthdate = ({ travelers }) => {
  return travelers
    ?.filter(traveler => calculateTravelerKind(traveler) !== 'adult')
    .map(children => children.birthdate);
};

export const bookingsTypeEnum  = {
  EXPERIENCE: 'experience',
  HOTEL: 'hotel',
  TRIP: 'trip',
  DRAFT: 'draft',
  HISTORY: 'history'
}
