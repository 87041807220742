import TripExport from '@koob/trip-export';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { getTrip } from '../../../api/trip';
import { me } from '../../../api/users';
import { getAllCountries } from '../../../api/countries';
import { getExperience } from '../../../api/experiences';
import { useTranslation } from 'react-i18next';
import KoobLoaderFullscreen from '@koob/ui/src/components/KoobLoaderFullscreen';

export default function ExportTrip() {
  const { tripId } = useParams();
  const { t } = useTranslation('trips');

  const [searchParams] = useSearchParams();

  const [experiences, setExperiences] = useState([]);

  const tripData = useAsync({
    promiseFn: getTrip,
    tripId,
    versionId: searchParams.get('version'),
  });
  const trip = tripData?.data?.data;

  const userData = useAsync({
    promiseFn: me,
  });
  const user = userData?.data?.data;
  const tourOperator = user?.organizations[0];

  const countriesData = useAsync({
    promiseFn: getAllCountries,
  });
  const countries = countriesData?.data?.data?.filter(c => trip?.countries?.find(country => country.id === c.id));

  const experienceIds = [
    ...new Set(trip?.tripDays?.map(day => day.experienceId)),
  ];

  useEffect(() => {
    if (experienceIds && experienceIds.length > 0) {
      const promises = experienceIds.map(async experienceId => {
        const experience = await getExperience({ experienceId });
        return experience.data;
      });

      Promise.all(promises).then(res => {
        setExperiences(res);
      });
    }
  }, [experienceIds?.length]);

  const images = experiences?.reduce((acc, experience) => {
    if (experience.pictures) {
      experience.pictures.forEach(image => {
        acc.push(image);
      });
    }
    return acc;
  }, []);

  const loading = tripData.isLoading || userData.isLoading || countriesData.isLoading;

  return (
    <>
      {loading ? (
        <KoobLoaderFullscreen
          title={t('export.loading.title')}
          description={t('export.loading.description')}
        />
      ) : (
        <TripExport
          trip={trip}
          countries={countries}
          tourOperator={tourOperator}
          images={images}
          experiences={experiences}
        />
      )}
    </>
  );
}
