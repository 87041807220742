import { Stack } from "@koob/margaret"
import { Button } from "components";
import { IcMapMarker } from "components/icons";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components"
import background from 'images/trip-designer1.png';
import { useApp, useError, useSnack } from "hooks";
import { useAsync } from "react-async";
import { getRequestAccess, sendRequestAccess } from "api/toRequestAccess";
import { KoobStateEnum } from "utils";
import KoobLayout from '../../components/Koob/KoobLayout';

const DiscoverBloc = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(2)};
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${background});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0.4;
    z-index: 0;
  }
`;

const DiscoverBody = styled(Stack)`
  padding: ${({ theme }) => theme.spacing(2)};
  max-width: 700px;
  font-weight: 600;
  z-index: 10;
`

const DiscoverTitle = styled.div`
  font-size: 50px;
  padding: 10px;
  font-weight: 800;
  opacity: 1;
`;

export const Discover = () => {
  const theme = useTheme();
  const { t } = useTranslation('tripDesigner',{ keyPrefix: 'discover'});
  const { currentOrganizationId, user } = useApp();
  const { sendErrorSnack } = useError();
  const { notify } = useSnack();

  const {data, reload} = useAsync({
    promiseFn: getRequestAccess
  });

  const requestAccess = data ? data?.data : {};
  const isRequested = requestAccess?.tripDesignerRequestState ? requestAccess?.tripDesignerRequestState !== KoobStateEnum.NOT_REQUESTED : false;
  const handleRequest = async() => {
    try {
      await sendRequestAccess(currentOrganizationId);
      notify(t('success'));
      reload();
    } catch (err) {
      sendErrorSnack(err);
    }
  }
  return (
    <KoobLayout>
      <DiscoverBloc
        direction="column"
        alignX="center"
        alignY="space-between"
        gutterSize={2}
        className="h-full"
      >
        <Stack alignX="center" gutterSize={3} style={{zIndex: 10}}>
          <IcMapMarker size={80} color={theme.textLight} />
          <DiscoverTitle>{t('title')}</DiscoverTitle>
        </Stack>
        <DiscoverBody
          size="full"
          alignX="center"
          alignY="center"
          gutterSize={0.3}
        >
          <Stack direction="column">
            <ul className="list-disc space-y-5 text-xl text-black-800 mt-5">
              {[1, 2, 3, 4, 5].map((itemNumber) => (
                <li key={itemNumber}>{t(`textItem${itemNumber}`)}</li>
              ))}
            </ul>

          </Stack>
          <Stack>

          </Stack>
        </DiscoverBody>

        <Stack>
          {user?.role === 'to_admin' && <Button
            variant="primary"
            onClick={handleRequest}
            disabled={isRequested}
          >
            {t('requestAccess')}
          </Button>}
        </Stack>
      </DiscoverBloc>
    </KoobLayout>
  )
}
