import { get, post, put, destroy } from './';

export const me = () => get('/api/v1/user');

export const getUsers = () => get(`/api/v1/users`);

export const getUser = ({ id }) => get(`/api/v1/users/${id}`);

export const getOrgUsers = ({ orgId }) => get(`/api/v1/users/org/${orgId}`);

export const createUser = payload => post(`/api/v1/users`, payload);

export const resentInvite = (id) => post(`/api/v1/users/${id}/resendInvite`);

export const discardUser = userId =>
  destroy(`/api/v1/users/${userId}`);

export const restoreUser = userId => put(`/api/v1/users/${userId}/restore`);

export const updateUserAcces = (userId, payload) =>
  put(`/api/v1/users/${userId}`, payload);
