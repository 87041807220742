import KoobLayout from '../components/Koob/KoobLayout';
import KoobTitle from '../components/Koob/KoobTitle';
import KoobParagraph from '../components/Koob/KoobParagraph';

export default function NotFound() {
  return (
    <KoobLayout>
      <div className="flex flex-col items-center justify-center h-[85vh]">
        <KoobTitle size="text-5xl">
          404 - Not Found
        </KoobTitle>
        <KoobParagraph size="xl">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The page you're looking for doesn't exist.
        </KoobParagraph>
      </div>
    </KoobLayout>
  );
}
