import { Button as MgtButton } from '@koob/margaret';
import { Link } from 'react-router-dom';

const Button = props => (
  <MgtButton
    {...props}
    to={!props?.disabled ? props.to : undefined}
    as={Boolean(props.as) ? props.as : Boolean(props.to) ? Link : null}
  />
);

export default Button;
