import { useTranslation } from "react-i18next";
import {LigneHorizontal, SubSectionTitle, SubSectionTitleContainer} from "./Base";
import DropdownListSubtainabilities from "./DropdownListSubtainabilities";

const {Stack} = require("@koob/margaret");

const SustainableCommitments = ({hotel}) => {
  const {t} = useTranslation('hotel');
  const sustainableCommitments = (hotel?.sustainableCommitments || []).filter((elem) => elem.descriptions);
  return (
    <>
      {sustainableCommitments.length > 0 &&
        <Stack size="full" direction="column">
          <SubSectionTitleContainer>
            <SubSectionTitle>{t('sustainableCommitments')}</SubSectionTitle>
          </SubSectionTitleContainer>

          {sustainableCommitments.map((item, index) => (
            <DropdownListSubtainabilities item={item} key={index}/>
          ))}

          <LigneHorizontal/>
        </Stack>
      }
    </>
  )
}

export default SustainableCommitments;
