import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  padding: 32px;
  background-color: ${({ theme }) => theme.grayBg};
  border-radius: 10px;
  margin-bottom: 30px;
`

const DescriptionContent = styled.p`
  margin-top: 8px;
  color: ${({ theme }) => theme.gray};
`

const KoobDescriptionComponent = ({description, title}) => {

  return (
    <>
      {
        description && (<div className="tw mx-8 xl:mx-0 mt-3">
          <DescriptionContainer>
            <div className="font-bold text-xl">{title}</div>
            <DescriptionContent dangerouslySetInnerHTML={{__html: sanitizeHtml(description)}}></DescriptionContent>
          </DescriptionContainer>
        </div>)
      }

    </>
  )
}
export default KoobDescriptionComponent;
