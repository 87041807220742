import KoobTitle from '../../../../components/Koob/KoobTitle';
import KoobParagraph from '../../../../components/Koob/KoobParagraph';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { get } from '../../../../api';

const getUser = async () => {
  return await get('/api/v1/user');
}

export default function ReceiptHeader() {
  const { t } = useTranslation('trips');

  const { data: userData } = useAsync({
    promiseFn: getUser,
  });
  const user = userData?.data;

  const organizationLogo = user?.organizations?.[0]?.avatarUrl;

  return (
    <div className="flex justify-between items-center">
      <div>
        <KoobTitle>
          {t('receipt.title')}
        </KoobTitle>

        <KoobParagraph>
          {t('receipt.description')}
        </KoobParagraph>
      </div>

      <div className="w-56">
        <img
          className="w-full object-center"
          src={organizationLogo}
          alt=""
        />
      </div>
    </div>
  )
}
