import { Container } from 'ui';
import { Trans, useTranslation } from 'react-i18next';
import { useState } from 'react';
import TripDocuments from '../../../../components/Trip/Documents';
import TripChat from '../../../../components/Trip/Chat';
import { closeTripConversations, getTripConversations, saveTripDays } from 'api/trip';
import { useAsync } from 'react-async';
import TripConversationItem from './TripConversationItem';
import { MdAddCircleOutline } from 'react-icons/md';
import { Button, Modal } from '../../../../components';
import { useApp, useError, useSnack, useTripDesigner } from '../../../../hooks';
import { isEqual, uniqueId } from 'lodash';
import { SpinnerContainer } from 'containers/Hotels/HotelsDetail';
import { Spinner as Loader, Stack } from '@koob/margaret';
import KoobModal from '../../../../components/Koob/KoobModal';
import ConversationForm from '../../../../components/Forms/ConversationForm';
import { createTripConversations } from '../../../../api/trip';
import KoobCard from 'components/Koob/KoobCard';
import { createFolder } from 'api/folders';
import { useField } from 'formik';
import { decodeId, formatDate } from 'utils';
import styled from 'styled-components';
import { IcArchive } from 'components/icons';
import { ExtraScopeEnum } from '@koob/enums';

const Warning = styled.div`
  color: ${({ theme }) => theme.danger};
  font-weight: bold;
`;

const ComposeChatTrip = ({ tripId, reloadTrip, trip }) => {
  const { t } = useTranslation('tripDesigner');
  const [showTab, setShowTab] = useState('Chat');
  const [selectedConversation, setSelectedConversation] = useState();
  const [showConversationTab, setShowConversationTab] = useState('');
  const { tripFilters, selectedExtras } = useTripDesigner();
  const [sortList, setSortList] = useState(true);
  const [showConversationModal, setShowConversationModal] = useState(false);
  const [closeConversation, setCloseConversation] = useState(false);
  const { sendErrorSnack } = useError();
  const { notify } = useSnack();
  const { user } = useApp();
  const [{ value: days }] = useField('days');

  const requestFilters = {
    status: tripFilters?.conversationStatus?.displayName ?? undefined,
    order: 'alphabetical',
    direction: sortList ? 'ASC' : 'DESC',
  };

  const {
    data: conversations,
    reload,
    isLoading,
  } = useAsync({
    promiseFn: getTripConversations,
    tripId: tripId ?? tripFilters?.tripId,
    filters: requestFilters,
    watchFn: (props, prevProps) => {
      return !isEqual(props.filters, prevProps.filters);
    },
  });

  const createClientFolder = async () => {
    if (tripFilters.clientFolder) {
      return;
    }
    const icr =
      user.lastName.substring(0, Math.min(3, user.lastName.length)) +
      user.firstName.substring(0, Math.min(3, user.firstName.length)) + uniqueId();

    tripFilters.clientFolder =  (await createFolder({
      icr: icr,
      name: user.lastName + ' ' + icr,
      travelers: [],
    })).data;

  };

  const createTripConversation = async values => {
    if (!values.title || values.title.trim() === '') return;

    try {
      const response = await createTripConversations({
        tripId: tripId ?? tripFilters?.tripId,
        body: {
          title: values.title,
        },
      });

      if (!trip?.clientFolder?.id) {
        const travelers = {
          adultPax: tripFilters?.composition?.adults,
          childrenBirthdates: tripFilters?.composition.childrenBirthdates.map(
            item => formatDate(item, 'yyyy-MM-dd'),
          ),
        };
        await createClientFolder();
        saveTripDays({
          tripId: tripId,
          tripDays:
            days?.map((day, index) => {
              return {
                dayIndex: index + 1,
                experienceId: day?.experience?.id ?? undefined,
                hotelId: day?.hotel?.id
                  ? '' + decodeId(day.hotel.id)
                  : undefined,
                hotelBookedOnMyOwn: day?.hotelBookedOnMyOwn ?? false,
                options: selectedExtras
                  ?.filter(
                    extra =>
                      extra.scope === ExtraScopeEnum.DAY &&
                      extra.dayIndex === index,
                  )
                  ?.map(extra => ({
                    extraId: extra.id,
                    parentExtraId: extra.parentExtraId,
                  })),
              };
            }) ?? [],
          folderId: tripFilters?.clientFolder?.id,
          travelers: travelers,
          globalOptions: selectedExtras
            ?.filter(extra => extra.scope === ExtraScopeEnum.GLOBAL)
            ?.map(extra => ({
              dayIndex: extra.dayIndex + 1,
              extraId: extra.id,
              parentExtraId: extra.parentExtraId,
            })),
        }).then(() => {
          notify(t('compose.savedAsDraft'));
          reloadTrip();
        });
      }
      reload();
      setSelectedConversation(response?.data);
      setShowConversationTab('list');
      setShowConversationModal(false);
    } catch (error) {
      sendErrorSnack(error);
    }
  };

  const handleCloseConversation = () => {
    closeTripConversations(tripId, selectedConversation.id)
      .then(() => {
        notify(t('conversation.closed'));
        reload();
      })
    setCloseConversation(false);
    setSelectedConversation(undefined);
    setShowConversationTab('');
  }

  return (
    <Container>
      <div className="flex">
        <div className="w-1/4 p-2">
          <div className="flex justify-between h-full">
            <KoobCard className="rounded-lg w-full">
              <div className="flex items-center justify-between text-center pb-2 border-b">
                <div>{t('conversation.title')}</div>
                <div className="flex items-center">
                  <div
                    className="flex items-center mr-2"
                    onClick={() => reload()}
                  >
                    <i className="far fa-rotate-right text-gray-600 p-2 rounded-lg text-lg" />
                  </div>
                  <div
                    className={`flex items-center ${
                      sortList ? 'text-gray-600' : 'bg-gray-150 text-orange-600'
                    } rounded-lg p-2 text-lg`}
                    onClick={() => setSortList(!sortList)}
                  >
                    <i className="far fa-arrow-down-z-a" />
                  </div>
                </div>
              </div>

              <div className={`w-full ${showConversationTab === 'list' ? 'h-[46rem]' : 'h-[38rem]'} overflow-auto`}>
                {conversations?.data?.length === 0 && (
                  <div className="text-center text-gray-500 py-4">
                    <p className="text-xl font-bold uppercase">
                      {t('conversation.emptyMessage')}
                    </p>
                    <p className="text-sm">
                      {t('conversation.emptyMessageDescription')}
                    </p>
                  </div>
                )}
                {isLoading && (
                  <SpinnerContainer>
                    <Loader />
                  </SpinnerContainer>
                )}
                {(conversations?.data || []).map((item, index) => (
                  <TripConversationItem
                    conversation={item}
                    tripId={tripId}
                    key={index}
                    setSelectedConversation={setSelectedConversation}
                    selectedConversation={selectedConversation}
                    setShowConversationTab={setShowConversationTab}
                  />
                ))}
              </div>
              <Button
                variant="primary"
                icon={<MdAddCircleOutline />}
                onClick={() => {
                  setShowConversationModal(true);
                }}
                className="w-full"
              >
                {t('conversation.new')}
              </Button>
            </KoobCard>
          </div>
        </div>

        <KoobCard className="rounded-lg w-full mt-2 mb-2">
              <div className='flex justify-between'>
                <div className="flex items-center">
                  {[
                    ...(showConversationTab === 'list'
                      ? [
                          {
                            label: t('conversation.documents.title'),
                            href: `documents`,
                          },
                        ]
                      : []),
                    {
                      label: t('conversation.chat.title'),
                      href: `chat`,
                    },
                  ].map(
                    link => showConversationTab === 'list' && (
                      <div
                        key={link.label}
                        onClick={() => {
                          setShowTab(link.label);
                        }}
                        className={`inline-flex items-center px-1 py-1 text-sm font-medium text-gray-900 ${
                          showTab === link.label
                            ? 'border-b-4 border-orange-500'
                            : ''
                        }`}
                      >
                        {link.label}
                      </div>
                    ),
                  )}
                </div>
              <div className='flex items-center'>
                {selectedConversation?.id && selectedConversation?.status !== "closed" &&
                <button
                  disabled={selectedConversation?.status === "closed"}
                  className={`${selectedConversation?.status === "closed"
                    ? 'bg-gray-400 cursor-not-allowed' : 'bg-orange-400 text-white hover:bg-orange-600'}
                    px-4 py-1 rounded-xl mb-2
                  `}
                  onClick={() => setCloseConversation(true)}>
                    {t('conversation.closeConversation')}
                </button>}
              </div>
            </div>

            {showTab === 'Documents' && (
              <TripDocuments
                tripId={tripId}
                conversationId={selectedConversation?.id}
              />
            )}
            {showTab === 'Chat' && (
              <TripChat
                reload={reload}
                tripId={tripId}
                conversationId={selectedConversation?.id}
                conversationTitle={selectedConversation?.title}
                showConversationTab={showConversationTab}
              />
            )}
        </KoobCard>
        {showConversationModal && (
          <KoobModal
            open={showConversationModal}
            setOpen={setShowConversationModal}
            overflow="visible"
          >
            <div className="px-5">
              <ConversationForm
                onSubmit={values => createTripConversation(values)}
                setShowConversationModal={setShowConversationModal}
              />
            </div>
          </KoobModal>
        )}

        {closeConversation && <Modal
          isOpen
          title={t('conversation.close.title', {
            name: selectedConversation?.title,
          })}
          onRequestClose={() => setCloseConversation(false)}
          footer={
            <Stack alignX="flex-end" gutterSize={1}>
              <Button
                onClick={handleCloseConversation}
                variant="danger"
                icon={<IcArchive size={20} />}
              >
                {t('conversation.close.confirm')}
              </Button>
              <Button variant="simple" onClick={() => setCloseConversation(false)}>
                {t('misc:cancel')}
              </Button>
            </Stack>
          }
        >
          <Stack direction="column" gutterSize={1} alignX="center">
          {selectedConversation?.id && <>
            <Warning>{t(`conversation.close.heading`)}</Warning>
            <div>
              <Trans
                i18nKey="conversation.close.explanation"
                name={selectedConversation.title}
              >
                <strong>{selectedConversation.title}</strong>
                {t('conversation.close.explanationTexte')}
              </Trans>
            </div>
            </>}
          </Stack>
        </Modal>
        }
      </div>
    </Container>
  );
};

export default ComposeChatTrip;
