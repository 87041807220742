import Tooltip from '@koob/to/src/components/Tooltip';
import KoobBadge from '@koob/ui/src/components/KoobBadge';
import React from 'react';
import moment from 'moment';

export default function CheckoutHotelRoomTag({ label, value, startAt, endAt, size }) {
  const tip = startAt && endAt
    ? `${moment(startAt).format('dd/MM/yyyy')} - ${moment(endAt).format('dd/MM/yyyy')}`
    : '';

  const badgeColor = () => {
    switch (value) {
      case 'room_refundable':
        return 'green';
      case 'room_non_refundable':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Tooltip
      tip={tip}
      disabled={!tip}
      color="purple"
      hasArrow={false}
      position="bottom"
    >
      <KoobBadge
        color={badgeColor()}
        size={size}
      >
        {label}
      </KoobBadge>
    </Tooltip>
  );
}
