import { cva } from 'class-variance-authority';
import KoobLoaderText from './KoobLoaderText';

export default function KoobLoaderFullscreen({ title, description, opacity = 'full' }) {
  const overlay = cva([
    'fixed top-0 left-0 h-screen w-screen z-20'
  ], {
    variants: {
      opacity: {
        full: 'bg-white',
        semi: 'bg-white bg-opacity-50 backdrop-blur',
        none: 'bg-white bg-opacity-0'
      }
    }
  });

  return (
    <div className={overlay({ opacity })}>
      <div className="h-full flex flex-col justify-center items-center">
        <div className="flex-col space-y-5 justify-center">
          <div className="flex justify-center">
            <img src="/logo512.png" alt="Koob" className="h-40 w-auto -my-10"/>
          </div>

          <div className="flex justify-center">
            <svg className="h-8 w-8 animate-spin text-orange-500" height="1em" viewBox="0 0 512 512">
              <path
                className="fa-primary"
                d="M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z"
              />
              <path
                className="fa-secondary opacity-40"
                d="M256 64C150 64 64 150 64 256s86 192 192 192c70.1 0 131.3-37.5 164.9-93.6l.1 .1c-6.9 14.9-1.5 32.8 13 41.2c15.3 8.9 34.9 3.6 43.7-11.7c.2-.3 .4-.6 .5-.9l0 0C434.1 460.1 351.1 512 256 512C114.6 512 0 397.4 0 256S114.6 0 256 0c-17.7 0-32 14.3-32 32s14.3 32 32 32z"
              />
            </svg>
          </div>

          <div className="max-w-sm mx-auto">
            <KoobLoaderText
              title={title}
              description={description}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
