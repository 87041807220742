import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';

export default function ComposeMapItineraryPoiMarker({ label, poi, icon, clusterer }) {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const position = {
    lat: parseFloat(poi?.lat),
    lng: parseFloat(poi?.lon)
  }

  return (
    <Marker
      position={position}
      label={label}
      icon={icon}
      onClick={() => setInfoWindowOpen(true)}
      clusterer={clusterer}
    >
      {infoWindowOpen && (
        <InfoWindow
          onCloseClick={() => setInfoWindowOpen(false)}
          position={position}
        >
          <div>
            <p>{poi?.name}</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  )
}
