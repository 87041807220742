import React from 'react';

export default function KoobEmptyState({ title, description }) {
  return (
    <div className="flex justify-center">
      <div className="px-8 py-32 max-w-sm flex flex-col justify-center">
        <div className="mx-auto">
          <svg className="w-32 h-32 text-gray-900 opacity-25" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
          </svg>
        </div>

        <div className="mt-6 text-center">
          <h3 className="text-xl font-semibold mb-2">
            {title}
          </h3>

          <p className="text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
