import { formatCurrency } from '@koob/to/src/utils';
import React from 'react';

export default function CheckoutHotelRoomPrice({ price, currency, travelersCount, nightsCount, small = false }) {
  return (
    <>
      <div
        className={[
          'text-orange-600 font-semibold',
          small ? 'text-base' : 'text-lg',
        ].join(' ')}
      >
        {formatCurrency({
          amount: price,
          currency,
        })}
      </div>

      <div
        className={[
          'text-gray-600 font-medium',
          small ? 'text-xs' : 'text-sm',
        ].join(' ')}
      >
        {formatCurrency({
          amount: price / (travelersCount ?? nightsCount),
          currency,
        })} / {travelersCount ? 'travelers' : 'night'}
      </div>
    </>
  );
}
