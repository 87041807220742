import { useTranslation } from "react-i18next";
import { BsCircleFill } from "react-icons/bs";
import styled from "styled-components";

const IconState = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  top: 54%;
  transform: translateY(-50%);
  left: -8px;
`;

const statusColors = {
  create: '#9ca3af',
  update: '#2563eb',
  cancelle: '#E74032',
  propose: '#facc15'
};


export const HistoryItem = ({ history }) => {
  const { t } = useTranslation('folders', { keyPrefix: 'tripHistory' });
  
  return (
    <div className="relative px-5 py-2 rounded-lg bg-gray-50 border mb-4">
      <IconState>
        <BsCircleFill size={15} color={statusColors[history?.label]} />
      </IconState>
      <div className="p-2 flex justify-between items-center">
        <div>
          <h3 className="tracking-wide">
            {t(`messages.${history?.label}`)}
          </h3>

          {history?.firstName && history?.lastName && (
            <div className="flex space-x-1 items-center text-gray-500">
              <svg className="h-4 w-4" height="1em" viewBox="0 0 448 512">
                <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/>
              </svg>
              <p className="text-sm">
                {history?.firstName} {history?.lastName}
              </p>
            </div>
          )}
        </div>

        <div>
          <p className="text-gray-500">{history?.createdAt}</p>
        </div>
      </div>
    </div>
  );
};