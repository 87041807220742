import { ActionLink } from 'ui';

const FoldersTripDraftEditButton = ({ tripId }) => {
  return (
    <ActionLink to={`/trip-designer/design-trip/${tripId}?from=draftEdit`} state={{from: 'draftEdit'}}>
      <i className="fa-regular fa-pencil-alt text-lg"/>
    </ActionLink>
  );
};

export default FoldersTripDraftEditButton;
