import { Link, useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import KoobButton from '../Koob/KoobButton';
import { archiveTripTemplate, restoreTripTemplate } from '../../api/trip';
import { formatDate } from 'utils';
import { useState } from 'react';
import { post } from '../../api';
import TripTagsModal from './Partials/TripTagsModal';

function ChatButton({ tripId }) {
  const navigate = useNavigate();

  const openChat = async () => {
    navigate(`/trips/${tripId}/chat`);
    window.scrollTo(0, 0);
  }

  return (
    <div
      onClick={openChat}
      className="cursor-pointer bg-purple-500/40 hover:bg-purple-400/40 transition backdrop-blur-sm rounded-full p-2.5"
    >
      <svg
        className="h-5 w-5 text-purple-500"
        viewBox="0 0 640 512"
      >
        <path
          d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2 0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.3-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9l0 0 0 0-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z"/>
      </svg>
    </div>
  )
}

function ExportButton({tripId}) {
  const navigate = useNavigate();

  const exportTrip = async () => {
    navigate(`/trips/${tripId}/export`);
    window.scrollTo(0, 0);
  }

  return (
    <div
      onClick={exportTrip}
      className="cursor-pointer bg-blue-500/40 hover:bg-blue-400/40 transition backdrop-blur-sm rounded-full p-2.5"
    >
      <svg
        className="h-5 w-5 text-blue-500"
        viewBox="0 0 576 512"
      >
        <path
          d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V288H216c-13.3 0-24 10.7-24 24s10.7 24 24 24H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM384 336V288H494.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H384zm0-208H256V0L384 128z"/>
      </svg>
    </div>
  )
}

function ReceiptButton({tripId}) {
  const navigate = useNavigate();

  const openReceipt = async () => {
    navigate(`/trips/${tripId}/receipt`);
    window.scrollTo(0, 0);
  }

  return (
    <div
      onClick={openReceipt}
      className="cursor-pointer bg-green-500/40 hover:bg-green-400/40 transition backdrop-blur-sm rounded-full p-2.5"
    >
      <div className="h-5 w-5 flex items-center justify-center">
        <i className="fas fa-dollar-sign text-green-500"/>
      </div>
    </div>
  )
}

function FavoriteButton({ isFavorite, tripId}) {
  const [favorite, setFavorite] = useState(isFavorite);
  const [isLoading, setIsLoading] = useState(false);

  const toggleFavorite = async () => {
    setIsLoading(true);
    const result = await post(`/api/v1/trip/${tripId}/favorite`);
    setFavorite(result.data);
    setIsLoading(false);
  }

  return (
    <div
      className="cursor-pointer bg-red-500/40 hover:bg-red-400/40 transition backdrop-blur-sm rounded-full p-2.5"
      onClick={toggleFavorite}
    >
      <svg
        className={[
          'h-5 transition',
          favorite ? 'text-red-500' : 'text-gray-400/80',
          isLoading ? 'animate-ping' : ''
        ].join(' ')}
        viewBox="0 0 512 512"
      >
        <path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/>
      </svg>
    </div>
  )
}

function TagsButton({ trip, reload, tags, reloadTags }) {
  const [showTagsModal, setShowTagsModal] = useState(false);

  return (
    <>
      <div
        className="cursor-pointer bg-orange-500/40 hover:bg-orange-400/40 transition backdrop-blur-sm rounded-full p-2.5"
        onClick={() => setShowTagsModal(true)}
      >
        <div className="h-5 w-5 flex justify-center items-center">
          <i className="fas fa-tags text-xl text-orange-500" />
        </div>
      </div>

      <TripTagsModal
        trip={trip}
        reload={reload}
        open={showTagsModal}
        onClose={() => setShowTagsModal(false)}
        tags={tags ?? []}
        reloadTags={reloadTags}
      />
    </>
  )
}

const TripCard = ({ trip, reload, isDistributedTrip, enableChat, enableExport, enableReceipt, enableFavorite, enableTagsEdition, tags, reloadTags }) => {
  const { t } = useTranslation('tripDesigner');

  const isTemplate = !!trip?.template?.name;

  return (
    <>
      <div className="relative bg-white rounded-xl shadow-k overflow-hidden">
        <div className="relative">
          <img
            className="w-full h-56 object-cover bg-gray-100"
            src={trip?.template?.imageUrl ?? trip?.imageUrl ?? '/img/hotel-image-placeholder.png'}
            onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
            alt=""
          />

          <div className='absolute w-full bottom-0'>
            <div className="relative flex justify-end py-2 px-3 space-x-1.5">
              {enableChat && (
                <ChatButton tripId={trip?.id} />
              )}

              {enableExport && (
                <ExportButton tripId={trip?.id} />
              )}

              {enableReceipt && (
                <ReceiptButton tripId={trip?.id} />
              )}

              {enableTagsEdition && (
                <TagsButton
                  trip={trip}
                  reload={reload}
                  tags={tags ?? []}
                  reloadTags={reloadTags}
                />
              )}

              {enableFavorite && (
                <FavoriteButton
                  isFavorite={trip?.isFavorite}
                  tripId={trip?.id}
                />
              )}
            </div>

            {trip?.tags?.length > 0 && (
              <div className="backdrop-blur-sm bg-orange-600 bg-opacity-70 w-full p-2" >
                <div className="text-center text-white text-xs" style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 2 }}>
                  {trip?.tags?.map((tag, index) => (
                    <span key={trip?.id + index}>
                    {tag.name}
                      {index !== trip.tags.length - 1 && ', '}
                  </span>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="px-4 py-4">
          <div className="flex justify-between items-center">
            <div>
              <div className="font-medium">
                {trip?.template?.name ?? trip?.name}
              </div>

              <div className="mt-1 text-gray-500 text-sm flex space-x-1">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" /></svg>
                <div>{trip?.startLocation?.title}</div>
              </div>
            </div>

            <div className="flex-shrink-0 text-gray-500 flex items-end space-x-2">
              <div>
                <div className="text-2xs uppercase font-medium">
                  {t('providedBy')}
                </div>
                <div className="text-sm font-medium">
                  {trip?.dmc?.displayName}
                </div>
              </div>

              <img
                className="w-8 h-8 object-cover bg-gray-100 rounded-full"
                src={trip?.organizationImage ?? '/img/hotel-image-placeholder.png'}
                onError={ev => ev.target.src = '/img/hotel-image-placeholder.png'}
                alt=""
              />
            </div>
          </div>

          <div className="flex justify-between items-center mt-1">
            <div className="flex space-x-1 text-gray-500 ">
              <svg className="h-5 w-5" viewBox="0 0 512 512">
                <path d="M480 256A224 224 0 1 1 32 256a224 224 0 1 1 448 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM240 112V256c0 5.3 2.7 10.3 7.1 13.3l96 64c7.4 4.9 17.3 2.9 22.2-4.4s2.9-17.3-4.4-22.2L272 247.4V112c0-8.8-7.2-16-16-16s-16 7.2-16 16z"/>
              </svg>
              <div>
                {trip?.duration > 0 ? t('library.duration', { count: trip?.duration }): null} {" "}
              </div>
            </div>
            <div className='flex gap-1 items-center text-gray-500 mt-1'>
              <svg viewBox="0 0 24 24" color="textLight" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19a2 2 0 002 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg>
              {formatDate(trip?.updatedAt, 'dd/MM/yyyy')}
            </div>
          </div>

          {isTemplate && (
            <div className="mt-2 flex justify-end gap-3">
              {!trip?.template?.deletedAt ? (
                <>
                  <div>
                    <Link to={`/trip-designer/design-trip/${trip?.id}`}>
                      <KoobButton>
                        {t('library.template.designTrip')}
                      </KoobButton>
                    </Link>
                  </div>

                  <div>
                    {!isDistributedTrip && <KoobButton
                      onClick={() => {
                        archiveTripTemplate({
                          tripId: trip?.id,
                          templateId: trip?.template?.id,
                        })
                          .then(() => {
                            if (reload) {
                              reload();
                            }
                          });
                      }}
                      color="secondary"
                    >
                      {t('library.template.archive')}
                    </KoobButton>}
                  </div>
                </>
              ) : (
                <div>
                  <KoobButton
                    onClick={() => {
                      restoreTripTemplate({
                        tripId: trip?.id,
                        templateId: trip?.template?.id,
                      })
                      .then(() => {
                        if (reload) {
                          reload();
                        }
                      });
                    }}
                    color="secondary"
                  >
                    {t('library.template.restore')}
                  </KoobButton>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TripCard;
