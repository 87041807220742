import { LanguageContext } from 'contexts';
import { useApp } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

const LanguageProvider = ({ children }) => {
  const { currentUserLang } = useApp();

  const [lang, setLang] = useState('en');

  const resetContext = useCallback(() => {
    setLang(currentUserLang)
  }, []);

  const value = {
    resetContext,
    updateQueryLocale: (value) => {
      setLang(value?.value);
    },
    lang,
    setLang,
  };

  useEffect(() => {
    if (currentUserLang) {
      setLang(currentUserLang);
    }
  }, [currentUserLang]);

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
