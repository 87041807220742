import React from 'react';
import KoobCard from '../../../components/Koob/KoobCard';
import KoobAvatar from '../../../components/Koob/KoobAvatar';
import KoobTitle from '../../../components/Koob/KoobTitle';
import { useTranslation } from 'react-i18next';
import {differenceInYears, format} from 'date-fns';
import { Troncate } from 'ui';

export default function FoldersGuestsListRow({  gender, firstName, lastName, birthdate, nationality, passportNumber, passportExpirationDate }) {
  const { t } = useTranslation('folders');

  return (
    <KoobCard>
      <div className="flex gap-5">
        <KoobAvatar img={`https://avatars.dicebear.com/api/initials/${firstName?.charAt(0) + lastName?.charAt(0)}.svg`}/>

        <div className="w-full">
          <KoobTitle size="text-xl">
            <div className="flex justify-between items-center">
              <Troncate maxWidth={100}>{firstName}<br></br>{lastName}</Troncate>
              <div className="capitalize text-base">{t(gender)}</div>
            </div>
          </KoobTitle>

          <div className="text-sm text-gray-500 leading-none">
            <div className="flex flex-col space-y-1">
              <div>
                {birthdate ? (
                  differenceInYears(new Date(), new Date(birthdate)) + t('guests.age')
                ) : (
                  '--'
                )}
              </div>
              <div>
                {t('guests.born')}
                {birthdate ? format(new Date(birthdate),' dd/MM/yyyy') : ' --'}
              </div>
            </div>

            <div className="mt-2.5 pt-2.5 border-t flex flex-col space-y-1">
              <div className="flex items-center gap-1">
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" /></svg>
                {nationality!=='' ? nationality : '--'}
              </div>
              {passportNumber && (
                <>
                  <div className="flex items-center gap-1">
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>
                    {passportNumber}
                  </div>
                </>
              )}
              {passportExpirationDate && (
                <div className="flex items-center gap-1">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                  {format(new Date(passportExpirationDate),'dd/MM/yyyy')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </KoobCard>
  )
}
