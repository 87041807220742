import React from 'react';
import KoobNavbar from './KoobNavbar';
import { cva } from 'class-variance-authority';

export default function KoobLayout({ children, horizontalPadding = true }) {
  const container = cva([
    'pt-[96px] py-5'
  ], {
    variants: {
      horizontalPadding: {
        true: 'px-6 lg:px-8'
      }
    }
  });

  return (
    <div className="min-h-screen bg-gray-50 pb-5">
      <KoobNavbar />

      <main className={container({ horizontalPadding })}>
        {children}
      </main>
    </div>
  )
}
