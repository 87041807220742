import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {KOEDIA, RAKUTEN} from "../../constants";
import { Stack } from "@koob/margaret";

const BookingInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: ${({ theme }) => theme.spacing(1)};
  span {
    font-weight: bold;
  }
`;

const ExternalBookingReference = ({ booking }) => {
  const { t } = useTranslation("booking");
  if (!booking.bookingRef || false === [KOEDIA, RAKUTEN].includes(booking.kind)) return null;
  const provider = booking.kind === RAKUTEN ? 'RTX' : 'Koedia';
  return (
    <Stack direction="column" guttersize={0.5}>
      <BookingInfo>
        <span>{provider} {t('externalReference')}</span> : {booking.bookingRef}
      </BookingInfo>

      {provider === "RTX" && <BookingInfo>
        <span>{t('rakutenId')}</span> : {booking?.hotel?.rakutenId}
      </BookingInfo>}
    </Stack>

  );
};

export default ExternalBookingReference;
