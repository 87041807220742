import React from 'react';

export default function KoobSearchBar({ value, onChange, placeholder, size = "large" }) {
  return (
    <div className="relative mt-1 rounded-full shadow-k">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5">
        <svg
          className={(size === "small" ? "h-3 w-3" : "h-5 w-5") + " text-gray-400"}
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <g id="_01_align_center" data-name="01 align center">
            <path d="M24,22.586l-6.262-6.262a10.016,10.016,0,1,0-1.414,1.414L22.586,24ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z" />
          </g>
        </svg>
      </div>
      <input
        type="text"
        className={(size === "small" ? "p-2.5 pl-10" : "p-4 pl-14") + " block w-full rounded-full border-gray-300 focus:border-gray-500 focus:ring-gray-200 sm:text-sm"}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
}
