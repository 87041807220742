import React from 'react';
import { useAsync } from 'react-async';
import ChatboxTrip from './Partials/Chatbox';
import { getTripConversationMessage } from '../../../api/trip';

export default function TripChat({tripId, conversationId, conversationTitle, showConversationTab, reload }) {
  const { data, reload: messageReload } = useAsync({
    promiseFn: getTripConversationMessage,
    tripId,
    conversationId,
    watch: conversationId
  });

  return (
    <ChatboxTrip
      messages={data?.data ?? []}
      refetch={messageReload}
      conversationReload={reload}
      tripId={tripId}
      conversationId={conversationId}
      conversationTitle={conversationTitle}
      showConversationTab={showConversationTab}
      displayAs="to"
    />
  );
}
