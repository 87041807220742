import React from 'react';

export function IconActivity({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 576 512">
      <path d="M64 64C28.7 64 0 92.7 0 128v66c0 7.3 4.9 13.7 12 15.5c20.7 5.3 36 24.1 36 46.5s-15.3 41.2-36 46.5C4.9 304.3 0 310.7 0 318v66c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V318c0-7.3-4.9-13.7-12-15.5c-20.7-5.3-36-24.1-36-46.5s15.3-41.2 36-46.5c7.1-1.8 12-8.2 12-15.5V128c0-35.3-28.7-64-64-64H64zM32 128c0-17.7 14.3-32 32-32H512c17.7 0 32 14.3 32 32v54.7c-28.2 12.3-48 40.5-48 73.3s19.8 61 48 73.3V384c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V329.3C60.2 317 80 288.8 80 256s-19.8-61-48-73.3V128zm128 64H416V320H160V192zm-32 0V320c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H160c-17.7 0-32 14.3-32 32z"/>
    </svg>
  )
}


export function IconProgram({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 448 512">
      <path d="M230 265.4C242.6 247.3 269.2 246.1 282.2 264.8L378.5 397.2C393.8 418.3 378.7 448 352.6 448H95.09C69.06 448 53.92 418.6 69.05 397.4L120 326.1C131.5 309.1 154.7 308.1 168.7 322L181.8 335.1L230 265.4zM352.6 416L256.3 283.6L197.2 369.1C194.5 373 190.2 375.5 185.4 375.9C180.7 376.4 176 374.7 172.7 371.3L146 344.7L95.09 416H352.6zM72 256C72 242.7 82.75 232 96 232C109.3 232 120 242.7 120 256C120 269.3 109.3 280 96 280C82.75 280 72 269.3 72 256zM112 0C120.8 0 128 7.164 128 16V64H320V16C320 7.164 327.2 0 336 0C344.8 0 352 7.164 352 16V64H384C419.3 64 448 92.65 448 128V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H96V16C96 7.164 103.2 0 112 0zM416 192H32V448C32 465.7 46.33 480 64 480H384C401.7 480 416 465.7 416 448V192zM384 96H64C46.33 96 32 110.3 32 128V160H416V128C416 110.3 401.7 96 384 96z" />
    </svg>
  )
}


export function IconTransfer({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 384 512">
      <path d="M272 192C272 236.2 236.2 272 192 272C147.8 272 112 236.2 112 192C112 147.8 147.8 112 192 112C236.2 112 272 147.8 272 192zM192 240C218.5 240 240 218.5 240 192C240 165.5 218.5 144 192 144C165.5 144 144 165.5 144 192C144 218.5 165.5 240 192 240zM384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384zM192 32C103.6 32 32 103.6 32 192C32 207.6 37.43 229 48.56 255.4C59.47 281.3 74.8 309.4 92.14 337.5C126.2 392.8 166.6 445.7 192 477.6C217.4 445.7 257.8 392.8 291.9 337.5C309.2 309.4 324.5 281.3 335.4 255.4C346.6 229 352 207.6 352 192C352 103.6 280.4 32 192 32z" />
    </svg>
  )
}


export function IconTransferAir({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 576 512">
      <path d="M562.7 221.2C570.3 230.8 576 242.6 576 256C576 282.9 554.4 303.1 534.2 315.2C513.1 327.9 486.9 336 466.3 336H365.9L279.1 487.8C270.6 502.8 254.7 512 237.4 512H181.2C159.1 512 144.6 491.7 150.4 471.2L189.1 336H136L97.6 387.2C91.56 395.3 82.07 400 72 400H30.03C13.45 400 0 386.6 0 369.1C0 367.2 .3891 364.4 1.156 361.7L31.36 256L1.156 150.3C.3888 147.6 0 144.8 0 142C0 125.4 13.45 112 30.03 112H72C82.07 112 91.56 116.7 97.6 124.8L136 176H189.1L150.4 40.79C144.6 20.35 159.1 0 181.2 0H237.4C254.7 0 270.6 9.23 279.1 24.19L365.9 176H466.3C486.1 176 513.3 184.4 534.3 197.2C544.9 203.7 555 211.7 562.7 221.2L562.7 221.2zM517.7 224.6C500.4 214.1 479.8 208 466.3 208H356.6C350.8 208 345.5 204.9 342.7 199.9L251.3 40.06C248.5 35.08 243.2 32 237.4 32H181.2L225.7 187.6C227 192.4 226.1 197.6 223.1 201.6C220 205.6 215.3 208 210.3 208H128C122.1 208 118.2 205.6 115.2 201.6L72 144H32.64L63.38 251.6C64.21 254.5 64.21 257.5 63.38 260.4L32.64 368H72L115.2 310.4C118.2 306.4 122.1 304 128 304H210.3C215.3 304 220 306.4 223.1 310.4C226.1 314.4 227 319.6 225.7 324.4L181.2 480H237.4C243.2 480 248.5 476.9 251.3 471.9L342.7 312.1C345.5 307.1 350.8 304 356.6 304H466.3C479.9 304 500.5 298.1 517.7 287.8C535.8 276.9 544 265.1 544 256C544 251.9 542.3 246.1 537.7 241.2C533.1 235.5 526.2 229.7 517.7 224.6L517.7 224.6zM265.2 32.12L251.3 40.06z" />
    </svg>
  )
}


export function IconTransferBoat({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 576 512">
      <path d="M336 0C362.5 0 384 21.49 384 48V64H432C458.5 64 480 85.49 480 112V237.3L513.3 248.1C548.1 259.3 558 303.8 531.4 328.8L442.9 411.7C436.5 417.7 426.4 417.4 420.3 410.9C414.3 404.5 414.6 394.4 421.1 388.3L509.5 305.4C518.4 297.1 515.1 282.3 503.5 278.5L304 213.1V384C304 392.8 296.8 400 287.1 400C279.2 400 271.1 392.8 271.1 384V213.1L72.53 278.5C60.94 282.3 57.62 297.1 66.51 305.4L154.9 388.3C161.4 394.4 161.7 404.5 155.7 410.9C149.6 417.4 139.5 417.7 133.1 411.7L44.63 328.8C17.96 303.8 27.9 259.3 62.68 248.1L95.1 237.3V112C95.1 85.49 117.5 64 143.1 64H191.1V48C191.1 21.49 213.5 0 239.1 0L336 0zM352 64V48C352 39.16 344.8 32 336 32H239.1C231.2 32 223.1 39.16 223.1 48V64H352zM143.1 96C135.2 96 127.1 103.2 127.1 112V226.9L273.2 179.1C282.8 176.9 293.2 176.9 302.8 179.1L448 226.9V112C448 103.2 440.8 96 432 96H143.1zM133.8 458.8C150.3 470 171.1 479.1 191.1 479.1C212.9 479.1 233.6 470 250.1 458.8C259.9 452.1 268.2 444.8 276.1 436.9C282.6 430.4 293.3 430.4 299.8 436.9C307.8 444.8 315.1 452.1 325.8 458.8C342.3 470 363.1 479.1 383.1 479.1C404.9 479.1 425.6 470 442.2 458.8C451.9 452.1 460.2 444.8 468.1 436.9C474.6 430.4 485.3 430.4 491.8 436.9C499.8 444.8 507.1 452.1 517.8 458.8C530.6 467.5 545.9 475.4 561.9 478.6C569.6 480.1 576 486.4 576 494.2C576 504 567.1 512.1 558.3 510.4C535.2 506.5 514.7 495.4 499.8 485.2C491.8 479.8 485.1 474.4 479.1 469.1C474.8 474.4 468.1 479.8 460.2 485.2C441.5 497.9 414.3 511.1 383.1 511.1C353.7 511.1 326.4 497.9 307.8 485.2C299.8 479.8 293.1 474.4 287.1 469.1C282.8 474.4 276.1 479.8 268.2 485.2C249.5 497.9 222.3 511.1 191.1 511.1C161.7 511.1 134.4 497.9 115.8 485.2C107.8 479.8 101.1 474.4 95.96 469.1C90.82 474.4 84.12 479.8 76.19 485.2C61.25 495.4 40.81 506.5 17.69 510.4C8.015 512.1 0 504 0 494.2C0 486.4 6.421 480.1 14.13 478.6C30.07 475.4 45.38 467.5 58.16 458.8C67.94 452.1 76.16 444.8 84.07 436.9C90.64 430.4 101.3 430.4 107.8 436.9C115.8 444.8 124.1 452.2 133.8 458.8H133.8z" />
    </svg>
  )
}

export function IconTransferLand({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 512 512">
      <path d="M113.6 98.2L85.3 192.6c3.5-.4 7.1-.6 10.7-.6H416c3.6 0 7.2 .2 10.7 .6L398.4 98.2c-6.1-20.3-24.8-34.2-46-34.2H159.6c-21.2 0-39.9 13.9-46 34.2zM74.3 227.8C49.6 236.7 32 260.3 32 288v96H480V288c0-27.7-17.6-51.3-42.3-60.2l-.5 .1-.1-.3c-6.6-2.3-13.8-3.6-21.2-3.6H96c-7.4 0-14.5 1.3-21.2 3.6l-.1 .3-.5-.1zm-26-23.1l.4-1.3L83 89c10.2-33.8 41.3-57 76.6-57H352.4c35.3 0 66.5 23.2 76.6 57l34.3 114.4 .4 1.3C492.6 221.2 512 252.3 512 288v96 16 16 48c0 8.8-7.2 16-16 16s-16-7.2-16-16V416H32v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V416 400 384 288c0-35.7 19.4-66.8 48.3-83.3zM104 272a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm280 24a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"/>
    </svg>
  )
}

export function IconTransferRail({ sizeClass }) {
  return (
    <svg className={sizeClass} viewBox="0 0 448 512">
      <path d="M192 336C192 318.3 206.3 304 224 304C241.7 304 256 318.3 256 336C256 353.7 241.7 368 224 368C206.3 368 192 353.7 192 336zM352 0C405 0 448 42.98 448 96V352C448 398 415.6 436.4 372.4 445.8L411.3 484.7C417.6 490.9 417.6 501.1 411.3 507.3C405.1 513.6 394.9 513.6 388.7 507.3L329.4 448H118.6L59.31 507.3C53.07 513.6 42.94 513.6 36.69 507.3C30.44 501.1 30.44 490.9 36.69 484.7L75.55 445.8C32.36 436.4 0 398 0 352V96C0 42.98 42.98 0 96 0H352zM352 32H96C60.65 32 32 60.65 32 96V224H416V96C416 60.65 387.3 32 352 32zM96 416H352C387.3 416 416 387.3 416 352V256H32V352C32 387.3 60.65 416 96 416z" />
    </svg>
  )
}

const iconComponents = {
  Activity: IconActivity,
  Program: IconProgram,
  Transfer: IconTransfer,
  AIR: IconTransferAir,
  BOAT: IconTransferBoat,
  LAND: IconTransferLand,
  RAIL: IconTransferRail,
  Other: IconProgram
};

export default function ExperienceTypeIcon({ type, config, sizeClass = 'h-5 w-5', colorClass = 'text-gray-500' }) {
  const IconComponent =
    iconComponents[config]
    ?? iconComponents[type]
    ?? iconComponents.Other;
  
  return (
    <div className={colorClass}>
      <IconComponent
        sizeClass={sizeClass}
      />
    </div>
  );
}
