import React from 'react';

export default function ComposeDayItemSelectorDisabled({ children }) {
  return (
    <div className="bg-gray-200 cursor-not-allowed h-full p-5 rounded-md border border-gray-300">
      <div className="h-full flex justify-center items-center">
        <div className="text-center text-gray-500 text-sm">
          {children}
        </div>
      </div>
    </div>
  )
}
