import styled, { css } from 'styled-components';

const Input = styled.input`
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 4px;
  transition: border-color 100ms ease;
  min-height: 36px;
  width: 100%;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  background-color: #ffffff;

  ${({ type }) =>
    type === 'password' &&
    css`
      padding-right: 44px;
    `}

  &:not([disabled]):focus,
  &:not([disabled]):active, &:not([disabled]):hover {
    outline: none;
    border: 1px solid ${({ theme }) => theme.darkSeparator};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &:not([disabled]),
      &:not([disabled]):hover,
      &:not([disabled]):active {
        border: 1px solid ${theme.danger};
      }
    `}
`;

export default Input;