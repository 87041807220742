import { useState } from 'react';
import KoobModal from '../Koob/KoobModal';
import KoobTitle from '../Koob/KoobTitle';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import {getOrganizationByTo} from 'api/requestConnection';
import { Formik, Form } from 'formik';
import { Stack } from '@koob/margaret';
import { TextField } from 'components/Fields';
import {SearchableSelectField} from 'components/Fields';
import LocationSearchableSelect from 'components/Hotel/LocationSearchableSelect';
import { searchLocations } from 'api/locations';
import { useTripDesigner } from 'hooks';
import { useAsync } from 'react-async';
import { Text } from 'ui';
import Stepper from 'components/Stepper';
import { DateField } from 'components/Fields/DatePicker';
import * as Yup from 'yup';
import {useNavigate} from "react-router-dom";
import { saveTrip } from '../../api/trip';
import LocalMultiSearchableSelectField from '../Fields/LocalMultiSearchableSelectField';
import { getCountriesByDmcId } from '../../api/countries';
import { useParams } from 'react-router-dom';
import { truncate } from 'lodash';

export default function ScratchFiltersModal({
  open,
  close,
  update = false,
  closeParent = () => {},
}) {
  const { t } = useTranslation('tripDesigner');
  const { tripData, tripFilters, setTripFilters, resetContext } = useTripDesigner();
  const navigate = useNavigate();
  const { fromId } = useParams();

  const [saving, setSaving] = useState(false);
  const [dmcId, setDmcId] = useState(tripFilters?.dmcId?.value ?? tripFilters?.dmcId);

  const { data } = useAsync({promiseFn: getOrganizationByTo});
  const dmcs = (data?.data || [])
    .map(dmc => ({
      value: dmc.id,
      label: dmc?.displayName,
    }));

  const { data: countriesData } = useAsync({
    promiseFn: getCountriesByDmcId,
    dmcId,
    watch: dmcId
  });

  const countries = (countriesData?.data || []);

  const onSubmit = (values) => {
    setSaving(true);

    saveTrip({
      name: values.programName,
      countries: values.countries?.map(country => {
        return {id: country.value}
      }),
      startLocation: values.startLocation ? values.startLocation : undefined,
      endLocation: values.endLocation ? values.endLocation : undefined,
      startDate: values.departureDate,
      duration: values.tripDuration,
      dmcId: values.dmcId?.value,
      minPax: values.minPax,
      maxPax: values.maxPax,
      nationality: values.nationality?.value
    })
      .then(response => {
        setTripFilters({
          ...tripFilters,
          programName: values.programName,
          departureDate: values.departureDate,
          tripDuration: values.tripDuration,
          startLocation: values.startLocation ? values.startLocation : undefined,
          endLocation: values.endLocation ? values.endLocation : undefined,
          countries: values.countries,
          nationality: values.nationality,
          dmcId: values.dmcId,
          minPax: values.minPax,
          maxPax: values.maxPax,
          composition: {
            adults: values?.minPax,
            children: 0,
            childrenBirthdates: [],
            count: values?.minPax,
            firstAdultNationality: values.nationality
          },
          tripId: response.data.tripId,
          clientFolder:
            window.location.pathname.includes('/folders/')
            || window.location.pathname.includes('/trip-designer/library')
              ? tripFilters?.clientFolder
              : undefined
        });

        navigate('/trip-designer/compose', {state: {tripId: response.data.tripId}});
      })
        .finally(()=> {
          setSaving(false);
        });
  }

  const onUpdate = (values) => {
    setSaving(true)
    saveTrip({
      name: values.programName,
      countries: values.countries?.map(country => {
        return {id: country.value}
      }),
      startLocation: values.startLocation ? values.startLocation : undefined,
      endLocation: values.endLocation ? values.endLocation : undefined,
      startDate: values.departureDate,
      duration: values.tripDuration,
      dmcId: values.dmcId?.value,
      minPax: values.minPax,
      maxPax: values.maxPax,
      nationality: values.nationality?.value,
      id: fromId ?? tripFilters?.tripId
    })
      .then((response) => {
        setTripFilters({
          ...tripFilters,
          programName: values.programName,
          departureDate: values.departureDate,
          tripDuration: values.tripDuration,
          startLocation: values.startLocation ? values.startLocation : undefined,
          endLocation: values.endLocation ? values.endLocation : undefined,
          countries: values.countries,
          nationality: values.nationality,
          dmcId: values.dmcId,
          minPax: values.minPax,
          maxPax: values.maxPax,
          composition: {
            adults: values.adultPax ?? values?.minPax,
            children: values.childrenBirthdates?.length ?? 0,
            childrenBirthdates:  values.childrenBirthdates ?? [],
            count: (values?.adultPax + values.childrenBirthdates?.length) ?? values?.minPax,
            firstAdultNationality: values.nationality
          }
        });

        close();

        setTimeout(() => {
          closeParent();
          navigate('/trip-designer/compose', {state: {tripId: response.data.tripId}})
        }, 200);
      })
        .finally(()=> setSaving(false));
  }

  return (
    <Formik
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={{
        programName: update ? tripFilters?.programName : '',
        departureDate: update ? tripFilters?.departureDate : '',
        tripDuration: tripFilters?.tripDuration ?? 1,
        nationality: update ? tripFilters?.nationality : '',
        startLocation: tripFilters?.startLocation,
        endLocation: tripFilters?.endLocation,
        countries: tripFilters?.countries ?? [],
        dmcId: tripFilters?.dmcId ?? '',
        minPax: tripFilters?.minPax ?? 1,
        maxPax: tripFilters?.maxPax ?? 1,
        adultPax: tripFilters?.adultPax ?? 1,
        childrenBirthdates: tripFilters?.childrenBirthdates ?? [],
      }}
      validationSchema={Yup.object().shape({
        programName: Yup.string().required(t('errors:required')),
        departureDate: Yup.string().required(t('errors:required')),
        tripDuration: Yup.number().required(t('errors:required')).positive().integer().min(1),
        startLocation: Yup.object(),
        endLocation: Yup.object(),
        countries: Yup.array().of(Yup.object()).required(t('errors:required')),
        dmcId: Yup.object().required(t('errors:required')),
        minPax: Yup.number().required(t('errors:required')).positive().integer().min(1),
        maxPax: Yup.number().required(t('errors:required')).positive().integer().min(Yup.ref('minPax'), t('errors:rangeNotValid'))
      })}
      validateOnMount={true}
    >
      {({ values, setFieldValue, isValid }) => (
        <Form>
          <KoobModal
            open={open}
            setOpen={() => {
              if (!update) {
                resetContext();
              }
              close();
            }}
            className="flex justify-center"
          >
            <div className="mb-4 text-center">
              <KoobTitle>
                { update ? t('designTripModal.editTitle') : t('designTripModal.title')}
              </KoobTitle>
            </div>

            <div className="flex flex-col space-y-6">
              <Stack direction="column" gutterSize={1}>
                <Text type="bodyLarge" fontWeight="700" color="textLight">
                  {t('designTripModal.form.programName')}
                </Text>

                <TextField name="programName" />
              </Stack>

              <Stack direction="column" gutterSize={1}>
                <Text type="bodyLarge" fontWeight="700" color="textLight" className={update && 'opacity-50 pointer-events-none'}>
                  {t('designTripModal.form.dmcName')}
                </Text>

                <SearchableSelectField
                  name="dmcId"
                  placeholder={t('designTripModal.form.dmcName')}
                  datas={dmcs}
                  renderOption={({ label }) => label}
                  renderSelectedOption={({ label }) => label}
                  onAfterChange={value => {
                    setFieldValue('dmcId', value)
                    setDmcId(value.value)
                  }}
                  local
                  disabled={update}
                />
              </Stack>

              <div className={(!!tripData && !update) && 'opacity-50 pointer-events-none'}>
                <Stack direction="column" size="full" gutterSize={1}>
                  <Text type="bodyLarge" fontWeight="700" color="textLight">
                    {t('designTripModal.form.countries')}
                  </Text>

                  <div className='w-full'>
                    {(countries && countries?.length > 0) && (
                      <LocalMultiSearchableSelectField
                        name={`countries`}
                        options={
                          countries?.map(country => ({ value: country.id, label: country.title }))
                        }
                        renderOption={option => option.label}
                        renderSelectedOption={option => option.label}
                        disabled={!dmcId}
                      />
                    )}
                  </div>
                </Stack>
              </div>

              <Stack direction="row" size="full" gutterSize={3}>
                <Stack direction="column" size="full" gutterSize={1}  alignX="center">
                  <Text type="bodyLarge" fontWeight="700" color="textLight">
                    {t('designTripModal.form.startLocation')}
                  </Text>

                  <div className='w-full'>
                    <LocationSearchableSelect
                      placeholder={t('designTripModal.form.locationsSearch')}
                      query={searchLocations}
                      renderSelectedOption={value => truncate(value.title, { length: 20 })}
                      onChange={value => setFieldValue('startLocation', value)}
                      wrapperStyle={{
                        marginLeft: 0,
                        minWidth: 100,
                        height: 50,
                        '--location-search-trigger-height': `${50}px`,
                      }}
                      kind="city"
                      inputStyle={{ minWidth: 100, height: 50 }}
                      initialValue={tripFilters?.startLocation}
                    />
                  </div>
                </Stack>

                <Stack direction="column" size="full" gutterSize={1}  alignX="center">
                  <Text type="bodyLarge" fontWeight="700" color="textLight">
                    {t('designTripModal.form.endLocation')}
                  </Text>
                  <div className='w-full'>
                    <LocationSearchableSelect
                      placeholder={t('designTripModal.form.locationsSearch')}
                      query={searchLocations}
                      renderOption={value => value.kind === 'city' ? value?.title : null}
                      renderSelectedOption={value => truncate(value?.title, { length: 20 })}
                      onChange={value => setFieldValue('endLocation', value)}
                      wrapperStyle={{
                        marginLeft: 0,
                        minWidth: 100,
                        height: 50,
                        '--location-search-trigger-height': `${50}px`,
                      }}
                      value={tripFilters?.endLocation}
                      kind="city"
                      inputStyle={{ minWidth: 100, height: 50 }}
                      initialValue={tripFilters?.endLocation}
                    />
                  </div>
                </Stack>
              </Stack>

              <Stack direction="row" gutterSize={2}>
                <Stack direction="column" gutterSize={1} size="full" alignX="center">
                  <Text type="bodyLarge" fontWeight="700" color="textLight">
                    {t('designTripModal.form.departureDate')}
                  </Text>

                  <DateField
                    name={'departureDate'}
                    showPlaceHolder={true}
                    actionOnfocus={function () {
                      return null
                    }}
                  />
                </Stack>

                <Stack gutterSize={1} direction="column" size="full" alignX="center">
                  <Text type="bodyLarge" fontWeight="700" color="textLight" className={update && 'opacity-50 pointer-events-none'}>
                    {t('designTripModal.form.tripDuration')}
                  </Text>

                  <Stepper
                    onChange={value => setFieldValue('tripDuration', value)}
                    value={values.tripDuration}
                    disabled={update}
                  />
                </Stack>
              </Stack>

              <Stack size="full" direction="row">
                <Stack gutterSize={1} direction="column" size="full"  alignX="center">
                  <Stack direction="column">
                    <Text type="bodyLarge" fontWeight="700" color="textLight">
                      {t('designTripModal.form.minPax')}
                    </Text>
                  </Stack>

                  <Stepper
                    onChange={value => setFieldValue('minPax', value)}
                    value={values?.minPax}
                  />
                </Stack>

                <Stack gutterSize={1} direction="column" size="full"  alignX="center">
                  <Stack direction="column">
                    <Text type="bodyLarge" fontWeight="700" color="textLight">
                      {t('designTripModal.form.maxPax')}
                    </Text>
                  </Stack>

                  <Stepper
                    onChange={value => setFieldValue('maxPax', value)}
                    value={values?.maxPax}
                  />
                </Stack>
              </Stack>

              <div className='mx-8 mt-2 flex justify-between'>
                <Button
                  type="button"
                  variant="simple"
                  onClick={() => {
                    fromId ? navigate('/trip-designer/library') : close(false);
                    if (!update) {
                      resetContext();
                    }
                  }}
                >
                  {t('misc:cancel')}
                </Button>

                <Button
                  type="submit"
                  variant="primary"
                  isLoading={saving}
                  disabled={!isValid}
                  onClick={() => update ? onUpdate(values) : onSubmit(values)}
                >
                  {update ? t('misc:update') : t('misc:continue')}
                </Button>
              </div>
            </div>
          </KoobModal>
        </Form>
      )}
    </Formik>
  )
}
