import KoobModal from '../../../../components/Koob/KoobModal';
import KoobTitle from '../../../../components/Koob/KoobTitle';
import { Text } from '../../../../ui';
import { ImageField, TextField } from '../../../../components/Fields';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import KoobButton from '../../../../components/Koob/KoobButton';
import { saveTripTemplate } from '../../../../api/trip';
import { useSnack } from '../../../../hooks';
import { useTheme } from 'styled-components';
import { Stack } from '@koob/margaret';
import { Button } from 'components';
import { IcBin } from 'components/icons';

function ImageSelector({
  images,
  selectedImage,
  setSelectedImage,
  setUploadPicture,
  alt = 'suggested',
}) {
  return (
    <div className="mt-2 overflow-x-auto grid grid-flow-col auto-cols-max gap-3 h-full">
      {images.map(image => (
        <div
          key={image}
          className={[
            'h-32 w-32 rounded-md overflow-hidden ring-4 my-1.5 mx-1',
            selectedImage === image ? 'ring-orange-500' : 'ring-gray-300',
          ].join(' ')}
          onClick={() => {
            setSelectedImage(image);
            setUploadPicture(null);
          }}
        >
          <img className="h-full w-full object-cover" src={image} alt={alt} />
        </div>
      ))}
    </div>
  );
}

const UploadImage = ({uploadPicture, setUploadPicture, setSelectedImage, selectedImage}) => {
  const theme = useTheme();
  const { t } = useTranslation('misc');

  const handleLoadFile = e => {
    const reader = new FileReader();
    const [file] = e.target.files;
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setUploadPicture(reader.result);
      setSelectedImage(reader.result);
    };
  };

  const handleReset = () => {
    setUploadPicture(null);
  };

  useEffect(() => {
    setUploadPicture(uploadPicture);
  }, [uploadPicture]);

  return (
    <Stack alignY="center" gutterSize={0.5}>
      <Stack direction='column' gutterSize={0.5}>
        <div
          className={[
            'h-32 w-32 rounded-md overflow-hidden ring-4 mt-3',
            uploadPicture === selectedImage ? 'ring-orange-500' : 'ring-gray-300',
          ].join(' ')}
        >
           <ImageField
              name="primaryAttachment"
              shape={"minus"}
              url={uploadPicture ?? '/img/headerData-image-placeholder.png'}
            />
        </div>

        <Stack direction="row">
          <Button as="label" variant="simple">
            {t('importImage')}
            <input
              type="file"
              accept="image/*"
              onChange={handleLoadFile}
              onClick={e => {
                e.target.value = null;
              }}
              style={{ display: 'none' }}
            />
          </Button>
          {Boolean(uploadPicture) && (
            <Button type="button" onClick={handleReset}>
              <IcBin size={24} color={theme.textLighter} />
            </Button>
          )}
        </Stack>
      </Stack>

    </Stack>
  )
}

export default function CreateTemplateModal({ open, close, setOpen, onTemplateSaved, tripId }) {
  const { t } = useTranslation('tripDesigner');
  const { notify } = useSnack();
  const [ { value: days } ] = useField('days');
  const [ { value: template } ] = useField('template');
  const [saving, setSaving] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadPicture, setUploadPicture] = useState(null);

  const suggestedImages = useMemo(() => {
    const results = [];

    days
      ?.filter(d => d?.experience?.dayIndex === null || d?.experience?.dayIndex === 1)
      ?.map(d => {
        results.push(d?.experience?.imageUrl);
        d?.experience?.pictures?.map(p => {
          if (!results.includes(p.imageUrl)) {
            results.push(p.imageUrl);
          }
        });
      });
    return results;
  }, [days]);

  const handleSave = () => {
    setSaving(true);

    saveTripTemplate({
      tripId: tripId,
      body: {
        name: template?.name,
        imageUrl: selectedImage,
      }
    })
      .then(() => {
        notify(t('templateModal.saved'));
        onTemplateSaved();
        setOpen(false);
      })
      .finally(() => setSaving(false));
  }

  return (
    <KoobModal
      open={open}
      setOpen={() => close()}
    >
      <KoobTitle className="text-center">
        {t('compose.saveAsTemplate')}
      </KoobTitle>

      <Text type="bodyLarge" fontWeight="700" color="textLight">
        {t('templateModal.name')}
      </Text>
      <TextField name="template.name" />

      <div className="mt-3">
        <Text type="bodyLarge" fontWeight="700" color="textLight">
          {t('templateModal.preview')}
        </Text>

        <Stack direction="row" gutterSize={1}>
          <ImageSelector
            images={suggestedImages?.filter(Boolean)}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setUploadPicture={setUploadPicture}
          />

          <UploadImage
            uploadPicture={uploadPicture}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setUploadPicture={setUploadPicture}
          />
        </Stack>
      </div>

      <div className="mt-5">
        <KoobButton
          block
          onClick={() => handleSave()}
          loading={saving}
          disabled={!template?.name || !selectedImage}
        >
          {t('compose.saveAsTemplate')}
        </KoobButton>
      </div>
    </KoobModal>
  )
}
