import { Stack, Button } from '@koob/margaret';
import { MdAlarm } from 'react-icons/md';
import { IcCancel } from 'components/icons';

const DurationButton = ({
  minDuration,
  maxDuration,
  setDurationModalShown,
  onCancel,
  translation,
}) => {
  const HEIGHT = 42;

  return (
    <Button
      variant="simple"
      style={{ width: "auto", height: HEIGHT, borderRadius: "20px" }}
      onClick={() => setDurationModalShown(true)}
    >
      <Stack gutterSize={0.375} alignY="center">
        <Stack gutterSize={0.375}>
          <MdAlarm size="18" color="grey" />

          <span>
            {maxDuration
              ? translation('search.expDurationRange', {
                  min: minDuration ?? 0,
                  max: maxDuration ?? 50,
                })
              : translation('search.expDuration')}
          </span>
        </Stack>

        {maxDuration && (
          <IcCancel
            size={24}
            color="rgba(0, 0, 0, 0.6)"
            onClick={(event) => { 
              event.stopPropagation();
              onCancel();
            }}
            style={{ marginLeft: 5 }}
          />
        )}
      </Stack>
    </Button>
  );
};

export default DurationButton;
