import { useState } from 'react';
import { Stack } from '@koob/margaret';
import { Text } from '@koob/to/src/ui';
import { KoobTitle, KoobParagraph,KoobButton, KoobModal, Stepper } from '@koob/ui';

const defaultInitialValue = {
  min: 0,
  max: 1
}

const DurationStepper = ({
  label,
  value,
  max,
  min,
  onChange,
  allowDecrement = true,
}) => (
  <Stack gutterSize={2} direction="column">
    <Stack direction="column" className="w-40">
      <Text type="h4" fontWeight="600" color="text" className="ml-2">
        {label}
      </Text>
    </Stack>
    <Stepper
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      allowDecrement={allowDecrement}
    />
  </Stack>
);

const DurationModal = ({
  open,
  setOpen,
  onSave,
  initialValue = defaultInitialValue,
  translation,
  max = 50,
}) => {
  const [duration, setDuration] = useState(initialValue);

  const minDurationGreaterThanMaxDuration = duration.min > duration.max;

  return (
    <KoobModal
      open={open}
      setOpen={() => setOpen(false)}
      widthClass="sm:max-w-xl overflow-visible"
    >
      <div className="text-center max-w-lg mx-auto">
        <KoobTitle size="text-xl">{translation("expDuration.title")}</KoobTitle>
        <KoobParagraph size="text-sm">
          {translation('expDuration.description')}
        </KoobParagraph>
      </div>

      <Stack size="full" alignX="space-around" className="mt-8">
        <DurationStepper
          label={translation('expDuration.minDuration')}
          value={duration?.min}
          min={0}
          max={duration?.max ?? 1}
          onChange={(value) =>
            setDuration((current) => ({
              ...current,
              min: value,
            }))
          }
        />
        <DurationStepper
          label={translation('expDuration.maxDuration')}
          value={duration?.max}
          min={1}
          max={max}
          onChange={(value) =>
            setDuration((current) => ({
              ...current,
              max: value,
            }))
          }
          allowDecrement={duration.max > duration.min}
        />
      </Stack>

      {minDurationGreaterThanMaxDuration && (
        <div className="text-center mt-4">
          <span className="text-red-600">
            {translation('expDuration.minDurationGreaterThanMaxDuration')}
          </span>
        </div>
      )}

      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton
          onClick={() => {
            setOpen();
            onSave(duration);
          }}
          color="primary"
          disabled={minDurationGreaterThanMaxDuration}
        >
          {translation('misc:save')}
        </KoobButton>
      </div>
    </KoobModal>
  );
};

export default DurationModal;
