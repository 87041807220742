export default function ExportDownloadButton({ onClick, processing, disabled = false }) {
  return (
    <button
      onClick={() => {
        if (!processing) {
          onClick();
        }
      }}
      className={[
        'h-14 w-14 rounded-full shadow-k flex justify-center items-center cursor-pointer transition',
        processing ? 'animate-pulse' : 'hover:bg-orange-600',
        disabled ? 'bg-gray-500 hover:bg-gray-500' : 'bg-orange-500'
      ].join(' ')}
      type="button"
      disabled={disabled}
    >
      <i className="fa-regular fa-cloud-download text-lg" />
    </button>
  );
}
