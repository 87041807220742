import React from 'react';

export default function CheckoutHotelRoomSource({
  name,
  logo,
}) {
  return (
    <div className="flex justify-end space-x-3 items-center">
      <div className="text-right text-gray-600 font-medium">
        {name}
      </div>

      {logo && (
        <img
          className="h-8 w-8 bg-gray-200 rounded-lg"
          src={logo}
          alt=""
        />
      )}
    </div>
  )
}
