import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { getExperience } from '../../api/experiences';
import KoobPlaceholder from '../Koob/KoobPlaceholder';
import Calendar from 'react-calendar';
import '../../styles/react-calendar.css';
import { useTranslation } from 'react-i18next';
import KoobButton from '../Koob/KoobButton';
import { differenceInDays } from 'date-fns';
import KoobParagraph from '../Koob/KoobParagraph';
import {formatDate} from "../../utils";

function CalendarLegend() {
  const { t } = useTranslation('experiences');
  return (
    <div className="flex space-x-5">
      {[
        {
          color: 'bg-red-600',
          label: t('state.closed')
        },
        {
          color: 'bg-yellow-500',
          label: t('state.on_request')
        },
        {
          color: 'bg-green-500',
          label: t('state.free_sale')
        }
      ].map((item) => (
        <div
          key={item.label}
          className="flex items-center space-x-2"
        >
          <div
            className={[
              item.color,
              'h-4 w-4 rounded-full'
            ].join(' ')}
          />
          <div>
            {item.label}
          </div>
        </div>
      ))}
    </div>
  )
}

export default function ExperienceAvailability({ experienceId, onSelect }) {
  const { t } = useTranslation('experiences');
  const { data, isLoading } = useAsync({
    promiseFn: getExperience,
    experienceId,
  });

  const [selectedDate, setSelectedDate] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const experience = data?.data ?? {};
  const periods = experience?.periods ?? [];

  const findPeriod = (date) => {
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      const newstartDate = new Date(period.startAt);
      newstartDate.setDate(newstartDate.getDate() - 1);
      const startDate = newstartDate;
      const endDate = new Date(period.endAt);
      
      if (date >= startDate && date <= endDate) {
        const dayOfWeek = date.getDay();
        if (period?.availableDays[(dayOfWeek+6)%7]) {
          return period;
        }
      }
    }
    return null;
  }

  const getDayType = (date) => {
    const period = findPeriod(date);
    if (period) {
      return period?.periodType;
    }
    return null;
  }

  var today = new Date();
  today.setDate(today.getDate() - 1);
  today = today.toISOString().split('T')[0];
  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      let classes = (date.toISOString().split('T')[0] >= today) ?
        ['rounded-full'] :
        ['!bg-white !text-gray-500']
      ;

      switch (getDayType(date)) {
        default:
          classes.push('bg-red-600');
          break;
        case 'on_request':
          classes.push('bg-yellow-500 hover:bg-yellow-600');
          break;
        case 'free_sale':
          classes.push('bg-green-500 hover:bg-green-600');
      }

      return classes.join(' ');
    }
  }

  const isDateSelectionValid = () => {
    if (!selectedDate) {
      return false;
    }

    if (experience?.durationDays > 1) {
      const startDate = new Date(selectedDate[0]);
      const endDate = new Date(selectedDate[1]);
      const startDateType = getDayType(startDate);
      endDate.setUTCHours(0, 0, 0, 0);
      const endDateType = getDayType(endDate);
      const days = Math.abs(differenceInDays(endDate, startDate)) + 1;

      if (days !== experience?.durationDays) {
        setErrorMessage(t('availability.errors.invalid_length', { count: experience?.durationDays }));
        return false;
      }

      if (startDateType !== endDateType) {
        setErrorMessage(t('availability.errors.different_period_types'));
        return false;
      }
    }

    if (experience?.durationDays > 2) {
      const startDate = selectedDate[0];
      const startDateType = getDayType(startDate);

      for (let i = 1; i < experience?.durationDays - 1; i++) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        const dateType = getDayType(date);
        if (dateType !== startDateType) {
          setErrorMessage(t('availability.errors.different_period_types'));
          return false;
        }
      }
    }
    setErrorMessage(null);
    return true;
  }

  useEffect(() => {
    setIsValid(isDateSelectionValid());
  }, [selectedDate]);

  return !isLoading ? (
    <div>
      <div className="mb-3">
        <KoobParagraph childClass="text-center">
          {t('availability.durationdays', { count: experience?.durationDays })}
        </KoobParagraph>
      </div>

      <div className="availability-calendar justify-center flex">
        <Calendar
          minDate={new Date()}
          tileClassName={tileClassName}
          selectRange={experience?.durationDays > 1}
          onChange={(value) => setSelectedDate(value)}
          calendarType="iso8601"
          locale="en-US"
        />
      </div>

      {errorMessage && (
        <div className="mt-2 text-red-500">
          {errorMessage}
        </div>
      )}

      <div className="mt-5 flex justify-between">
        <CalendarLegend />

        <div className="flex space-x-3">
          <KoobButton
            onClick={() => {
              if (!Array.isArray(selectedDate)) {
                onSelect({startAt: formatDate(selectedDate, 'yyyy-MM-dd'), endAt: formatDate(selectedDate, 'yyyy-MM-dd')});
              } else {
                onSelect({startAt: formatDate(selectedDate[0], 'yyyy-MM-dd'), endAt: formatDate(selectedDate[selectedDate.length-1], 'yyyy-MM-dd')});
              }

            }}
            disabled={!isValid}
          >
            {t('misc:continue')}
          </KoobButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="mb-5 w-[350px] mx-auto">
        <KoobPlaceholder className="h-6 w-full rounded" />

        <div className="mt-3">
          <KoobPlaceholder className="h-[356px] w-full rounded" />
        </div>
      </div>

      <KoobPlaceholder className="h-12 w-full rounded" />
    </>
  );
}
