import ExportTourOperatorLogo from './ExportTourOperatorLogo';
import { KoobBadge } from '@koob/ui';

export default function ExportSection({ title, subtitle, badge, trip, tourOperator, children }) {
  return (
    <div className="relative h-full min-h-screen px-16 py-14 border-b-8 border-k-orange">
      <div className="grid grid-cols-5 gap-5 text-2xl text-gray-900">
        <div className="col-span-3">
          <div className="flex space-x-3">
            <div className="font-semibold">
              {title}
            </div>

            {badge && (
              <div className="flex-shrink-0">
                <KoobBadge
                  size="lg"
                  color="orange"
                >
                  {badge}
                </KoobBadge>
              </div>
            )}
          </div>

          {subtitle && (
            <div className="mt-1 capitalize text-xl text-gray-500">
              {subtitle}
            </div>
          )}
        </div>

        <div className="col-span-2 text-right italic text-gray-500">
          {trip?.name}
        </div>
      </div>

      <div className="mt-8">
        {children}
      </div>

      <div className="absolute bottom-10 right-10 text-gray-900 opacity-50">
        <ExportTourOperatorLogo
          tourOperator={tourOperator}
        />
      </div>
    </div>
  );
}
