import { TripDesignerContext } from 'contexts';
import { useCallback, useEffect, useState } from 'react';

const TripDesignerProvider = ({ children }) => {
  const [libraryFilters, setLibraryFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listMode, setListMode] = useState('map');
  const [searchHotels, setSearchHotels] = useState(false);
  const [itineraryPinLevel, setItineraryPinLevel] = useState(0);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [compareWithVersion, setCompareWithVersion] = useState(null);
  const [lastAddedExperienceId, setLastAddedExperienceId] = useState(null);
  const [tripFilters, setTripFilters] = useState(
    localStorage.getItem('tripFiltersCache')
      ? JSON.parse(localStorage.getItem('tripFiltersCache'))
      : null
  );
  const [tripData, setTripData] = useState(
    localStorage.getItem('tripDataCache')
      ? JSON.parse(localStorage.getItem('tripDataCache'))
      : null
  );
  const [selectedExtras, setSelectedExtras] = useState(
    localStorage.getItem('tripExtrasCache')
      ? JSON.parse(localStorage.getItem('tripExtrasCache'))
      : []
  );
  const [initialExtras, setInitialExtras] = useState([]);
  const [compareVersionTotalPrice, setCompareVersionTotalPrice] = useState(null);

  useEffect(() => {
    if (!tripFilters && !tripData) {
      localStorage.removeItem('tripFiltersCache');
      localStorage.removeItem('tripDataCache');
    }
    if (tripFilters) {
      localStorage.setItem('tripFiltersCache', JSON.stringify(tripFilters));
    }
    if (tripData) {
      localStorage.setItem('tripDataCache', JSON.stringify(tripData));
    }
  }, [tripFilters, tripData]);

  useEffect(() => {
    localStorage.setItem('tripExtrasCache', JSON.stringify(selectedExtras ?? []));
  }, [selectedExtras]);

  useEffect(() => {
    if (selectedType === 'hotel' && selectedDay !== null) {
      setSearchHotels(true);
    } else {
      setSearchHotels(false);
    }
  }, [selectedType, selectedDay]);

  const resetContext = useCallback(() => {
    setTripFilters(null);
    setTripData(null);
    setSelectedDay(null);
    setSelectedType(null);
    setLastAddedExperienceId(null);
    setSelectedExtras([]);
    setCompareWithVersion(null);
    setInitialExtras([]);
  }, []);

  const setDates = useCallback(({startAt, endAt}) => {
    setTripFilters(current => ({
      ...current,
      startAt,
      endAt
    }));
  }, []);

  const selectDay = useCallback((dayIndex, type) => {
    setSelectedDay(dayIndex);
    setSelectedType(type);
  }, []);

  const resetDaySelection = useCallback(() => {
    setSelectedDay(null);
    setSelectedType(null);
  }, []);

  const value = {
    tripFilters,
    setTripFilters,
    libraryFilters,
    setLibraryFilters,
    resetContext,
    setDates,
    tripData,
    setTripData,
    loading,
    setLoading,
    selectedDay,
    selectedType,
    selectDay,
    resetDaySelection,
    compareWithVersion,
    setCompareWithVersion,
    selectedExtras,
    setSelectedExtras,
    lastAddedExperienceId,
    setLastAddedExperienceId,
    searchHotels,
    setSearchHotels,
    itineraryPinLevel,
    setItineraryPinLevel,
    listMode,
    setListMode,
    initialExtras,
    setInitialExtras,
    setCompareVersionTotalPrice,
    compareVersionTotalPrice
  };

  return (
    <TripDesignerContext.Provider value={value}>
      {children}
    </TripDesignerContext.Provider>
  );
};

export default TripDesignerProvider;
