import styled, { css } from 'styled-components';
import { Box, Stack } from '@koob/margaret';

const SubHeaderBase = styled(Box)`
  height: 68px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px ${({ theme }) => theme.separator};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  transform: translateY(-2px);

  ${({ variant }) =>
    variant === 'large' &&
    css`
      height: 92px;
      padding-bottom: ${({ theme }) => theme.spacing()};
    `}
`;

const SubHeader = ({ element, variant }) => {
  return (
    <SubHeaderBase variant={variant}>
      <Stack size="full" gutterSize={0.75}>
        {element}
      </Stack>
    </SubHeaderBase>
  );
};

export default SubHeader;
