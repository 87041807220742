import React from 'react';

export default function CheckoutHotelRoomFeature({ icon, iconUrl, children }) {
  return (
    <div className="flex space-x-1.5 items-center text-gray-600">
      {iconUrl ? (
        <img
          className="h-4 w-4"
          src={iconUrl}
          alt=""
        />
      ) : (
        <i className={['far', icon].join(' ')}/>
      )}

      <div>
        {children}
      </div>
    </div>
  )
}
