import React from 'react';

export default function ComposeDayExperienceOptionsButton({ label, count, onSave, type = 'global' }){
  return (
    <button
      className={[
        'border-none font-semibold transition',
        type === 'global' ? 'text-red-600 hover:text-red-700' : 'text-slate-700 hover:text-slate-800'
      ].join(' ')}
      onClick={(event) => {
        event.preventDefault();
        onSave();
      }}
    >
      <div className="flex space-x-1.5 items-center">
        <div className="text-xs">
          &rarr; {label}
        </div>
        <div className="p-px h-4 w-4 text-xs text-orange-500 bg-orange-100 rounded-full flex items-center justify-center">
          <div>
            {count ?? 0}
          </div>
        </div>
      </div>
    </button>
  )
}
