import { post } from '@koob/to/src/api';
import { HTML_TO_PDF_API_KEY, HTML_TO_PDF_API_URL } from '@koob/to/src/constants';

const prepareDocumentForHtmlToPdf = (clonedDocument) => {
  // Get the base URL for constructing absolute paths
  const baseUrl = window.location.origin;

  // Update link href attributes with absolute paths
  clonedDocument.querySelectorAll('link[href]').forEach((link) => {
    const relativePath = link.getAttribute('href');
    const absolutePath = new URL(relativePath, baseUrl).href;
    link.setAttribute('href', absolutePath);
  });

  // Link webfonts to the cloned document, as they are usually
  // imported from the React app and not available in the cloned document
  const head = clonedDocument.querySelector('head');
  const style = clonedDocument.createElement('style');
  style.textContent = `
      @font-face {
        font-family: 'Font Awesome 6 Pro';
        font-style: normal;
        font-weight: 400;
        font-display: block;
        src: url(https://koob-utils.bigblackcorp.com/fonts/fa-regular-400.woff2) format("woff2");
      }
    `;
  head.appendChild(style);

  // Remove elements with the export-hidden class
  clonedDocument.querySelectorAll('.export-hidden').forEach((element) => {
    element.remove();
  });

  // Locate the #export-target element
  const exportTarget = clonedDocument.querySelector('#export-target');
  if (exportTarget) {
    // Create a fragment to hold the contents of #export-target
    const fragment = document.createDocumentFragment();
    while (exportTarget.firstChild) {
      fragment.appendChild(exportTarget.firstChild);
    }

    // Append the fragment to the body of the cloned document
    clonedDocument.body.appendChild(fragment);

    // Remove the original #export-target and its parents up to the body
    let currentElement = exportTarget;
    while (currentElement && currentElement !== clonedDocument.body) {
      const parent = currentElement.parentElement;
      parent.removeChild(currentElement);
      currentElement = parent;
    }
  }

  return clonedDocument.documentElement.outerHTML;
};

const htmlToPdf = async ({ html, options, filename, encodeHtml = true }) => {
  await post(
    HTML_TO_PDF_API_URL,
    {
      html: encodeHtml ? encodeURIComponent(html) : html,
      isHtmlEncoded: encodeHtml,
      options: {
        format: 'A4',
        printBackground: true,
        ...options,
      },
      api_key: HTML_TO_PDF_API_KEY
    },
    {
      responseType: 'blob'
    },
    false
  )
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const href = window.URL.createObjectURL(blob);

      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = `${filename}.pdf`;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    });
};

export default htmlToPdf;
export { prepareDocumentForHtmlToPdf };
