import { destroy, get, post, put, putFile } from './';

export const getBooking = ({ bookingId, skip }) => {
  if (skip) {
    return Promise.resolve(null);
  }
  return get(`/api/v1/bookings/${bookingId}`);
}

export const cancelBooking = ({ bookingId, reason }) =>
  destroy(`/api/v1/bookings/${bookingId}`, { data: { reason } });

export const updateBooking = ({ bookingId, data }) =>
  put(`/api/v1/bookings/${bookingId}`, data);

export const getBookingDocuments = ({ bookingId }) =>
  get(`/api/v1/bookings/${bookingId}/documents`);

export const createBookingDocument = ({ bookingId, data }) =>
  putFile(`/api/v1/bookings/${bookingId}/documents`, data);

export const getBookingMessages = ({ bookingId }) =>
  get(`/api/v1/bookings/${bookingId}/messages`);

export const createBookingMessage = ({ bookingId, data }) =>
  put(`/api/v1/bookings/${bookingId}/messages`, data);

export const exportToCSV = ({ data }) =>
  post(`/api/v1/bookings/extractCSV`, data);

export const getBookingHistory = ({ bookingId }) =>
  get(`/api/v1/bookings/${bookingId}/histories`);
