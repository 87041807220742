export default function ReceiptWrapper({ children }) {
  return (
    <div className="max-w-6xl mx-auto">
      <div className="bg-white py-16 rounded-lg border">
        <div id="export-target">
          <div className="px-16 flex-col space-y-16">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
