import React from 'react';

export default function KoobDescriptionListRow({
  label,
  value,
  capitalize,
  children,
  icon
}) {
  return (
    <div className="py-2 sm:py-3 sm:grid sm:grid-cols-3 items-center sm:gap-2 sm:px-6">
      <dt className="text-sm font-medium text-gray-500 flex justify-center">
        <div className="mr-1.5">{icon}</div>
        {label}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {children || typeof value !== 'boolean' ? (
          <div className={capitalize && 'capitalize'}>{value ?? children}</div>
        ) : (
          <div>
            {value ? (
              <svg className="h-5 text-green-500" viewBox="0 0 448 512">
                <path d="M203.3 331.3C197.1 337.6 186.9 337.6 180.7 331.3L116.7 267.3C110.4 261.1 110.4 250.9 116.7 244.7C122.9 238.4 133.1 238.4 139.3 244.7L192 297.4L308.7 180.7C314.9 174.4 325.1 174.4 331.3 180.7C337.6 186.9 337.6 197.1 331.3 203.3L203.3 331.3zM0 96C0 60.65 28.65 32 64 32H384C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96zM32 96V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V96C416 78.33 401.7 64 384 64H64C46.33 64 32 78.33 32 96z" />
              </svg>
            ) : (
              <svg className="h-5 text-gray-500" viewBox="0 0 448 512">
                <path d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM384 64H64C46.33 64 32 78.33 32 96V416C32 433.7 46.33 448 64 448H384C401.7 448 416 433.7 416 416V96C416 78.33 401.7 64 384 64z" />
              </svg>
            )}
          </div>
        )}
      </dd>
    </div>
  );
}
