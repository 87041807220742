import SearchableSelect from './SearchableSelect';
import SearchableSelectLocal  from './SearchableSelectLocal';
import { useField } from 'formik';
import { FormField, FormLabel, ErrorMessageWrapper } from 'ui/forms';
import { Stack } from '@koob/margaret';
import { Asterix } from './Base';

const SearchableSelectField = ({
  name,
  label,
  rightLabel,
  onAfterChange,
  handleChangeCity,
  local,
  required,
  disabled,
  ...props
}) => {
  const [{ value }, { touched, error }, { setValue }] = useField({ name });
  const hasError = Boolean(touched) && Boolean(error);

  return (
    <FormField>
      <Stack alignX="space-between" alignY="center">
        {label && <FormLabel>{label} {required && <Asterix/>} </FormLabel>}
        {rightLabel}
      </Stack>
      <div id={`${name?.toLowerCase()}-select`}>
        {local && (
          <SearchableSelectLocal
            {...props}
            onAfterChange={onAfterChange}
            onChange={value => {
              setValue(value);
              if (Boolean(handleChangeCity)) {
                handleChangeCity(value);
              }
            }}
            value={value}
            hasError={hasError}
            disabled={disabled}
          />
        )}
        {!local && (
          <SearchableSelect
            {...props}
            onAfterChange={onAfterChange}
            onChange={value => {
              setValue(value);
              if (Boolean(handleChangeCity)) {
                handleChangeCity(value);
              }
            }}
            value={value}
            hasError={hasError}
            disabled={disabled}
          />
        )}
      </div>

      {hasError && <ErrorMessageWrapper>{error}</ErrorMessageWrapper>}
    </FormField>
  );
};

export default SearchableSelectField;
