import { ExperienceBookingContext } from 'contexts';
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const ExperienceBookingProvider = ({ children }) => {
  const [experienceFilters, setExperienceFilters] = useState({});
  const resetContext = useCallback(() => {
    setExperienceFilters({});
  }, []);
  const [basket, setBasket] = useState([]);
  const addExperienceToBasket = useCallback((experience, filters) => {
    setBasket((basket) => [...basket, {...experience, filters, internalId: uuidv4()}]);
  }, []);
  const removeExperienceFromBasket = useCallback((experience) => {
    setBasket((basket) => basket.filter((basketExp) => basketExp.internalId !== experience.internalId));
  }, []);
  const updateExperienceInBasket = useCallback((experience) => {
    setBasket((basket) => basket.map((basketExp) => basketExp.internalId === experience.internalId ? experience : basketExp));
  }, []);
  const setDates = useCallback(({startAt, endAt}) => {
    setExperienceFilters(current => ({
      ...current,
      startAt,
      endAt
    }));
  }, []);
  const resetBasket = useCallback(() => {
    setBasket([]);
  }, []);
  const value = {
    experienceFilters,
    setExperienceFilters,
    resetContext,
    basket,
    addExperienceToBasket,
    removeExperienceFromBasket,
    updateExperienceInBasket,
    setDates,
    resetBasket,
    setBasket
  };
  return (
    <ExperienceBookingContext.Provider value={value}>
      {children}
    </ExperienceBookingContext.Provider>
  );
};

export default ExperienceBookingProvider;
