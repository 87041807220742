import { Spinner as MgtSpinner } from '@koob/margaret';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: ${({ theme }) => theme.spacing(0.5)};
  background-color: #ffffff;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing()};
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  transform: translateX(-50%);
`;

const Spinner = ({noAbsolute}) => {
  if (noAbsolute) {
    return <MgtSpinner size={24} />;
  }
  return (
    <SpinnerWrapper noAbsolute={noAbsolute}>
      <MgtSpinner size={24} />
    </SpinnerWrapper>
  );
};

export default Spinner;
