import React from 'react';
import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import KoobButton from '../../../components/Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import KoobModal from '../../../components/Koob/KoobModal';
import { createFolder } from '../../../api/folders';
import { useSnack } from '../../../hooks';
import FoldersModalForm from './FoldersModalForm';
import {camelCase} from "lodash";

export default function FoldersModal({ open, setOpen, onFolderCreated }) {
  const { t } = useTranslation('folders');
  const { notify } = useSnack();

  const [pending, setPending] = React.useState(false);

  const handleCreateCustomerFile = async values => {
      setPending(true);
      try {
        const result = await createFolder({
          name: values.name,
          icr: values.icr,
          travelers: values.travelers.map(guest => {
            return {
              gender: guest.gender?.value,
              firstName: guest.firstName,
              lastName: guest.lastName,
              birthdate: guest.birthdate,
              nationality: guest.nationality?.value,
              passportNumber: guest.passportNumber,
              passportExpirationDate: guest.passportExpirationDate,
              ageIsExact: guest?.ageIsExact,
            };
          }),
        });

        notify(t('success'));
        onFolderCreated(result.data);
        setOpen(false);
      } catch (err) {
        if (err.response && err.response.status === 409) {
          notify(t('modal.guests.existingIcr'), { type: 'error' });
          return;
        }
        if (err.response && err.response.status === 500) {
          notify(t('modal.guests.somethingWentWrong'), { type: 'error' });
          return;
        }
        const error = camelCase(err?.response?.data?.message ?? 'error');

        notify(t(`errors:${error}`), { type: 'error' });
      } finally {
        setPending(false);
      }
  };

  return (
    <KoobModal open={open} setOpen={() => setOpen(false)} widthClass="sm:max-w-5xl overflow-visible">
      <div className="text-center max-w-lg mx-auto">
        <KoobTitle size="text-xl">
          {t('modal.title')}
        </KoobTitle>
        <KoobParagraph size="text-sm">
          {t('modal.description')}
        </KoobParagraph>
      </div>

      <FoldersModalForm
        onSubmit={values => handleCreateCustomerFile(values)}
        isPending={pending}
      />

      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton onClick={() => setOpen(false)} color="secondary">
          {t('misc:cancel')}
        </KoobButton>
      </div>
    </KoobModal>
  )
}
