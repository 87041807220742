import KoobButton from '../../../components/Koob/KoobButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ExperienceExtrasModalRecommendationCancelButton({
  onCancelAdd
}) {
  const { t } = useTranslation('experiences');

  return (
    <KoobButton
      onClick={() => {
        onCancelAdd();
      }}
    >
      {t('misc:cancel')}
    </KoobButton>
  );
}
