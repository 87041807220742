import React from 'react';
import KoobBadge from '../../../Koob/KoobBadge';
import { useTranslation } from 'react-i18next';

export default function BookingStatusBadge({ status }) {
  const { t } = useTranslation('folders', {keyPrefix: 'bookings'});

  const badge = () => {
    switch (status) {
      case 'confirmed':
        return <KoobBadge color="green">{t('status.confirmed')}</KoobBadge>;
      case 'pending':
        return <KoobBadge color="orange">{t('status.pending')}</KoobBadge>;
      case 'pending_source':
        return <KoobBadge color="orange">{t('status.pending')}</KoobBadge>;
      case 'sent':
        return <KoobBadge color="blue">{t('status.sent')}</KoobBadge>;
      case 'canceled':
        return <KoobBadge color="red">{t('status.cancelled')}</KoobBadge>;
      case 'refused':
        return <KoobBadge color="red">{t('status.refused')}</KoobBadge>;
      case 'failed':
        return <KoobBadge color="red">{t('status.failed')}</KoobBadge>;
      case 'draft':
        return <KoobBadge color="red">{t('status.draft')}</KoobBadge>;
      case 'failure':
        return <KoobBadge color="red">{t('status.failure')}</KoobBadge>;
      case 'replaced':
        return <KoobBadge color="purple">{t('status.replaced')}</KoobBadge>;
      case 'booked':
        return <KoobBadge color="blue">{t('status.booked')}</KoobBadge>;
      default:
        return <KoobBadge color="orange">{t('status.new')}</KoobBadge>;
    }
  }

  return (
    <div className="flex justify-center">
      {badge()}
    </div>
  );
}
