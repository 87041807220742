import styled from "styled-components";

const Symbole =  styled.span`
    color: red;
`;

export const Asterix = () => {
    return(
        <Symbole>*</Symbole>
    );
}