import styled, {createGlobalStyle, css} from 'styled-components';
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Stack } from '@koob/margaret';
import { MdToday, MdClose, MdArrowForward } from 'react-icons/md';
import { FormLabel } from 'ui/forms';
import 'react-date-picker/dist/DatePicker.css';
import { initialLocale } from 'utils';

export const DatesPickerStyles = createGlobalStyle`
.react-date-picker__inputGroup__input, .react-daterange-picker__inputGroup {
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;

  input {
  color: ${({ theme }) => theme.textLight};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
}

  .react-daterange-picker__range-divider > * {
    padding-left: var(--range-picker-padding, ${({ theme }) =>
  theme.spacing(1)});
    padding-right: var(--range-picker-padding, ${({ theme }) =>
  theme.spacing(1)});
  }

  .react-date-picker,
  .react-daterange-picker {
    min-height: 42px;

    .react-date-picker__wrapper,
    .react-daterange-picker__wrapper {
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 20px;
      width: var(--range-picker-width, auto);
    }
  }

  .react-date-picker__calendar.react-date-picker__calendar,
  .react-daterange-picker__calendar.react-daterange-picker__calendar {
    z-index: 2;
  }

  .react-daterange-picker__calendar-button.react-daterange-picker__calendar-button {
    display: none;
  }

  .react-date-picker__inputGroup__input.react-date-picker__inputGroup__input,
  .react-daterange-picker__inputGroup__input.react-daterange-picker__inputGroup__input {
    height: auto;
    outline: none;
  }

  .react-date-picker__inputGroup__leadingZero,
  .react-daterange-picker__inputGroup__leadingZero {
    color: ${({ theme }) => theme.textLight};
  }

  .react-date-picker__inputGroup.react-date-picker__inputGroup,
  .react-daterange-picker__inputGroup.react-daterange-picker__inputGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${({ theme }) => theme.spacing(0.5)};
    padding-right: ${({ theme }) => theme.spacing(0.25)};

    ~ .react-daterange-picker__inputGroup {
      padding-left: ${({ theme }) => theme.spacing(0.25)};;
    }
  }
  .react-daterange-picker__inputGroup__leadingZero {
    display: none;
  }
  .react-daterange-picker__inputGroup__input {
    padding: 0 4px !important;
    margin: 0 0px;
  }

  .react-date-picker__inputGroup__divider,
  .react-daterange-picker__inputGroup__divider {
    color: ${({ theme }) => theme.textLight};
  }

  .react-date-picker__inputGroup__input:invalid.react-date-picker__inputGroup__input:invalid,
  .react-daterange-picker__inputGroup__input:invalid.react-daterange-picker__inputGroup__input:invalid {
    background: transparent;
  }

  .react-calendar {
    &.react-calendar {
      border: 1px solid ${({ theme }) => theme.separator};
      border-radius: 4px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
    }

    .react-calendar__navigation__prev-8-button,
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation {
      margin-bottom: 0;
      background-color: ${({ theme }) => theme.darkPurple};
      color: #ffffff;
      font-weight: 700;
      overflow: hidden;
      border-radius: 4px 4px 0 0;

      button:enabled:hover,
      button:enabled:focus {
        background-color: ${({ theme }) => theme.lightPurple};
      }

      button[disabled] {
        background-color: ${({ theme }) => theme.darkPurple};
      }
    }

    abbr[title] {
      text-decoration: none;
    }

    .react-calendar__month-view__days__day--weekend {
      color: inherit;
    }

    .react-calendar__month-view__days__day {
      border-radius:100%;
        > abbr {
        border-radius:100% !important;
      }
    }

    .react-calendar__tile--active {
      background-color: ${({ theme }) => theme.orange} !important;
      color: white !important;
      > abbr {
        background-color: ${({ theme }) => theme.orange} !important;
      }
    }

    .react-calendar__tile--hasActive {
      background-color: ${({ theme }) => theme.orange} !important;
      color: white !important;
      > abbr {
        background-color: ${({ theme }) => theme.orange} !important;
      }
    }

    .react-calendar__tile--hover {
      background-color: ${({ theme }) => theme.orange} !important;
      color: white !important;
      > abbr {
        background-color: ${({ theme }) => theme.orange} !important;
      }
    }

    .react-calendar__tile::hover {
      background-color: ${({ theme }) => theme.orange} !important;
      color: white !important;
      > abbr {
        background-color: ${({ theme }) => theme.orange} !important;
      }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: ${({ theme }) => theme.textLight};
    }

    .react-calendar__tile:disabled {
      background-color: transparent;
      color: ${({ theme }) => theme.textLighter};
    }

    .react-calendar__month-view__weekdays {
      background-color: ${({ theme }) => theme.darkPurple};
      color: #ffffff;
      font-weight: 700;
    }

    .react-calendar__tile {
      padding: ${({ theme }) => theme.spacing(0.25)};
      min-height: 3em;
      display: flex;
      align-items: stretch;
      justify-content:center;

      > abbr {
        padding: ${({ theme }) => theme.spacing(0.25)};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        width: 100%;
      }
    }

    .react-calendar__tile--now {
      background-color: transparent;
      color: inherit;
    }

    .react-calendar__tile--active {
      background-color: transparent;
      color: inherit;

      > abbr, > abbr:hover {
        background-color: ${({ theme }) => theme.secondary};
        color: #ffffff;
      }
    }

    .react-calendar__tile:enabled {
      &:hover,
      &:focus {
        background-color: transparent;

        > abbr {
          background-color: #f0f0f0;
        }
      }
    }
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.secondary};
  font-size: 18px;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'light' &&
    css`
      color: ${({ theme }) => theme.disabled};
      font-size: 16px;
    `}

  ${({ variant }) =>
    variant === 'rangeDivider' &&
    css`
      color: ${({ theme }) => theme.textLight};
      padding: 0 ${({ theme }) => theme.spacing()};
    `}
`;

const Wrapper = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.borderRadius.large};

  input {
    color: ${({ theme }) => theme.textLight};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.disabled};
      `}
  }
`;

const DateRangePicker = ({
  disabled,
  label,
  onChange,
  value,
  minDate,
  maxDate,
  isClearable = true,
  wrapperStyle,
  ...props
}) => (
  <Stack
    direction="column"
    gutterSize={0.5}
    alignX="stretch"
    style={wrapperStyle}
  >
    {Boolean(label) && <FormLabel>{label}</FormLabel>}
    <Wrapper disabled={disabled} style={wrapperStyle}>
      <ReactDateRangePicker
        value={value}
        onChange={onChange}
        locale={`${initialLocale.value}-${initialLocale?.label}`}
        maxDate={maxDate}
        minDate={minDate}
        format="dd/MM/yyyy"
        disabled={disabled}
        clearIcon={
          isClearable && (
            <Icon variant="light">
              <MdClose />
            </Icon>
          )
        }
        calendarIcon={
          <Icon disabled={disabled}>
            <MdToday />
          </Icon>
        }
        rangeDivider={
          <Icon variant="rangeDivider">
            <MdArrowForward />
          </Icon>
        }
        {...props}
      />
      <DatesPickerStyles />
    </Wrapper>
  </Stack>
);

export default DateRangePicker;
