import { Select } from 'components/Fields';
import { Stack } from '@koob/margaret';
import { useAsync } from 'react-async';
import { useLanguage } from 'hooks';
import { getLanguages } from 'api/languages';

const initialLocale = {
  value: 'en',
  label: 'EN',
  alpha2: 'gb',
};

const LocaleSwitcher = () => {
  const {data} = useAsync({promiseFn: getLanguages});
  const { updateQueryLocale, lang } = useLanguage();

  const LanguageCode = {}
  const locales = data?.data?.map(({name , description}) => {
    LanguageCode[name] = description.toLowerCase();
    return {
      label: name.toUpperCase(),
      value: name,
      alpha2: description.toLowerCase()
    };
  });

  const optionSelected = lang ? {
    value: lang,
    label: lang.toUpperCase(),
    alpha2: LanguageCode[lang] || lang
} : initialLocale

  return (
    <div>
      <Select
        containerStyle={{ minWidth: 110, width: 110 }}
        onChange={updateQueryLocale}
        value={optionSelected}
        options={locales}
        renderSelectedValue={({ label, alpha2 }) => (
          <Stack gutterSize={0.375} alignY="center">
            <span className={`flag-icon flag-icon-${alpha2}`} />
            <span>{label}</span>
          </Stack>
        )}
        renderOption={({ label, alpha2 }) => (
          <Stack gutterSize={0.375} alignY="center">
            <span className={`flag-icon flag-icon-${alpha2}`} />
            <span>{label}</span>
          </Stack>
        )}
      />
    </div>
  );
};

export default LocaleSwitcher;
