import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { IcCancel } from 'components/icons';
import { isEqual } from 'lodash';
import { Dropdown, Spinner, Stack } from '@koob/margaret';
import {
  PopoverContainer,
  PopoverMenu as RawPopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from '@koob/margaret';
import Scrollbars from 'react-custom-scrollbars';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { useBooking } from 'hooks';
import {useAsync} from 'react-async';
import {useTranslation} from "react-i18next";

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--location-search-trigger-height, auto);
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 20px;
  line-height: 15px;
  min-height: 36px;
  position: relative;
  transition: border-color 100ms ease;

  ${({ disabled }) =>
    Boolean(disabled) &&
    css`
      background-color: ${({ theme }) => theme.backgroundDisabled};
      color: ${({ theme }) => theme.disabled};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

const PlaceHolderLabel = styled.div`
  color: ${({ theme }) => theme.textLight};
  font-weight: 600;
  white-space: nowrap;
  padding-left: 8px;

  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.disabled};
    `}

  ${({ variant }) =>
    variant === 'transparent' &&
    `
      color: #ffffff;
      font-style: none;
    `}
`;

const SearchInput = styled.input`
  outline: none;
  border: 0;
  padding: 0;
`;

const LabelOption = styled.p`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: 0;
  color: ${({ theme }) => theme.darkSeparator};
  margin: 0;
`;

const PopoverSearchItem = ({ title, kind, data, value, onChange }) => {
  return (
    <PopoverItem>
      <LabelOption>
        {title} ({data?.length})
      </LabelOption>
      {data?.map(({ id, title }) => (
        <PopoverItemButton
          style={{ fontSize: 14 }}
          key={id}
          type="button"
          onClick={() => onChange({ title, id, kind })}
          isActive={isEqual(value, title)}
        >
          {title}
        </PopoverItemButton>
      ))}
    </PopoverItem>
  );
};

const LocationSearchableSelect = ({
  placeholder,
  onChange,
  onAfterChange,
  disabled,
  wrapperStyle,
  inputStyle,
  query,
  popoverFooter,
  renderSelectedOption,
  emptyState,
  hasError,
  kind,
  initialValue
}) => {
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const inputRef = useRef();

  const { filters } = useBooking();
  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [value, setValue] = useState(initialValue ?? filters?.location);
  const {t} = useTranslation('hotel', {keyPrefix: 'search'});

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const { loading, data } = useAsync({search: debouncedSearch,promiseFn:query, watch: debouncedSearch});

  const countriesOptions = data?.data?.countries ?? [];
  const regionsOptions = data?.data?.regions ?? [];
  const citiesOptions = data?.data?.cities ?? [];
  const hotelsOptions = data?.data?.hotels ?? [];

  const removeFilter = e => {
    e.preventDefault();
    onChange('');
    setSearch('');
    setValue(null);
  }

  const handleChange = value => {
    onChange(value);
    setValue(value);

    if (Boolean(onAfterChange)) {
      onAfterChange(value);
    }
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  useDeepCompareEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  useEffect(() => {
    setSearch('');

    if (!isOpen) {
      return;
    }

    inputRef.current.focus();
  }, [isOpen]);

  return (
    <Dropdown
      disabled={disabled}
      ref={dropdownRef}
      wrapperStyle={wrapperStyle}
      onToggle={setIsOpen}
      trigger={
        <Trigger disabled={disabled} hasError={hasError}>
          {isOpen ? (
            <>
              <SearchInput
                style={inputStyle}
                ref={inputRef}
                onChange={e => setSearch(e.target.value)}
                placeholder={
                  Boolean(value) ? renderSelectedOption(value) : placeholder
                }
              />
              {loading && <Spinner size={20} variant="button" />}
            </>
          ) : (
            <>
              <PlaceHolderLabel disabled={disabled}>
                {Boolean(value) ? renderSelectedOption(value) : placeholder}
              </PlaceHolderLabel>
              { value &&
                <IcCancel size={24} onClick={removeFilter} style={{marginLeft: 5}}/>
              }
            </>
          )}
        </Trigger>
      }
    >
      <PopoverContainer style={{ maxHeight: '100px' }}>
        <PopoverMenu>
          <PopoverMenuInner
            ref={popoverRef}
            autoHeight
            autoHeightMax={250}
          >
            {Boolean(countriesOptions?.length > 0) && (!kind || kind === "country") && (
              <PopoverSearchItem
                title={t('countries')}
                kind="country"
                data={countriesOptions}
                value={value}
                onChange={handleChange}
              />
            )}

            {Boolean(regionsOptions?.length > 0) && (!kind || kind === "region") && (
              <PopoverSearchItem
                title={t('regions')}
                kind="region"
                data={regionsOptions}
                value={value}
                onChange={handleChange}
              />
            )}

            {Boolean(citiesOptions?.length > 0) && (!kind || kind === "city") && (
              <PopoverSearchItem
                title={t('cities')}
                kind="city"
                data={citiesOptions}
                value={value}
                onChange={handleChange}
              />
            )}
            {Boolean(hotelsOptions?.length > 0) && (!kind || kind === "hotel") && (
              <PopoverSearchItem
                title={t('hotels')}
                kind="hotel"
                data={hotelsOptions}
                value={value}
                onChange={handleChange}
              />
            )}
          </PopoverMenuInner>

          {(Boolean(popoverFooter) ||
            (Boolean(emptyState) && !loading && data.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </PopoverContainer>
    </Dropdown>
  );
};

LocationSearchableSelect.defaultProps = {
  placeholder: '',
  options: [],
  excludedIds: [],
  renderOption: ({ id }) => id,
  renderSelectedOption: ({ id }) => id,
};

export default LocationSearchableSelect;
