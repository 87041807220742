import React, { useCallback, useEffect, useState }  from 'react';
import KoobTitle from '../../../components/Koob/KoobTitle';
import { useTranslation } from 'react-i18next';
import LibraryFilters from '../../../components/Trip/LibraryFilters';
import styled from 'styled-components';
import TripCard from 'components/Trip/TripCard';
import { useTripDesigner } from '../../../hooks';
import { getTripsBooked } from 'api/trip';
import { isEqual } from 'lodash';
import { useAsync } from 'react-async';
import Spinner from '../../../components/Spinner';
import { getTags } from '../../../api/tag';
import KoobLayout from '../../../components/Koob/KoobLayout';

function TripTypeButton({ type, label, bookingsType, setBookingsType, icon }) {
  return (
    <div
      className={[
        'flex gap-2 mt-2 py-2 px-3 text-base font-medium rounded-xl border hover:border-gray-500 border-gray-300 transition ease-in bg-white',
        bookingsType === type ? 'bg-gradient-to-b from-k-gd1 to-k-gd2 text-white' : 'text-gray-500'
      ].join(' ')}
      onClick={() => setBookingsType(type)}
    >
      {icon}
      {label}
    </div>
  )
}

export default function TripsList() {

  const KoobTripCount = styled.h6`
    margin-top: 15px;
    color: grey;
  `;

  const { t } = useTranslation('tripDesigner');
  const { libraryFilters, setLibraryFilters } = useTripDesigner();

  const requestFilters= {
    search: libraryFilters?.search ?? "",
    cities: libraryFilters?.cities?.map((city) => city.id) ?? [],
    countries: libraryFilters?.countries?.map((country) => country.value) ?? [],
    tripDuration: libraryFilters?.tripDuration ?? 50,
    dmcId: libraryFilters?.dmcId?.value ? Number(libraryFilters?.dmcId?.value) : undefined,
    userId: parseInt(libraryFilters?.users?.id) ?? undefined,
    departureLocation: libraryFilters?.departureLocation?.[0]?.id ?? "",
    returnLocation: libraryFilters?.returnLocation?.[0]?.id ?? "",
    guided: libraryFilters?.guided,
    segments: libraryFilters?.segments?.map((segment) => segment.id) ?? [],
    themes: libraryFilters?.themes?.map((theme) => theme.id) ?? [],
    tags: libraryFilters?.tags?.map((tag) => tag.id) ?? [],
    type: libraryFilters?.type ?? 'trip',
    onlyFavorites: libraryFilters?.onlyFavorites ?? false,
    withArchived: libraryFilters?.withArchived ?? false,
  };

  const { data: tripsData, reload, isLoading } = useAsync({
    promiseFn: getTripsBooked,
    filters: requestFilters,
    watchFn: (props,prevProps) => {
      return !isEqual(props.filters, prevProps.filters)
    },
   });
  const trips = tripsData?.data ?? []

  const [tags, setTags] = useState([]);
  const { data: tagsData, reload: reloadTags } = useAsync({
    promiseFn: getTags,
    scope: 'Trip',
  });

  useEffect(() => {
    setTags(tagsData?.data ?? [])
  },[tagsData])

  const updateFilterValue = useCallback(
    (property, value) => {
      setLibraryFilters(current => ({
        ...current,
        [property]: value,
      }));
    },
    [setLibraryFilters],
  );

  return (
    <KoobLayout>
      <div className="max-w-7xl mx-auto">
        <LibraryFilters tags={tags}/>

        <div className="flex justify-between items-center">
          <KoobTitle size="text-2xl">
            {t('library.title')}
          </KoobTitle>

          <div className="flex space-x-1">
            <TripTypeButton
              type={"trip"}
              label="Trips"
              bookingsType={libraryFilters?.type ?? 'trip'}
              setBookingsType={value => updateFilterValue('type', value)}
            />

            <TripTypeButton
              type={"template"}
              label="Templates"
              bookingsType={libraryFilters?.type}
              setBookingsType={value => updateFilterValue('type', value)}
            />
          </div>
        </div>

        <KoobTripCount>
          {t('library.results', {count: trips?.length})}
        </KoobTripCount>

        <div className="mt-3">
          {!isLoading ? (
            <div className="grid md:grid-cols-2 lg:grid-cols-3 items-start gap-5">
              {trips.map(trip => (
                <TripCard
                  key={trip.id}
                  trip={trip}
                  reload={reload}
                  enableChat={libraryFilters?.type === 'trip' || !libraryFilters?.type}
                  enableFavorite
                  enableTagsEdition
                  enableExport
                  enableReceipt={libraryFilters?.type === 'trip'}
                  tags={tags}
                  reloadTags={reloadTags}
                />
              ))}
            </div>
          ) : (
            <Spinner noAbsolute/>
          )}
        </div>
      </div>
    </KoobLayout>
  );
}
