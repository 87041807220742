import React, { useState } from 'react';
import KoobContainer from 'components/Koob/KoobContainer';
import KoobButton from 'components/Koob/KoobButton';
import KoobSearchBar from 'components/Koob/KoobSearchBar';
import { useAsync } from 'react-async';
import { getFolders } from 'api/folders';
import KoobEmptyState from 'components/Koob/KoobEmptyState';
import KoobPlaceholder from 'components/Koob/KoobPlaceholder';
import FoldersModal from './Partials/FoldersModal';
import KoobTableSortable from 'components/Koob/KoobTableSortable';
import { useTranslation } from 'react-i18next';
import {useDebounce} from 'react-use';
import {endOfMonth, startOfMonth, format} from 'date-fns';
import { formatDate } from 'utils';
import DateRangePicker from 'components/Fields/DateRangePicker';
import BookingStatusBadge from 'components/Hotel/Booking/Edit/BookingStatusBadge';
import { Link, useNavigate } from 'react-router-dom';
import { SpinnerContainer } from 'containers/Hotels/HotelsDetail';
import { Button, Spinner as Loader } from '@koob/margaret';
import {isEqual} from "lodash";
import KoobTitle from '../../components/Koob/KoobTitle';
import { useApp, useError, useSnack } from 'hooks';
import { saveAs } from 'file-saver';
import { exportToCSV } from 'api/bookings';
import Spinner from 'components/Spinner';

export default function FoldersList() {
  const { t } = useTranslation('folders');
  const navigate = useNavigate();

  const defaultStartDate = startOfMonth(Date.now());
  const defaultEndDate = endOfMonth(Date.now());

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [search, setSearch] = useState();
  const [startAt, setStartAt] = useState(defaultStartDate);
  const [endAt, setEndAt] = useState(defaultEndDate);
  const [debouncedSearch, setDebouncedSearch] = useState();
  const [downloading, setDownloading] = useState(false);
  const { sendErrorSnack } = useError();
  const { notify } = useSnack();
  const {currentUserRole} = useApp();

  const { data, isPending, isLoading } = useAsync({
    promiseFn: getFolders,
    watchFn: (props,prevProps) => {
      return !isEqual(props.search,prevProps.search) || !isEqual(props.from,prevProps.from) || !isEqual(props.to,prevProps.to)
    },
    search: debouncedSearch,
    from: format(startAt, 'yyyy-MM-dd'),
    to: format(endAt, 'yyyy-MM-dd'),
  });

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const folders = data?.data ?? [];

  const columns = React.useMemo(
    () => [
      {label: t('table.createdBy'), accessor: 'createdBy'},
      {label: t('table.name'), accessor: 'name'},
      {label: t('table.icr'), accessor: 'icr'},
      {
        label: t('table.status'),
        accessor: 'status',
        sortType: (rowA, rowB) => {
          const statusA = rowA.original.status?.props?.status;
          const statusB = rowB.original.status?.props?.status;
          const order = ['confirmed', 'sent', 'new', 'cancelled'];

          return order.indexOf(statusA) - order.indexOf(statusB);
        },
        customContent: (
          <div className="group">
            <svg className="h-5 w-5" viewBox="0 0 512 512">
              <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"/>
            </svg>
            <span className="max-w-md group-hover:opacity-100 transition-opacity bg-gray-700 p-2 text-sm text-gray-100 rounded-md absolute left-1/2 -translate-x-1/2 opacity-0 m-4 mx-auto">
              {t('table.statusBulle')}
            </span>
          </div>
        )
      },
      {label: t('table.start'), accessor: 'start'},
      {label: t('table.end'), accessor: 'end'},
      {label: t('table.guest'), accessor: 'guest'},
      {
        label: t('table.bookings'),
        accessor: 'bookings',
        sortType: (rowA, rowB) => {
          const statusA = rowA.original.bookings?.props?.children[1]?.props?.children;
          const statusB = rowB.original.bookings?.props?.children[1]?.props?.children;

          return statusA - statusB;
        }
      },
      {label: t('table.provider'), accessor: 'provider'},
      {label: '', accessor: 'actions'},
    ], []
  );

  const rows = React.useMemo(() => {
    return folders.map((folder) => {
      return {
        createdBy: `${folder.createdBy?.firstName} ${folder.createdBy?.lastName}`,
        name: folder.name,
        icr: folder.icr,
        status: <BookingStatusBadge status={folder.bookings[0]?.state} />,
        start: formatDate(folder.bookings[0]?.startAt, 'dd/MM/yyyy'),
        end: formatDate(folder.bookings[0]?.endAt, 'dd/MM/yyyy'),
        guest: folder.travelers.length > 0 && `${folder.travelers[0].firstName} ${folder.travelers[0].lastName}`,
        bookings: (
          <div className="flex items-center space-x-2">
            <span>
              <svg className="h-5 w-5" viewBox="0 0 576 512">
                <path d="M128 192C128 174.3 142.3 160 160 160H416C433.7 160 448 174.3 448 192V320C448 337.7 433.7 352 416 352H160C142.3 352 128 337.7 128 320V192zM400 208H176V304H400V208zM576 128V208C549.5 208 528 229.5 528 256C528 282.5 549.5 304 576 304V384C576 419.3 547.3 448 512 448H64C28.65 448 0 419.3 0 384V304C26.51 304 48 282.5 48 256C48 229.5 26.51 208 0 208V128C0 92.65 28.65 64 64 64H512C547.3 64 576 92.65 576 128zM48 172.8C76.69 189.4 96 220.5 96 256C96 291.5 76.69 322.6 48 339.2V384C48 392.8 55.16 400 64 400H512C520.8 400 528 392.8 528 384V339.2C499.3 322.6 480 291.5 480 256C480 220.5 499.3 189.4 528 172.8V128C528 119.2 520.8 112 512 112H64C55.16 112 48 119.2 48 128V172.8z"/>
              </svg>
            </span>
            <span>{folder.bookings.length}</span>
          </div>
        ),
        provider: folder.bookings.length > 0 && (
          folder.bookings[0]?.kind === 'dmc' ? (folder.bookings[0]?.hotel?.organization?.displayName ?? folder.bookings[0]?.experiencesBooked[0]?.experience?.organizationName) : 'BedBank'
        ),
        actions: (
          <Link to={`/folders/${folder.id}`}>
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M113.3 47.41l183.1 191.1c4.469 4.625 6.688 10.62 6.688 16.59s-2.219 11.97-6.688 16.59l-183.1 191.1c-9.152 9.594-24.34 9.906-33.9 .7187c-9.625-9.125-9.938-24.38-.7187-33.91l168-175.4L78.71 80.6c-9.219-9.5-8.906-24.78 .7187-33.91C88.99 37.5 104.2 37.82 113.3 47.41z"/></svg>
          </Link>
        )
      }
    });
  }, [folders]);

  const downloadCSV = async () => {
    setDownloading(true);
    try {
      const response = await exportToCSV({
        data: {
          responseType: 'blob',
          search,
          order: {column: "STATE", direction: "ASC"},
          rangeDates: (Boolean(startAt) && Boolean(endAt)) 
            ? { 
                from: format(startAt, 'yyyy-MM-dd'),
                to: format(endAt, 'yyyy-MM-dd') 
              }
            : null,
          rangeDatesTravelPeriod: undefined,
          isTo: true
        }
      });
      const blob = new Blob([response.data], { type: 'text/csv' });
      saveAs(blob, 'bookings.csv');
      setDownloading(false);
      notify(t('downloadSuccess'));
    } catch (err) {
      sendErrorSnack(err);
      setDownloading(false);
    }
  };


  return (
    <KoobContainer>

      <div className="flex justify-between">
        <KoobTitle size="text-2xl">{t('title')}</KoobTitle>

        <KoobButton onClick={() => setShowCreateModal(true)}>
          {t('create')}
        </KoobButton>
      </div>

      <div className="mt-4 mb-10">
        <div className="flex items-center space-x-3">
          <div className="w-1/3">
            <KoobSearchBar
              placeholder={t('search')}
              value={search}
              onChange={setSearch} />
          </div>
          <div className="w-1/3">
            <div className="ml-2 font-medium text-sm mb-1">{t('period')}</div>
            <DateRangePicker
              wrapperStyle={{
                width: 350,
                '--range-picker-width': `350px`,
                '--range-picker-padding': '5px',
              }}
              value={[startAt, endAt]}
              isClearable={false}
              onChange={value => {
                const start = new Date(value?.[0]);
                const end = new Date(value?.[1]);
                setStartAt(start);
                setEndAt(end);
              }}
            />
          </div>
          <div className="w-1/3 flex justify-end">
            {currentUserRole === 'to_admin' && <Button
              variant="primary"
              onClick={downloadCSV}
              disabled={downloading}
            >
              {downloading ? (
                <>
                  <Spinner /> {t('downloading')}
                </>
              ) : (
                t('extractCSV')
              )}
            </Button>}
          </div>
        </div>
      </div>

      {isLoading && <SpinnerContainer>
        <Loader />
      </SpinnerContainer>}

      {!isPending ? (
        <>
          {folders?.length > 0 ? (
            <KoobTableSortable
              columns={columns}
              data={rows}
            />
          ) : (
            <KoobEmptyState
              title={t('emptyState.title')}
              description={t('emptyState.description')}
            />
          )}
        </>
      ) : (
        <div>
          <KoobPlaceholder className="mb-1 h-10" />

          <div className="grid grid-cols-1 gap-1">
            {[...Array(5)].map((_e, key) => (
              <KoobPlaceholder className="h-16" key={key} />
            ))}
          </div>
        </div>
      )}

      <FoldersModal
        open={showCreateModal}
        setOpen={setShowCreateModal}
        onFolderCreated={folder => navigate(`/folders/${folder.id}`)}
      />
    </KoobContainer>
  )
}
