import React from 'react';

export default function KoobTable({ heads, children, footer }) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="">
              <tr>
                {heads?.map((head, index) => (
                  <th
                    key={index}
                    scope="col"
                    className={[
                      'py-3 text-sm text-gray-900 font-bold tracking-wide',
                      head.align === 'right' ? 'text-right' : 'text-left',
                    ].join(' ')}
                  >
                    <div>
                      {head.label}
                    </div>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {children}
              </tbody>
              {footer && (
                <tfoot className="">
                <tr>
                  <td
                    className="border-t border-gray-200 py-3 text-sm text-gray-900 font-bold tracking-wide"
                    colSpan={heads?.length}
                  >
                    {footer}
                  </td>
                </tr>
                </tfoot>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
