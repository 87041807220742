import ExportExperienceImages from './ExportExperienceImages';
import React from 'react';

export default function ExportDaySectionContent({ images, children }) {
  const maxImages = 4;

  return (
    <div className="h-full flex-col justify-center">
      <div className="grid grid-cols-5 gap-16">
        <div className="col-span-2">
          {images?.length && (
            <ExportExperienceImages
              images={images.slice(0, maxImages)}
            />
          )}
        </div>

        <div className="col-span-3 flex-col space-y-5">
          {children}
        </div>
      </div>
    </div>
  );
}
