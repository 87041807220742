import { media } from "ui";
import styled, { css } from "styled-components";
import { keys } from "lodash";
import { useField, useFormikContext } from "formik";
import { SegmentedControl } from "@koob/margaret";

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.error};
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;

const SegmentedControlStyled = styled(SegmentedControl)`
  color: green;
  ${({ variant }) =>
    variant === "checked" &&
    `
      color: green;
      background-color: #D6D6D6;
      color: #fff;
      &.li button {
        color: #fff;
      }
    `}
`;

const SegmentedControlWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

export const SegmentedControlField = ({ options, disabled, ...props }) => {
  const [meta, { setValue }] = useField(props.name);
  const formikProps = useFormikContext();
  return (
    <>
      <SegmentedControlWrapper style={{ display: "flex" }}>
        {options.map((option, index) => (
          <SegmentedControlStyled
            key={index}
            multiple={false}
            values={option.value}
            onChange={value => {
              setValue(value);
            }}
            disabled={disabled}
            onClick={() => {
              formikProps.setFieldValue(props.name, option.value);
            }}
            options={[option]}
            variant={meta.value === option.value ? "checked" : ""}
          />
        ))}
      </SegmentedControlWrapper>
      {meta.error && meta.touched && <ErrorText>{meta.error}</ErrorText>}
    </>
  );
};

export const Container = styled.div`
  background-color: transparent;
  display: block;
  padding: ${({ theme }) => theme.spacing()};
  margin: 0 auto;

  ${media.tablet`
    padding: ${({ theme }) => theme.spacing(2)};
  `};

  ${media.desktop`
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) =>
    theme.spacing(4)};
    ${props =>
    props.reservation &&
    css`
      max-width: 100%;
      min-height: 700px;
    `}
  `}
  > * {
    margin-bottom: 0;

    + * {
      margin-top: ${({ theme }) => theme.spacing()};
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const SplitContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  ${props =>
    props.reservation &&
    css`
      margin-top: 30px;
    `}

  ${props =>
    props.reverse &&
    css`
      flex-direction: column;
    `}
  ${media.medium`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  height: 52px;
`;

export const LeftContainer = styled.div`
  max-width: 800px;
  width: 100%;
  text-align: left;
  margin-top: ${({ theme }) => theme.spacing(2)};

  > * + * {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  ${props =>
    props.reservation &&
    css`
      max-width: unset;
      padding-left: 0;
      ${media.medium`
      padding-left:10%;
    `}
    `}

  ${media.medium`
  text-align: left;
  margin-top:0;

    ${Buttons} {
      display: flex;
      justify-content: flex-end;
    }
  `}
`;

export const RightContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin-top: 0;
  ${media.medium`
    margin-left: ${({ theme }) => theme.spacing(2)};
    margin-top: ${({ theme }) => theme.spacing(-4)};
    max-width: 300px;

    ${props =>
    props.reservation &&
    css`
      max-width: 400px;
      margin-top: -15px;
    `}
    ${props =>
      props.reservationExp &&
      css`
          margin-top: 0 !important;
          max-width: 500px;
      `};
  `};
`;

export const Button = styled.button`
  ${({ theme }) => theme.fontStyles.body}
  display: flex;
  align-items: center;
  text-align: center;
  background: none;
  justify-content: center;
  text-decoration: none;
  outline: none;
  border: 0;
  cursor: pointer;
  white-space: nowrap;
  text-transform: uppercase;
  transition: all 150ms ease;

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    pointer-events: none;
    color: ${({ theme }) => theme.textDisabled};
    border-color: ${({ theme }) => theme.separatorSoft};
  }

  ${props =>
    props.variant === "primary" &&
    css`
      background-color: ${({ theme }) => theme.primary};
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing()};
      color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      font-weight: 600;
      height: 100%;

      &:hover,
      &:active {
        background-color: ${({ theme }) => theme.primaryLight};
      }
    `}
  ${props =>
    props.full &&
    css`
      width: 100%;
    `}
  ${props =>
    props.reservation &&
    css`
      background-color: #ffa24b;
      border-radius: 30px;
      color: #000;
      text-transform: none;
    `}
  svg {
    margin-right: ${({ theme }) => theme.spacing()};
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing(-1)};
  margin-left: ${({ theme }) => theme.spacing(-1)};
  grid-template-columns: repeat(1, 1fr);

  ${media.tablet`
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  `}
`;

export const Cell = styled.div`
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing()};
  padding-left: ${({ theme }) => theme.spacing()};
  min-width: 0;

  ${props =>
    props.sizes &&
    css`
      flex: 0 0 ${Math.floor(props.sizes.default * 10000) / 100}%;

      ${keys(props.sizes)
        .filter(key => key !== "default")
        .map(
          breakpoint => css`
            ${media[breakpoint]`flex: 0 0 ${
              Math.floor(props.sizes[breakpoint] * 10000) / 100
            }%`};
          `
        )};
    `};
`;

export const Text = styled.span`
  color: #6c6c6c;
  font-size: 1rem !important;

  > div > ul > li {
    font-size: 1rem !important;
    margin-left: -19px;
  }

  > div > p {
    font-size: 1rem !important;
  }

  > div > h2 {
    font-size: 1.925rem !important;
    margin-top: 35px;
    color: #000 !important;
  }
`;

export const ButtonReset = styled.button`
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: inherit;
  outline: none;
  cursor: pointer;
  padding: 0;

  svg {
    display: flex;
    color: #000 ! imporant;
  }

  ${props =>
    props.left &&
    css`
      text-align: left;

      svg {
        display: flex;
        color: #000 ! imporant;
      }
    `}
`;

export const TextButton = styled(ButtonReset)`
  display: flex;
  font-size: 17px;
  padding: 0;
  margin: 0;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};

    &:hover {
      color: ${({ theme }) => theme.primary};
    }
  }
`;

export const SectionCard = styled.div.attrs()`
  position: relative;
  border: 1px solid grey;
  border-radius: 1rem;
  padding-inline: ${({ theme }) => theme.spacing(1.5)};

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${({ theme }) => theme.primary};
    `}
`;
