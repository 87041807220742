import React from 'react';

export default function KoobButton ({ color = 'primary', block = false, roundedClass = 'rounded-md', loading, onClick, disabled, children, ...props }) {
   return (
     <button
       className={[
         'px-4 py-2 font-medium focus:outline-none shadow-k transition',
          color === 'primary' && 'bg-gradient-to-b from-k-gd1 to-k-gd2 text-white',
          color === 'secondary' ? 'bg-white hover:bg-white border border-gray-200 hover:border-gray-300 text-gray-600' : 'border-none',
          color === 'green' && 'bg-gradient-to-b from-green-500/75 to-green-500 text-white',
          loading || disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-100',
          roundedClass,
          block ? 'w-full' : '',
       ].join(' ')}
       id={props.id}
       onClick={(!disabled && !loading) ? onClick : () => {}}
       {...props}
     >
       {children}
     </button>
   );
}
