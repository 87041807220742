import { differenceInDays } from 'date-fns';
import moment from 'moment';
import ExportTourOperatorLogo from './ExportTourOperatorLogo';

function IconValue({ icon, value }) {
  return (
    <div className="flex space-x-3 items-center text-xl text-gray-400 font-semibold">
      <i className={`fa-regular ${icon}`} />

      <div>
        {value}
      </div>
    </div>
  );
}

export default function ExportHero({ trip, countries, tourOperator, images }) {
  const backgroundImage = images[0];
  const daysCount = differenceInDays(new Date(trip?.endDate), new Date(trip?.startDate));

  return (
    <div className="relative z-20 isolate flex flex-col justify-end overflow-hidden min-h-screen border-b-8 border-k-orange">
      <img
        className="absolute inset-0 -z-10 h-full w-full object-cover"
        src={
          trip?.template?.imageUrl ??
          trip?.imageUrl ??
          backgroundImage?.imageUrl
        }
        alt=""
      />

      <div className="absolute inset-0 -z-10 bg-gradient-to-t from-black/90 via-black/40" />

      <div className="p-10 flex justify-between items-center">
        <div>
          <div className="text-white text-4xl font-semibold">
            {trip?.name}
          </div>

          {countries?.length > 0 && (
            <div className="mt-1 text-gray-300 text-2xl font-medium">
              {countries?.map((country) => country.title).join(', ')}
            </div>
          )}

          <div className="mt-5 flex space-x-16">
            <IconValue icon="fa-users" value={`${trip?.maxPax} Guests`}/>

            {!trip?.template && (
              <IconValue
                icon="fa-plane-departure"
                value={`${moment(trip?.startDate).format(
                  'DD/MM/YYYY',
                )} → ${moment(trip?.endDate).format('DD/MM/YYYY')}`}
              />
            )}

            <IconValue icon="fa-sun-bright" value={`${daysCount+1} Days`} />

            <IconValue icon="fa-moon" value={`${daysCount} Nights`} />
          </div>
        </div>

        <div className="text-white">
          <ExportTourOperatorLogo tourOperator={tourOperator} noImage />
        </div>
      </div>
    </div>
  );
}
