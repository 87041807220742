import React from 'react';

export default function ExportDayMeal({meals}) {
  const includedColor = 'text-k-orange';
  const notIncludedColor = 'text-gray-300';

  return (
    <div className="flex space-x-8 text-2xl">
      <div className={meals?.includes('breakfast') ? includedColor : notIncludedColor}>
        <i className="fa-regular fa-croissant"/>
      </div>

      <div className={meals?.includes('lunch') ? includedColor : notIncludedColor}>
        <i className="fa-regular fa-plate-utensils"/>
      </div>

      <div className={meals?.includes('dinner') ? includedColor : notIncludedColor}>
        <i className="fa-regular fa-bowl-hot"/>
      </div>
    </div>
  );
}
