import React, { useState } from 'react';
import KoobTitle from 'components/Koob/KoobTitle';
import KoobParagraph from 'components/Koob/KoobParagraph';
import KoobButton from 'components/Koob/KoobButton';
import { useTranslation } from 'react-i18next';
import KoobModal from 'components/Koob/KoobModal';
import MultiRangeSlider from 'components/MultiRangeSlider/MultiRangeSlider';
import RangeSlider from 'components/MultiRangeSlider/RangeSlider';

export default function PriceRangeModal({ open, setOpen, onSave, initialesValues, min = 0, max = 10000, mode }) {
  const { t } = useTranslation('tripDesigner');
  const [range, setRange] = useState([min, max]);

  return (
    <KoobModal open={open} setOpen={() => setOpen(false)} widthClass="sm:max-w-xl overflow-visible">
      <div className="text-center max-w-lg mx-auto">
        <KoobTitle size="text-xl">
          {t(`${mode}.title`)}
        </KoobTitle>
        <KoobParagraph size="text-sm">
          {t(`${mode}.description`)}
        </KoobParagraph>
      </div>

      <div className='mt-6'>
        {mode === "priceRange" && (
          <MultiRangeSlider
            min={min}
            max={max}
            values={initialesValues}
            onChange={({ min, max }) => setRange([min, max])}
          />
        )}
        {mode !== "priceRange" && (
          <RangeSlider
            min={min}
            max={max}
            values={initialesValues}
            onChange={({ min, max }) => setRange([min, max])}
          />
        )}
      </div>

      <div className="mt-8 flex justify-end space-x-2">
        <KoobButton onClick={() => {
            setOpen();
            onSave(range);
          }} color="primary">
          {t('misc:save')}
        </KoobButton>
      </div>
    </KoobModal>
  )
}
