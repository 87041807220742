import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import { useTranslation } from 'react-i18next';
import React from 'react';

export default function ExperienceExtrasModalHeader({
  recommendationMode,
  childrenExtraMode,
  currentExperienceName,
  nextExperienceName,
}) {
  const { t } = useTranslation('tripDesigner');

  return (
    <>
      {!recommendationMode && !childrenExtraMode && (
        <div>
          <KoobTitle>
            {t('extrasModal.title')}
          </KoobTitle>

          <KoobParagraph>
            {t('extrasModal.description')}
          </KoobParagraph>
        </div>
      )}

      {recommendationMode && (
        <div>
          <KoobTitle>
            {t('extrasModal.recommendation.title')}
          </KoobTitle>

          <KoobParagraph>
            {t('extrasModal.recommendation.description')}
            <br/>
            {t('extrasModal.recommendation.forPrograms')}
            <strong>{currentExperienceName}</strong> &{' '}
            <strong>{nextExperienceName}</strong>.
          </KoobParagraph>
        </div>
      )}

      {childrenExtraMode && (
        <div>
          <KoobTitle>{t('extrasModal.childrenExtras.title')}</KoobTitle>
          <KoobParagraph>
            {t('extrasModal.childrenExtras.description')}
          </KoobParagraph>
        </div>
      )}
    </>
  );
}
