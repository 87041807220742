import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobParagraph from '../../../components/Koob/KoobParagraph';
import KoobButton from '../../../components/Koob/KoobButton';

export default function ExperienceExtrasModalSkipTransitionExtra({
  dayIndex,
  onSkip,
}) {
  const { t } = useTranslation('tripDesigner');
  const [, , { setValue: setSkipTransitionOption }] = useField(
    `days.${dayIndex}.experience.skipTransitionOption`,
  );

  const skipTransitionOption = () => {
    setSkipTransitionOption(true);
    onSkip();
  };

  return (
    <div className="m-3 bg-gray-100 p-4 rounded-lg">
      <div className="flex justify-between items-center">
        <div>
          <KoobTitle size="lg">
            {t('extrasModal.recommendation.other.title')}
          </KoobTitle>
          <KoobParagraph size="md">
            {t('extrasModal.recommendation.other.description')}
            <br />
          </KoobParagraph>
        </div>

        <div>
          <KoobButton onClick={() => skipTransitionOption()}>
            {t('extrasModal.recommendation.other.button')} &rarr;
          </KoobButton>
        </div>
      </div>
    </div>
  );
}
