import { Route, Routes } from 'react-router-dom';
import TripExport from './Export/ExportTrip';
import TripChat from './Chat';
import TripReceipt from './Receipt';

const TripsRoutes = () => {
  return (
    <Routes>
      <Route path="/:tripId/export" element={<TripExport />} />
      <Route path="/:tripId/receipt" element={<TripReceipt />} />
      <Route path="/:tripId/chat" element={<TripChat />} />
    </Routes>
  );
};

export default TripsRoutes;
