import React from 'react';
import ExportDaySectionTitle from './ExportDaySectionTitle';
import ExportDayOption from './ExportDayOption';

export default function ExportDayOptions({ options, mode = 'day' }) {
  return (
    <div className="bg-gray-100 p-8 rounded-lg">
      <div>
        <ExportDaySectionTitle>
          <i className="fa-regular fa-plus-circle mr-2" />
          {mode === 'day' ? 'Day Options' : 'Global Options'}
        </ExportDaySectionTitle>

        {options?.map((option) => (
          <ExportDayOption
            key={option.id}
            optionId={option.optionId}
          />
        ))}
      </div>
    </div>
  );
}
