import styled, { useTheme } from 'styled-components';
import { Stack ,Button } from '@koob/margaret';
import  Input  from './forms/Input';
import { IcAddCircle, IcRemoveCircle } from 'components/icons';


const NumberInput = styled(Input)`
  max-width: 80px;
  text-align: center;
  width: 4em;
`;

const Stepper = ({
  value,
  min = 0,
  max = Number.MAX_SAFE_INTEGER,
  disabled,
  onChange,
  allowDecrement = true,
}) => {
  const theme = useTheme();
  const MAX_DIGITS = 5;

  const handleChange = (newValue) => {
    if (!Number.isInteger(newValue)) {
      return;
    }
    if (value === null || value === undefined) {
      onChange(newValue);
    } else if (newValue > value) {
      onChange(Math.min(max, newValue));
    } else if (newValue < value) {
      onChange(Math.max(min, newValue));
    }
  };

  return (
    <Stack alignY="center" gutterSize={0.5}>
      {!disabled && (
        <div className={(!allowDecrement || value <= min) && "opacity-25"}>
          <Button
            onClick={() => handleChange(value - 1)}
            type="button"
            icon={<IcRemoveCircle size={24} color={theme.text} />}
            disabled={!allowDecrement || value <= min}
          />
        </div>
      )}
      <NumberInput
        type="text"
        pattern="[0-9]*"
        onInput={(event) => {
          const newInput =
            event.target.validity.valid &&
            event.target.value.length <= MAX_DIGITS
              ? event.target.value
              : value;
          if (newInput) {
            handleChange(parseInt(newInput));
          } else {
            handleChange(0);
          }
        }}
        value={value}
        disabled={disabled}
      />
      {!disabled && (
        <div className={value >= max && "opacity-25"}>
          <Button
            onClick={() => {
              if (!Boolean(value)) {
                onChange(1);
              } else if (Number.isInteger(value)) {
                onChange(Math.min(max, value + 1));
              }
            }}
            type="button"
            icon={<IcAddCircle size={24} color={theme.text} />}
            disabled={value >= max}
          />
        </div>
      )}
    </Stack>
  );
};

export default Stepper;