import { Card } from "ui";

const { default: styled, css } = require("styled-components");


const SectionCard = styled(Card).attrs({ size: 'full', as: 'section' })`
  padding: ${({ theme }) => theme.spacing()};
  height: min-content;

  ${({ isUpdated, theme }) =>
    isUpdated &&
    `
      background-color: ${theme.orangeLighter}
    `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid ${({ theme }) => theme.primary};
    `}

  ${({ variant }) =>
    variant === 'archive' &&
    css`
      background-color: ${({ theme }) => theme.yellowLight};
      background: repeating-linear-gradient(
        45deg,
        ${({ theme }) => theme.separatorLight},
        ${({ theme }) => theme.separatorLight} 16px,
        ${({ theme }) => theme.yellowLight} 16px,
        ${({ theme }) => theme.yellowLight} 32px
      );
    `}

    ${({ disabled, theme }) =>
    disabled &&
    `
      background-color: ${theme.disabled}
    `}
`;

export default SectionCard;