function ExportExperienceImage({ url }) {
  return (
    <img
      className="w-full h-auto aspect-square object-cover rounded-xl"
      src={url}
    />
  )
}

export default function ExportExperienceImages({ images }) {
  const gridClass = images?.length > 1 ? 'grid-cols-2' : '';

  return (
    <div
      className={[
        'max-w-xl mx-auto grid gap-5',
        gridClass,
      ].join(' ')}
    >
      {images?.map((image, index) => (
        <ExportExperienceImage
          key={index}
          url={image?.imageUrl ?? image}
        />
      ))}
    </div>
  )
}
