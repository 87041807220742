const ExperienceType = Object.freeze({
    ACTIVITY: 'Activity',
    TRANSFER: 'Transfer',
    PROGRAM: 'Program',
    AIR_TRANSFER: 'AIR',
    RAIL_TRANSFER: 'RAIL',
    LAND_TRANSFER: 'LAND',
    BOAT_TRANSFER: 'BOAT',

    isActivity(type) {
      return type === this.ACTIVITY;
    },

    isTransfer(type) {
      return type === this.TRANSFER;
    },

    isProgram(type) {
      return type === this.PROGRAM;
    },
    isAirTransfer(type) {
      return type === this.AIR_TRANSFER;
    },
    isRailTransfer(type) {
      return type === this.RAIL_TRANSFER;
    },
    isLandTransfer(type) {
      return type === this.LAND_TRANSFER;
    },
    isBoatTransfer(type) {
      return type === this.BOAT_TRANSFER;
    },
  },
);

export default ExperienceType;
