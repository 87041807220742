import { Stack } from '@koob/margaret';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { IoImagesOutline } from 'react-icons/io5';
import { RoleButton as Button } from 'components/RoleButton';
import ImageField from "components/Fields/ImageField";

const MiniBloc = styled.div`
  display: flex;
  height: 160px;
  width: 350px;
  position: relative;
  border-radius: 10px;
  ${props =>
  css`
      background-image: url('${props.image}');
      background-position: center center;
      background-size: cover;
    `};
`;

const BlocContainer = styled.div`
  width: 360px;
  margin-left : 1.5%;
`;

const MyButton = styled(Button)`
  position: relative;
  padding: 16px;
  background-color: #ffff;
  border-radius: 5px;
  float: right;
  bottom: 50px;
  right: 2%;
  height: 20px;
  color: black;
`;

const MyButtonMultiple = styled(Button)`
  position: absolute;
  padding: 16px;
  background-color: #ffff;
  border-radius: 5px;
  bottom: 10px;
  right: 2%;
  height: 20px;
  color: black;
`;

function ImageLabel({ label }) {
  return label ? (
    <div className="absolute bottom-2 left-2 px-2 py-1 text-xs text-white rounded-lg bg-black/50 backdrop-blur-sm">
      {label}
    </div>
  ) : null;
}

export const BlocImages = ({ images, showNames = false }) => {
  const { t } = useTranslation('hotel');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const isMultiple = images?.length > 1;

  const openModalAtIndex = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <div className="relative">
        <ImageField
          name="primaryAttachment"
          shape={isMultiple ? "multiple" : "full"}
          url={images[0]?.imageUrl ?? '/img/headerData-image-placeholder.png'}
        />

        {!isMultiple && (
          <MyButton
            icon={<IoImagesOutline />}
            onClick={openModalAtIndex}
          >
            {t('showAllPictures')}
          </MyButton>
        )}

        {showNames && (
          <ImageLabel label={images[0]?.displayName} />
        )}
      </div>

      {isMultiple && (
        <BlocContainer>
          <Stack direction="column">
            <Stack direction="column" gutterSize={1.3}>
              <MiniBloc
                image={images[1]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
              >
                {showNames && (
                  <ImageLabel label={images[1]?.displayName} />
                )}
              </MiniBloc>

              <MiniBloc
                image={images[2]?.imageUrl ?? '/img/hotel-image-placeholder.png'}
              >
                {showNames && (
                  <ImageLabel label={images[2]?.displayName} />
                )}

                <Stack>
                  <MyButtonMultiple
                    icon={<IoImagesOutline />}
                    onClick={openModalAtIndex}
                  >
                    {t('showAllPictures')}
                  </MyButtonMultiple>
                </Stack>
              </MiniBloc>
            </Stack>

          </Stack>
        </BlocContainer>
      )}

      <ModalGateway>
        {modalIsOpen && (
          <Modal onClose={() => setModalIsOpen(false)}>
            <Carousel views={images.map(img => {
              return {
                src: img?.imageUrl,
                caption: img?.displayName
              }
            })} />
          </Modal>
        )}
      </ModalGateway>
    </>
  )
}
