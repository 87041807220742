import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export default function DocumentListItem({ document }) {
  const { t } = useTranslation('booking');

  return (
    <div className="py-5 flex justify-between items-center">
      <div className="font-semibold">
        {document.document?.metadata?.filename ??
          t('bookings.documents.noFilename')}
      </div>

      <div className="flex space-x-3 items-center">
        <div className="text-gray-500 text-sm">
          {t('documents.uploadedBy', {
            when: formatDistanceToNow(parseISO(document.createdAt)),
            who: document.user
              ? `${document.user?.firstName} ${document.user?.lastName}`
              : t('chat.anonymous'),
          })}
        </div>

        {document?.documentUrl ? (
          <a className="border-none" href={document?.documentUrl} download target="_blank" rel="noreferrer">
            <svg className="h-5 w-5 text-gray-600" viewBox="0 0 512 512">
              <path d="M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V294.1l-95-95c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 369c9.4 9.4 24.6 9.4 33.9 0L409 233c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95V24zM128.8 304H64c-35.3 0-64 28.7-64 64v80c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V368c0-35.3-28.7-64-64-64H383.2l-48 48H448c8.8 0 16 7.2 16 16v80c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16H176.8l-48-48zM432 408a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z" />
            </svg>
          </a>
        ) : (
          <svg className="h-5 w-5 text-red-400" viewBox="0 0 384 512">
            <path d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM192 392c-13.25 0-24 10.75-24 24s10.75 24 24 24c13.26 0 24-10.75 24-24S205.3 392 192 392zM191.1 368C200.8 368 208 360.8 208 352V240C208 231.2 200.8 224 192 224S176 231.2 176 240V352C176 360.8 183.1 368 191.1 368z" />
          </svg>
        )}
      </div>
    </div>
  );
}
