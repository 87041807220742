import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';

export const Lightning = ({ popover, absolute }) => {
  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus">
      <div className="font-bold bg-gray-100 text-lg rounded-md p-1 h-8">{popover}</div>
    </Popover>
  );

  return (

    <OverlayTrigger
      trigger={['hover']}
      placement="top"
      overlay={popoverHoverFocus}
    >
      <div className={absolute ? "absolute top-5 right-5" : ""}>
        <div className="bg-green-600 border border-green-600 bg-opacity-60 backdrop-blur-sm rounded-full p-2">
          <svg className="text-white h-4 w-4" viewBox="0 0 448 512">
            <path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288H175.5L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7H272.5L349.4 44.6z"/>
          </svg>
        </div>
      </div>
    </OverlayTrigger>
  );
};
