import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';

export default function ComposeMapItineraryProgramMarker({ label, program, icon, clusterer }) {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const position = {
    lat: parseFloat(program?.startPosition?.lat),
    lng: parseFloat(program?.startPosition?.lon),
  }

  return (
    <Marker
      position={position}
      label={label}
      icon={icon}
      onClick={() => setInfoWindowOpen(true)}
      clusterer={clusterer}
    >
      {infoWindowOpen && (
        <InfoWindow
          onCloseClick={() => setInfoWindowOpen(false)}
          position={position}
        >
          <div>
            <p>{program?.title}</p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  )
}
