import KoobModal from '../../Koob/KoobModal';
import { useTranslation } from 'react-i18next';
import KoobTitle from '../../Koob/KoobTitle';
import KoobParagraph from '../../Koob/KoobParagraph';
import KoobButton from '../../Koob/KoobButton';
import LocalMultiSearchableSelectField from '../../Fields/LocalMultiSearchableSelectField';
import { Form, Formik } from 'formik';
import { createTag, saveTags } from '../../../api/tag';
import { useState } from 'react';
import { useSnack, useApp } from '../../../hooks';
import {useRef} from 'react';

export default function TripTagsModal({ trip, reload, open, onClose, tags, reloadTags }) {
  const { t } = useTranslation('tripDesigner');
  const [currentSearch, setCurrentSearch] = useState("")
  const { notify } = useSnack();
  const { currentUserRole } = useApp();
  const formRef = useRef();

  const handleCreate = async (search) => {
    try {
      const newTag = await createTag({name: search, scope: 'Trip'});
      notify(t('library.createSuccess'));
      reloadTags();
      formRef.current.values.tags.push({label: search, value: newTag.data.id})
    } catch (error) {
      notify(t('library.createError'), { type: 'error' });
    }
  }

  const handleSubmit = async values => {
    try {
      await saveTags({values: values.tags?.map((tag) => tag.value), tripId: '' + trip.id})
      await reload();
      notify(t('library.editSuccess'));
    } catch (error) {
      notify(t('library.editError'), { type: 'error' });
    }
  }


  const PopoverFooter = () => {
    return (
      <div className='flex flex-col w-full'>
        <hr/>
        <div className='flex justify-between items-center py-4 px-8'>
          <p>{t('library.tags.currentSearch', {search: currentSearch})}</p>
          <KoobButton type='button' onClick={() => handleCreate(currentSearch)}>
            {t('library.tags.create')}
          </KoobButton>
        </div>
      </div>
    )
  }

  return (
    <KoobModal
      open={open}
      setOpen={() => onClose}
      widthClass="max-w-2xl"
      overflow='visible'
    >
      <KoobTitle>
        {t('library.tags.title')}
      </KoobTitle>
      <KoobParagraph>
        {t('library.tags.description')}
      </KoobParagraph>

      <div className="mt-8 mx-auto flex flex-col justify-center">
          <Formik
            initialValues={{
              tags:  trip?.tags?.map((tag) => ({label: tag.name, value: tag.id})) ?? []
            }}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            <Form>
              <div className='mb-16 mx-auto max-w-md'>
                <LocalMultiSearchableSelectField
                  label={t('library.tags.select')}
                  name="tags"
                  options={tags?.map((tag) => ({label: tag.name, value: tag.id})) ?? []}
                  renderOption={(option) => option.label}
                  renderSelectedOption={value => value.label}
                  popoverFooter={
                    !tags?.some((tag) => tag.name.toLowerCase() === currentSearch.toLowerCase())
                    && currentSearch.length !== 0
                    && currentUserRole === 'to_admin'
                    && <PopoverFooter />
                  }
                  setCurrentSearchValue={setCurrentSearch}
                  placeholder={t('library.tags.placeholder')}
                />
              </div>

              <div className="mt-5 flex space-x-3 justify-end">
                <KoobButton
                  type="button"
                  onClick={onClose}
                >
                  {t('misc:cancel')}
                </KoobButton>

                <KoobButton
                  type="submit"
                  onClick={onClose}
                >
                  {t('misc:save')}
                </KoobButton>
              </div>
            </Form>
          </Formik>
        </div>
    </KoobModal>
  )
}
