import styled, { createGlobalStyle } from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TOOLBARS = {
  complete: [
    ['bold', 'italic', 'underline', 'link'],
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],

    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ align: [] }],
  ],
};

const EditorReactQuill = styled(ReactQuill)`
  width: 100%;

  ${({ fillSpace }) =>
    fillSpace &&
    `
    flex: 1;
  `}
`;

const EditorReactStyle = createGlobalStyle`
  .ql-editor {
    border-radius: 0 0 4px 4px;
    line-height: 1.5;
    border: 1px solid #ffffff;
    min-height: 6em;
    cursor: text;
    max-height: 20em;
    overflow-y: auto;
    background-color: #ffffff;
    ${({ fillHeight }) => fillHeight && `height: 38px;`}

    ${({ customHeight }) =>
    customHeight > 0 &&
      `height: ${customHeight}px;`}  
  }
`;


const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'code-block',
  'link', 'image',
];


const Editor = ({
  value,
  fillSpace,
  variant = 'simple',
  onChange,
  disabled,
  enchanced,
  ...props
}) => {
  const modules = {"toolbar": null}
  const enchancedText = {
    toolbar: TOOLBARS[variant],
    clipboard: {
      matchVisual: false,
    },
  }
  return (
    <>
      <EditorReactStyle fillHeight={props.fillHeight} customHeight={props.customHeight}/>
      <EditorReactQuill
        modules={!enchanced ? modules : enchancedText}
        theme="snow"
        value={value || ''}
        onChange={onChange}
        readOnly={disabled}
        fillSpace={fillSpace}
        formats={formats}
        fillHeight={props.fillHeight}
        placeholder={props.placeholder}
      />
    </>
  );
};

export default Editor;
