import { times } from 'lodash';
import { Stack, ButtonReset } from '@koob/margaret';
import { MdStar } from 'react-icons/md';
import styled, { useTheme } from 'styled-components';
import {IcCancel} from '../icons';

const StarButton = styled(ButtonReset)`
  ${({ onClick }) =>
    !Boolean(onClick) &&
    `
      cursor: default;
    `}
`;

const StarsPicker = ({
  value,
  onChange,
  max = 5,
  shouldShowInactiveStars = true,
  disabled,
  canceleable
}) => {
  const theme = useTheme();
  const maxShown = shouldShowInactiveStars ? max : value;

  return (
    <Stack>
      {times(maxShown, index => (
        <StarButton
          type="button"
          onClick={
            Boolean(onChange) && !disabled ? () => onChange(index + 1) : null
          }
          key={index}
          as={!Boolean(onChange) && disabled ? 'span' : null}
        >
          <MdStar
            size={24}
            color={value >= index + 1 ? theme.yellow : theme.textLighter}
          />
        </StarButton>
      ))}
      {
        canceleable && <IcCancel size={24} onClick={() => onChange(null)} style={{marginLeft: 5}}/>
      }
    </Stack>
  );
};

export default StarsPicker;
