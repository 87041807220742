const {Stack} = require('@koob/margaret');
import { HotelTag, LigneHorizontal, SubSectionTitle, SubSectionTitleContainer } from 'components/Hotel/HotelDetails/Base';
import KoobDropdownListDescription from './KoobDropdownListDescription';

const KoobAccordeonBlocComponent = ({accordeonDetail, tags, title, variant}) => {

  const details = (accordeonDetail || []).filter((elem) => !!elem.content);
  return (
    <>
      <div>
        {(details?.length > 0 || (tags ?? []).length > 0) &&
          <>
            {variant !== "booking" && <LigneHorizontal />}

            <SubSectionTitleContainer>
              <Stack size="full" direction="column">
                <Stack size="full" style={{flexWrap: 'wrap', paddingBottom: '15px'}}>
                  <div className="flex-1">
                    <SubSectionTitle>
                      {title}
                    </SubSectionTitle>
                  </div>
                  {(tags || []).map((tag, index) => (
                    <div className="rounded-full py-1 mr-2 flex-none" key={index}>
                      <HotelTag color="orange">
                        <div className="text-xs font-extrabold uppercase leading-none m-0">
                          {tag}
                        </div>
                      </HotelTag>
                    </div>
                  ))}
                </Stack>

                {details.map((item, index) => (
                  <KoobDropdownListDescription
                    key={index}
                    description={item}
                    variant={variant}
                  />
                ))}

                {variant !== "booking" && <LigneHorizontal />}
              </Stack>
            </SubSectionTitleContainer>
          </>
        }
      </div>
    </>
  )
}
export default KoobAccordeonBlocComponent;
