export default function ExportDayPointsOfInterestList({ pois }) {
  return (
    <ul className="flex-col space-y-3">
      {pois?.map((poi, index) => (
        <li key={index}>
          &bull; {poi?.name}
        </li>
      ))}
    </ul>
  )
}
