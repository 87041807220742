import { useParams } from 'react-router-dom';
import { useAsync } from 'react-async';
import { getTrip } from '../../../api/trip';
import ComposeChatTrip from '../../TripDesigner/Compose/Partials/ComposeChatTrip';
import { Formik } from 'formik';
import KoobLayout from '../../../components/Koob/KoobLayout';
import KoobBackButton from '../../../components/Koob/KoobBackButton';
import KoobTitle from '../../../components/Koob/KoobTitle';
import React from 'react';
import KoobContainer from '../../../components/Koob/KoobContainer';

export default function TripChat() {
  const { tripId } = useParams();

  const { data: tripData, reload } = useAsync({
    promiseFn: getTrip,
    tripId,
  });
  const trip = tripData?.data;

  return (
    <KoobLayout>
      <KoobContainer>
        <div className="mt-5 flex items-center space-x-5 px-9">
          <KoobBackButton/>

          <KoobTitle>{trip?.name}</KoobTitle>
        </div>

        <Formik
          initialValues={trip ?? {}}
          onSubmit={() => {
          }}
        >
          <ComposeChatTrip
            tripId={tripId}
            reloadTrip={reload}
            trip={trip}
          />
        </Formik>
      </KoobContainer>
    </KoobLayout>
  )
}
