import { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { MdKeyboardArrowDown, MdSearch } from 'react-icons/md';
import { isEqual } from 'lodash';
import { Dropdown, Stack } from '@koob/margaret';
import {
  PopoverContainer,
  PopoverMenu as RawPopoverMenu,
  PopoverItem,
  PopoverItemButton,
} from '@koob/margaret';
import Scrollbars from 'react-custom-scrollbars';
import { useDeepCompareEffect } from 'react-use';

const PopoverMenuInner = styled(Scrollbars)``;

const PopoverMenu = styled(RawPopoverMenu)`
  max-height: none;
  overflow-y: initial;
`;

const PopoverFooter = styled(Stack)``;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 8px;
  line-height: 15px;
  min-width: 176px;
  width: 100%;
  min-height: 36px;
  position: relative;
  transition: border-color 100ms ease;

  ${({ disabled }) =>
  Boolean(disabled) &&
  css`
      cursor: not-allowed;
      background-color: ${({ theme }) => theme.disabled};
      color: #6d6d6d;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${({ hasError }) =>
  hasError &&
  css`
      &,
      &:hover,
      &:active {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

const PlaceHolderLabel = styled.div`
  color: ${({ theme }) => theme.placeholderColor};
  white-space: nowrap;

  ${({ disabled }) =>
  disabled &&
  `
      color: #6d6d6d;
    `}

  ${({ variant }) =>
  variant === 'transparent' &&
  ` color: #ffffff;
      font-style: none;
      `}
`;

const SearchInput = styled.input`
  outline: none;
  border: 0;
  width: 100%;
`;

const SearchableSelect = ({
                            placeholder,
                            onChange,
                            onAfterChange,
                            disabled,
                            wrapperStyle,
                            value,
                            isSearch,
                            datas,
                            popoverFooter,
                            renderOption,
                            renderSelectedOption,
                            emptyState,
                            hasError,
                          }) => {
  const dropdownRef = useRef();
  const popoverRef = useRef();
  const inputRef = useRef();
  const [filterOptions, setFilterOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState();
  const [search, setSearch] = useState('');

  const options = (isSearch && search.length === 0 ? [] : datas)
    .filter(data => data.value !== 60)
    .map(filteredData => filteredData);

  useEffect(() => {
    setLoading(true);
    setFilterOptions(options);
    setLoading(false);
  }, [options.length]);

  const filterOption = items => {
    setFilterOptions(() =>
      items.filter(item =>
        item?.label
          ? item?.label
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .includes(search.toLowerCase())
          : '',
      ),
    );
  };

  useEffect(() => {
    if (search.length && filterOptions.length) {
      filterOption(filterOptions);
    } else if (search.length === 0) {
      setFilterOptions(() => options);
    }
  }, [search]);

  const handleChange = value => {
    onChange(value);

    if (Boolean(onAfterChange)) {
      onAfterChange(value);
    }
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  useDeepCompareEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ value }]);

  useEffect(() => {
    setSearch('');

    if (!isOpen) {
      return;
    }

    inputRef.current.focus();
  }, [isOpen]);

  return (
    <Dropdown
      disabled={disabled}
      ref={dropdownRef}
      wrapperStyle={wrapperStyle}
      onToggle={setIsOpen}
      trigger={
        <Trigger disabled={disabled} hasError={hasError}>
          {isOpen ? (
            <>
              <SearchInput
                ref={inputRef}
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder={
                  Boolean(value) ? renderSelectedOption(value) : placeholder
                }
              />
              {isSearch && <MdSearch size={24} />}
            </>
          ) : (
            <>
              <PlaceHolderLabel disabled={disabled}>
                {Boolean(value) ? renderSelectedOption(value) : placeholder}
              </PlaceHolderLabel>
              {isSearch ? (
                <MdSearch size={24} />
              ) : (
                <MdKeyboardArrowDown size={24} />
              )}
            </>
          )}
        </Trigger>
      }
    >
      <PopoverContainer style={{ maxHeight: '100px' }}>
        <PopoverMenu>
          <PopoverMenuInner ref={popoverRef} autoHeight autoHeightMax={250}>
            {filterOptions.map((option, index) => (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => handleChange(option)}
                  isActive={isEqual(value, option)}
                >
                  {renderOption(option)}
                </PopoverItemButton>
              </PopoverItem>
            ))}
          </PopoverMenuInner>

          {((Boolean(popoverFooter) && !Boolean(emptyState)) ||
            (Boolean(emptyState) && !loading && options.length === 0)) && (
            <PopoverFooter>{popoverFooter}</PopoverFooter>
          )}
        </PopoverMenu>
      </PopoverContainer>
    </Dropdown>
  );
};

SearchableSelect.defaultProps = {
  placeholder: '',
  options: [],
  excludedIds: [],
};

export default SearchableSelect;
