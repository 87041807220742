import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {LigneHorizontal, SubSectionTitle, SubSectionTitleContainer} from "./Base";

const TitleText = styled.span`
  font-weight: bold;
  font-size: 15px;
`;

const MeetingRooms = ({hotel}) => {
  const {t} = useTranslation('hotel');
  const meetingRooms = hotel?.meetingRooms.filter((elem) => elem.displayName) || [];
  return (
    <>
      {meetingRooms.length > 0 &&
        <div className="my-6">
          <SubSectionTitleContainer>
            <SubSectionTitle>{t('meetingRooms')}</SubSectionTitle>
          </SubSectionTitleContainer>


          <div className="mt-4 grid grid-cols-2 gap-4">
            {meetingRooms.map((meetingRoom, index) => (
              <div className="bg-white rounded-lg shadow-k" key={index}>
                <div className="py-4 px-6">
                  <TitleText>{meetingRoom?.displayName}</TitleText>

                  {(meetingRoom?.meetingRoomFacilities || []).map((data, ind) => (
                    <div key={ind}>
                      <span>- {data.facility.displayName}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <LigneHorizontal/>
        </div>
      }
    </>
  )
}

export default MeetingRooms;
